



















































































































































import Utils from '@/ts/util/Utils';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class Home extends Vue {
    isLoaded = false;
    numVersions = 0;
    latestVersion = '';
    lastUpdate = '';

    async mounted() {
        let markdown = (await import(`@/data/info/${'changelog'}.md`)).default;
        let lines: string[] = markdown.split('\n');
        let versions = lines.filter(x => x.trim().startsWith('###')).map(x => Utils.trim(x, '#'));

        this.numVersions = versions.filter(x => !x.includes('Pre-Launch')).length;

        let latest = versions.shift();
        if (latest == null) {
            this.latestVersion = '';
            this.lastUpdate = '';
        }
        else {
            let split = latest.split('(');
            this.latestVersion = Utils.trim(split[0].trim(), 'V');
            this.lastUpdate = (split.length == 1) ? '' : Utils.trim(split[1].trim(), '() ');
        }

        this.isLoaded = true;
    }
}
