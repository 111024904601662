import Vue from 'vue';
import App from './App.vue';
import router from './router';

// PrimeVue & PrimeIcon stylesheets
import '@/assets/css/theme_override.css'; // import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

// Other Stylesheets
import '@/assets/css/parchment_styles.css';
import '@/assets/css/transitions.css';
import '@/assets/css/loader.css';
import '@/assets/css/styles.css';
import 'animate.css'

// Custom Components
import StringListEditor from '@/ui/_global_components/StringListEditor.vue';
import PageFrame from '@/ui/_components/page_layout/PageFrame.vue';
import ImageView from '@/ui/_components/page_layout/ImageView.vue';
import Loader from '@/ui/_components/page_layout/Loader.vue';
import Footer from '@/ui/_components/page_layout/Footer.vue';

Vue.component('StringListEditor', StringListEditor);
Vue.component('PageFrame', PageFrame);
Vue.component('ImageView', ImageView);
Vue.component('Loader', Loader);
Vue.component('Footer', Footer);

import BlockDisplay from '@/ui/Gamebooks/block_components/BlockDisplay.vue';
Vue.component('BlockDisplay', BlockDisplay);

// PrimeVue Directives
import Tooltip from 'primevue/tooltip';
Vue.directive('tooltip', Tooltip);

import ToastService from 'primevue/toastservice';
Vue.use(ToastService);

import VDragged from 'v-dragged';
Vue.use(VDragged);

// PrimeVue Components
import Tree from 'primevue/tree';
import Toast from 'primevue/toast';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Slider from 'primevue/slider';
import Dialog from 'primevue/dialog';
import Rating from 'primevue/rating';
import Menubar from 'primevue/menubar';
import Listbox from 'primevue/listbox';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import TreeTable from 'primevue/treetable';
import Accordion from 'primevue/accordion';
import Paginator from 'primevue/paginator';
import DataTable from 'primevue/datatable';
import ColorPicker from 'primevue/colorpicker';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import ContextMenu from 'primevue/contextmenu';
import OverlayPanel from 'primevue/overlaypanel';
import AccordionTab from 'primevue/accordiontab';
import SelectButton from 'primevue/selectbutton';
import DeferredContent from 'primevue/deferredcontent';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import Panel from '@/ui/_components/utils/Panel.vue';
import NumInput from '@/ui/_components/utils/NumInput.vue'; // This is not a primevue component

Vue.component('Tree', Tree);
Vue.component('Toast', Toast);
Vue.component('Chips', Chips);
Vue.component('Panel', Panel);
Vue.component('NumInput', NumInput);
Vue.component('Column', Column);
Vue.component('Button', Button);
Vue.component('Slider', Slider);
Vue.component('Rating', Rating);
Vue.component('Dialog', Dialog);
Vue.component('Menubar', Menubar);
Vue.component('Listbox', Listbox);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('Dropdown', Dropdown);
Vue.component('Checkbox', Checkbox);
Vue.component('Textarea', Textarea);
Vue.component('Accordion', Accordion);
Vue.component('Paginator', Paginator);
Vue.component('DataTable', DataTable);
Vue.component('TreeTable', TreeTable);
Vue.component('InputText', InputText);
Vue.component('ColorPicker', ColorPicker);
Vue.component('InputSwitch', InputSwitch);
Vue.component('InputNumber', InputNumber);
Vue.component('ContextMenu', ContextMenu);
Vue.component('AccordionTab', AccordionTab);
Vue.component('SelectButton', SelectButton);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('DeferredContent', DeferredContent);
Vue.component('TriStateCheckbox', TriStateCheckbox);


// Other Component Imports
import VueMarkdown from 'vue-markdown';
Vue.component('VueMarkdown', VueMarkdown);

// Router setup
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to?.meta?.title || "Artemis";
    });
});

// Analytics setup
import VueAnalytics from 'vue-analytics'

let hostname = window?.location?.hostname;
if (hostname?.includes('artemis-tabletop')) {
    Vue.use(VueAnalytics, {
        id: 'UA-190592096-1',
        router
    });
}
else {
    console.warn('Excluding analytics for hostname: ' + hostname, window.location);
}


// Root configuration
Vue.config.productionTip = false;

Vue.prototype.$fire = function(eventName: string, ...args: any[]) {
    let target: any = this.$parent;
    while (target != null) {
        if (target.__evHandler != null) {
            let handled = target.__evHandler(eventName, ...args);
            if (handled) break;
        }

        target = target.$parent;
    }
}

Vue.prototype.$toggleContextMenu = function(event: any, items: any[]) {
    let target: any = this;
    while (target != null) {
        if (target.toggleContextMenu != null) {
            let handled = target.toggleContextMenu(event, items);
            if (handled) break;
        }

        target = target.$parent;
    }
}

Vue.prototype.$copy = function(value: string, toast=true) {
    let element = document.createElement('textarea');
    document.body.appendChild(element);
    element.value = value;
    element.select();
    element.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
    element.remove();

    if (toast) {
        root.$toast.add({severity:'info', summary: 'Copied!', life: 500});
    }
}

Vue.prototype.$parchment = function(index=0) {
    let list = ['parchment-a', 'parchment-b', 'parchment-c', 'parchment-d', 'parchment-e'];
    return list[index % list.length];
}


// Data imports
import CharacterNode from '@/ts/calcengine/CharacterNode';
import Toaster from '@/ts/ui_integrations/Toaster';
import { ContentManager, ContentTemplate } from '@/ts/api/content/_index';
import { InfoManager } from '@/ts/api/info/_index';
import { Grant } from '@/ts/api/grants/_index';

import { TemplateOrGroup } from './ts/util/loregen2/Templates';
import { LoreGenDAO } from './ts/api/loregen/LoreGenDAO';
import { FilterSpec, CatFactory } from './ts/util/filters/_index';
import LoreGen from './ts/util/loregen/LoreGen';

import BestiaryFilterFactory from './ts/api/bestiary/BestiaryFilterFactory';
import SpellFilterFactory from './ts/api/spells/SpellFilterFactory';
import ItemFilterFactory from './ts/api/items/ItemFilterFactory';
import BestiaryEntry from '@/ts/api/bestiary/BestiaryEntry';
import SpellEntry from './ts/api/spells/SpellEntry';
import ItemEntry from './ts/api/items/ItemEntry';

import { BestiaryDAO } from '@/ts/api/bestiary/BestiaryDAO';
import { StringMap } from '@/ts/api/sharedModels/Types';
import { SpellDAO } from './ts/api/spells/SpellDAO';
import { ItemDAO } from './ts/api/items/ItemDAO';
import Utils from './ts/util/Utils';
import { RoadmapEntry } from './ts/api/roadmap/RoadmapEntry';
import { RoadmapDAO } from './ts/api/roadmap/RoadmapDAO';

type CharacterMap = {[key: string]: CharacterNode};

function loadSearchDataAsync<T>(
    root: any,
    key: string,
    getIndexAsync: () => Promise<T[]>,
    getFilters: (list: T[], useFilterCache?: boolean) => FilterSpec<T>,
    fillAsync: (list: T[]) => Promise<T[]>,
    redownload = false)
{
    getIndexAsync().then(async list => {
        Vue.set(root, key, list);
        if (!redownload) {
            root.filters[key] = getFilters(root[key]);
            root.loadingStatus[key] = false;
        }

        setTimeout(async () => {
            await fillAsync(root[key]);
            if (redownload) {
                root.filters[key] = getFilters(root[key], false);
                root.loadingStatus[key] = false;
                let catName = key.endsWith('s') ? key.slice(0, key.length - 1) : key;
                CatFactory.downloadFilterCache(root.filters[key], catName);
            }
        }, 10);
    }).catch(err => console.error(err));
}

let root = new Vue({
    router,
    render: h => h(App),
    created() {
        let content = this.content;
        /* TODO:
        window.onbeforeunload = function() {
            if (content.editMarks.length > 0) {
                content.save(true);
                return true;
            }
        };
        */

        if (this.$ga) {
            let $this = this;
            setInterval(function () {
                $this.$ga.event({
                    eventCategory: 'utility',
                    eventAction: 'checkin',
                });
            }.bind(this), 3*60*1000); // Send checkin events (to keep session analytics alive) every 3 minutes 
        }
    },
    mounted: function () {
        // Initialize Data Managers
        this.content.init().then(x => console.log('Content loaded')).catch(err => console.error(err));
        this.infoManager.init().then(x => console.log('Info loaded')).catch(err => console.error(err));

        loadSearchDataAsync(this, 'bestiary', BestiaryDAO.getIndex, BestiaryFilterFactory.get, BestiaryDAO.fill);
        loadSearchDataAsync(this, 'spells', SpellDAO.getIndex, SpellFilterFactory.get, SpellDAO.fill);
        loadSearchDataAsync(this, 'items', ItemDAO.getIndex, ItemFilterFactory.get, ItemDAO.fill);
        
        let $this = this;
        LoreGenDAO.getAll().then((x: LoreGen) => {
            $this.loreGen.templates.push(...x.templates);
            $this.loadingStatus.loreTemplates = false;
            console.log('LoreTemplates loaded');
        }).catch(err => console.error(err));
        LoreGenDAO.getAll2().then((x: TemplateOrGroup[]) => {
            $this.generatorTemplates.push(...x);
            $this.loadingStatus.loreTemplates2 = false;
            console.log('LoreTemplates2 loaded');
        }).catch(err => console.error(err));
        RoadmapDAO.getCurrent().then((x: RoadmapEntry[]) => {
            $this.roadmapPhases.current.push(...x);
            $this.loadingStatus.roadmapCurrent = false;
            console.log('Roadmap loaded');
        }).catch(err => console.error(err));
        RoadmapDAO.getNext().then((x: RoadmapEntry[]) => {
            $this.roadmapPhases.next.push(...x);
            $this.loadingStatus.roadmapNext = false;
            console.log('Roadmap loaded');
        }).catch(err => console.error(err));
        BestiaryDAO.getPolymorphDescriptions().then((x: StringMap) => {
            $this.polymorphDescriptions = x;
            $this.loadingStatus.polymorphDescriptions = false;
            console.log('PolymorphDescriptions loaded');
        }).catch(err => console.error(err));

        // TODO: Setup autosave
        /*
        let content = this.content;
        window.setInterval(() => {
            content.save(true);
        }, 1000 * 60);
        */

        let width1 = window?.innerWidth ?? 0;
        let width2 = document?.documentElement?.clientWidth ?? 0;
        this.windowWidth = Math.max(width1, width2);
        
        window.addEventListener('resize', () => {
            let width1 = window?.innerWidth ?? 0;
            let width2 = document?.documentElement?.clientWidth ?? 0;
            this.windowWidth = Math.max(width1, width2);
        });
    },
    data() {
        let toaster = new Toaster(this.$toast.add);
        return {
            windowWidth: -1,

            bestiary: [] as BestiaryEntry[],
            spells: [] as SpellEntry[],
            items: [] as ItemEntry[],

            polymorphDescriptions: {} as StringMap,

            generatorTemplates: [] as TemplateOrGroup[],
            roadmapPhases: {
                current: [] as RoadmapEntry[],
                next: [] as RoadmapEntry[],
            },
            loreGen: new LoreGen(toaster),

            filters: { // TODO: rename to filterSpecs
                bestiary: null as FilterSpec<BestiaryEntry>|null,
                spells: null as FilterSpec<SpellEntry>|null,
                items: null as FilterSpec<ItemEntry>|null,
            },
            loadingStatus: {
                loreTemplates2: true,
                loreTemplates: true,
                roadmapCurrent: true,
                roadmapNext: true,
                polymorphDescriptions: true,

                bestiary: true,
                spells: true,
                items: true,
            },

            content: new ContentManager(toaster),
            infoManager: new InfoManager(),

            characterNodes: {} as CharacterMap
        }
    },
    methods: {
        onWindowClose(event: any) {
            // TODO: this.content.save();
        },
        getColor(content: ContentTemplate|Grant): string {
            const COMPLETE_COLOR = 'green';
            if (content instanceof ContentTemplate) {
                let requiredComps: {[key: string]: string[]} = {
                    'race': ['meta', 'children', 'linkIsCorrect'],
                    'feat': ['meta', 'grants', 'linkIsCorrect'],
                    'class': ['meta', 'children', 'linkIsCorrect', 'classSkills', 'proficiencies'],
                    'racialTrait': ['meta', 'grants'],
                    'classFeature': ['meta', 'grants']
                };
    
                let reqs = requiredComps[content.contentType];
                for (let req of reqs) {
                    if (!(content._completionStatus as any)[req]) {
                        return 'red';
                    }
                }
            }
            else if (!content._completionStatus) {
                return 'red';
            }
    
            if ('children' in content) {  
                for (let child of Object.values(content.children)) {
                    if (this.getColor(child) != COMPLETE_COLOR) {
                        return 'GoldenRod'
                    }
                }
            }
            if ('grants' in content) {
                for (let grant of Object.values(content.grants)) {
                    if (this.getColor(grant) != COMPLETE_COLOR) {
                        return 'GoldenRod'
                    }
                }
            }
            return COMPLETE_COLOR;
        }
    }
});

root.$mount('#app');
