type Dynamic = {[key:string]: any};

export default class SanityCheck {
    static notNull<T>(obj: T|null|undefined, message = 'Expected non-null value'): T {
        console.assert(obj != null, 'Sanity Check Failed: ' + message);
        return obj as T;
    }

    static guaranteeShift<T>(obj: T|null|undefined): T {
        return SanityCheck.notNull(obj, 'Shift from non-empty array should not produce null value');
    }

    static warnIf(condition: boolean, ...data: any[]){
        if (condition) {
            console.warn(...data);
        }
    }

    static objFieldCompare(expected: Dynamic, actual: Dynamic, constructorName: string) {
        let expectedKeys = Object.keys(expected);
        let actualKeys = Object.keys(actual);
        let unexpectedKeys = actualKeys.filter(x => !expectedKeys.includes(x));
        let unexpectedValues = {} as Dynamic;
        unexpectedKeys.forEach(k => unexpectedValues[k] = actual[k]);

        if (unexpectedKeys.length > 0) {
            console.warn(`Unexpected keys found during instantiation of ${constructorName}`, {expectedKeys, unexpectedKeys, unexpectedValues, actual});
        }
    }
}