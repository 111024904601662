import {Stat} from '@/ts/api/characters/_index';
import {Info} from '@/ts/api/info/_index';

export class BonusInfo extends Info {
    readonly defaultBonusType: string;

    constructor(json: any, path: string) {
        json = json ?? {};
        super(json, path);

        this.defaultBonusType = json.defaultBonusType ?? "";
    }

    // TODO: Make this a bonus
    create(): Stat {
        return new Stat({
            refName: this.refName,
            value: {
                type: this.defaultValueType,
                mode: this.defaultValueMode
            }
        });
    }
}