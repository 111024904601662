


















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { RangeSetting } from '@/ts/util/config/Setting';

@Component
export default class RangeSettingPanel extends Vue {
    @Prop({required: true})
    setting!: RangeSetting;
}
