


























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { CategoryConfig, Filter, Option } from "@/ts/util/filters/_index";
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';
import TristateFilterList from '@/ui/_components/config/filters/TristateFilterList.vue';
import BistateFilterList from '@/ui/_components/config/filters/BistateFilterList.vue';

type OptionGroup = {
    name: string,
    description?: string,
    getKeys: (options: Option[]) => (string|number)[]
};

@Component({
    components: {
        ConfigElementPanel,
        TristateFilterList,
        BistateFilterList,
    }
})
export default class ListFilterPanel extends Vue {
    @Prop({required: true})
    filter!: Filter<any>;

    @Prop({required: true})
    rootList!: any[];

    @Prop({default: false})
    expandedView!: boolean;

    @Prop({default: false})
    fullView!: boolean;

    isCollapsed = !(this.expandedView || this.fullView);
    initialLoad = false;
    finalLoad = false;

    // TODO: This doesn't do anything (?) remove it?
    @Watch('expandedView')
    updateCollapsed() {
        if (this.expandedView) this.isCollapsed = false;
    }
    
    @Watch('isCollapsed')
    load() {
        if (!this.isCollapsed) {
            let $this = this;
            $this.initialLoad = true;
            let waitTime = this.numOptions < 400 ? 0 : this.numOptions;
            setTimeout(() => {
                $this.finalLoad = true;
            }, Math.min(2500, waitTime));
        }
    }

    mounted() {
        this.load()
    }

    get isTristate() {
        return this.filter.isTriStateList;
    }

    get panelClasses() {
        let classes = [];
        if (this.fullView) classes.push('fullView');
        if (this.expandedView) classes.push('expandedView');

        return classes.join(' ');
    }

    get showOptionFilter() {
        let len = this.config.options?.length ?? 0;
        return len > 20;
    }

    get andOrHoverText() {
        return 'Filter Mode\n\n*Or: will include elements that match one or more of the selected options.\n\n*And: will only include elements that match all selected options.\n\nIf no options are selected, this filter will be disregarded.';
    }

    get hasSettings() {
        return !this.filter.hideFilterToggle || this.toggleGroups.length > 0;
    }

    get toggleGroups() {
        return this.filter.toggleGroups ?? [];
    }

    get settings() {
        if (this.filter?.isListFilter) {
            let config = this.filter.categoryConfig;
            let settings = Object.values(config.settings);
            return settings;
        }
        else return [];
    }

    get isActive() {
        return this.filter.isActive;
    }

    get config(): CategoryConfig<any> {
        return this.filter.config as CategoryConfig<any>;
    }

    get numOptions(): number {
        return this.filter.categoryConfig.options?.length ?? 0;
    }

    set(setting: OptionGroup, value: boolean|null) {
        let keys = setting.getKeys(this.config.options ?? []);
        if (this.filter.isTriStateList) {
            for (let key of keys) {
                this.filter.triStateOptions[key] = value;
            }
        }
        else {
            let all = this.filter.categoryConfig.options ?? [];
            let current = this.filter.selectedOptions ?? [];
            let currentKeys = current.map(x => x.key);

            if (value) {
                // Select all unselected matches
                let newOptions = all.filter(x => keys.includes(x.key) || currentKeys.includes(x.key));
                this.filter.selectedOptions.splice(0, current.length, ...newOptions);
            }
            else {
                // Unselect all currently selected matches
                let newOptions = current.filter(x => !keys.includes(x.key));
                this.filter.selectedOptions.splice(0, current.length, ...newOptions);
            }
        }
    }

    toggleOverlay(event: any) {
        let overlay: any = this.$refs.settingsOverlay;
        overlay?.toggle(event);
    }
}
