export class UserSettings {
    static MinColumnWidth = 332;

    customWidth = 1296;
    columnWidth = ColumnWidths.Wide;
    statDisplay = StatDisplayType.NotesTabBottom;
    sidebarDisplay = SidebarDisplayType.DualSidebar;

    get isCustomWidth() {
        return this.columnWidth == ColumnWidths.Custom;
    }

    get useColumnWidth() {
        return this.columnWidth != ColumnWidths.Full;
    }

    get maxColumnWidth() {
        if (this.columnWidth == ColumnWidths.Custom) return Math.max(this.customWidth, UserSettings.MinColumnWidth);
        else if (this.columnWidth == ColumnWidths.Wide) return 1296;
        else if (this.columnWidth == ColumnWidths.Narrow) return 750;
        else return -1;
    }

    get showStatsTop() {
        return this.statDisplay == StatDisplayType.Top;
    }

    get showStatsBottom() {
        return this.statDisplay == StatDisplayType.Bottom;
    }

    get showStatsInNotesAtTop() {
        return this.statDisplay == StatDisplayType.NotesTabTop;
    }

    get showStatsInNotesAtBottom() {
        return this.statDisplay == StatDisplayType.NotesTabBottom;
    }

    get showStatsTab() {
        return this.statDisplay == StatDisplayType.StatsTab;
    }

    get padLeft() {
        return this.sidebarDisplay == SidebarDisplayType.RightPadded;
    }

    get padRight() {
        return this.sidebarDisplay == SidebarDisplayType.LeftPadded;
    }

    get showDiceTab() {
        return this.sidebarDisplay != SidebarDisplayType.DualSidebar;
    }

    get showLeftSidebar() {
        return [SidebarDisplayType.LeftPadded, SidebarDisplayType.LeftStretch, SidebarDisplayType.DualSidebar].includes(this.sidebarDisplay);
    }

    get showRightSidebar() {
        return [SidebarDisplayType.RightPadded, SidebarDisplayType.RightStretch, SidebarDisplayType.DualSidebar].includes(this.sidebarDisplay);
    }
}

export enum ColumnWidths {
    Narrow = 'Narrow',
    Wide = 'Wide',
    Full = 'Full',
    Custom = 'Custom',
}

export enum StatDisplayType {
    Top = 'On Top',
    Bottom = 'On Bottom',
    StatsTab = 'In Stats Tab',
    NotesTabTop = 'Top of Notes',
    NotesTabBottom = 'Bottom of Notes',
    CombatOnly = 'Combat Only',
}

export enum SidebarDisplayType {
    LeftPadded,
    RightPadded,
    LeftStretch,
    RightStretch,
    DualSidebar,
}