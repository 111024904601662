














import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import AdvancedSearch from '@/ui/AdvancedSearch/AdvancedSearch.vue';
import TableResults from '@/ui/AdvancedSearch/TableResults.vue';
import CardResults from '@/ui/AdvancedSearch/CardResults.vue';

import { CardResultsConfig } from '@/ui/AdvancedSearch/CardResultsConfig';
import ItemEntry, { ItemDisplays, MdTag } from '@/ts/api/items/ItemEntry';
import { SmallMode, TableConfig } from './TableConfig';
import { Format } from '@/ts/util/Format';

@Component({
    components: {
        AdvancedSearch,
        TableResults,
        CardResults,
    }
})
export default class AdvancedItemSearch extends Vue {
    cardConfig(big: boolean): CardResultsConfig<ItemEntry> {
        return {
            big,
            sortAlgorithms: ItemDisplays.sortAlgorithms,
            getConfig: ItemDisplays.getCardConfig,
        };
    }

    tableConfig(): TableConfig<ItemEntry> {
        return {
            getSearchTerm: ItemDisplays.searchTerm,
            getConfig: ItemDisplays.getCardConfig,
            getLinks: item => item.links,
            columns: [
                {
                    name: "Name",
                    field: "name",
                    smMode: SmallMode.Default,
                    display: entry => entry.name,
                    isSortable: true,
                },
                {
                    name: "Category",
                    field: "itemCategory",
                    smMode: SmallMode.Hide,
                    display(entry: ItemEntry) {
                        return entry.itemCategory;
                    },
                    isSortable: true,
                },
                {
                    name: "Cost",
                    field: "cost",
                    smMode: SmallMode.Default,
                    display(entry: ItemEntry) {
                        return formatGP(entry.cost);
                    },
                    isSortable: true,
                },
                {
                    name: "Weight",
                    field: "weight",
                    smMode: SmallMode.Hide,
                    display(entry: ItemEntry) {
                        return formatWeight(entry.weight);
                    },
                    isSortable: true,
                },
                {
                    name: "Tags",
                    field: "",
                    smMode: SmallMode.Hide,
                    display(entry: ItemEntry) {
                        return formatTagList(entry.tags);
                    },
                    isMD: true,
                },
            ],
        };
    }
}

function formatTagList(tags: MdTag[]) {
    return tags?.map(x => x.name).join('; \n\n') ?? '';
}

function formatGP(gp: string|number) {
    return typeof gp == 'number' ? Format.currency(gp, 'gp', true).replace(/^0 .p/g, '').trim() : gp;
}

function formatWeight(weight: string|number) {
    if (typeof weight == 'number') {
        let unit = weight == 1 ? 'lb' : 'lbs';
        return `${weight} ${unit}`;
    }
    else return weight;
}
