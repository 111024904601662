import { ValueMode, ValueType } from '@/ts/api/characters/_index';

export class Info {
    longDescription = ""; // This is loaded asyncronously // TODO: This was renamed from 'md'; All references in .json files need to be updated
    shortDescription: string; // TODO: This was renamed from 'description'; All references in .json files need to be updated

    unit: string;
    refName: string;
    longName: string;
    shortName: string;
    groupName: string;
    
    readonly usePrefix: boolean;
    readonly defaultValueType: ValueType;
    readonly defaultValueMode: ValueMode;

    readonly infoType: string;
    readonly mdRef: string;
    readonly path: string;

    constructor(json: any, path: string) {
        json = json ?? {};

        this.unit = json.unit ?? "";
        this.refName = json.refName ?? "";
        this.longName = json.longName ?? "";
        this.shortName = json.shortName ?? "";
        this.groupName = json.groupName ?? "";
        this.shortDescription = json.shortDescription ?? "";

        this.usePrefix = json.usePrefix ?? false;
        this.defaultValueType = json.defaultValueType ?? ValueType.INT;
        this.defaultValueMode = json.defaultValueMode ?? ValueMode.DEFAULT;
        
        this.path = path;
        this.mdRef = json.mdRef ?? "";
        this.infoType = json.infoType ?? "";
    }
}