




































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { RoadmapEntry } from '@/ts/api/roadmap/RoadmapEntry'
import Toaster from '@/ts/ui_integrations/Toaster';

var icons = require.context('@/assets/icons/tools/v2', true, /\.svg$/);

@Component({
    components: {
    }
})
export default class PhaseDisplay extends Vue {
    @Prop({required: true})
    phase!: RoadmapEntry;
    
    @Prop({default: true})
    showComplete!: boolean;

    toaster = new Toaster(this.$toast.add);
    iconNames: any = {
        'Encounter Gen': 'adv_generator_encounter',
        'Shop Gen': 'adv_generator_shop',
        'Item Search': 'adv_search_items',
        'Monster Search': 'adv_search_monsters',
        'Spell Search': 'adv_search_spells'
    };

    icon(entry: RoadmapEntry) {
        let iconName = this.iconNames[entry?.icon ?? 'None'];

        if (iconName == undefined) return undefined;
        let fileName = `./${iconName}.svg`;
        try {
            return icons(fileName);
        }
        catch (error) {
            console.warn('Icon not found', {fileName, error});
            return undefined;
        }
    }
}
