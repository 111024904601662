import { Combat } from '@/ts/generators/CombatGenerator';
import { LootConfig } from '@/ts/generators/LootConfig';
import ItemEntry, { ItemCategory, ItemDisplays, ItemFields, SimpleCategories } from '@/ts/api/items/ItemEntry';
import Randomizer from "@/ts/util/Randomizer";
import Utils from '@/ts/util/Utils';
import { createModdedItem, Loot } from './Loot';
import SanityCheck from '../util/SanityCheck';
import { BestiaryFields } from '../api/bestiary/BestiaryEntry';
import { CategoryWeights } from './CategoryWeights';

export default class LootGenerator {
    random: Randomizer;

    constructor(seed: string) {
        this.random = new Randomizer(seed);
    }

    generate(encounter: Combat, config: LootConfig): Loot {
        let gen = new LootGen(encounter, config, this.random);
        let budget = gen.getBudget();
        let loot = new Loot();

        const exclude = (x: ItemEntry) => ['(per ', ' per ', 'slave'].some(y => x.name.includes(y)) || (x.itemCategory == ItemCategory.BlackMarket && x.name.toLowerCase().includes('slave'));
        let items = config.getFilteredList().filter(x => !exclude(x) && ItemFields.parsePrice(x) <= budget);
        let zeroCostItems = items.filter(x => ItemFields.parsePrice(x) <= 0 || ItemFields.parsePrice(x) == null);
        items = items.filter(x => ItemFields.parsePrice(x) > 0 && ItemFields.parsePrice(x) != null);

        let floorThreshold = budget / 5;
        let roundFilter = items.filter(x => ItemFields.parsePrice(x) >= floorThreshold);
        floorThreshold = Math.min(...roundFilter.map(x => ItemFields.parsePrice(x))); // TODO: optimize this

        let genIntItem = this.random.chance(config.intelligentChance.value ?? 0);
        while (roundFilter.length > 0 && budget > 0) {
            let item = this.weightedSelection(roundFilter, config.categoryWeights);

            if (genIntItem) { // TODO: Do not make consumables intelligent
                item = ItemWorkshop.makeIntelligent(item, this.random);
                genIntItem = false;
            }
            loot.items.push(item);

            budget -= ItemFields.parsePrice(item);
            if (budget < floorThreshold) {
                floorThreshold = 0;
                roundFilter = items;
            }

            roundFilter = roundFilter.filter(x => ItemFields.parsePrice(x) <= budget);
        }

        if (budget >= 0.01) {
            // TODO: Chance of random gems/art items?
            loot.coins.addRandomAssortment(budget);
        }

        loot.updateTotalValue();
        loot.items.sort((a, b) => ItemFields.parsePrice(b) - ItemFields.parsePrice(a));

        if (config.reduceCoins.value) loot.coins.reduce();
        return loot;
    }

    weightedSelection(list: ItemEntry[], categoryWeights: CategoryWeights): ItemEntry {
        if (categoryWeights.isEqual) return SanityCheck.notNull(this.random.selection(list));

        // TODO: Optimization: instead of seperating category lists every time an item is generated, seperate these lists once, and reduce each filter every time
        let catTable = categoryWeights.rollableTable
            .map(x => {
                return {weight: x.weight, text: list.filter(item => ItemFields.simpleCategories(item).includes(x.text))};
            })
            .filter(x => x.text.length > 0);
        let catList = this.random.randomize(catTable) ?? list;
        return this.random.gtSelection(catList);
    }
}

class LootGen {
    encounter: Combat;
    config: LootConfig;
    random: Randomizer;

    constructor(encounter: Combat, config: LootConfig, random: Randomizer) {
        this.encounter = encounter;
        this.random = random;
        this.config = config;
    }

    getBudget(): number {
        if (this.config.isRangeBudget) {
            return this.config.budgetRange.roll(this.random, HoardValues.nullAdjRange);
        }
        else {
            if (!this.config.isCRBudget) console.warn(`Unrecognized budgetMode [${this.config.budgetMode.selected.key}]`, this.config);

            let goldByCR = HoardValues.get(this.config.progression.selected.key ?? 'medium', this.encounter?.cr ?? this.config.budgetCR.value ?? this.random.int(1, 20));
            let adjustment = (this.random.int(0, 10) / 100) * (this.random.bool() ? -1 : 1);
            let magMultiplier = this.getMagnitudeMultiplier();
            let abpMultiplier = this.config.useABP.value ? 0.5 : 1;
            let genMultiplier = this.config.budgetMultiplier.value ?? 1;
            if (genMultiplier < 0) genMultiplier = Math.abs(genMultiplier);
            
            return Math.floor(goldByCR * (1+adjustment) * magMultiplier * abpMultiplier * genMultiplier);
        }
    }

    getMagnitudeMultiplier() {
        let random = this.random;
        let entries = this.encounter?.components?.map(x => x.element) ?? [];
        // TODO: Account for empty entries array (randomly select a magnitude)

        const array = ['none', 'incidental', 'standard', 'double', 'triple']; // TODO: Support 'half'
        let magnitudes = entries.map(x => {
            let choices = BestiaryFields.treasureMagnitudes(x);
            let selected = random.gtSelection(choices);
            return array.indexOf(selected);
        });
        let mult = Math.max(...magnitudes);

        if (mult < 0) {
            console.warn(`Negative multiplier`, entries, this.config);
            mult = 2; // standard
        }

        // Adjust for selected magnitudes
        let validMults = this.config.budgetMagnitude.selectedKeys.map(x => array.indexOf(x));
        if (!validMults.includes(mult)) {
            let closest = 100;
            for (let x of validMults) {
                let currentDist = Math.abs(mult-closest);
                let dist = Math.abs(mult-x);
                if (dist < currentDist) closest = x;
                else if (dist == currentDist && this.random.bool()) closest = x;
            }

            if (closest > array.length) mult = 2; // standard
            else mult = closest;
        }

        if (mult == 0) return 0;
        else if (mult <= 2) return mult/2;
        else return mult - 1;
    }
}

export class HoardValues {
    private static slowProgression = [20, 30, 40, 55, 85, 170, 350, 550, 750, 1000, 1350, 1750, 2200, 2850, 3650, 4650, 6000, 7750, 10000, 13000, 16500, 22000, 28000, 35000, 44000, 55000, 69000, 85000, 102000, 125000, 150000, 175000, 205000, 240000, 280000];
    private static fractionalCRStrings = ["1/8", "1/6", "1/4", "1/3", "1/2"];
    private static fractionalCRS = [1/8, 1/6, 1/4, 1/3, 1/2];

    static nullAdjRange = [HoardValues.slowProgression[0], HoardValues.slowProgression[HoardValues.slowProgression.length-1]];

    static get(progression: string, cr: number): number {
        let index = (cr < 1) ? HoardValues.fractionalCRS.indexOf(cr) : (cr+4);
        if (index < 0) {
            console.warn('Expected index for HoardValues to be non-negative', {cr, index});
            index = 5; // If the specified CR is not found, dedfault to CR 1.
        }
        else if (index > this.slowProgression.length) index = this.slowProgression.length - 1;

        let slowGP = this.slowProgression[index];
        if (progression == 'slow') return slowGP;
        else if (progression == 'fast') return Math.floor(slowGP*1.5*1.5);
        else {
            if (progression != 'medium') console.warn('Unexpected progression key: ' + progression);
            return Math.floor(slowGP*1.5);
        }
    }
}

export class ItemWorkshop {
    static makeIntelligent(base: ItemEntry, rnd: Randomizer) {
        const alignments = ['LG', 'LN', 'LE', 'NG', 'N', 'NE', 'CG', 'CN', 'CE'];
        const abilityPriceMods = [0, 200, 500, 700, 1000, 1400, 2000, 2800, 4000, 5200, 8000];
        const otherLanguages = ['Aboleth', 'Abyssal', 'Aklo', 'Celestial', 'Draconic', 'Dwarven', 'Elven', 'Gnoll', 'Goblin', 'Halfling', 'Infernal', 'Necril', 'Undercommon'];
        
        let ego = 0; // TODO: calc base ego
        let priceMod = 0;
        let item = createModdedItem(base);

        let name = item.name;
        if (name.includes(')')) {
            let split = name.split(')');
            let index = split.length - 2;
            split[index] = split[index] + ', Intelligent';
            item.name = split.join(')');
        }
        else {
            item.name = `${item.name} (Intelligent)`;
        }

        item.alignment = rnd.gtSelection(alignments);

        item.int = ItemWorkshop.getItemAbilityScore(rnd);
        item.wis = ItemWorkshop.getItemAbilityScore(rnd);
        item.cha = ItemWorkshop.getItemAbilityScore(rnd);
        [item.int, item.wis, item.cha].forEach(x => {
            priceMod += abilityPriceMods[x-10];
            ego += Math.floor((x-10)/2);
        });

        item.languages = ['Common'];
        let intMod = Math.floor((item.int-10)/2);
        let count = 0;
        while (item.languages.length < (1+intMod)) {
            if (count > 100) {
                console.warn('Language overflow while generating intelligent item', item);
                item.languages = ['Common'].concat(...otherLanguages.slice(0, intMod));
            }

            let language = rnd.gtSelection(otherLanguages);
            if (!item.languages.includes(language)) item.languages.push(language);
            count += 1;
        }

        let addChance = (percent: number, value: string, array: string[], priceInc: number, egoInc = 0) => {
            if (rnd.chance(percent)) {
                array.push(value);
                priceMod += priceInc;
                ego += egoInc;
            }
        };
        let add = (value: string, array: string[], priceInc: number, egoInc = 0) => {
            addChance(100, value, array, priceInc, egoInc);
        };

        let index = rnd.int(0, 2);
        item.senses = [['Senses (30 ft.)', 'Senses (60 ft.)', 'Senses (120 ft.)'][index]];
        priceMod += (index*500);
        addChance(70, 'Darkvision', item.senses, 500);
        addChance(5, 'Blindsense', item.senses, 5000, 1);
        addChance(30, 'Read languages', item.senses, 1000, 1);
        addChance(20, 'Read magic', item.senses, 2000, 1);

        item.communication = ['Empathy'];
        addChance(80, 'Speech', item.communication, 500);
        addChance(50, 'Telepathy', item.communication, 1000, 1);

        item.powers = [];
        const spells0 = ['Guidance', 'Haunted Fey Aspect', 'Flare', 'Prestidigitation', 'Purify Food and Drink', 'Putrefy Food and Drink', 'Virtue'];
        const spells1 = ['Alter Musical Instrument', 'Alarm', 'Charm Person', 'Charm Animal', 'Confusion, Lesser', 'Enlarge Person', 'Fairness', 'Glue Seal', 'Grease', 'Youthful Appearance', 'Sow Thought', 'Shield of Faith'];
        const spells2 = ['Wind Wall', 'Weapon of Awe', 'Spellcurse', 'Acute Senses', 'Aid'];
        const spells3 = ['Draconic Ally', 'Hydraulic Torrent', 'Ice Spears', 'Major Image', 'Zephyr\'s Fleetness', 'Fireball'];
        const spells4 = ['Arcane Eye', 'Ball Lightning', 'Firefall', 'Flaming Sphere, Greater', 'Globe of Invulnerability, Lesser'];
        const spells5 = ['Blood Boil', 'Mind Fog', 'Passwall', 'Polymorph', 'Wall of Force'];
        const spells6 = ['Antilife Shell', 'Animate Objects', 'Mind Thrust VI', 'Permanent Image', 'Wither Limb'];
        const spells7 = ['Arcane Cannon', 'Firebrand', 'Forcecage', 'Siege of Trees', 'Submerge Ship'];
        const skills = ['Appraise', 'Bluff', 'Diplomacy', 'Handle Animal', 'Linguistics', 'Perception', 'Sense Motive', 'Spellcraft', 'Knowledge (arcana)', 'Knowledge (dungeoneering)', 'Knowledge (engineering)', 'Knowledge (geography)', 'Knowledge (history)', 'Knowledge (local)', 'Knowledge (nature)', 'Knowledge (nobility)', 'Knowledge (planes)', 'Knowledge (religion)'];
        for (let i = 0; i < 5; i++) {
            let chance = rnd.int(1, 100);
            // 01–10	Item can cast a 0-level spell at will	+1,000 gp	+1
            if (chance <= 10) {
                let spell = rnd.gtSelection(spells0);
                let str = `Can cast _${spell}_ at-will.`;
                if (!item.powers.includes(str)) add(str, item.powers, 1000, 1);
            }

            // 11–20	Item can cast a 1st-level spell 3/day	+1,200 gp	+1
            else if (chance <= 20) {
                let spell = rnd.gtSelection(spells1);
                let str = `Can cast _${spell}_ 3/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 1200, 1);
            }

            // 21–25	Item can use magic aura on itself at will	+2,000 gp	+1
            if (chance <= 25) {
                let str = `Can cast _Magic Aura_ on itself at-will.`;
                if (!item.powers.includes(str)) add(str, item.powers, 2000, 1);
            }

            // 26–35	Item can cast a 2nd-level spell 1/day	+2,400 gp	+1
            else if (chance <= 35) {
                let spell = rnd.gtSelection(spells2);
                let str = `Can cast _${spell}_ 1/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 2400, 1);
            }

            // 36–45	Item has 5 ranks in one skill*	+2,500 gp	+1
            else if (chance <= 45) {
                let skill = rnd.gtSelection(skills);
                let str = `Has 5 ranks in ${skill}.`;
                if (!item.powers.includes(str)) add(str, item.powers, 2500, 1);
            }

            // 46–50	Item can sprout limbs and move with a speed of 10 feet	+5,000 gp	+1
            if (chance <= 50) {
                let str = `Can cast _Magic Aura_ on itself at-will.`;
                if (!item.powers.includes(str)) add(str, item.powers, 5000, 1);
            }

            // 51–55	Item can cast a 3rd-level spell 1/day	+6,000 gp	+1
            else if (chance <= 55) {
                let spell = rnd.gtSelection(spells3);
                let str = `Can cast _${spell}_ 1/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 6000, 1);
            }

            // 56–60	Item can cast a 2nd-level spell 3/day	+7,200 gp	+1
            else if (chance <= 60) {
                let spell = rnd.gtSelection(spells2);
                let str = `Can cast _${spell}_ 3/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 7200, 1);
            }

            // 61–70	Item has 10 ranks in one skill*	+10,000 gp	+2
            else if (chance <= 70) {
                let skill = rnd.gtSelection(skills);
                let str = `Has 10 ranks in ${skill}.`;
                if (!item.powers.includes(str)) add(str, item.powers, 10000, 2);
            }

            // 71–75	Item can change shape into one other form of the same size	+10,000 gp	+2
            if (chance <= 75) {
                let str = `Can change shape into one other form of the same size.`;
                if (!item.powers.includes(str)) add(str, item.powers, 10000, 2);
            }

            // 76–80	Item can fly, as the spell, at a speed of 30 feet	+10,000 gp	+2
            if (chance <= 80) {
                let str = `Can fly, as the spell, at a speed of 30 feet.`;
                if (!item.powers.includes(str)) add(str, item.powers, 10000, 2);
            }

            // 81–85	Item can cast a 4th-level spell 1/day	+11,200 gp	+2
            else if (chance <= 85) {
                let spell = rnd.gtSelection(spells4);
                let str = `Can cast _${spell}_ 1/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 11200, 2);
            }

            // 86–90	Item can teleport itself 1/day	+15,000 gp	+2
            if (chance <= 90) {
                let str = `Can teleport itself 1/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 15000, 2);
            }

            // 91–95	Item can cast a 3rd-level spell 3/day	+18,000 gp	+2
            else if (chance <= 95) {
                let spell = rnd.gtSelection(spells3);
                let str = `Can cast _${spell}_ 3/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 18000, 2);
            }

            // 96–100	Item can cast a 4th-level spell 3/day	+33,600 gp	+2
            else if (chance <= 100) {
                let spell = rnd.gtSelection(spells4);
                let str = `Can cast _${spell}_ 3/day.`;
                if (!item.powers.includes(str)) add(str, item.powers, 33600, 2);
            }

            // 70% chance that the item has no more abilities
            if (rnd.chance(70)) break;
        }

        const purposes = [
            'Defeat/slay diametrically opposed alignment',
            'Defeat/slay arcane spellcasters (including spellcasting monsters and those that use spell-like abilities)',
            'Defeat/slay divine spellcasters (including divine entities and servitors)',
            'Defeat/slay nonspellcasters',
            'Defeat/slay {creature_type}',
            'Defeat/slay {creature_race}',
            'Defend {creature_race}',
            'Defeat/slay the servants of {deity}',
            'Defend the servants of {deity}',
            'Defeat/slay everyone and everything (other than the item and the wielder)'
        ];
        const creatureTypes = ['Aberrations', 'Animals', 'Constructs', 'Dragons', 'Fey', 'Magical Beasts', 'Monstrous Humanoids', 'Oozes', 'Plants', 'Undead', 'Vermin'];
        const creatureRaces = ['Dwarves', 'Elves', 'Gnomes', 'Half Elves/Orcs', 'Halflings', 'Humans', 'Kobolds', 'Goblins', 'Orcs', 'Aasimar', 'Tieflings', 'Lashunta', 'Drow', 'Dhampir', 'Gathlains'];
        const deities = ['Abadar', 'Asmodeus', 'Calistria', 'Cayden Cailean', 'Desna', 'Erastil', 'Gorum', 'Gozreh', 'Iomedae', 'Irori', 'Lamashtu', 'Nethys', 'Norgorber', 'Pharasma', 'Rovagug', 'Sarenrae', 'Shelyn', 'Torag', 'Urgathoa', 'Zon-Kuthon'];
        if (rnd.chance(3)) {
            item.purpose = rnd.gtSelection(purposes)
                .replace(/{creature_type}/g, rnd.gtSelection(creatureTypes))
                .replace(/{creature_race}/g, rnd.gtSelection(creatureRaces))
                .replace(/{deity}/g, rnd.gtSelection(deities));
                
            let chance = rnd.int(1, 100);
            item.dedicatedPowers = [];
            
            // 01–20	Item can detect any special purpose foes within 60 feet	+10,000 gp	+1
            if (chance <= 20) {
                let str = `Can detect any special purpose foes within 60 feet.`;
                if (!item.dedicatedPowers.includes(str)) add(str, item.dedicatedPowers, 10000, 1);
            }

            // 21–35	Item can use a 4th-level spell at will	+56,000 gp	+2
            else if (chance <= 35) {
                let spell = rnd.gtSelection(spells4);
                let str = `Can cast _${spell}_ at-will.`;
                if (!item.dedicatedPowers.includes(str)) add(str, item.dedicatedPowers, 56000, 2);
            }

            // 36–50	Wielder gets +2 luck bonus on attacks, saves, and checks	+80,000 gp	+2
            if (chance <= 50) {
                let str = `Wielder gets +2 luck bonus on attacks, saves, and checks.`;
                if (!item.dedicatedPowers.includes(str)) add(str, item.dedicatedPowers, 80000, 2);
            }

            // 51–65	Item can use a 5th-level spell at will	+90,000 gp	+2
            else if (chance <= 65) {
                let spell = rnd.gtSelection(spells5);
                let str = `Can cast _${spell}_ at-will.`;
                if (!item.dedicatedPowers.includes(str)) add(str, item.dedicatedPowers, 90000, 2);
            }

            // 66–80	Item can use a 6th-level spell at will	+132,000 gp	+2
            else if (chance <= 80) {
                let spell = rnd.gtSelection(spells6);
                let str = `Can cast _${spell}_ at-will.`;
                if (!item.dedicatedPowers.includes(str)) add(str, item.dedicatedPowers, 132000, 2);
            }

            // 81–95	Item can use a 7th-level spell at will	+182,000 gp	+2
            else if (chance <= 95) {
                let spell = rnd.gtSelection(spells7);
                let str = `Can cast _${spell}_ at-will.`;
                if (!item.dedicatedPowers.includes(str)) add(str, item.dedicatedPowers, 182000, 2);
            }

            // 96–100	Item can use true resurrection on wielder, once per month	+200,000 gp	+2
            if (chance <= 100) {
                let str = `Can use true resurrection on wielder, once per month.`;
                if (!item.dedicatedPowers.includes(str)) add(str, item.dedicatedPowers, 200000, 2);
            }
        }

        let basePrice = ItemFields.parsePrice(item);
        if (basePrice <= 1000) {}
        else if (basePrice <= 5000) ego += 1;
        else if (basePrice <= 10000) ego += 2;
        else if (basePrice <= 20000) ego += 3;
        else if (basePrice <= 50000) ego += 4;
        else if (basePrice <= 100000) ego += 6;
        else if (basePrice <= 200000) ego += 8;
        else ego += 12;

        item.ego = ego;
        item.cost = basePrice + priceMod;
        return item;
    }

    static getItemAbilityScore(rnd: Randomizer) {
        let roll = rnd.int(1, 120);
        let add = 10 - Math.floor(Math.sqrt(roll)); // Calculates a number between [1, 10] with 10 being exponentially more likely, then inverses the odds to give a range of [0, 9]
        if (rnd.bool()) add += 1;

        let score = 10 + add;
        if (score <= 10) return 10;
        else if (score >= 20) return 20;
        else return score;
    }
}