






























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import GamebookDropdownButton from '@/ui/Gamebooks/block_components/GamebookDropdownButton.vue';
import BlockQuoteDisplay from '@/ui/Gamebooks/block_components/BlockQuoteDisplay.vue';
import TextBlockDisplay from '@/ui/Gamebooks/block_components/TextBlockDisplay.vue';
import RefBlockDisplay from '@/ui/Gamebooks/block_components/RefBlockDisplay.vue';
import GamebookButton from '@/ui/Gamebooks/block_components/GamebookButton.vue';
import SpanDisplay from '@/ui/Gamebooks/block_components/SpanDisplay.vue';
import NewsletterWidget from '@/ui/Gamebooks/NewsletterWidget.vue';
import ReviewWidget from '@/ui/Gamebooks/ReviewWidget.vue';

import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { Block, BlockTypes, ConditionalBlock, ScriptBlock } from '@/ts/gamebooks/parsing/Blocks';

@Component({
    components: {
        GamebookDropdownButton,
        BlockQuoteDisplay,
        TextBlockDisplay,
        NewsletterWidget,
        RefBlockDisplay,
        GamebookButton,
        ReviewWidget,
        SpanDisplay,
    }
})
export default class BlockDisplay extends Vue {
    @Prop({required: true})
    session!: GamebookSession;

    @Prop({required: true})
    block!: Block;

    conditionalState: boolean|null = null;

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    mounted() {
        this.execute();
        this.evalConditionalState();
    }

    @Watch('block.type')
    execute() {
        if (this.block.type == BlockTypes.Script) {
            this.session.execute(this.block as ScriptBlock);
        }
    }

    @Watch('session.variables', {deep: true})
    evalConditionalState() {
        if (this.block.type == BlockTypes.Conditional) {
            this.conditionalState = this.session.evaluate(this.block as ConditionalBlock);
        }
    }
}
