import {ContentTemplate, ContentFactory} from '@/ts/api/content/_index';

import Utils from '@/ts/util/Utils';
import axios from 'axios';

type Dynamic = {[key: string]: any};

export class ContentDAO {
    static Templates = {
        createSerializable(content: Dynamic): ContentTemplate {
            let copy = Utils.deepCopy(content);
            copy._id = copy.templateId;
            delete copy['templateId'];
            Utils.deepClean(copy);
    
            return copy;
        },
    
        async getAll(): Promise<ContentTemplate[]> {
            // TODO: Check local storage first, then commence update
            let res = await axios.get('/api/content');
            let contentList = res.data.map((x: any) => {
                x.templateId = x._id;
                delete x['_id'];
                purgeSystemId(x);
                return ContentFactory.createTemplate(x);
            });
    
            return contentList;
        },
    
        async patch(content: ContentTemplate) {
            let body = ContentDAO.Templates.createSerializable(content);
            let response = await axios.post('/api/content', body);
            return response;
        }
    };
}

// TODO: modify the data in the database, and remove this hack
function purgeSystemId(content: any) {
    delete content['systemId'];
    let children = content.children;
    if (children) {
        Object.values(children).forEach((x: any) => purgeSystemId(x));
    }
}