import LoreGen from '@/ts/util/loregen/LoreGen';
import LoreTemplate from '@/ts/util/loregen/LoreTemplate';
import { ElementTemplate, TemplateGroup, TemplateOrGroup, TemplateTypes } from '@/ts/util/loregen2/Templates';
import Utils from '@/ts/util/Utils';
import axios from 'axios';

type Dynamic = {[key: string]: any};

export class LoreGenDAO {
    static async getAll(): Promise<LoreGen> {
        // TODO: Check local storage first, then commence update
        let res: any = await axios.get('/api/content/loregen');

        delete res.data['_id'];
        let loreGen = new LoreGen();
        for (let x of res.data.templates ?? []) {
            let temp = new LoreTemplate();
            Utils.overwrite(temp, x);
            if (!Array.isArray(temp.data)) {
                temp.data = typeof temp.data == 'string' ? [temp.data] : [''];
            }
            loreGen.templates.push(temp);
        }

        return loreGen;
    }

    static async getAll2(): Promise<TemplateOrGroup[]> {
        // TODO: Check local storage first, then commence update
        let res: any = await axios.get('/api/content/generators');

        let get = (raw: any) => {
            let temp = raw.isGroup ? new TemplateGroup(raw.parentPath ?? '') : new ElementTemplate(raw.type ?? TemplateTypes.Line, raw.parentPath ?? '', raw.isTopLevel ?? false);
            Utils.overwrite(temp, raw);

            if (temp.isGroup) {
                let group = temp as TemplateGroup;
                let path = TemplateGroup.getPath(group);
                group.childGroups.forEach(x => {x.parentPath = path});
                group.children.forEach(x => {x.parentPath = path});

                group.childGroups = group.childGroups.map(x => get(x) as TemplateGroup);
                group.children = group.children.map(x => get(x) as ElementTemplate);
            }

            return temp;
        }

        let array = (res.data.templates ?? []).map((x: any) => get(x));
        return array;
    }

    static async patch(content: LoreGen, authInput: string) {
        let body = Utils.deepCopy(content);
        body.auth = authInput;
        let response = await axios.post('/api/content', body);
        return response;
    }

    static async patch2(content: TemplateOrGroup[], authInput: string) {
        let body = {
            templates: Utils.deepCopy(content),
            auth: authInput
        };
        let response = await axios.post('/api/content/v2', body);
        return response;
    }
}