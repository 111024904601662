import {RuleSectionGrant, ConditionGrant, SelectionGrant, CompanionGrant,
    LanguageGrant, CastingGrant, ContentGrant, TrackerGrant, PassiveGrant,
    ChoiceGrant, ActionGrant, BonusGrant, MultiGrant, PoolGrant, Grant} from '@/ts/api/grants/_index'
import DataUtils from '@/ts/util/DataUtils';
import SanityCheck from '@/ts/util/SanityCheck';

type Dynamic = {[key: string]: any};

export class GrantFactory {

    static createTemplate(json: Dynamic): Grant {
        let constructors: {[key: string]: Function} = {
            'bonus': (x: Dynamic) => new BonusGrant(x),
            'pool': (x: Dynamic) => new PoolGrant(x),
            'casting': (x: Dynamic) => new CastingGrant(x),
            'content': (x: Dynamic) => new ContentGrant(x),
            'choice': (x: Dynamic) => new ChoiceGrant(x),
            'selection': (x: Dynamic) => new SelectionGrant(x),
            'language': (x: Dynamic) => new LanguageGrant(x),
            'companion': (x: Dynamic) => new CompanionGrant(x),
            'action': (x: Dynamic) => new ActionGrant(x),
            'passive': (x: Dynamic) => new PassiveGrant(x),
            'ruleSection': (x: Dynamic) => new RuleSectionGrant(x),
            'condition': (x: Dynamic) => new ConditionGrant(x),
            'tracker': (x: Dynamic) => new TrackerGrant(x),
            'multi': (x: Dynamic) => new MultiGrant(x)
        };

        let type = json.grantType;
        if (type != null && type in constructors) {
            return check(constructors[type](json), json);
        }
        else {
            console.warn(`Unknown grant type: ${type}`);
            return check(new Grant(json), json);
        }
    }

    static initMap(obj: any): {[key: string]: Grant} {
        return DataUtils.initMap(obj, 'templateId', x => GrantFactory.createTemplate(x), 'Grant', false);
    }
}

function check<T>(grant: T, json: any) {
    SanityCheck.objFieldCompare(grant, json, 'Grant');
    return grant;
}