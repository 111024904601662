

















































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import LoreTemplate from '@/ts/util/loregen/LoreTemplate';
import Toaster from '@/ts/ui_integrations/Toaster';
import Markov from '@/ts/util/loregen/Markov';
import LoreGen from '@/ts/util/loregen/LoreGen';
import { LoreGenDAO } from '@/ts/api/loregen/LoreGenDAO';

@Component({
    components: {
        Sidebar,
    }
})
export default class GeneratorConfig extends Vue {
    current: LoreTemplate|null = null;
    results: string[] = [];

    genTypeOptions = ['single', 'multiple'];
    genTypeSelected = 'single';
    genWarning: string = "";

    toaster = new Toaster(this.$toast.add);
    authInput = "";

    sampleBlocks = false;
    selectionKeys = {};
    expandedKeys = {};

    nodeSelected(node: {data: LoreTemplate}) {
        this.current = node.data;
    }

    newLoreTemplate() {
        this.templates.push(new LoreTemplate(`New Template ${this.templates.length+1}`));
    }

    deleteTemplate(slotProps: {index: number, node: {data: LoreTemplate}}) {
        let index = this.templates.findIndex(x => x.id == slotProps?.node?.data?.id);
        if (index < 0) {
            console.warn('Could not delete template. Index = -1.');
            this.toaster.warn('Unable to delete template.', 'Internal error. Try refreshing the page and trying again.');
            return;
        }

        let removed = this.templates.splice(index, 1);
        let oldCurrent = this.current;
        let $this = this;

        setTimeout(() => {
            let oldId = oldCurrent?.id;
            let currentId = $this.current?.id;
            let removedId = removed[0]?.id;

            if (currentId == removedId) {
                $this.current = (oldId == removedId) ? null : oldCurrent;
            }
        }, 1)
    }

    deleteBlock(slotProps: {index: number, option: LoreTemplate}) {
        if (this.current && this.isBlocks) {
            let current = this.current;
            let array = current.data;
            if (array.length == 1) {
                this.toaster.warn('Cannot remove last remaining block.', 'Delete the generator instead.')
            }
            else {
                array.splice(slotProps.index, 1);
                current._blockIndex = Math.min(current._blockIndex, current.data.length-1);
            }
        }
    }

    addBlock() {
        if (this.current && this.isBlocks) {
            let current = this.current as LoreTemplate;
            let array = current.data as string[];
            array.push('');
            current._blockIndex = array.length - 1;
        }
    }

    generate() {
        this.genWarning = "";
        this.results = [];
        if (!this.current) {
            this.genWarning = "No generator is selected";
        }
        else {
            this.results = this.loreGen.generate(this.current, 1).map(x => x.displayText);
            if (this.results.some(x => x.length > 25)) {
                this.sampleBlocks = true;
                this.results = this.results.slice(0, 3);
            }
            else {
                this.sampleBlocks = false;
            }
        }
    }

    save() {
        if (this.authInput?.trim().length > 0) {
            LoreGenDAO.patch(this.loreGen, this.authInput).then(x => {
                if (x.status == 204) {
                    this.toaster.success('Saved!');
                }
                else if (x.status == 200) {
                    this.toaster.success('Up to Date!');
                }
                else {
                    this.toaster.warn(`${x.status}`, x.statusText);
                }
            })
            .catch(x => {
                this.toaster.error('Save failed');
                console.error(x);
            });
        }
        else {
            this.toaster.warn('Enter Auth Code First')
        }
    }

    subTableChanged(newIndex: number) {
        if (this.current && this.genTypeSelected == 'multiple') {
            if (newIndex == null) this.current._blockIndex = 0;
            else if (newIndex >= this.current.data.length) this.current._blockIndex = this.current.data.length - 1;
        }
    }

    @Watch('current.id')
    updateGenType() {
        this.genTypeSelected = this.isBlocks ? 'multiple' : 'single';
    }

    @Watch('genTypeSelected')
    updateLoreType() {
        if (this.current) {
            let current = this.current;
            let newType = this.genTypeSelected;
            if (newType == 'single') {
                current._blockIndex = -1;
                if (current.data.length > 1) {
                    current._blockBackup = current.data;
                    current.data = current.data.slice(0, 1);
                }
            }
            else {
                current._blockIndex = 0;
                if (current.data.length == 1 && current._blockBackup && current._blockBackup.length > 1) {
                    current.data = current._blockBackup;
                }
            }
        }
    }

    get loreGen(): LoreGen {
        return (this.$root as any).loreGen;
    }

    get templates(): LoreTemplate[] {
        return this.loreGen.templates;
    }

    get noTemplates() {
        return this.templates.length == 0;
    }

    get isLoading() {
        return false;
    }

    get isBlocks() {
        if (this.current == null) return false;
        else return this.current.data.length > 1 || (this.current._blockIndex ?? -1) >= 0;
    }

    get blocks() {
        let blocks = [] as {index: number, name: string}[];
        let values = this.current?.data ?? [];
        for (let index = 0; index < values.length; index += 1) {
            blocks.push({
                index: index,
                name: LoreTemplate.getTitle(values[index])
            });
        }

        return blocks;
    }

    get nodes() {
        let groups = {} as any;
        let others = [] as any[];
        for (let template of this.templates) {
            let groupName = template.group.trim();
            if (groupName.length > 0) {
                if (!(groupName in groups)) {
                    groups[groupName] = group(groupName);
                }

                groups[groupName].children.push(node(template));
            }
            else {
                others.push(node(template));
            }
        }
        return Object.values(groups).concat(others);
    }
}

function node(data: LoreTemplate) {
    return {
        key: data.id,
        label: data.name,
        data: data
    };
}
function group(groupName: string) {
    return {
        key: `group_${groupName}`,
        label: groupName,
        data: groupName,
        children: [],
        leaf: false,
        selectable: false,
    };
}
