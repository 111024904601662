











import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { BlockQuote } from '@/ts/gamebooks/parsing/Blocks';

@Component({
    components: {
    }
})
export default class BlockQuoteDisplay extends Vue {
    @Prop({required: true})
    session!: GamebookSession;

    @Prop({required: true})
    block!: BlockQuote;

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }
}
