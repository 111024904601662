import { Block, ButtonBlock } from "./Blocks";

export interface Section {
    type: SectionTypes;
}

export enum SectionTypes {
    Scene = 'scene',
    Combat = 'combat',
    Document = 'document', // info & handout
}

export enum DocTypes {
    Handout = 'handout',
    Info = 'info',
}

export class Scene implements Section {
    id: string;
    transit?: string; // If set, this scene will transition automatically to the specified scene.
    location?: string;
    align?: string; // Determines whether or not the scene text is centered
    blocks: Block[] = [];
    endBlocks: Block[] = []; // TODO: This may not be necessary, now that dividers are marked with isEndSection and BlockDisplay accounts for that flag
    
    get type() { return SectionTypes.Scene }

    constructor(id: string) {
        this.id = id;
    }
}

export class CombatScene extends Scene {
    enemyIds: string[] = [];
    eventIds: string[] = [];
    winBlock?: ButtonBlock;
    loseBlock?: ButtonBlock;
    altChoices: ButtonBlock[] = [];
    simulcap?: number; // The max number of enemies allowed to act in a single round. Used to represent eneies restricted by terrain or chokepoints.
    
    get type() { return SectionTypes.Combat }

    constructor(id: string) {
        super(id);
    }
}

export class GamebookDocument implements Section {
    id: string;
    save?: boolean;
    docType: DocTypes;
    blocks: Block[] = [];
    
    get type() { return SectionTypes.Document }

    constructor(id: string, docType: DocTypes) {
        this.id = id;
        this.docType = docType;
    }
}