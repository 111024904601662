import {Grant} from '@/ts/api/grants/_index';

type Dynamic = {[key: string]: any};

export class LanguageGrant extends Grant {
    starting: string[]; // List of languages that are automatically given by this grant
    selections: string[]; // Some features give players the option to choose between multiple languages; this is a list of languages that can be chosen (this is NOT the high intelligence list)
    highIntOptions: string[]; // List of languages that can be selected with high intelligence. If this is empty, it defaults to 'all non-secret languages'

    numSelections: number; // Number of selections that can be made from the selections list. If selections is empty, and this value is > 0, the user can select from any non-secret languages

    constructor(json: Dynamic) {
        super(json);
        
        this.starting = json.starting ?? [];
        this.selections = json.selections ?? [];
        this.highIntOptions = json.highIntOptions ?? [];
        
        this.numSelections = json.numSelections ?? 0;
    }
}