

















































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import Toaster from '@/ts/ui_integrations/Toaster';
import { AllTemplateTypes, AnnonVariableTemplate, ElementTemplate, ObjectTemplate, TemplateTypes, VariableTemplate } from '@/ts/util/loregen2/Templates';
import Utils from '@/ts/util/Utils';

@Component({
    components: {
    }
})
export default class TemplateDisplay extends Vue {
    @Prop({required: true})
    template!: ElementTemplate;

    toaster = new Toaster(this.$toast.add);
    typeOptions = AllTemplateTypes;
    activeTab = 'variables';

    selectedObject: ObjectTemplate|null = null;
    lastSelection: ObjectTemplate|null = null;
    displayDeleteFieldDialog = false;
    displayAddFieldDialog = false;
    newFieldKey = '';
    delFieldKey = '';

    @Watch('selectedObject')
    updateLastSelection() {
        if (this.selectedObject == null) {
            this.selectedObject = this.lastSelection;
        }
        else {
            this.lastSelection = this.selectedObject;
        }
    }

    deleteBlock(index: number) {
        if (this.template.parts.length < 2) {
            this.toaster.warn('Cannot delete last block!');
        }
        else {
            this.template.parts.splice(index, 1);
        }
    }

    addBlock() {
        this.template.parts.push('');
    }

    addField() {
        let key = this.newFieldKey.trim();
        if (key.length == 0) {
            this.toaster.warn('Key must not be empty');
        }
        else if (key in this.getKeys()) {
            this.toaster.warn('Key must be unique within this object type.');
        }
        else {
            for (let obj of this.template.objects) {
                Vue.set(obj, key, '');
            }
            this.displayAddFieldDialog = false;
        }
    }

    showDeleteFieldDialog(key: string) {
        this.delFieldKey = key;
        this.displayDeleteFieldDialog = true;
    }

    deleteField() {
        let key = this.delFieldKey;
        for (let obj of this.template.objects) {
            Vue.delete(obj, key);
        }
        this.displayDeleteFieldDialog = false;
    }

    addObject() {
        let obj: ObjectTemplate = {
            key: 'NewObject',
            _id: Utils.guid()
        }

        for (let key of this.getKeys()) {
            if (!(key in obj)) {
                obj[key] = '';
            }
        }
        this.template.objects.push(obj);
    }

    getKeys() {
        if (this.template.objects.length == 0) return [];
        else return Object.keys(this.template.objects[0]);
    }

    deleteObject(slotProps: {index: number, option: ObjectTemplate}) {
        let array = this.template.objects;
        array.splice(slotProps.index, 1);

        let $this = this;
        let previous = this.lastSelection;
        setTimeout(() => {
            if (previous == slotProps.option) {
                $this.lastSelection = null;
                $this.selectedObject = null;
            }
            else {
                $this.lastSelection = previous;
                $this.selectedObject = previous;
            }
        }, 1);
    }

    showAddFieldDialog() {
        this.newFieldKey = '';
        this.displayAddFieldDialog = true;
    }

    openPartContextMenu(event: any, index: number) {
        let $this = this;
        let items = [
            {
                label: 'Delete',
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    $this.deleteBlock(index);
                }
            },
        ];
        (this as any).$toggleContextMenu(event, items);
    }

    addVariable() {
        this.template.variables.push(new VariableTemplate());
    }

    addComponent() {
        this.template.annonVariables.push(new AnnonVariableTemplate());
    }

    openVarGridContextMenu(event: any, index?: number) {
        let $this = this;
        let items = [
            {
                label: 'New Variable',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                    $this.addVariable();
                }
            },
        ];
        if (index != undefined) {
            let variable = $this.template.variables[index];
            items.push({
                label: `Delete '${variable.key}'`.replace(/ ''$/g, ''),
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    $this.template.variables.splice(index, 1);
                }
            });
        }
        (this as any).$toggleContextMenu(event, items);
    }

    openCompGridContextMenu(event: any, index?: number) {
        let $this = this;
        let items = [
            {
                label: 'New Component',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                    $this.addComponent();
                }
            },
        ];
        if (index != undefined) {
            let variable = $this.template.annonVariables[index];
            items.push({
                label: `Delete '${variable.key}'`.replace(/ ''$/g, ''),
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    $this.template.annonVariables.splice(index, 1);
                }
            });
        }
        (this as any).$toggleContextMenu(event, items);
    }

    @Watch('template')
    @Watch('template.parts')
    @Watch('template.seperators')
    makeGuarantees() {
        if (this.template.objects == undefined) Vue.set(this.template, 'objects', []);
        if (this.template.parts == undefined) Vue.set(this.template, 'parts', []);
        if (this.template.parts.length == 0) this.template.parts.push('');
        if (this.template.seperators == undefined) Vue.set(this.template, 'seperators', []);
        if (this.isObjects) {
            let unsetObjects = this.template.objects.filter(obj => (obj._id == null || obj._id.length == 0));
            for (let obj of unsetObjects) {
                obj._id = Utils.guid();
            }
        }
        
        let desiredLength = (this.template.parts.length - 1);
        while (this.template.seperators.length < desiredLength) {
            this.template.seperators.push('[space]');
        }
        if (this.template.seperators.length > desiredLength) {
            let removalCount = this.template.seperators.length;
            this.template.seperators.splice(desiredLength, removalCount);
        }
        for (let index = 0; index < this.template.seperators.length; index++) {
            let empty = this.template.seperators[index].trim().length == 0
            if (empty) this.template.seperators[index] = '[space]';
        }
    }

    updateSeperator(index: number) {
        let seperator = this.template.seperators[index];
        if (seperator.length == 0) seperator = '[space]';
        seperator = seperator.replace(/ +$/, '[space]');
        seperator = seperator.replace(/^ +/, '[space]');
        seperator = seperator.replace(/\[s[^\]]+$/, '');

        let starts = seperator.startsWith('[space]');
        let ends = seperator.endsWith('[space]');
        seperator = seperator.replace(/\[space\]/g, ' ').trim();
        seperator = `${starts ? '[space]' : ''}${seperator}${ends ? '[space]' : ''}`;
        seperator = seperator.replace(/(\[space\])+/g, '[space]').trim();
        this.template.seperators[index] = seperator;
    }

    mounted() {
        this.makeGuarantees();
    }

    onInput(event: any) {
        if (event.keyCode == 13) {
            // Enter Pressed
            this.addField();
        }
    }

    get objEntries() {
        return Object.entries(this.selectedObject ?? {}).filter(x => x[0] != '_id');
    }

    get isObjects() { return this.template.type == TemplateTypes.Objects }
    get isMarkov() { return this.template.type == TemplateTypes.Markov }
    get isBlock() { return this.template.type == TemplateTypes.Block }
    get isLine() { return this.template.type == TemplateTypes.Line }
}
