




import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Filter } from "@/ts/util/filters/_index";

@Component
export default class TextFilterPanel extends Vue {
    @Prop({required: true})
    filter!: Filter<any>;
}
