




























































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { CardConfig, StatEntry, Tab, TagEntry } from "@/ui/DataCards/CardConfig";

@Component
export default class DataCard extends Vue {
    @Prop({required: true})
    config!: CardConfig;

    @Prop({default: null})
    qty!: number|null;

    @Prop({default: false})
    big!: boolean;

    activeTab = this.config.tabs[0]?.name;

    @Watch('config.tabs')
    updateActiveTab() {
        this.activeTab = this.config.tabs[0]?.name;
    }

    get gridStats(): StatEntry[] {
        return this.config.topGrid ?? [];
    }
    
    get tagStats(): StatEntry[] {
        return this.config.topTags ?? [];
    }

    get hasFooter(): boolean {
        return this.hasTitleTags || this.hasSources;
    }

    get hasTitleTags(): boolean {
        return this.config.sources && this.config.sources.length > 0;
    }

    get hasSources(): boolean {
        return this.config.sources && this.config.sources.length > 0;
    }

    showTab(tab: Tab) {
        if (this.big) return this.config.tabs[0].name == tab.name;
        else return this.activeTab == tab.name;
    }

    tagClasses(tag: TagEntry) {
        let classes = 'p-tag';
        if (tag.styleClass && tag.styleClass.length > 0) {
            classes += ' ' + tag.styleClass;
        }

        return classes;
    }

    empty(array: any[]|null) {
        return array?.length === 0;
    }

    imageSearch() {
        let term = this.config.searchTerm?.toString();
        if (term) window.open(`https://google.com/search?tbm=isch&q=${encodeURIComponent(term)}`, '_blank');
    }

    redirect() {
        // TODO: Use user-settings to determine which link (AON or D20) to use
        let link = this.config.links?.aon ?? '';
        let domainCheck = ['https://www.aonprd.com', 'https://aonprd.com'].some(x => link.startsWith(x));
        console.assert(domainCheck, 'External link failed domain check', {config: this.config, link});
        if (domainCheck) window.open(link, '_blank');
    }
}
