import Utils from "../Utils";

export default class CRS {
    static AllXPS = [50, 65, 100, 135, 200, 400, 600, 800, 1200, 1600, 2400, 3200, 4800, 6400, 9600, 12800, 19200, 25600, 38400, 51200, 76800, 102400, 153600, 204800, 307200, 409600, 614400, 819200, 1228800, 1638400, 2457600, 3276800, 4915200, 6553600, 9830400];
    static FC_Cache = [] as ComboCacheEntry[];

    static getCombinations(size: number, xpTarget: number): Combination[] {
        const FC_Cache = CRS.FC_Cache;

        let filter = FC_Cache.filter(x => x.size == size && x.xpTarget == xpTarget);
        if (filter.length > 0) {
            return filter[0].combinations;
        }
        else {
            let raw = CRS.getRawCombinations(size, CRS.AllXPS, xpTarget);
            let combinations = [] as Combination[];
            for (let arr of raw) {
                arr = arr.sort();
                let entry = new Entry(arr.pop() as number, 1);
                let entries = [entry] as Entry[];
                while (arr.length > 0) {
                    let num = arr.pop() as number;
                    if (num == entry.xp) entry.qty += 1;
                    else {
                        entry = new Entry(num, 1);
                        entries.push(entry);
                    }
                }
        
                combinations.push(new Combination(entries));
            }
        
            FC_Cache.push(new ComboCacheEntry(size, xpTarget, combinations));
            if (FC_Cache.length > 5) FC_Cache.shift();
            return combinations;
        }
    }
    
    static getRawCombinations(size: number, array: number[], xpTarget: number): number[][] {
        let validCombinations = [] as number[][];
        let digits = [...array.filter(x => x <= xpTarget)]
            .sort((a, b) => b - a) // Sort highest to lowest
            .slice(0, 12); // Limit the total digits to 12, to reduce processing times
    
        // Start with a number of digits equal to size, all filled with the largest digit
        let combination = [] as number[];
        for (let i = 0; i < size; i += 1) {
            combination.push(digits[0]);
        }
        if (Utils.sum(combination) == xpTarget) {
            validCombinations.push([...combination]);
        }
    
        let decrement = () => {
            let cIndex = size-1; // start with the last digit;
            let wrap = false;
            while (cIndex >= 0) {
                let digit = combination[cIndex];
                let digitIndex = digits.indexOf(digit);
                let newDigitIndex = digitIndex + 1;
    
                if (newDigitIndex >= digits.length) {
                    // Wraparound
                    cIndex -= 1;
                    wrap = true;
                }
                else {
                    let newDigit = digits[newDigitIndex];
                    combination[cIndex] = newDigit;
                    if (wrap) {
                        for (let i2 = cIndex+1; i2 < combination.length; i2 += 1) {
                            combination[i2] = newDigit;
                        }
                    }
                    break;
                }
            }
    
            return cIndex >= 0;
        };
    
        while (decrement()) {
            if (Utils.sum(combination) == xpTarget) {
                validCombinations.push([...combination]);
            }
        }
    
        return validCombinations;
    }

    static getCRString(cr: number): string {
        switch (cr) {
            case 1/8: return "1/8";
            case 1/6: return "1/6";
            case 1/4: return "1/4";
            case 1/3: return "1/3";
            case 1/2: return "1/2";
            default: return `${cr}`;
        }
    }
    
    static getCRFromXP(xp: number): number {
        const AllXPS = CRS.AllXPS;
        let filter = AllXPS.filter(x => x <= xp);
        if (filter.length == 0) {
            return 0;
        }
        else if (AllXPS[AllXPS.length-1] == filter[filter.length-1]) {
            // CR may be > 30
            let cr = 30;
            let crXP = 9830400;
            while (xp > crXP) {
                let old = crXP;
    
                cr += 1;
                crXP *= 2;
                if (old > crXP) {
                    console.warn('Encountered CR wraparound - returning max CR: ' + cr);
                    return cr
                }
            }
    
            return cr;
        }
        else {
            let index = AllXPS.indexOf(filter[filter.length-1]);
            switch (index) {
                case 0: return 1/8;
                case 1: return 1/6;
                case 2: return 1/4;
                case 3: return 1/3;
                case 4: return 1/2;
                default: return index - 4;
            }
        }
    }

    static getXPfromCR(cr: number): number {
        if (cr < 1) {
            return Math.round(cr*400/5) * 5;
        }
        else {
            let xpBudget = Math.pow(2, Math.floor(cr/2)) * 400;
            if ((cr&1) === 0) {
                xpBudget = xpBudget/2 + Math.pow(2, cr/2)*100;
            }
    
            return xpBudget;
        }
    }
}

export class Entry {
    xp: number;
    qty: number;

    constructor(xp: number, qty: number) {
        this.xp = xp;
        this.qty = qty;
    }

    clone() {
        return new Entry(this.xp, this.qty);
    }
}

export class Combination {
    entries: Entry[];
    totalXP: number;
    totalQTY: number;

    constructor(entries: Entry[]) {
        this.entries = entries;
        this.totalXP = entries.reduce((agg, entry) => agg + (entry.xp * entry.qty), 0);
        this.totalQTY = entries.reduce((agg, entry) => agg + entry.qty, 0);
    }

    clone() {
        return new Combination(this.entries.map(x => x.clone()));
    }
}

export class ComboCacheEntry {
    size: number;
    xpTarget: number;
    combinations: Combination[];

    constructor(size: number, xpTarget: number, combinations: Combination[]) {
        this.size = size;
        this.xpTarget = xpTarget;
        this.combinations = combinations;
    }
}