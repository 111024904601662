



























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import AdvancedSearch from '@/ui/AdvancedSearch/AdvancedSearch.vue';
import TableResults from '@/ui/AdvancedSearch/TableResults.vue';
import CardResults from '@/ui/AdvancedSearch/CardResults.vue';

import { CardResultsConfig } from '@/ui/AdvancedSearch/CardResultsConfig';
import { SmallMode, TableConfig } from './TableConfig';
import SpellEntry, { SpellDisplays } from '@/ts/api/spells/SpellEntry';
import Utils from '@/ts/util/Utils';

@Component({
    components: {
        AdvancedSearch,
        TableResults,
        CardResults,
    }
})
export default class AdvancedSpellSearch extends Vue {
    cardConfig(big: boolean): CardResultsConfig<SpellEntry> {
        return {
            big,
            sortAlgorithms: SpellDisplays.sortAlgorithms,
            getConfig: SpellDisplays.getCardConfig,
        };
    }

    tableConfig(): TableConfig<SpellEntry> {
        return {
            getConfig: SpellDisplays.getCardConfig,
            getLinks: item => item.links,
            columns: [
                {
                    name: "Name",
                    field: "name",
                    smMode: SmallMode.Compress,
                    display: entry => entry.name,
                    isSortable: true,
                },
                {
                    name: "School",
                    field: "school",
                    smMode: SmallMode.Hide,
                    display: entry => (entry.school == null ? '' : Utils.titleCase(entry.school)) + (entry.subschool ? ` (${entry.subschool})` : ''),
                    isSortable: true,
                },
                {
                    name: "Level",
                    field: "primaryLevel",
                    smMode: SmallMode.Hide,
                    display: entry => entry.primaryLevel === undefined ? "—" : (entry.primaryLevel + levelRange(entry)),
                    isSortable: true,
                    // bodyStyle: "text-align: center; padding-right: 28px; padding-left: 4px;"
                    // headerStyle: "text-align: center;"
                },
                {
                    name: "Description",
                    field: "short_description",
                    smMode: SmallMode.Hide,
                    display: entry => entry.short_description,
                },
                {
                    name: "Sources",
                    field: "sourceName",
                    smMode: SmallMode.Hide,
                    // TODO: Use markdown and entry.sourceURL to create hyperlinks
                    display: entry => entry.sourceName,
                    isSortable: true,
                },
            ],
        };
    }
}

function levelRange(spell: SpellEntry) {
    let range = Utils.unique([spell.minLevel, spell.maxLevel].filter(x => x != null));
    if (range.length == 0) {
        return '?';
    }
    else if (range.length == 1) {
        let num = range[0];
        
        if (num == spell.primaryLevel) return '';
        else {
            console.warn(`Unexpected level range found`, {expected: spell.primaryLevel, found: num, spell});
            return ` (${num})`;
        }
    }
    else {
        return ` (${range[0]}-${range[1]})`;
    }
}
