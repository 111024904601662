export interface Block {
    type: BlockTypes;
}

export enum BlockTypes {
    Ref = 'ref',
    Text = 'text',
    Script = 'script',
    Button = 'button',
    Divider = 'divider',
    EndNode = 'end_node',
    BlockQuote = 'block_quote',
    Conditional = 'conditional',
    DropdownButton = 'dropdown_button',
}

export enum RefTypes {
    Info = 'info',
    Roll = 'roll',
    Image = 'image',
    Enemy = 'enemy',
    Handout = 'handout',
}

export class TextBlock implements Block {
    spans: Span[] = [];
    headerLevel?: number;
    format: TextBlockFormatting = {};

    get type() { return BlockTypes.Text }
}

export class BlockQuote implements Block {
    blocks: Block[] = [];

    get type() { return BlockTypes.BlockQuote }
}

export interface TextBlockFormatting {
    center?: boolean;
}

export class RefBlock implements Block {
    refId: string;
    refType: RefTypes;
    
    qty?: number; // for enemy
    linkedCombatId?: string; // for enemy
    align?: string; // for image
    
    get type() { return BlockTypes.Ref }

    constructor(refId: string, refType: RefTypes) {
        this.refId = refId;
        this.refType = refType;
    }
}

export class ButtonBlock implements Block {
    refId: string;
    condition?: string;
    refIdOnSuccess?: string;

    iconRef?: string;
    aboveText?: string;
    buttonText?: string;
    inlineResults?: boolean;

    get type() { return BlockTypes.Button }

    constructor(refId: string, buttonText?: string) {
        this.refId = refId;
        this.buttonText = buttonText;
    }
}

export type LinkedPair = {buttonText: string, refId: string}; // TODO: add iconRef?: string;
export class DropdownButtonBlock implements Block {
    options: string[] = [];
    results: LinkedPair[] = [];

    aboveTextPre?: string;
    aboveTextPost?: string;

    get type() { return BlockTypes.DropdownButton }

    constructor(aboveTextPre?: string, aboveTextPost?: string) {
        this.aboveTextPre = aboveTextPre;
        this.aboveTextPost = aboveTextPost;
    }

    validate(): boolean {
        if (this.options.length != this.results.length) {
            console.warn('DropdownButtonBlock validation error: option/result mismatch', this);
            return false;
        }
        else if (this.options.length <= 1) {
            console.warn('DropdownButtonBlock validation error: expected >1 options for dropdown', this);
            return false;
        }
        else {
            return true;
        }
    }
}

export class Divider implements Block {
    get type() { return BlockTypes.Divider }
    isEndSection: boolean;

    constructor(isEndSection=false) {
        this.isEndSection = isEndSection;
    }
}

export class EndNode implements Block {
    get type() { return BlockTypes.EndNode }
}

export class ScriptBlock implements Block {
    // ScriptBlock (e.g. set: $map to true)
    instruction: ScriptInstruction;
    target = '';
    value = false;

    constructor(instruction: ScriptInstruction) {
        this.instruction = instruction;
    }

    get type() { return BlockTypes.Script }
}

export enum ScriptInstruction {
    Set = 'set'
}

export class ConditionalBlock implements Block {
    condition: string; // e.g. $password is true
    ifBlocks: Block[] = [];
    elseBlocks: Block[] = [];

    constructor(condition: string) {
        this.condition = condition;
    }

    get type() { return BlockTypes.Conditional }
}

export class Span {
    text: string;

    isBold?: boolean;
    isQuote?: boolean;
    isItalic?: boolean;
    
    inlineRefId?: string; // for [ref:] elements
    inlineLinkId?: string; // for [link:] elements
    inlineRollId?: string; // for [roll:] elements
    linkedPopupId?: string; // for linked (non-inline) [handout:] and [info:] elements

    constructor(text: string) {
        this.text = text;
    }
}