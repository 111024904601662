import BestiaryEntry, { AbilitySnippet, Aura, Casting, DR, PsychicMagic, Resistance, SpecialAbility, StatNotes, Stats } from '@/ts/api/bestiary/BestiaryEntry';

import axios, { AxiosResponse } from 'axios';
import Utils from '@/ts/util/Utils';
import Links from '../sharedModels/Links';
import { StringMap } from '@/ts/api/sharedModels/Types';
import Vue from 'vue';

export class BestiaryDAO {
    static async getIndex(): Promise<BestiaryEntry[]> {
        let res: AxiosResponse<BestiaryEntry[]> = await axios.get('/api/bestiary/index');
        let bestiary = res.data;
        bestiary.forEach(x => process(x));

        // TODO: Do not hydrate; instead, make these properties optional and handle error cases inline
        bestiary.forEach(x => hydrate(x));
        return bestiary;
    }

    static async fill(index: BestiaryEntry[]): Promise<BestiaryEntry[]> {
        // TODO: Check local storage first, then commence update
        let res: AxiosResponse<{[key: string]: BestiaryEntry}> = await axios.get('/api/bestiary/fills');
        let fills = res.data;

        for (let entry of index) {
            let fill = fills[entry.id];
            if (fill == null) {
                console.warn(`Fill not found: ${entry.id}`);
                continue;
            }

            for (let [k, v] of Object.entries(fill)) {
                Vue.set(entry, k, v);
            }

            process(entry, true);
        }

        return index;
    }

    static async getPolymorphDescriptions(): Promise<StringMap> {
        let res: AxiosResponse<StringMap> = await axios.get('/api/bestiary/polymorph');
        return res.data;
    }
}

class FilledBestiaryEntry implements BestiaryEntry {
    constructor() {
        this.id = "";
        this.name = "";
        this.links = {
            aon: "",
            d20: "",
            aonFeats: {} as StringMap
        };
        this.groups = [];

        this.race_class = "";
        
        this.cr = "";
        this.xp = 0;
        this.mr = 0;
        this.type = "";
        this.size = "";
        
        this.boon = "";

        // ### Ecology section (quick stats)
        this.treasure = "";
        this.advancement = "";
        this.environment = "";
        this.organization = "";
        this.favoredClass = "";
        this.levelAdjustment = "";
        
        this.alignment = "";
        this.descriptor = "";
        this.description = "";
        this.physicalDescription = "";
        
        this.combat = "";
        this.tactics = "";
        this.ecology = "";
        this.adventureHooks = "";
        this.habitatAndSociety = "";

        this.sources = [];
        this.senses = [];
        this.auras = [];
        this.immunities = [];
        this.resistances = [];
        this.weaknesses = [];
        this.dr = [];
        
        this.healthAbilities = [];
        this.movementAbilities = [];
        this.defensiveAbilities = [];
        this.specialAttacks = [];

        this.specialAbilities = [];

        this.meleeAttacks = [];
        this.rangedAttacks = [];
        this.castingSections = [];
        this.magicDetailLines = {};
        this.psychicMagic = {} as PsychicMagic;

        this.feats = [];
        this.languages = [];
        this.specialQualities = [];
        this.racialModifiers = []; // TODO: Include this with skills
        
        this.gear = [];

        this.stats = {speeds: {}, skills: {}} as Stats;
        this.statNotes = {speeds: {}, skills: {}} as StatNotes;
    }

    // These fields are filled by the client, and are not present in the original data
    _cr = 0;
    _hdCount = 0;
    hd = ""
    _environments = [] as string[];
    _locations = [] as string[];
    _climates = [] as string[];

    // Identification & Metadata
    id: string;
    name: string;
    links: Links;
    groups: string[];
    sources: string[];

    cr: string;
    xp: number;
    mr: number;

    // Grid Stats
    stats: Stats;
    statNotes: StatNotes;

    type: string; // Type and subtypes e.g. 'outsider (native)'
    size: string;
    auras: Aura[];
    alignment: string;

    // Lore
    boon: string;
    treasure: string;
    languages: string[];
    advancement: string;
    environment: string;
    organization: string;
    favoredClass: string;
    levelAdjustment: string;
    race_class: string; // "Race Class Level" e.g. Human bard 19. TODO: Parse race and classes from this line

    descriptor: string;
    description: string;
    physicalDescription: string;
    
    ecology: string; // Paragraph text
    adventureHooks: string; // Paragraph text
    habitatAndSociety: string; // Paragraph text
    
    combat: string; // TODO: This appears to be a parse error, and only appears on Dragon pages
    tactics: string; // TODO: There are some parse errors here - base statistics should not be listed under tactics
    
    gear: string[]; // list of all items listed in Gear, Combat Gear, and Other Gear

    // Actions
    psychicMagic: PsychicMagic;
    meleeAttacks: string[][]; // Represents groups of attacks capable by the monster. For instance, if this contains 3 lists, the monster may make all attacks in any ONE of the three lists
    rangedAttacks: string[][]; // Represents groups of attacks capable by the monster. For instance, if this contains 3 lists, the monster may make all attacks in any ONE of the three lists
    castingSections: Casting[]; // Each entry holds details for the casting capabilities of a monster, including the type of spells and number of castings per day, CL, DC, etc.
    magicDetailLines: {[key: string]: string}; // Each entry holds details for casting class elements (e.g. bloodline, domain, psychic discipline, etc)
    
    // Abilities
    movementAbilities: AbilitySnippet[]; // E.g. "air walk, earth glide"
    defensiveAbilities: AbilitySnippet[]; // E.g. "leaden weariness, reflect spell;"
    healthAbilities: AbilitySnippet[]; // E.g. "regeneration 15 (electricity)"
    specialAttacks: AbilitySnippet[]; // E.g. "banish darkness, consume light"
    specialAbilities: SpecialAbility[];
    specialQualities: string[];

    // Other [Defenses & Build Information]
    resistances: Resistance[];
    immunities: string[];
    weaknesses: string[];
    senses: string[];
    dr: DR[];

    feats: string[];
    racialModifiers: string[];
}

function hydrate(entry: any) {
    let filled = new FilledBestiaryEntry();
    for (let [k, v] of Object.entries(filled)) {
        if (entry[k] == undefined) entry[k] = v;
    }
}

function process(entry: BestiaryEntry, enforceHDCount=false) {
    // Set _cr
    entry._cr = Utils.parseFraction(entry.cr)

    // Process additional runtime fields
    let hpNotes = entry.statNotes?.hp;
    if (hpNotes) {
        let index = hpNotes.indexOf('(');
        let index2 = hpNotes.indexOf(')');
        console.assert(index >= 0 && index2 >= 0, 'Unexpected hp note format:', {hpNotes, entry: entry});

        if (index >= 0 && index2 >= 0) {
            entry.hd = hpNotes.slice(index, index2 + 1);
            
            let hd = Utils.trim(entry.hd, '()');
            let sIndex = hd.indexOf(';');
            let preCalc = (sIndex < 0) ? hd : hd.slice(0, sIndex);

            if (preCalc.endsWith(' HD')) {
                entry._hdCount = parseInt(preCalc.split(' ')[0]);
                sIndex = -1; // Ignore the remainder of the string inside parenthesis - it represents a complex HD arrangement, not notes.
            }
            else {
                entry._hdCount = parseInt(hd.split('d')[0]);
            }
            
            let pre = hpNotes.slice(0, index);
            let post = hpNotes.slice(index2 + 1).trim();
            if (post.startsWith(';')) post = post.slice(1).trim();
            let innerNotes = (sIndex < 0) ? '' : hd.slice(index+1).trim();
            let outterNotes = [pre, post].filter(x => x.length > 0).join('; ');
            console.assert(pre.length == 0 || post.length == 0, 'Unexpected hp note format:', {hpNotes, pre, post, entry: entry});
            console.assert(outterNotes.length == 0 || innerNotes.length == 0, 'Unexpected hp note format:', {hpNotes, pre, post, innerNotes, entry: entry});

            entry.statNotes.hp = (outterNotes + ' ' + ((innerNotes.length > 0) ? `(${innerNotes})` : '')).trim();
        }
    }
    let hasHDValue = entry._hdCount != null && !isNaN(entry._hdCount);
    if (enforceHDCount) console.assert(hasHDValue, 'Expected numbered HD', entry);

    // Environments, Locations, and Climates
    let preprocess = entry.environment?.trim() ?? '';
    if (!preprocess) {
        entry._environments = [];
        entry._locations = [];
        entry._climates = [];
    }
    else {
        preprocess = preprocess.replace(/[Tt]he /g, '').replace(/beneath /g, '').replace(/usually /g, '').replace(/especially /g, '').replace(/ only/g, '').replace(/[Aa]ny /g, '').trim();
        preprocess = preprocess.replace(/ and surrounding nations/g, ''); // TODO: This is hacky, and perhaps should be handled differently; it only applies to one Andoran entry
        preprocess = preprocess.length == 0 ? 'Any' : preprocess;

        // Climate, Location/Plane, Environment
        const climatesList = ['temperate', 'cold', 'warm', 'hot', 'tropical'];
        let environments: string[] = [];
        let locations: string[] = [];
        let climates: string[] = [];

        let split = Utils.splitMultiple(preprocess, [', and ', ', or ', ' and ', ' or ', ',', ';', '(', ')']);
        for (let str of split) {
            str = str.trim();
            if (str.length == 0) continue;

            let climateMatch = climatesList.filter(x => str.startsWith(x)).shift();
            if (climateMatch) {
                climates.push(climateMatch);
                str = str.slice(climateMatch.length).trim();
                if (str.length == 0) continue;
            }
            
            if (/^[A-Z]/g.test(str)) {
                // We are a location
                locations.push(str);
            }
            else {
                // We are an environment
                environments.push(str);
            }
        }
        
        // Manual adjustments
        for (let i = 0; i < environments.length; i += 1) {
            let env = environments[i];
            if (['living hosts in any climate', 'any urban'].includes(env)) {
                environments[i] = 'Any';
            }
        }

        // Replace Env & Location Aliases
        for (let key of Object.keys(envAliases)) {
            let aliases = envAliases[key];
            environments = environments.map(x => aliases.includes(x) ? key : x);
            for (let location of moveToLocations) {
                if (environments.includes(location)) locations.push(Utils.titleCase(location, true));
            }
            environments = environments.filter(x => !moveToLocations.includes(x));
        }
        for (let key of Object.keys(locationAliases)) {
            let aliases = locationAliases[key];
            locations = locations.map(x => aliases.includes(x) ? key : x);
        }
        
        entry._environments = Utils.unique(environments);
        entry._locations = Utils.unique(locations);
        entry._climates = Utils.unique(climates);

        if (entry._environments.length == 0) entry._environments.push('any');
        if (entry._climates.length == 0) entry._climates.push('any');
    }
}

type AliasMap = {[key:string]: (string|null)[]};
const envAliases: AliasMap = {
    'any': [null, '', 'during storms', 'lightning storms', 'except water', 'living hosts in climate'],
    'sky': ['skies'],
    'swamp': ['swamps'],
    'islands': ['island'],
    'jungle': ['jungles'],
    'lakes': ['lake'],
    'marshes': ['marsh'],
    'hills': ['hill', 'rocky hills'],
    'plains': ['plain'],
    'forest': ['deep forest', 'forests'],
    'underground': ['non-cold underground'],
    'desert': ['deserts'],
    'coast': ['coastal', 'coastline', 'coastlines', 'mountainous coastlines', 'coasts', 'coastal regions', 'shore', 'shorelines'],
    'freshwater': ['fresh water', 'ponds'],
    'ocean': ['oceans', 'saltwater'],
    'river': ['rivers'],
    'mountains': ['mountain', 'mountain valleys'],
    'ruins': ['ruin', 'former azlanti ruin'],
    'water': ['waterfalls', 'waters', 'underwater', 'aquatic'],
    'volcanic': ['volcano', 'volcanoes', 'volcanic mountains', 'volcanic underground'],
    /*
    'other': ['aboveground natural area', 'badlands', 'battlefield', 'battlefields', 'battle',
                'blighted land', 'gas giants', 'glaciers', 'graveyards', 'haunted sites', 'near ghouls',
                'wetlands', 'woodlands', 'world\'s moon', 'wilderness', 'outer space', 'pumpkin patches',
                'sewers', 'tar seeps', 'terrestrial vacuum', 'trenches', 'tundra', 'wastelands'],
    */
    'evil planes': ['evil Outer Planes', 'evil Outer Plane', 'evil outer planes', 'evil planes', 'evil-aligned planes', 'evil-aligned plane'],
    'good planes': ['good planes', 'good-aligned planes', 'good-aligned plane'],
    'lawful planes': ['lawful planes', 'lawful plane'],
    'elemental planes': ['elemental plane']
};
const moveToLocations = ['evil planes', 'good planes', 'lawful planes', 'extraplanar', 'elemental planes', 'necropolis of Nogortha',
                        'ramlock\'s hallow', 'demiplane', 'primal land of fey'];
const locationAliases: AliasMap = {
    'Shadow Plane': ['Shadow Plane', 'Plane of Shadow'],
    'Outer Planes': ['Outer Planes', 'Outer Plane'],
};