export default class Script {
    text = "";
    name?: string;
    markov = false;
    isCard = false;
    isBlock = false;
    tableCols?: string[];
    preserveWhitespace = false;
    variables: {[key:string]: string} = {};

    get hasVariables() {
        return Object.keys(this.variables).length > 0;
    }

    get isTable() {
        return this.tableCols != null && this.tableCols.length > 0
    }

    static parse(text: string): Script {
        let lines = text.split('\n');
        let line = lines.shift();
        let script = new Script();
        while (line != null) {
            if (line.startsWith('$#')) {
                script.name = line.slice(2).trim();
            }
            else if (line.startsWith('$MARKOV')) {
                script.markov = true;
            }
            else if (line.startsWith('$BLOCK')) {
                script.isBlock = true;
            }
            else if (line.startsWith('$CARD')) {
                script.isCard = true;
            }
            else if (line.startsWith('$PRESERVE TIWS')) {
                script.preserveWhitespace = true;
            }
            else if (line.startsWith('$TABLE')) {
                let remainder = line.slice('$TABLE'.length);
                if (remainder.startsWith(':')) remainder = remainder.slice(1);
                script.tableCols = remainder.trim().split('|').map(x => x.trim());
                script.isBlock = false;
            }
            else if (line.startsWith('$END')) {
                break;
            }
            else if (line.startsWith('$')) {
                let remainder = line.slice(1);
                let index = remainder.indexOf(':');
                let key = remainder.slice(0, index).trim();
                let value = remainder.slice(index+2); // Exclude the colon and the following space
                script.variables[key] = value;
            }
            else if (line.trim().length > 0) {
                lines.unshift(line);
                break;
            }
            line = lines.shift();
        }
    
        script.text = lines.join('\n');
        if (!script.preserveWhitespace) {
            script.text = script.text.trim()
        }
        return script;
    }
}