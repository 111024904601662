import Utils from "../Utils";

export default class LoreTemplate {
    data: string[] = [''];
    group: string = "";
    name: string = "";
    id: string;

    _blockBackup?: string[];
    _blockIndex: number = -1;
    _settings: ConfigSetting[] = [];

    constructor(name = 'New Template') {
        this.name = name;
        this.id = Utils.guid(false);
    }

    static hydrate(json: any) {
        return Utils.overwrite(new LoreTemplate(), json);
    }

    static getTitle(block: string) {
        let start = block.indexOf("$#");
        if (start < 0) return 'UNNAMED';
        else {
            let end = block.indexOf('\n');
            if (end < 0) {
                return block.slice(start+2).trim();
            }
            else {
                return block.slice(start+2, end).trim();
            }
        }
    }
}

export class ConfigSetting {
    varName: string;
    displayName: string;
    rootOptions = [] as ConfigOption[];

    constructor(varName: string) {
        this.varName = varName;
        this.displayName = `[${Utils.titleCase(varName.toLowerCase(), true)}]`;
    }
}

export class ConfigOption {
    displayName: string;
    childOptions = [] as ConfigOption[];
    _value: boolean|null = true;

    constructor(displayName: string) {
        this.displayName = displayName;
    }
}