import {Value} from '@/ts/api/characters/_index';
import DataUtils from '@/ts/util/DataUtils';
import Utils from '@/ts/util/Utils';

type Dynamic = {[key: string]: any};
type BonusMap = {[key: string]: Bonus};

export class Bonus extends Value {
    sourceId: string;
    bonusMode: BonusMode;
    disableReason: string;
    
    get isStat() { return false; }
    get isBonus() { return true; }

    constructor(json: Dynamic) {
        if (json == null) json = {};
        super(json);
        
        this.bonusMode = json.bonusMode ?? BonusMode.DEFAULT;
        this.disableReason = json.disableReason ?? "";
        this.sourceId = json.sourceId ?? "";

        if (Utils.isEmpty(this.sourceId)) console.warn('Bonus instantiated without sourceId', this);
    }

    static instantiate(obj: any): BonusMap {
        return DataUtils.initMap(obj, 'refName', x => new Bonus(x), 'Bonus');
    }

    static blank(refName: string, sourceId: string) {
        return new Bonus({refName, sourceId});
    }
}

export enum BonusMode {
    DEFAULT = 0,
    ENABLED = 1,
    DISABLED = 2
}