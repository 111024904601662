











































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import GamebookButton from '@/ui/Gamebooks/block_components/GamebookButton.vue';

import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { ButtonBlock } from '@/ts/gamebooks/parsing/Blocks';
import { GamebookMeta } from '@/ts/gamebooks/models/GamebookData';
var images = require.context('@/assets/gamebooks/', true, /\.((jpeg)|(jpg)|(png))$/);

@Component({
    components: {
        GamebookButton
    }
})
export default class CoverPage extends Vue {
    @Prop({required: true})
    session!: GamebookSession;

    get meta(): GamebookMeta {
        return this.session.gamebook.meta;
    }

    get coverSrc() {
        let meta = this.session.gamebook.meta;
        let id = meta.refId;
        let imageName = meta.coverImage;
        return images(`./${id}/${imageName}`);
    }

    get instructionsButtonBlock() {
        return new ButtonBlock('instructions', 'Learn How to Play');
    }

    get startButtonBlock() {
        return new ButtonBlock('start', 'Start Your Adventure!');
    }

    get supportLink() {
        return this.session.gamebook.meta.supportLink;
    }

    get supportDomain() {
        if (!this.supportLink) return undefined;
        let domain = this.supportLink.replace(/^https\:\/\//gi, '');
        let split = domain.split('/')[0].split('.');
        let post = split.pop();
        let pre = split.pop();
        if (pre && post) return `${pre}.${post}`;
        else return undefined;
    }
}
