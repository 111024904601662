










import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { TextBlock } from '@/ts/gamebooks/parsing/Blocks';

@Component({
    components: {
    }
})
export default class TextBlockDisplay extends Vue {
    @Prop({required: true})
    block!: TextBlock;

    get level() {
        return this.block.headerLevel;
    }

    get styleClasses() {
        let classes = [];
        if (this.block.format.center) classes.push('centered');
        return classes.join(' ');
    }
}
