import Vue from "vue";

export default class Segment {
    /**
     * The original string;
     * If this segment is a variable, it will be the complete template for the variable (e.g. "The town of [Town]" or "[Names.Places]").
     * Otherwise, it will either be a literal (e.g. " was born in the town of ") or a reference (e.g. "[Town]" or [town.name]). Never a combination of the two. */
    original: string;

    /** Reference to the Segment that contains this segment as a child or variable. This reference is used to resolve cascading variables. */
    parent?: Segment;

    /** The segments that were generated after evaluating the original string. If this segment is a literal or has not yet been evaluated, it will have 0 child segments */
    segments: Segment[] = [];

    /** Some references invoke scripts that declare variables. This is a map of those variables (if relevant) including their original string and their evaluation and child variables (if relevant) */
    variables?: {[key:string]: Segment};

    isTableSegment = false;

    _exp?: boolean; // Is display expanded

    constructor(original: string, parent?: Segment) {
        this.original = original;
        this.parent = parent;
    }

    create(text: string): Segment {
        return new Segment(text, this);
    }

    createAndAddChild(text: string): Segment {
        let child = this.create(text);
        this.segments.push(child);

        return child;
    }

    clearChildren() {
        this.segments.splice(0, this.segments.length);
    }

    clearVariables() {
        if (this.variables) {
            for (let key of Object.keys(this.variables)) {
                Vue.delete(this.variables, key);
            }
        }
    }

    resolveVariable(name: string, direction?: Direction): Segment|undefined {
        let vars = this.variables ?? {};
        let value = vars[name];
        if (value) return value;
        else if (direction == Direction.Up || this.segments.length > 1) return this.parent?.resolveVariable(name, Direction.Up);
        else if (direction == Direction.Down) return this.segments[0]?.resolveVariable(name, Direction.Down);
        else {
            let up = this.resolveVariable(name, Direction.Up);
            let down = this.resolveVariable(name, Direction.Down);
            return up ?? down;
        }
    }

    get isLiteral(): boolean {
        return !this.original.startsWith('[');
    }

    get isError(): boolean {
        return this.original.startsWith('[$') && this.original.includes('_ERR');
    }

    get hasError(): boolean {
        return this.isError || this.segments.some(x => x.hasError);
    }

    get text(): string {
        if (this.isLiteral || this.isError) return this.original;
        else if (this.segments.length == 0) {
            console.warn('Attempted to get text of unevaluated segment', this);
            return this.original;
        }
        else {
            return this.segments.map(x => x.text).join('');
        }
    }

    get displayText(): string {
        let output = this.text;
        
        let openIndex = output.indexOf('{a/an}');
        while (openIndex >= 0) {
            let pre = output.slice(0, openIndex);
            let post = output.slice(openIndex + '{a/an}'.length);
            let first = post.trim().charAt(0);
            let word = /[aeiou]/g.test(first) ? 'an' : 'a';

            output = pre + word + post;
            openIndex = output.indexOf('{a/an}');
        }

        let pronouns = this.resolveVariable('pronouns');
        if (pronouns) {
            openIndex = output.indexOf('{person}');
            while (openIndex >= 0) {
                let pre = output.slice(0, openIndex);
                let post = output.slice(openIndex + '{person}'.length);
                let word = pronouns.resolveVariable('default_identifier')?.text ?? '{person}';
    
                output = pre + word + post;
                openIndex = output.indexOf('{person}');
            }
    
            openIndex = output.indexOf('{t');
            while (openIndex >= 0) {
                let closeIndex = output.indexOf('}');
                let pre = output.slice(0, openIndex);
                let post = output.slice(closeIndex+1);
                let inner = output.slice(openIndex+1, closeIndex);

                let word = inner;
                let type: string|undefined;
                if (inner == 'they') type = 'subject';
                if (inner == 'them') type = 'object';
                if (inner == 'their') type = 'possessive_adj';
                if (inner == 'theirs') type = 'possessive_pro';
                if (inner == 'themself') type = 'reflexive';
                if (inner == "they're") type = 'contraction';

                if (type) {
                    word = pronouns.resolveVariable(type)?.text ?? inner;
                }
                else {
                    console.warn('Unknown conjugation: ' + inner);
                }
    
                output = pre + word + post;
                openIndex = output.indexOf('{t');
            }
        }

        // TODO: Post-process other functions

        return output;
    }

    get title(): string {
        return this.resolveVariable('TITLE')?.text ?? '';
    }

    get subtitle(): string {
        return this.resolveVariable('SUBTITLE')?.text ?? '';
    }

    get tableEntryMetadata() {
        let last = this.original.split('|').pop() ?? "";
        last = last.trim();

        if (last.startsWith('[*')) {
            let inner = last.slice(2, -1);
            let innerSplit = inner.split('&').map(x => x.trim());
            return {
                name: innerSplit[0],
                group: innerSplit[1] ?? innerSplit[0],
            }
        }
    }
}

export enum Direction {
    Up,
    Down,
}