


































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import { SceneLog } from '@/ts/gamebooks/SceneLog';
import { DiceMacro, GamebookSession, MacroComponent } from '@/ts/gamebooks/GamebookSession';
var images = require.context('@/assets/icons/dice/', false, /\.svg$/);

@Component({
    components: {
    }
})
export default class MacroBox extends Vue {
    @Prop({required: true})
    session!: GamebookSession;

    bonus = 0;
    qty = 1;

    get sceneLog(): SceneLog {
        return this.session.sceneLog;
    }
    
    showMacroEditor(macro: DiceMacro) {
        let $this = this as any;
        $this.$fire('show-dice-macro-editor', macro);
    }

    imgUrl(component: MacroComponent) {
        let matches = component.equationString.match(/d[0-9]+/);
        if (matches == null || matches.length == 0) return null;
        let fileName = matches[0] + '.svg';
        
        const dice = ['d4.svg', 'd6.svg', 'd8.svg', 'd10.svg', 'd12.svg', 'd20.svg'];
        if (!dice.includes(fileName)) return null;
        return images(`./${fileName}`);
    }

    roll(macro: DiceMacro) {
        for (let component of macro.components) {
            let macroText = component.equationString;
            this.sceneLog.push(`${component.name}: ${macroText} 🠖 [[${macroText}]]`);
        }
    }

    macroTooltip(macro: DiceMacro) {
        return macro.components.map(x => `${x.name}:\n${x.equationString}`).join('\n\n');
    }

    deleteMacro(index: number) {
        this.session.macros.splice(index, 1);
    }

    openContextMenu(event: any, macro: DiceMacro, index: number) {
        let $this = this;
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-fw pi-pencil',
                command: () => {
                    $this.showMacroEditor(macro);
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    $this.deleteMacro(index);
                }
            },
        ];
        (this as any).$toggleContextMenu(event, items);
    }
}
