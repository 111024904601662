































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import { SceneLog } from '@/ts/gamebooks/SceneLog';
var images = require.context('@/assets/icons/dice/', false, /\.svg$/);

@Component({
    components: {
    }
})
export default class Dicebox extends Vue {
    @Prop({required: true})
    sceneLog!: SceneLog;

    dice = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20'];
    bonus = 0;
    qty = 1;

    adv = 'None';
    advOptions = ['Advantage', 'Disadvantage', 'None'];

    imgUrl(fileName: string) {
        return images(`./${fileName}`);
    }

    roll(dName: string) {
        let macroText = `${this.qty}${dName}`;
        if (this.adv == 'Advantage') macroText = `${this.qty*2}${dName}k${this.qty}`;
        else if (this.adv == 'Disadvantage') macroText = `${this.qty*2}${dName}kl${this.qty}`;

        if (this.bonus > 0) macroText += ` + ${this.bonus}`;
        else if (this.bonus < 0) macroText += ` - ${Math.abs(this.bonus)}`;
        this.sceneLog.push(`${macroText} 🠖 [[${macroText}]]`);
    }
}
