import Utils from "../Utils";

export type ObjectTemplate = {
    [key:string]: string,
    key: string,
    _id: string,
};

export interface TemplateOrGroup {
    isTemplate: boolean;
    isGroup: boolean;

    parentPath: string;
    key: string;
    id: string;
}

export class TemplateGroup implements TemplateOrGroup {
    childGroups: TemplateGroup[] = [];
    children: ElementTemplate[] = [];
    parentPath = '';
    key = 'NewGroup';
    id = Utils.guid();

    isTemplate = false;
    isGroup = true;

    constructor(parentPath: string) {
        this.parentPath = parentPath;
    }

    static findAndRemove(target: TemplateOrGroup, array: TemplateOrGroup[]) {
        let index = array.indexOf(target);
        if (index >= 0) {
            array.splice(index, 1);
            return true;
        }

        let groups = array.filter(x => x.isGroup) as TemplateGroup[];
        for (let group of groups) {
            if (TemplateGroup.findAndRemove(target, group.childGroups)) return true;
            if (TemplateGroup.findAndRemove(target, group.children)) return true;
        }

        return false;
    }

    static getChildren(target: TemplateOrGroup): TemplateOrGroup[] {
        if (target.isTemplate) return [];
        else {
            let group = target as TemplateGroup;
            return (group.childGroups as TemplateOrGroup[]).concat(group.children)
        }
    }

    static flattenChildren(target: TemplateGroup): ElementTemplate[] {
        let children = [...target.children];
        for (let group of target.childGroups) {
            children.push(...TemplateGroup.flattenChildren(group));
        }
        return children;
    }

    static flattenAll(target: TemplateOrGroup): TemplateOrGroup[] {
        let array = [target];
        if (target.isGroup) {
            let group = target as TemplateGroup;
            for (let child of TemplateGroup.getChildren(group)) {
                array.push(... TemplateGroup.flattenAll(child));
            }
        }
        return array;
    }

    static getPath(target: TemplateOrGroup): string {
        return [...target.parentPath.split('.'), target.key].filter(x => x.trim().length > 0).join('.');
    }
}

export class ElementTemplate implements TemplateOrGroup {
    annonVariables: AnnonVariableTemplate[] = [];
    variables: VariableTemplate[] = [];
    key = 'NewTemplate';
    type: TemplateTypes;
    isTopLevel: boolean;

    isTemplate = true;
    isGroup = false;
    id = Utils.guid();
    parentPath: string;

    // For TopLevel Templates
    displayInfo: CardDisplayInfo;

    // For Object Templates
    objects: ObjectTemplate[] = [];

    // For Line Templates
    parts: string[] = [''];
    seperators: string[] = [];

    constructor(type: TemplateTypes, parentPath: string, isTopLevel = false) {
        this.type = type;
        this.parentPath = parentPath;
        this.isTopLevel = isTopLevel;
        this.displayInfo = new CardDisplayInfo();
    }
}

export class CardDisplayInfo {
    title = '';
    subtitle = '';
}

export enum TemplateTypes {
    Objects = 'Objects',
    Markov = 'Markov',
    Block = 'Block',
    Line = 'Line',
}

export const AllTemplateTypes = [TemplateTypes.Objects, TemplateTypes.Markov, TemplateTypes.Block, TemplateTypes.Line];

export class VariableTemplate {
    /**
     * This is how the variable will be referenced.
     * The key should be unique to the Template it is a part of.
     **/
    key = 'new_variable';

    /**
     * If unset, isInherited must be true.
     * If isInherited is true, and this is unset, the variable is considered required.
     * Otherwise, this value will be used when a reference does not specify the argument.
     **/
    defaultValue = '';

    /** Specifies whether or not references to the Template can specify this variable as an argument. */
    isInherited = false;
}

export class AnnonVariableTemplate {
    /** The key, in [], of the table to roll. E.g. "[NPC]" **/
    key = '';

    /** A human-readable label, for user-reference only. E.g. "Residents" or "Bakers" **/
    name = '';

    /** The number of templates to generate. Can reference dice, math, and numbers. E.g. "5d6*4 + 3" **/
    qtyEquation = '1';
}