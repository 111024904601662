import { SimpleCategories } from "../api/items/ItemEntry";
import { NumberSetting } from "../util/config/Setting";

export class CategoryWeights {
    wondrousItems = new NumberSetting('Wondrous Items', '', 0).setInitial(20);
    consumable = new NumberSetting('Consumables', '', 0).setInitial(20);
    weapons = new NumberSetting('Weapons', '', 0).setInitial(20);
    armor = new NumberSetting('Armor', '', 0).setInitial(20);
    misc = new NumberSetting('Misc.', '', 0).setInitial(20);

    get snapshot() {
        return {
            wondrousItems: this.wondrousItems.getGtdSnapshot(),
            consumable: this.consumable.getGtdSnapshot(),
            weapons: this.weapons.getGtdSnapshot(),
            armor: this.armor.getGtdSnapshot(),
            misc: this.misc.getGtdSnapshot(),
        };
    }

    get isEqual() {
        let values = Object.values(this.snapshot);
        let x = values[0];
        for (let value of values) {
            if (value != x) return false;
        }
        return true;
    }

    get rollableTable(): {weight: number, text: SimpleCategories}[] {
        let catTable: {weight: number, text: SimpleCategories}[] = [];
        let addTableEntry = (weight: number, category: SimpleCategories) => {
            if (weight > 0) catTable.push({weight, text: category});
        }

        let weights = this.snapshot;
        addTableEntry(weights.wondrousItems, SimpleCategories.WondrousItems);
        addTableEntry(weights.consumable, SimpleCategories.Consumables);
        addTableEntry(weights.weapons, SimpleCategories.Weapons);
        addTableEntry(weights.armor, SimpleCategories.Armor);
        addTableEntry(weights.misc, SimpleCategories.Misc);

        return catTable
    }
}