import { AttackDef, EnemyDef, EventDef, GamebookData, GamebookMeta, ImageDef, LinkDef, RefDef, RollDef } from "@/ts/gamebooks/models/GamebookData";
import SanityCheck from "@/ts/util/SanityCheck";
import Utils from "@/ts/util/Utils";
import { Block, BlockQuote, BlockTypes, ButtonBlock, ConditionalBlock, Divider, DropdownButtonBlock, EndNode, RefBlock, RefTypes, ScriptBlock, ScriptInstruction, Span, TextBlock } from "./Blocks";
import { Scene, Section, SectionTypes, GamebookDocument, DocTypes, CombatScene } from "./Scene";

export class Gamebook {
    scenes: {[key: string]: Scene} = {};
    combats: {[key: string]: CombatScene} = {};
    infoDocs: {[key: string]: GamebookDocument} = {};
    handouts: {[key: string]: GamebookDocument} = {};
    startId = '';
    
    rolls: {[key: string]: ParsedRoll} = {};
    refs: {[key: string]: RefDef} = {};
    links: {[key: string]: LinkDef} = {};
    images: {[key: string]: ImageDef} = {};
    events: {[key: string]: EventDef} = {};
    enemies: {[key: string]: ParsedEnemy} = {};

    meta: GamebookMeta;

    addScene(scene: Scene) {
        this.scenes[scene.id] = scene;
        if (this.startId == '') this.startId = scene.id;
    }

    addCombat(scene: CombatScene) {
        this.combats[scene.id] = scene;
        if (this.startId == '') this.startId = scene.id;
    }

    addDocument(doc: GamebookDocument) {
        if (doc.docType == DocTypes.Handout) {
            this.handouts[doc.id] = doc;
        }
        else if (doc.docType == DocTypes.Info) {
            this.infoDocs[doc.id] = doc;
        }
        else {
            console.warn('Unrecognized doc type in Gamebook', doc);
        }
    }

    constructor(meta: GamebookMeta) {
        this.meta = meta;
    }
}

export class ParsedRoll {
    name: string;
    dc: number;

    descriptionBlocks: Block[] = [];
    successBlocks: Block[] = [];
    failBlocks: Block[] = [];
    
    tieredBlocks: {[key: string]: Block[]}|null = null;

    constructor(def: RollDef) {
        this.name = def.name;
        this.dc = def.dc;
    }
}

export class ParsedEnemy {
    name: string;
    credit: string;
    imageRef?: string;
    tactics?: string; // TODO: parse this
    attacks: {[key: string]: AttackDef} = {};

    size: string;
    type: string;
    alignment: string;
    specialDetails?: string;
    
    ac: number;
    hp: number;
    str: number;
    dex: number;
    con: number;
    int: number;
    wis: number;
    cha: number;

    constructor(def: EnemyDef) {
        this.name = def.name;
        this.credit = def.ref;
        this.imageRef = def.image;

        this.size = def.size;
        this.type = def.type;
        this.alignment = def.alignment;
        this.specialDetails = def.specialDetails;

        this.ac = def.ac;
        this.hp = def.hp;
        this.str = def.str;
        this.dex = def.dex;
        this.con = def.con;
        this.int = def.int;
        this.wis = def.wis;
        this.cha = def.cha;
    }
}

export class GamebookParser {
    static parse(scriptString: string, data: GamebookData) {
        let gamebook = new Gamebook(data.meta);
        gamebook.images = data.images;
        gamebook.events = data.events;
        gamebook.links = data.links;
        gamebook.refs = data.refs;

        for (let [k, v] of Object.entries(data.rolls)) {
            let parsed = new ParsedRoll(v);
            if (v.description) parsed.descriptionBlocks.push(...GamebookParser.parseBlocks(v.description));
            parsed.successBlocks.push(...GamebookParser.parseBlocks(v.success));
            parsed.failBlocks.push(...GamebookParser.parseBlocks(v.fail));
            if (v.tiers) {
                parsed.tieredBlocks = {};
                for (let tier of Object.keys(v.tiers)) {
                    let blocks = GamebookParser.parseBlocks(v.tiers[tier])
                    parsed.tieredBlocks[tier] = blocks;
                }
            }
            gamebook.rolls[k] = parsed;
        }

        for (let [k, v] of Object.entries(data.enemies)) {
            let parsed = new ParsedEnemy(v);
            // TODO: Parse tactics
            gamebook.enemies[k] = parsed;
        }

        for (let [k, atk] of Object.entries(data.attacks)) {
            atk = Utils.deepCopy(atk);
            let enemy = gamebook.enemies[atk.enemyId];
            if (enemy == undefined) console.warn('Attack references non-existent enemy', {enemyId: atk.enemyId, atk});
            enemy.attacks[k] = atk;
        }

        scriptString = scriptString.replace(/\r\n/g, '\n');
        let blockStrings = scriptString.split('<===>').map(x => x.trim()).filter(x => x.length > 0);
        for (let blockString of blockStrings) {
            let lines = blockString.split('\n');
            let firstLine = SanityCheck.notNull(lines.shift());
            let section = GamebookParser.tryCreateSection(firstLine);

            if (section == undefined) continue;
            else if (section.type == SectionTypes.Scene) {
                let scene = section as Scene;
                let blocks = GamebookParser.parseBlocks(lines);
                
                let endIndex = Utils.indexOf(blocks, x => x.type == BlockTypes.Divider && (x as Divider).isEndSection);
                let endBlocks = endIndex >= 0 ? blocks.slice(endIndex) : [];
                blocks = endIndex >= 0 ? blocks.slice(0, endIndex) : blocks;

                scene.blocks.push(...blocks);
                scene.endBlocks.push(...endBlocks);
                gamebook.addScene(scene);
            }
            else if (section.type == SectionTypes.Document) {
                let document = section as GamebookDocument;
                let blocks = GamebookParser.parseBlocks(lines);
                document.blocks.push(...blocks);
                gamebook.addDocument(document);
            }
            else if (section.type == SectionTypes.Combat) {
                let combat = section as CombatScene;

                let preBlocks: string[] = [];
                while (lines.length > 0) {
                    if (lines[0].startsWith('[')) break;
                    else {
                        let x = SanityCheck.notNull(lines.shift());
                        preBlocks.push(x);
                    }
                }
                let blocks = GamebookParser.parseBlocks(preBlocks);
                blocks.forEach(x => {
                    if (x.type == BlockTypes.Text) {
                        let textBlock = x as TextBlock;
                        textBlock.format.center = true;
                    }
                });
                combat.blocks.push(...blocks);

                for (let line of lines) {
                    if (!line.startsWith('[') || !line.endsWith(']')) {
                        console.warn('Expected combat lines to be bracket wrapped.', {line, firstLine});
                        continue;
                    }

                    // Parse enemies, win, lose, and extra choices
                    if (line.startsWith('[enemy:')) {
                        let inner = line.slice('[enemy:'.length, line.length-1);
                        let qty = 1;
                        if (/\|[0-9]+$/g.test(inner)) {
                            let split = inner.split('|');
                            let last = SanityCheck.notNull(split.pop());
                            inner = split.join('|');
                            qty = parseInt(last);
                            if (isNaN(qty)) {
                                console.warn('EnemyQty parse error: NaN.', {qty, last, line});
                                qty = 1;
                            }
                        }

                        if (!/^[a-zA-Z_][a-zA-Z_0-9]*$/g.test(inner)) console.warn('EnemyId format is not valid', {id: inner, line});
                        for (let i = 0; i < qty; i++) combat.enemyIds.push(inner);
                    }
                    else if (line.startsWith('[event:')) {
                        let inner = line.slice('[event:'.length, line.length-1);
                        if (!/^[a-zA-Z_][a-zA-Z_0-9]*$/g.test(inner)) console.warn('EventId format is not valid', {id: inner, line});
                        combat.eventIds.push(inner);
                    }
                    else if (/^\[((win)|(lose)) ?=>/.test(line)) {
                        let parsed = GamebookParser.parseBlocks(line);
                        if (parsed.length != 1) console.warn('Expected win/lose choice line to contain one block', {parsed, line});
                        else if (parsed.length > 0) {
                            if (parsed[0].type != BlockTypes.Button) console.warn('Expected win/lose choice line to contain Button block', {parsed, line});
                            else {
                                let resolutionBlock = parsed[0] as ButtonBlock;
                                let isWin = resolutionBlock.buttonText == 'win';
                                resolutionBlock.buttonText = isWin ? 'You Win' : 'You Lose';
                                resolutionBlock.iconRef = isWin ? 'win' : 'lose';

                                if (isWin) combat.winBlock = resolutionBlock;
                                else combat.loseBlock = resolutionBlock;
                            }
                        }
                    }
                    else {
                        // This is an alternate end condition
                        let altBlocks = GamebookParser.parseBlocks(line);
                        if (altBlocks.some(x => x.type != BlockTypes.Button)) console.warn('Parsing error: expected only button types', {line, altBlocks});
                        combat.altChoices.push(...(altBlocks as ButtonBlock[]));
                    }
                }

                gamebook.addCombat(combat);
            }
            else {
                console.warn('Unsupported section type.', {firstLine, section});
                continue;
            }
        }
        return gamebook;
    }

    static parseBlocks(lines: string|string[]): Block[] {
        if (lines.length == 0) return [];
        if (!Array.isArray(lines)) {
            lines = lines.replace(/\r\n/g, '\n').split('\n');
        }

        let blocks = [] as Block[];
        let lineIndex = -1;
        while (lineIndex < lines.length-1) {
            lineIndex += 1;
            let line = lines[lineIndex];
            
            let trimmed = line.trim();
            if (trimmed.length == 0) {
                continue;
            }
            else if (trimmed == '---' || trimmed == '===') {
                blocks.push(new Divider());
            }
            else if (trimmed.startsWith('> ')) {
                let block = new BlockQuote();
                let blockLines = [] as string[];
                while (trimmed.startsWith('> ')) {
                    blockLines.push(trimmed.slice(2));

                    lineIndex += 1;
                    line = (lineIndex < lines.length) ? lines[lineIndex] : '';
                    trimmed = line.trim();
                }
                let innerBlocks = GamebookParser.parseBlocks(blockLines);
                block.blocks.push(...innerBlocks);

                blocks.push(block);
            }
            else if (trimmed == '[$the_end]') {
                blocks.push(new EndNode());
            }
            else if (/^\[[^\]]+\]$/g.test(trimmed)) { // This line is a single reference, e.g. [enemy:jackal|2] or [info:combat_instructions] or [roll:sneakPastJackals]
                let inside = trimmed.slice(1, trimmed.length-1);
                if (['enemy:', 'info:', 'handout:', 'image:', 'roll:'].some(x => inside.startsWith(x))) { // RefBlock
                    let colonIndex = inside.indexOf(':');
                    let type = inside.slice(0, colonIndex);
                    let refData = inside.slice(colonIndex+1);

                    let pipeIndex = refData.indexOf('|');
                    let hasPipe = pipeIndex > 0;
                    let preData = hasPipe ? refData.slice(0, pipeIndex) : refData;
                    let pipedData = hasPipe ? refData.slice(pipeIndex+1) : '';
                    if (pipedData.includes('|')) console.warn('Script contains multiple pipe characters in single line reference. This may be intentional.', {inside, line});
                    
                    let refType = type as RefTypes;
                    if ([RefTypes.Enemy, RefTypes.Image, RefTypes.Info, RefTypes.Handout, RefTypes.Roll].includes(refType)) {
                        let block = new RefBlock(preData, refType);
                        if (pipedData != '') {
                            if ([RefTypes.Info, RefTypes.Handout, RefTypes.Roll].includes(refType)) {
                                console.warn('Unexpected pipe in single-line reference.', {type, inside, line});
                            }
                            else if (refType == RefTypes.Enemy) {
                                let arrowIndex = pipedData.indexOf('=>');
                                if (arrowIndex >= 0) {
                                    let refId = pipedData.slice(arrowIndex+2);
                                    block.linkedCombatId = refId;
                                    if (!/^[a-zA-Z_][a-zA-Z_0-9]*$/g.test(refId)) console.warn('Invalid refId found in enemy ref.', {refId, inside, line});
                                    pipedData = pipedData.slice(0, arrowIndex);
                                }

                                if (pipedData.length > 0) {
                                    if (!/^[1-9][0-9]*$/g.test(pipedData)) console.warn('Unexpected pipe format in single-line reference.', {type, inside, pipedData, line});
                                    else {
                                        block.qty = parseInt(pipedData);
                                        if (isNaN(block.qty)) console.warn('Enemy qty is NaN', {type, inside, pipedData, line});
                                    }
                                }
                            }
                            else if (refType == RefTypes.Image) {
                                if (!/^((right)|(left)|(center))$/g.test(pipedData)) console.warn('Unsupported image alignment.', {type, inside, pipedData, line});
                                else block.align = pipedData;
                            }
                            else {
                                console.warn('Unsupported pipe in single-line reference.', {type, refType, inside, line});
                            }
                        }
                        blocks.push(block);
                    }
                    else console.warn('Unsupported single-line type.', {type, inside, line});
                }
                else if (inside.includes('(dropdown:')) { // DropdownButtonBlock
                    // E.g., [Or slip him a bribe of (dropdown: 1 copper, 1 silver, 1 gold)|(Bribe him=>bribeFail)|(Bribe him=>bribeFail)|(Bribe him=>bribeSuccecss)]
                    // TODO: Use the same parse code for dropdown choices and button blocks
                    let pre = inside.split('(dropdown:')[0];
                    let post = SanityCheck.notNull(inside.split(')').pop());
                    let dropdownScript = inside.slice(pre.length, inside.length - post.length);
                    let split = Utils.splitNonGroup(dropdownScript, ['|']);

                    let primary = SanityCheck.notNull(split.shift());
                    if (!/^\(dropdown:[^\)]+\)$/.test(primary)) {
                        console.warn('Unsupported DropdownButtonBlock format.', {primary, inside, line});
                        continue;
                    }

                    let block = new DropdownButtonBlock(pre.trim().length == 0 ? undefined : pre, post.trim().length == 0 ? undefined : post);
                    let options = primary.slice('(dropdown:'.length, primary.length-1).split(',').map(x => x.trim());
                    block.options.push(...options);
                    for (let element of split) {
                        element = element.trim();
                        if (!/^\([^\)]+\)$/.test(element)) {
                            console.warn('Unsupported DropdownButtonBlock option format. Expected parenthesis.', {element, primary, inside, line});
                        }
                        else {
                            let subSplit = element.slice(1, element.length-1).split('=>').map(x => x.trim());
                            if (subSplit.length != 2) {
                                console.warn('Unsupported DropdownButtonBlock option format. Expected one =>', {element, primary, inside, line})
                            }
                            else {
                                block.results.push({buttonText: subSplit[0], refId: subSplit[1]});
                            }
                        }
                    }
                    blocks.push(block);
                }
                else { // ButtonBlock
                    let arrowIndex = inside.indexOf('=>');
                    if (arrowIndex < 0) {
                        console.warn('Unsupported ButtonBlock format. No arrow found.', {inside, line});
                        // TODO: Insert the inside text?
                        continue;
                    }
                    
                    let buttonData = inside.slice(0, arrowIndex).trim();
                    let refId = inside.slice(arrowIndex+2).trim();
                    let condition = null as string|null;
                    let ifRefId = null as string|null;

                    if (refId.startsWith('(') && refId.endsWith(')')) {
                        let insideConditional = refId.slice(1, refId.length-1).trim();
                        let qIndex = insideConditional.indexOf('?');
                        if (qIndex < 0) {
                            console.warn('Expected button conditional to contain question-mark syntax', {refId, insideConditional, inside, line});
                            continue;
                        }
                        else {
                            let results = insideConditional.slice(qIndex+1).trim().split(':').map(x => x.trim());
                            // TODO: use Utils.splitNonGroups to support nested conditionals
                            if (results.length != 2) {
                                console.warn('Expected button conditional to contain one colon. Note: nested conditionals not yet supported.', {refId, insideConditional, inside, line});
                                continue;
                            }
                            else {
                                condition = insideConditional.slice(0, qIndex).trim();
                                ifRefId = results[0]; // The refId to jump to if the condition is true
                                refId = results[1]; // The fallback refId to jump to if the condition is false
                            }
                        }
                    }

                    if (/^[a-z_0-9.]+$/gi.test(refId)) {
                        // Split pipes and parse button block
                        let split = Utils.splitNonGroup(buttonData, ['|']).map(x => x.trim());
                        if (split.length > 4) console.warn('Invalid data format found in single-line button declaration.', {refId, inside, line, split});
                        else {
                            let block = new ButtonBlock(refId);
                            if (condition) block.condition = condition;
                            if (ifRefId) block.refIdOnSuccess = ifRefId;

                            let last = split[split.length-1];
                            if (last.trim() == '*inline') {
                                split.pop();
                                block.inlineResults = true;
                                last = split[split.length-1];
                            }
                            if (last.startsWith('icon:')) {
                                let iconId = last.slice('icon:'.length).trim();
                                if (!/^[a-zA-Z_][a-zA-Z_0-9]*$/g.test(iconId)) console.warn('Invalid iconId found in single-line button declaration.', {iconId, inside, line});

                                split.pop();
                                block.iconRef = iconId;
                                last = split[split.length-1];
                            }

                            if (split.length == 1) {
                                block.buttonText = split[0];
                            }
                            else {
                                if (split.length > 2) console.warn('Invalid data format found in single-line button declaration.', {refId, inside, line, split});
                                block.aboveText = split[0];
                                block.buttonText = split[1];
                            }

                            blocks.push(block);
                        }
                    }
                    else {
                        console.warn('Invalid refId found in single-line button declaration.', {refId, inside, line});
                        continue;
                    }
                }
            }
            else if (trimmed.startsWith('{')) { // This line is a directive, e.g. {bottom_choices|Where do you want to go now?}
                if (/^\{bottom_choices[^\}]*\}$/g.test(trimmed)) {
                    let split = trimmed.slice(1, trimmed.length-1).split('|');
                    let first = SanityCheck.notNull(split.shift());
                    if (first != 'bottom_choices') {
                        console.warn('Unexpected split of bottom_choices.', {line, split});
                    }
                    else {
                        // TODO: Mark and Stylize bottom choices differently
                        let text = (split.length == 0) ? 'What would you like to do?' : split.join('|');
                        let textBlock = GamebookParser.parseTextBlock(`**${text}**`);
                        textBlock.format.center = true;
                        blocks.push(new Divider(true), textBlock, new Divider());
                    }
                }
                else {
                    // TODO
                    console.warn('Unsupported line start in Scene.', {line});
                }
            }
            else if (trimmed.startsWith('(if:') || trimmed.startsWith('(else:')) { // This line is a conditional, e.g. (if: $password is true) or (else:)
                // TODO: Support else if
                let index = Utils.findGroupClose(trimmed, '(', ')');
                if (index < 0) {
                    console.warn('Unclosed conditional found in script. Skipping line.', {line});
                    continue;
                }
                
                let inner = trimmed.slice(1, index);
                let remainder = trimmed.slice(index+1).trim();
                let blockLines = [] as string[];
                if (!remainder.startsWith('{')) {
                    blockLines.push(remainder);
                }
                else {
                    remainder = remainder.slice(1);
                    if (remainder.endsWith('}')) {
                        blockLines.push(remainder.slice(0, remainder.length-1));
                    }
                    else {
                        blockLines.push(remainder);
                        // TODO: This parsing needs to account for nested {} blocks
                        let closingLineIndex = Utils.indexOf(lines, x => x.trim().endsWith('}') && !x.includes('{'), lineIndex+1);
                        if (closingLineIndex < 0) {
                            console.warn("Unclosed conditional block found in script (no closing '}'). Using single line only.", {line});
                        }
                        else {
                            blockLines.push(...lines.slice(lineIndex+1, closingLineIndex+1));
                            let last = SanityCheck.notNull(blockLines.pop());
                            last = last.trim().slice(0, last.length-1);
                            blockLines.push(last);

                            lineIndex = closingLineIndex;
                        }
                    }
                }
                
                let colonIndex = inner.indexOf(':'); // TODO: Assert >= 0
                let front = inner.slice(0, colonIndex);
                inner = inner.slice(colonIndex+1).trim();
                let conditionalBlock = new ConditionalBlock(inner);
                if (front == 'if') {
                    conditionalBlock.ifBlocks.push(...GamebookParser.parseBlocks(blockLines));
                }
                else if (front == 'else') {
                    let lastBlock = Utils.last(blocks);
                    if (lastBlock == undefined || lastBlock.type != BlockTypes.Conditional) {
                        console.warn('Gamebook parser found else conditional without corresponding if. Skipping block.', {front, line, lastBlock, blocks});
                        continue;
                    }
                    else {
                        conditionalBlock = lastBlock as ConditionalBlock;
                        conditionalBlock.elseBlocks.push(...GamebookParser.parseBlocks(blockLines));
                        continue;
                    }
                }
                else {
                    // TODO: Support else if
                    console.warn('Unexpected conditional front.', {front, line});
                    continue;
                }
                blocks.push(conditionalBlock);
            }
            else if (trimmed.startsWith('(')) { // This line is an inline script directive, e.g. (set: $map to false) or ($delay)
                if (!trimmed.endsWith(')')) {
                    console.warn('Expected script directive to end with close-parenthesis', {line});
                }
                else {
                    trimmed = trimmed.slice(1, trimmed.length-1);
                    let colonIndex = trimmed.indexOf(':');
                    let instruction = (colonIndex < 0) ? '' : trimmed.slice(0, colonIndex);
                    let remainder = (colonIndex < 0) ? trimmed : trimmed.slice(colonIndex+1).trim();
                    if (instruction == 'set') {
                        let split = remainder.split(' to ');
                        if (split.length != 2) {
                            console.warn('Unexpected arguments to script directive \'set\'', {instruction, line, split});
                        }
                        else {
                            let scriptBlock = new ScriptBlock(ScriptInstruction.Set);
                            scriptBlock.target = split[0].trim();
                            if (split[1] == 'true') {
                                scriptBlock.value = true;
                            }
                            else if (split[1] == 'false') {
                                scriptBlock.value = false;
                            }
                            else {
                                console.warn('Unknown value found for script directive', {instruction, line, target: split[0], value: split[1]});
                                // TODO: Support non-boolean values
                                continue;
                            }
                            blocks.push(scriptBlock);
                        }
                    }
                    else {
                        console.warn('Unknown script directive', {instruction, line});
                    }
                }
            }
            else {
                let textBlock = GamebookParser.parseTextBlock(line);
                blocks.push(textBlock);
            }
        }

        return blocks;
    }

    static parseTextBlock(line: string) {
        let block = new TextBlock();
        if (/^#+ /g.test(line)) {
            let index = 0;
            for (let c of line) {
                if (c == '#') index++;
                else break;
            }
            block.headerLevel = index;
            line = line.slice(index).trim();
        }

        let bold = false;
        let quote = false;
        let italics = false;
        let text = "";
        let createSpan = (text: string) => {
            let span = new Span(text);
            if (bold) span.isBold = true;
            if (quote) span.isQuote = true;
            if (italics) span.isItalic = true;
            block.spans.push(span);
            return span;
        }
        let process = () => {
            if (text.length > 0) {
                createSpan(text);
                text = "";
            }
        };

        for (let index = 0; index < line.length; index++) {
            let c = line[index];
            let nextChar = index == line.length ? undefined : line[index + 1];

            if (c == '*') {
                process();
                if (nextChar == '*') {
                    bold = !bold;
                    index++;
                }
                else {
                    italics = !italics;
                }
            }
            else if (c == '"') {
                if (!quote) {
                    process();
                    text += c;
                    quote = true;
                }
                else {
                    text += c;
                    process();
                    quote = false;
                }
            }
            else if (c == '[') {
                let endIndex = line.indexOf(']', index);
                if (endIndex < 0) {
                    console.warn('Script contains unclosed bracket', {line, index});
                    text += line.slice(index);
                    break;
                }
                else {
                    process();
                    let inside = line.slice(index+1, endIndex);

                    if (['ref:', 'link:', 'roll:', 'info:', 'handout:'].some(x => inside.startsWith(x))) {
                        let colonIndex = inside.indexOf(':');
                        let type = inside.slice(0, colonIndex);
                        let refData = inside.slice(colonIndex+1);

                        let pipeIndex = refData.indexOf('|');
                        let hasPipe = pipeIndex > 0;
                        let refId = hasPipe ? refData.slice(0, pipeIndex) : refData;
                        let pipedData = hasPipe ? refData.slice(pipeIndex+1) : '';
                        if (pipedData.includes('|')) console.warn('Script contains multiple pipe characters in inline reference. This may be intentional.', {inside, line});
                        
                        let span = createSpan('');
                        span.text = pipedData;
                        if (type == 'ref') span.inlineRefId = refId;
                        else if (type == 'link') span.inlineLinkId = refId;
                        else if (type == 'roll') span.inlineRollId = refId;
                        else if (type == 'info') span.linkedPopupId = refId;
                        else if (type == 'handout') span.linkedPopupId = refId;
                        else console.warn('Unsupported inline type.', {type, inside, line});
                    }
                    else {
                        console.warn('Unsupported inline type.', {inside, line});
                        // TODO: Insert the inside text?
                    }

                    index = endIndex;
                }
            }
            else if (c == ']') {
                console.warn('Script contains unopened closing bracket (])', {line, index});
            }
            else {
                text += c;
            }
        }

        process();
        return block;
    }

    static tryCreateSection(firstLine: string): Section|undefined {
        if (!firstLine.startsWith('{') || !firstLine.endsWith('}')) {
            console.warn('Unexpected script format. Block does not start with definition.', {firstLine});
            return undefined;
        }
        else {
            let pairs = firstLine.slice(1, firstLine.length-1).split(',').map(x => x.trim());
            let typeDef = SanityCheck.notNull(pairs.shift());
            let index = typeDef.indexOf(':');
            if (index < 1) {
                console.warn('Unexpected script format. Block does not start with type:id.', {firstLine});
                return undefined;
            }
            
            let type = typeDef.slice(0, index);
            let id = typeDef.slice(index+1).trim();
            let data = Utils.parsePairs(pairs, {firstLine, pairs, message: 'Unexpected script component format.'});

            if (type == 'scene') {
                let scene = new Scene(id);
                if (data.location) {
                    if (typeof data.location == 'string') scene.location = data.location;
                    else console.warn('Unexpected scene data. Location should be a string.', {location: data.location});
                }
                if (data.transit) {
                    if (typeof data.transit == 'string') scene.transit = data.transit;
                    else console.warn('Unexpected scene data. Transit should be a string.', {transit: data.transit});
                }
                if (data.align) {
                    if (typeof data.align == 'string' && ['center', 'left'].includes(data.align)) scene.align = data.align;
                    else console.warn('Unexpected scene data. Align should be a string, and only "center" and "left" is currently supported.', {transit: data.transit});
                }
                if (Object.keys(data).filter(x => !['location', 'transit', 'align'].includes(x)).length > 0) console.warn('Unexpected keys found for scene.', {data, scene});
                return scene;
            }
            else if (['info', 'handout'].includes(type)) {
                let doc = new GamebookDocument(id, type as DocTypes);
                if (data.save) {
                    if (data.save == 'true') doc.save = true;
                    else if (data.save == 'false') doc.save = false;
                    else console.warn('Unexpected doc data. Location should be a boolean.', {save: data.save});
                }
                if (Object.keys(data).filter(x => !['save'].includes(x)).length > 0) console.warn('Unexpected keys found for doc.', {data, doc});
                return doc;
            }
            else if (type == 'combat') {
                let scene = new CombatScene(id);
                if (data.location) {
                    if (typeof data.location == 'string') scene.location = data.location;
                    else console.warn('Unexpected combat data. Location should be a string.', {location: data.location});
                }
                if (data.simulcap) {
                    let simulcap = parseInt(data.simulcap);
                    if (!isNaN(simulcap)) scene.simulcap = simulcap;
                    else console.warn('Unexpected combat data. Simulcap should be a number.', {simulcap: data.simulcap});
                }
                if (Object.keys(data).filter(x => !['location', 'simulcap'].includes(x)).length > 0) console.warn('Unexpected keys found for combat.', {data, scene});
                return scene;
            }
            else {
                console.warn('Unsupported section type.', {firstLine, type});
                return undefined;
            }
        }
    }
}