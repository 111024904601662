import { Setting } from "@/ts/util/config/Setting";
import Utils from "../Utils";
import { Value, Filter, Option } from "./_index";

export type ConfigMap<T> = {[key: string]: FilterConfig<T>}
export type OptionGroup = {
    name: string,
    description?: string,
    getKeys: (options: Option[]) => (string|number)[]
};

export const NONE_KEY = '__none';
export const OTHER_KEY = '__other';

type GetValueFunc<T> = (entry: T, filters?: {[key: string]: Filter<T>}, settings?: {[key:string]: Setting}) => Value|Value[];

export abstract class FilterConfig<T> {
    abstract type: FilterTypes;

    /** The user-readable name of the filter */
    name: string;
    description?: string;
    adv?: boolean;

    filterGroup?: string;

    /**
     * Getter function.
     * Each entry in the primary list can have one or more values.
     * An entry will be included in the filtered list iff one or more of the values matches the filter condition.
     * 
     * @param entry: The entry to get values from
     * @param filters: All filters in the same group. Some filters depend on the state of other filters to determine their values (e.g. Spell Level depends on which Spell Lists are selected). This argument gives you access to that context. If this argument is undefined, return values as if all context were in its default state. This is done if the system needs to calculate option values dynamically.
     */
    getValues: GetValueFunc<T>;

    // Sanity Checks
    expectedValueType?: string;
    assertNonNull?: boolean;

    constructor(name: string, getValues: GetValueFunc<T>) {
        this.name = name;
        this.getValues = getValues;
    }

    setDescription(description: string) {
        this.description = description;
        return this;
    }

    setGroup(group: string) {
        this.filterGroup = group;
        return this;
    }

    advOnly() {
        this.adv = true;
        return this;
    }

    static initConfigMap<T>(map: ConfigMap<T>) {
        for (let entry of Object.values(map)) {
            if (entry.type == FilterTypes.Category) {
                let config = entry as CategoryConfig<T>;
                let notSet = config.toggleGroups == undefined || config.toggleGroups.length == 0;
                if (notSet) {
                    config.setToggleGroups([
                        {
                            name: 'All',
                            description: `Toggle all ${Utils.plural(config.name)}`,
                            getKeys(options: Option[]) {
                                return options.map(x => x.key);
                            }
                        }
                    ]);
                }
            }
        }
    }
}

export class BoolConfig<T> extends FilterConfig<T> {
    boolMode = BooleanMode.Default;
    type = FilterTypes.Bool;

    constructor(name: string, getValues: GetValueFunc<T>) {
        super(name, getValues);
    }

    setMode(mode: BooleanMode): BoolConfig<T> {
        this.boolMode = mode;
        return this;
    }

    setTriState(): BoolConfig<T> {
        this.boolMode = BooleanMode.TriState;
        return this;
    }
}

export class CategoryConfig<T> extends FilterConfig<T> {
    type = FilterTypes.Category;

    hideFilterToggle?: boolean;
    toggleGroups?: OptionGroup[];
    options?: Option[];
    
    settings = {} as {[key:string]: Setting};

    // If options is undefined, they will be autocalculated based on the following settings
    otherOption?: Option;
    noneOption?: Option;
    titleCase?: boolean;
    otherThreshold?: number;

    constructor(name: string, getValues: GetValueFunc<T>) {
        super(name, getValues);
        this.useNone();
    }

    hideAndOr(): CategoryConfig<T> {
        this.hideFilterToggle = true;
        return this;
    }

    setToggleGroups(toggleGroups: OptionGroup[]): CategoryConfig<T> {
        this.toggleGroups = toggleGroups;
        return this;
    }

    setOptions(options: Option[]): CategoryConfig<T> {
        this.options = options;

        let other = this.options.filter(x => x.key == OTHER_KEY).pop();
        let none = this.options.filter(x => x.key == NONE_KEY).pop();

        if (other != null) this.otherOption = other;
        else if (this.otherOption != null) this.options.unshift(this.otherOption);

        if (none != null) this.noneOption = none;
        else if (this.noneOption != null) this.options.unshift(this.noneOption);

        return this;
    }

    addSetting(key: string, setting: Setting): CategoryConfig<T> {
        this.settings[key] = setting;
        return this;
    }

    useTitleCase(): CategoryConfig<T> {
        this.titleCase = true;
        return this;
    }

    condense(threshold=4) {
        this.otherThreshold = threshold;
        this.useOther();
        return this;
    }

    useOther(): CategoryConfig<T> {
        if (this.options != null) {
            this.otherOption = this.options.filter(x => x.key == OTHER_KEY).pop();
        }

        if (this.otherOption == null) {
            this.otherOption = new Option(OTHER_KEY, 'Other', 0);
            this.options?.unshift(this.otherOption);
        }

        return this;
    }

    useNone(): CategoryConfig<T> {
        if (this.options != null) {
            this.noneOption = this.options.filter(x => x.key == NONE_KEY).pop();
        }

        if (this.noneOption == null) {
            this.noneOption = new Option(NONE_KEY, 'None', 0);
            this.options?.unshift(this.noneOption);
        }
        
        return this;
    }

    noNone(): CategoryConfig<T> {
        if (this.options != null) {
            let none = this.options.filter(x => x.key == NONE_KEY).pop();
            if (none) {
                let index = this.options.indexOf(none);
                if (index >= 0) this.options.splice(index, 1);
            }
        }

        this.noneOption = undefined;
        return this;
    }
}

export class RangeConfig<T> extends FilterConfig<T> {
    type = FilterTypes.Range;

    useNullConfigSetting?: boolean; // Specifies whether or not the user should be able to enable/disable "Include Undefined Entries"
    stringConverter?: (value: string) => number; // If undefined, strings will be treated as null
    minMin?: number; // The lowest valid value (inclusive)
    maxMax?: number; // The highest valid value (inclusive)

    constructor(name: string, getValues: GetValueFunc<T>) {
        super(name, getValues);
    }

    useNullConfig() {
        this.useNullConfigSetting = true;
        return this;
    }

    setStringConverter(stringConverter: (value: string) => number) {
        this.stringConverter = stringConverter;
        return this;
    }

    setMin(min: number) {
        this.minMin = min;
        return this;
    }

    setMax(max: number) {
        this.maxMax = max;
        return this;
    }
}

export class TextConfig<T> extends FilterConfig<T> {
    useCaseConfigSetting?: boolean; // Specifies whether or not the user should be able to enable/disable case-sensitivity for the search
    type = FilterTypes.Text;

    constructor(name: string, getValues: GetValueFunc<T>) {
        super(name, getValues);
    }

    useCaseConfig() {
        this.useCaseConfigSetting = true;
        return this;
    }
}

export enum FilterTypes {
    Category,
    Range,
    Text,
    Bool,
}

export enum BooleanMode {
    ExclusiveFalse, // This applies to a binary filter. Unlike Default, in ExclusiveFalse mode, if if a filter has a value of "false" it will only include results that do not have that value. For instance, say an object has three sources ["AP #1", "AP #30", "AP #56"]. Only the AP #56 filter is selected. In Default mode, the object will be shown because it has AP #56, which is currently selected. In ExclusiveFalse mode, the object will be excluded, because it has AP #1 and #30, which are currently unselected.
    TriState,
    Default,
}