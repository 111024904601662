





































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import Toaster from '@/ts/ui_integrations/Toaster';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { Entity, EntityLog } from '@/ts/gamebooks/EntityLog';
import Utils from '@/ts/util/Utils';
import { DescriptiveEntry } from '@/ts/gamebooks/DescriptiveEntry';

@Component({
    components: {
    }
})
export default class EntryDisplay extends Vue {
    @Prop({required: true})
    entry!: DescriptiveEntry;

    @Prop({default: ''})
    type!: string;

    @Prop({required: true})
    deleteEntry!: () => void;

    toaster = new Toaster(this.$toast.add);

    get editMode() {
        return this.entry.editing;
    }

    tooltip(action: string) {
        return Utils.titleCase(`${action} ${this.type}`.trim());
    }

    editEntry() {
        this.entry.editing = true;
    }
    
    closeEditMode() {
        this.entry.editing = false;
    }

    popoutEntry() {
        // TODO
        this.toaster.todo('Entity Popouts');
    }

    onInput(event: any) {
        if (event.keyCode == 13) {
            // Enter Pressed
            this.entry.editing = false;
        }
    }
}
