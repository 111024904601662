






















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ButtonBlock, ConditionalBlock, Span } from '@/ts/gamebooks/parsing/Blocks';
import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';

var icons = require.context('@/assets/icons/gamebooks', true, /\.svg$/);

@Component({
    components: {
    }
})
export default class GamebookButton extends Vue {
    @Prop({required: true})
    block!: ButtonBlock;

    @Prop({required: true})
    session!: GamebookSession;

    showInlineBlocks = false;

    onclick() {
        if (this.block.inlineResults) {
            // Toggle whether or not the inline results are visible
            this.showInlineBlocks = !this.showInlineBlocks;
        }

        // TODO: Show warning to user on error setting the scene or evaluating a condition
        else if (this.block.condition) {
            if (this.block.refIdOnSuccess == undefined) {
                console.warn('ButtonBlock has condition, but no refIdOnSuccess', {block: this.block});
                this.session.setScene(this.block.refId);
            }
            else {
                let temp = new ConditionalBlock(this.block.condition); // TODO: support ButtonBlocks in Evaluate
                let result = this.session.evaluate(temp);
                this.session.setScene(result ? this.block.refIdOnSuccess : this.block.refId);
            }
        }
        else {
            this.session.setScene(this.block.refId);
        }
    }

    tryGetIcon(name: string|undefined) {
        if (name == undefined) return undefined;
        let fileName = `./${name}.svg`;
        try {
            return icons(fileName);
        }
        catch (error) {
            console.warn('Icon not found', {fileName, error});
            return undefined;
        }
    }

    get iconUrl() {
        return this.tryGetIcon(this.block.iconRef);
    }

    get text() {
        return this.block.buttonText ?? 'Continue';
    }

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    get subBlocks() {
        let key = this.block.refId;
        let scene = this.session.gamebook.scenes[key];
        if (scene) {
            return scene.blocks;
        }
        else {
            // TODO: log warning
            return [];
        }
    }
}
