































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';
import SearchWizard from '@/ui/AdvancedSearch/SearchWizard.vue';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import { Filter, FilterSpec, Option, TabLayout } from "@/ts/util/filters/_index";
import Randomizer from "@/ts/util/Randomizer";
import Utils from '@/ts/util/Utils';
import Toaster from '@/ts/ui_integrations/Toaster';
import SanityCheck from '@/ts/util/SanityCheck';
import { Setting } from '@/ts/util/config/Setting';

@Component({
    components: {
        ConfigElementPanel,
        SearchWizard,
        Sidebar,
    }
})
export default class AdvancedSearch<T> extends Vue {
    @Prop({required: true})
    rootContent!: T[];

    @Prop({required: true})
    filterSpec!: FilterSpec<T>;

    @Prop({default: null})
    settings!: Setting[]|null;

    @Prop({default: false})
    preMount!: boolean;

    @Prop({default: true})
    hasCards!: boolean;

    @Prop({default: true})
    hasTable!: boolean;

    @Prop({default: true})
    hasBigCards!: boolean;

    showAdvFilters = false;
    sortResults = true;
    showFilter = true;
    filterTab = true;

    showInstructions = true;

    genCount = 1;
    randomResults: T[] = [];
    random = new Randomizer();
    toaster = new Toaster(this.$toast.add);

    rndDisplayStyle = this.hasTable ? 'Table' : this.hasCards ? 'Cards' : 'Big Cards';
    rndTab = false;

    useLegacyView = false;
    windowWidth = -1;
    
    mounted() {
        let view = this.$route.query.v;
        if (view == 'randomizer') {
            this.rndTab = true;
        }
        else if (view == 'legacy') {
            this.useLegacyView = true;
        }
        else if (view == 'expanded') {
            this.showFilterModal();
        }

        // TODO: Read query string
        /*
        let filterProps = Object.entries(this.$route.query).filter(x => x[0] != 'v');
        if (filterProps.length > 0) {
            this.clearFilters();
            for (let [key, value] of filterProps) {
                // TODO: Account for max and min
                let filter: any = this.allFilters.filter(x => filterKey(x.name) == key).pop();
                if (filter) {
                }
                else {
                    console.warn('Filter not found for query: ' + key);
                }
            }
        }
        */

       // TODO:
       /*
        let width1 = window?.innerWidth ?? 0;
        let width2 = document?.documentElement?.clientWidth ?? 0;
        this.windowWidth = Math.max(width1, width2);
       */

        this.windowWidth = window.innerWidth;
        if (window.innerWidth > 960) this.showFilter = true;
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
            if (window.innerWidth > 960) this.showFilter = true;
        });

        if (this.lsSupported()) {
            let lastUpdate = localStorage.getItem('searchLastViewedAt');
            if (lastUpdate) {
                let last = parseInt(lastUpdate);
                let current = Date.now();
                const oneWeek = 1000 * 60 * 60 * 24 * 7;
                if (last < (current - (2*oneWeek))) {
                    this.showInstructions = true;
                }
                else {
                    let value = localStorage.getItem('showInstructions');
                    this.showInstructions = (value == 'true') || (value == null);
                }
            }

            localStorage.setItem('searchLastViewedAt', Date.now().toString());
            localStorage.setItem('showInstructions', `${this.showInstructions}`);
        }
        
        this.onConfigUpdate();
    }

    filterMap: {[key: string]: Filter<T>} = {};
    filters: Filter<T>[] = [];
    isInit = false;

    @Watch('showInstructions')
    onShowInstructionsUpdate() {
        localStorage.setItem('showInstructions', `${this.showInstructions}`);
    }

    @Watch('isLoading')
    @Watch('filterSpec.configs', {deep: true})
    onConfigUpdate() {
        if (this.isInit || this.isLoading) return;

        let map = {} as {[key: string]: Filter<T>};
        for (let [key, value] of Object.entries(this.filterSpec.configs)) {
            map[key] = new Filter<T>(value);
        }

        console.log('Creating new filterMap');
        this.isInit = true;
        this.filterMap = map;
        this.filters = Object.values(this.filterMap);
    }

    get sidebarFilters(): Filter<T>[] {
        // TODO: Deprecate the adv field
        let filters = (this.showAdvFilters) ? this.filters : this.filters.filter(x => !x.isAdvanced);
        return filters.filter(x => x.name != "Source" && x.filterGroup == null);
    }

    get sidebarFilterGroups(): {name: string, filters: Filter<T>[]}[] {
        // TODO: Deprecate the adv field
        let filters = this.filters.filter(x => x.filterGroup != null);
        if (!this.showAdvFilters) filters = filters.filter(x => !x.isAdvanced);

        let map = {} as {[key: string]: Filter<T>[]};
        for (let filter of filters) {
            let key = SanityCheck.notNull(filter.filterGroup);
            if (!(key in map)) map[key] = [];
            map[key].push(filter);
        }

        return Object.entries(map).map(x => {return {name: x[0], filters: x[1]}});
    }

    get sourceFilter(): Filter<T>|undefined {
        return this.filters.filter(x => x.name == 'Source').shift();
    }

    get showClearAll() {
        return this.results?.length != this.rootContent?.length;
    }

    get isLoading() {
        return this.preMount || this.rootContent == undefined || !this.filterSpec;
    }

    get rndDisplayOptions() {
        let options = [];
        if (this.hasCards) options.push('Cards');
        if (this.hasBigCards && this.windowWidth > 960) options.push('Big Cards');
        if (this.hasTable) options.push('Table');

        return options;
    }

    lsSupported() {
        let mod = 'mod_test'
        try {
            localStorage.setItem(mod, mod);
            localStorage.removeItem(mod);
            return true;
        } catch(e) {
            return false;
        }
    }

    unlegacy() {
        this.useLegacyView = false;
        let query = Object.assign({}, this.$route.query);
        delete query.v;
        this.$router.replace({ query });
    }

    showFilterModal() {
        let wizard: any = this.$refs.searchWizard;
        wizard.displayModal = true;
    }

    clearFilters() {
        this.filters.forEach(x => x.clear());
    }

    crSort(cr: string) {
        if (cr == '-' || Utils.isEmpty(cr)) return 0;
        return Utils.parseFraction(cr);
    }

    addRandom() {
        for (let i = 0; i < this.genCount; i += 1) {
            let res = this.random.selection(this.results);
            if (res) this.randomResults.unshift(res);
        }
    }

    clearRandom() {
        this.randomResults = [];
    }

    get results(): T[] {
        return Filter.filter(this.rootContent, this.filterMap);
    }

    get parchments() {
        return ['parchment-a', 'parchment-b', 'parchment-c', 'parchment-d', 'parchment-e'];
    }
}
