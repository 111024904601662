import Toaster, { Position } from "@/ts/ui_integrations/Toaster";
import Segment, { Direction } from "@/ts/util/loregen2/Segment";
import SanityCheck from "@/ts/util/SanityCheck";
import Randomizer from "@/ts/util/Randomizer";
import Utils from "@/ts/util/Utils";

import { ElementTemplate, TemplateGroup, TemplateOrGroup, TemplateTypes } from "./Templates";
import Vue from "vue";

const Random = Randomizer.Global;
type StringMap = {[key: string]: string};

class PathResult {
    result: TemplateOrGroup|Segment|null;

    constructor(result: TemplateOrGroup|Segment|null) {
        this.result = result;
    }
    
    get _dyn() { return this.result as any }
    get isErr() { return this.result == null; }
    get isGroup() { return !this.isErr && this._dyn.isGroup; }
    get isTemplate() { return !this.isErr && this._dyn.isTemplate; }
    get isSegment() { return !this.isErr && !this.isGroup && !this.isTemplate; }

    get segment() { return this.result as Segment }
    get group() { return this.result as TemplateGroup }
    get template() { return this.result as ElementTemplate }
}

export default class Generator {
    templates: TemplateOrGroup[] = [];
    toaster?: Toaster;

    constructor(toaster?: Toaster) {
        this.toaster = toaster;
    }

    _getChildren(parent: TemplateOrGroup): TemplateOrGroup[] {
        if (parent.isTemplate) return [];
        else {
            let group = parent as TemplateGroup;
            return (group.childGroups as TemplateOrGroup[]).concat(group.children);
        }
    }

    _evaluatePath(path: string, context: Segment): PathResult {
        if (path.trim().length == 0) {
            console.warn('Attempted to evaluate empty template path', {path, context});
            return new PathResult(null);
        }

        let split = Utils.splitNonGroup(path, ['.'], '[]');
        let result = new PathResult(null);
        let key = split.shift();
        
        let searchTemplates = (templates: TemplateOrGroup[], key: string) => {
            let matches = templates.filter(x => x.key == key);
            if (matches.length == 0 && result.isErr) {
                // If this is the first itteration, and no matches are found, try searching all templates
                let all = templates.flatMap(x => TemplateGroup.flattenAll(x));
                matches = all.filter(x => x.key == key);
            }

            let search = SearchResult.create(matches, key, path, context, this.toaster);
            if (search.result) {
                let template = SanityCheck.notNull(search.result);
                result = new PathResult(template);
                return true;
            }
            else {
                console.warn('Unable to find template reference for key', {key, currentContext: result, search, path, context});
                return false;
            }
        };

        let searchVariables = (segment: Segment, key: string) => {
            let variable = segment.resolveVariable(key, Direction.Up);
            if (variable == null) {
                console.warn('Unable to resolve variable from template', {variableKey: key, template: result.template, segment, path, context});
                return false;
            }
            else {
                result = new PathResult(variable);
                return true;
            }
        };

        while (key != null) {
            // Evaluate variable keys
            if (key.startsWith('[')) {
                let temp = context.create(key);
                this.evaluate(temp, 0);
                if (temp.isError) {
                    console.warn('Unable to resolve nested variable keys', {variableKey: key, path, context});
                    return new PathResult(null);
                }
                else {
                    key = temp.text;
                }
            }

            // Resolve the evaluated key
            if (result.isErr) {
                // If result.isErr, then this is the first pass - attempt to resolve root variables and templates
                let variable = context.resolveVariable(key, Direction.Up);
                if (variable != null) {
                    result = new PathResult(variable);
                }
                else {
                    let templates = this.templates;
                    let success = searchTemplates(templates, key);
                    if (!success) return new PathResult(null);
                }
            }
            else if (result.isGroup) {
                let templates = this._getChildren(result.group);
                let success = searchTemplates(templates, key);
                if (!success) return new PathResult(null);
            }
            else if (result.isTemplate) {
                // roll template >> Segment
                let segment = context.create(`[${result.template.key}]`);
                this.evaluateTemplate(segment, result.template, 0);

                // A key reference on a template can only refer to a variable
                let success = searchVariables(segment, key);
                if (!success) return new PathResult(null);
            }
            else if (result.isSegment) {
                // A key reference on a segment can only refer to a variable
                let success = searchVariables(result.segment, key);
                if (!success) return new PathResult(null);
            }
            else {
                console.warn('Unexpected state: result is not group, template, segment, or err', result);
                return new PathResult(null);
            }

            key = split.shift();
        }

        return result;
    }

    /**
     * 
     * @param templatePath The path of the template or group to generate, without []. E.g. "Names.People"
     * @param context 
     * @returns 
     */
    generate(templatePath: string, context?: Segment): Segment {
        // TODO: Support variable overrides
        let segment = new Segment(`[${templatePath}]`);
        this.evaluate(segment, 0);
        return segment;
    }

    evaluate(segment: Segment, currentDepth: number): void {
        const MaxDepth = 10;
        segment.clearChildren();
        let line = segment.original;

        if (currentDepth >= MaxDepth) {
            console.warn('Max depth reached when trying to decode Lore Generator. Results may not be accurate.');
            segment.createAndAddChild('[$DEPTH_ERR]');
        }
        else if (segment.isLiteral || segment.isError) {
            return;
        }
        else if (!line.startsWith('[') || !line.endsWith(']')) {
            console.warn('Invalid segment format. Expected reference.', segment);
            segment.createAndAddChild('[$FORMAT_ERR]');
        }
        else {
            let inner = line.slice(1, -1);
            let target = this._evaluatePath(inner, segment);
    
            if (target.isErr) segment.createAndAddChild('[$REF_ERR]');
            else if (target.isGroup) this.evaluateGroup(segment, target.group, currentDepth);
            else if (target.isTemplate) this.evaluateTemplate(segment, target.template, currentDepth);
            else if (target.isSegment) {
                this.decode(segment, target.segment.text);
                for (let child of segment.segments) {
                    this.evaluate(child, currentDepth + 1);
                }
            }
            else {
                console.warn('Unexpected state: eval target is not group, template, segment, or err', target);
                segment.createAndAddChild('[$INTERNAL_ERR]');
            }
        }
    }

    evaluateGroup(segment: Segment, group: TemplateGroup, currentDepth: number): void {
        // TODO: Support weights (add a field called TemplateGroup.weightMap and fetch the weight data from there)
        let children = TemplateGroup.flattenChildren(group);
        if (children.some(x => x.isTopLevel)) {
            children = children.filter(x => x.isTopLevel);
        }

        if (children.length == 0) {
            console.warn('Attempted to generate from empty TemplateGroup', group);
            segment.createAndAddChild('[$EMPTY_GROUP_ERR]');
        }
        else {
            let template = Random.gtSelection(children);
            this.evaluateTemplate(segment, template, currentDepth);
        }
    }

    evaluateTemplate(segment: Segment, template: ElementTemplate, currentDepth: number): void {
        // Parse & Evaluate variables
        segment.clearVariables();
        this.evaluateVariables(segment, template, currentDepth);

        if (template.type == TemplateTypes.Objects) {
            if (template.objects.length == 0) {
                console.warn('Objects template has no objects', template);
                this.toaster?.warn('Empty Objects Template', TemplateGroup.getPath(template), 3000, Position.BottomRight);
                segment.createAndAddChild('[$TEMPLATE_ERR]');
            }
            else {
                // TODO: Remove this hardcoded exclusion, and replace with user-customizable weights
                let objectsGroup = template.objects.filter(x => x._group != 'Extended Pronouns');
                let objTemplate = Random.gtSelection(objectsGroup);
                let objKeys = Object.keys(objTemplate).filter(x => x != '_id');
                if (objKeys.length > 0) {
                    if (segment.variables == null) Vue.set(segment, 'variables', {});
    
                    let variables = SanityCheck.notNull(segment.variables);
                    for (let key of objKeys) {
                        let value = objTemplate[key];
    
                        // TODO: Combine this with this.evaluateVariables()
                        let varSegment = segment.create(value);
                        this.decode(varSegment, value);
                        Vue.set(variables, key, varSegment);
        
                        for (let child of varSegment.segments) {
                            this.evaluate(child, currentDepth + 1);
                        }
                    }
                    segment.createAndAddChild('[key]');
    
                    // Evaluate each child segment
                    for (let child of segment.segments) {
                        this.evaluate(child, currentDepth + 1);
                    }
                }
            }
        }
        else {
            let line = this.rollLine(template);
            if (line == null) {
                segment.createAndAddChild('[$INTERNAL_ERR]');
            }
            else if (line.length == 0) {
                console.warn('Template generated empty line', template);
                this.toaster?.warn('Empty Template', TemplateGroup.getPath(template), 3000, Position.BottomRight);
                segment.createAndAddChild('[$TEMPLATE_ERR]');
            }
            else {
                // Parse segment strings
                this.decode(segment, line);
    
                // Evaluate each child segment
                for (let child of segment.segments) {
                    this.evaluate(child, currentDepth + 1);
                }
            }
        }
    }

    evaluateVariables(segment: Segment, template: ElementTemplate, currentDepth: number): void {
        // Parse & Evaluate variables
        if (template.variables && template.variables.length > 0) {
            if (segment.variables == null) Vue.set(segment, 'variables', {});

            let variables = SanityCheck.notNull(segment.variables);
            for (let varTemplate of template.variables) {
                let varSegment = segment.create(varTemplate.defaultValue);
                this.decode(varSegment, varTemplate.defaultValue);
                Vue.set(variables, varTemplate.key, varSegment);

                for (let child of varSegment.segments) {
                    this.evaluate(child, currentDepth + 1);
                }
            }
        }
    }

    getSeperator(template: ElementTemplate, index: number): string {
        let maxIndex = template.parts.length - 1;
        if (index >= maxIndex) return '';

        let seperator = (index < template.seperators.length) ? template.seperators[index] : '';
        if (seperator.length == 0) seperator = ' ';
        seperator = seperator.replace(/\[space\]/g, ' ');
        seperator = seperator.replace(/\[null\]/g, '');
        return seperator;
    }

    rollLine(template: ElementTemplate): string|null {
        if (template.type == TemplateTypes.Block) {
            return template.parts[0];
        }
        else if (template.type == TemplateTypes.Line) {
            let text = '';
            for (let index = 0; index < template.parts.length; index++) {
                let part = template.parts[index];
                // TODO: Support [NULL]
                text += this.rollWeightedLines(part.split('\n'));
                text += this.getSeperator(template, index);
            }
            return text;
        }
        else if (template.type == TemplateTypes.Markov) {
            console.warn('Markov not yet supported - filling with a random selection', template);
            return Random.gtSelection(template.parts[0].split('\n'));
        }
        else {
            if (template.type == TemplateTypes.Objects) console.warn('Unsupported template type for rollText()', template);
            else console.warn('Unknown template type', template);
            return null;
        }
    }

    rollWeightedLines(lines: string[]): string {
        // Determine which line to use
        let table = lines.map(x => {
            if (/^\[%[0-9]+\]/g.test(x)) {
                // Starts with a weight
                let endIndex = x.indexOf(']');
                return {
                    weight: parseInt(x.slice(2, endIndex)),
                    text: x.slice(endIndex+1)
                }
            }
            else {
                return {
                    weight: 1,
                    text: x
                }
            }
        });

        let entry = Random.randomize(table);
        return entry ?? '[$INTERNAL_ERR]';
    }

    /** Split a line into Segments, and add them to the specified parent as children */
    decode(parent: Segment, line: string): void {
        const original = line;

        while (line.length > 0) {
            let openIndex = line.indexOf('[');
            let closeIndex = -1;
            if (openIndex >= 0) {
                let openCount = 0;
                for (let i = openIndex+1; i < line.length; i++) {
                    if (line[i] == '[') {
                        openCount++;
                    }
                    else if (line[i] == ']') {
                        if (openCount == 0) {
                            closeIndex = i;
                            i = line.length;
                        }
                        else {
                            openCount--;
                        }
                    }
                }
            }

            if (openIndex < 0 || closeIndex < 0) {
                if (openIndex != closeIndex) {
                    console.warn(`Attempted to decode string with improper brackets`, {original, text: line, openIndex, closeIndex});
                    this.toaster?.warn('Unexpected formatting detected', original, 3000, Position.BottomRight);
                }

                parent.createAndAddChild(line);
                line = "";
                break;
            }
            else {
                let pre = line.slice(0, openIndex);
                let reference = line.slice(openIndex, closeIndex+1); // Ref text, with brackets - e.g. "[Table]"
                line = line.slice(closeIndex+1);
                
                if (pre.length > 0) parent.createAndAddChild(pre);
                parent.createAndAddChild(reference);
            }
        }
    }
}

class SearchResult<T> {
    result?: T;
    error?: string;

    static create<T>(search: T[], refName: string, fullPath: string, context: Segment, toaster?: Toaster) {
        let result = new SearchResult<T>();
        let refPath = [];
        let segment: Segment|undefined = context;
        while (segment != undefined) {
            refPath.unshift(segment.original);
            segment = segment.parent;
        }

        if (search.length == 0) {
            console.warn('Template not found', {refName, fullPath, search});
            toaster?.warn('Template not found', `${fullPath}\nRef Path: ${refPath.join('.')}`, 3000, Position.BottomRight);
            result.error = '[$REF_ERR]';
        }
        else if (search.length > 1) {
            console.warn('Multiple matches found', {refName, fullPath, search});
            toaster?.warn('Multiple matches found', fullPath, 3000, Position.BottomRight);
            result.error = '[$DUP_ERR]';
        }
        else {
            result.result = search[0];
        }

        return result;
    }
}