import { DescriptiveEntry } from "./DescriptiveEntry";

export class GBJournal {
    entries: JournalEntry[] = [];

    add(name = 'New Entry') {
        let entry = new JournalEntry(name);
        this.entries.push(entry);
        return entry;
    }

    removeIndex(index: number) {
        let array = this.entries;
        if (index < array.length) array.splice(index, 1);
    }

    clear() {
        this.entries.splice(0, this.entries.length);
    }
}

export class JournalEntry implements DescriptiveEntry {
    name: string;
    shortDesc = '';
    longDesc = '';

    editing = false;

    constructor(name: string) {
        this.name = name;
    }
}