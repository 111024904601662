
































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SceneLogUI from '@/ui/Gamebooks/sidebar_components/SceneLogUI.vue';
import MacroBox from '@/ui/Gamebooks/sidebar_components/MacroBox.vue';
import DiceBox from '@/ui/Gamebooks/sidebar_components/DiceBox.vue';

import { SceneLog } from '@/ts/gamebooks/SceneLog';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';

@Component({
    components: {
        SceneLogUI,
        MacroBox,
        DiceBox,
    }
})
export default class DicePanel extends Vue {
    @Prop({required: true})
    session!: GamebookSession;
    
    input = '';

    get sceneLog(): SceneLog {
        return this.session.sceneLog;
    }

    @Watch('sceneLog.entries')
    scrollSceneLog() {
        this.$nextTick(() => {
            let logComponent: any = this.$refs.scenelog;
            logComponent.scrollTop = logComponent.scrollHeight;
        });
    }

    onInput(event: any) {
        if (event.keyCode == 13) {
            // Enter Pressed
            this.sendInput();
        }
    }

    openContextMenu(event: any) {
        let sceneLog = this.sceneLog;
        let items = [
            {
                label: 'Clear Scene Log',
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    sceneLog.clear();
                }
            }
        ];
        (this as any).$toggleContextMenu(event, items)
    }

    sendInput() {
        if (/^[0-9]*d[0-9]+ *[\-\+\/\*]?/g.test(this.input) || /^[0-9]*d[0-9]+$/g.test(this.input) || /^\(*[0-9]+ *[\-\+\/\*]/g.test(this.input)) {
            this.input = `${this.input} 🠖 [[${this.input}]]`;
        }
        this.sceneLog.push(this.input);
        this.input = '';
    }
}
