import {Grant} from '@/ts/api/grants/_index';

type StringMap = {[key: string]: string};
type Dynamic = {[key: string]: any};

export class SelectionGrant extends Grant {
    pools: string[]; // Each entry is a contentType (e.g. feat) followed by an optional selector statement (e.g. " WHERE 'general' IN x.categories")
    includeIds: string[]; // Each entry is a contentId (a guid, or guid:defaultName)
    excludeIds: string[]; // Each entry is a contentId (a guid, or guid:defaultName)
    statAliases: StringMap; // refName to equationString

    numSelections: string; // Equation string for the number of selections granted (e.g. '1' or 'int.mod + 3')
    indSelectionLimits: string; // The maximum number of times each option may be selected (typically 1), unless otherwise stated. If this is left blank, no limit will be imposed.

    recurrences: { level: string; numSelections: string; }[]; // Maps a level to an equation string representing the number of additional selections that can be made at that level

    constructor(json: Dynamic) {
        super(json);
        
        this.pools = json.pools ?? [];
        this.includeIds = json.includeIds ?? [];
        this.excludeIds = json.excludeIds ?? [];
        this.statAliases = json.statAliases ?? {};
        
        this.numSelections = json.numSelections ?? "";
        this.indSelectionLimits = json.indSelectionLimits ?? "";
        
        this.recurrences = json.recurrences ?? [];
    }
}