import Links from "@/ts/api/sharedModels/Links";
import Utils from "@/ts/util/Utils";
import { CardConfig, TagEntry } from "@/ui/DataCards/CardConfig";
import { SortAlgo } from "@/ui/AdvancedSearch/CardResultsConfig";

export default interface ItemEntry {
    // This is a utility field, for userdata and item generation
    isModdedItem: boolean;

    // These fields are filled by the client, and are not present in the original data
    links: Links;
    abpOnly: boolean; // Whether or not this item grants a bonus that is disallowed by Automatic Bonus Progression rules
    abpPlus: boolean; // Whether or not this item grants an additional bonus or effect that is NOT disallowed by Automatic Bonus Progression rules

    // All Items
    id: string;
    name: string;
    tags: MdTag[]; // Tags are the visual symbols next to an entry (e.g. 3.5 or PFS)
    sources: string[];
    md: string;

    itemCategory: string;
    cost: string|number; // Numeric value represents the cost in gp (or fraction thereof). String value represents either 'varies'/'special', a modification (such as +25gp, -5sp or x4), or a cost-per-unit (e.g. '5 gp/bottle')
    weight: string|number; // Numeric value represents the weight in lbs (or fraction thereof). String value represents either 'varies'/'special', a modification (such as +25gp, -5sp or x4), or a cost-per-unit (e.g. '5 lbs/bottle')

    noteRefs: string[];
    deity: string;
    
    // Magic Items
    rodType: string;
    itemSlot: string;
    constructModType: string;
    magicPriceModifier: string;

    // Relics
    casterLevel: number;

    // Alchemical Items
    craftDC: string|number; // Numeric value represents the craftDC, string value = 'varies'

    // Ioun Stones
    shape: string;
    effect: string;
    strength: IounStoneStrength;

    // Weapons & Ammo (including siege and firearm ammunition)
    dmg: string;
    crit: string;
    range: string;
    dmgType: string;
    special: string;
    weaponCategory: WeaponCategories;
    weaponProficiency: WeaponProficiencies;
    
    // Firearms
    era: FirearmEra;
    capacity: number;
    misfireRange: string;
    
    // Siege Weapons
    aim: number;
    load: number;
    crew: number;
    engineType: string;
    engineSize: string;
    engineSpeed: number;
    
    // Armors & Shields
    acp: number;
    asf: number; // this is a float, representing a percentage (e.g. 0.15 is 15%)
    armorSpeed: number; // standard (30ft) move speed while wearing the armor - in ft.
    armorProficiency: ArmorProficiencies;
    armorBonus: number;
    maxDexBonus: number; // Undefined represents '---'

    // Vehicles
    vehicleType: string;
    vehicleSize: string;
    transportCostPerMile: number; // Cost in gp

    // Intelligent Items
    alignment: string;
    int: number;
    wis: number;
    cha: number;
    ego: number;
    senses: string[];
    languages: string[];
    communication: string[];
    powers: string[];
    purpose: string;
    dedicatedPowers: string[];
}

export enum FirearmEra {
    Early = "Early",
    Advanced = "Advanced",
    Modern = "Modern"
}

export enum WeaponProficiencies {
    Simple = "Simple",
    Martial = "Martial",
    Exotic = "Exotic",
    Firearms = "Firearms",
    Special = "Special",
}

export enum WeaponCategories {
    Light = "Light",
    OneHanded = "One-Handed",
    TwoHanded = "Two-Handed",
    Ranged = "Ranged",
    Special = "Special",
    // "see text" and "varies" are also options for this field
}

export enum ArmorProficiencies {
    Light = "Light Armor",
    Medium = "Medium Armor",
    Heavy = "Heavy Armor",
    Shields = "Shields",
    TowerShields = "Tower Shields",
}

export enum IounStoneStrength {
    Cracked = 'Cracked',
    Flawed = 'Flawed',
    Normal = 'Normal',
}

export enum ItemCategory {
    UniqueWeapon = "Unique Weapon",
    UniqueShields = "Unique Shields",
    UniqueArmor = "Unique Armor",
    Altars = "Altars",
    Favours = "Favours",
    WondrousItems = "Wondrous Items",
    Staves = "Staves",
    Rods = "Rods",
    IounStones = "Ioun Stones",
    MagicalTattoo = "Magical Tattoo",
    ShadowPiercing = "Shadow Piercing",
    Potions_Oils = "Potions/Oils",

    ShieldQualities = "Shield Qualities",
    ArmorQualities = "Armor Qualities",
    MeleeWeaponQualities = "Melee Weapon Qualities",
    RangedWeaponQualities = "Ranged Weapon Qualities",

    ConstructModifications = "Construct Modifications",
    ArmorMods = "Armor Mods",
    WeaponMods = "Weapon Mods",

    TransendentArtifact = "Transendent Artifact",
    MetagameArtifact = "Metagame Artifact",
    MajorArtifact = "Major Artifact",
    MinorArtifact = "Minor Artifact",
    CursedItems = "Cursed Items",

    InteligentItem = "Inteligent Item",
    Relic = "Relic",

    AlchemicalRemedies = "Alchemical Remedies",
    AlchemicalTools = "Alchemical Tools",
    Concoctions = "Concoctions",
    Tinctures = "Tinctures",
    Herbs = "Herbs",

    AdventuringGear = "Adventuring Gear",
    AnimalGear = "Animal Gear",
    BlackMarket = "Black Market",
    ChannelFoci = "Channel Foci",
    Clothing = "Clothing",
    Dragoncraft = "Dragoncraft",
    Kits = "Kits",
    Tools = "Tools",
    FirearmsGear = "Firearms Gear",
    Vehicles = "Vehicles",
    TortureImplements = "Torture Implements",
    Entertainment = "Entertainment",
    Food_Drink = "Food/Drink",

    AlchemicalWeapons = "Alchemical Weapons",
    SiegeFirearms = "Siege Firearms",
    SiegeWeapons = "Siege Weapons",
    Explosives = "Explosives",
    Firearms = "Firearms",
    Weapons = "Weapons",
    Shields = "Shields",
    Armor = "Armor",

    Ammunition = "Ammunition",
    SiegeAmmunition = "Siege Ammunition",
    FirearmsAmmunition = "Firearms Ammunition",
    AlchemicalAmmunition = "Alchemical Ammunition",
}

export enum SimpleCategories {
    WondrousItems,
    Consumables,
    Weapons,
    Armor,
    Misc
}

/**
 * A markdown tag is any icon or additional marker that is presented in an index title or description text.
 * Each tag has an image url (which is scrubbed before making it to the client), comment (which typically functions as hover text), and a human-readable name describing the tag
 */
export interface MdTag {
    name: string;
    comment: string;
}

export class BonusCost {
    static Armors = [1000, 4000, 9000, 16000, 25000, 36000, 49000, 64000, 81000, 100000];
    static Weapons = [2000, 8000, 18000, 32000, 50000, 72000, 98000, 128000, 162000, 200000];
}

export class ItemFields {
    static sourceNames(entry: ItemEntry) {
        return (entry.sources ?? []).map(source => source.split('pg.')[0].trim());
    }
    
    static tags(entry: ItemEntry) {
        let tags = entry.tags ?? [];
        return tags.map(x => x.name);
    }
    
    static deity(entry: ItemEntry) {
        let string = entry.deity?.trim() ?? '';
        return (string.length == 0) ? 'Any' : string;
    }
    
    static damage(entry: ItemEntry) {
        let str = entry.dmg?.trim();
        if (str == null) return null;
        if (str == '1d4 fire') str = '1d4';
        str = str.replace(/(([3-9]|([1-9][0-9]+))d[0-9]+)|(2d12)/g, '3d6+');

        let hasOther = false;
        for (let regex of [/(; )?see text/g, /\(?special\)?/g, /see description/g, /varies/g, /half/g, /bleed( [0-9]+)?/g]) {
            if (regex.test(str)) {
                hasOther = true;
                str = str.replace(regex, '');
            }
        }

        let ret = str.split('/');
        if (hasOther) ret.push('see text / special');
        ret = ret.map(x => x.trim()).filter(x => !['---', 'none'].includes(x) && x.length > 0);
        return ret;
    }
    
    static critRange(entry: ItemEntry) {
        let str = entry.crit?.trim();
        if (str == null || str == '---') return null;

        let ranges = str.match(/[0-9]+\-[0-9]+/g);
        let empty = ranges == null || ranges.length == 0;
        return empty ? '20' : ranges;
    }
    
    static critMultiplier(entry: ItemEntry) {
        let str = entry.crit?.trim();
        if (str == null || str == '---') return null;

        let multipliers = str.match(/x[0-9]+/g) ?? [];
        let remainder = str.replace(/([0-9]+\-[0-9]+)|(x[0-9]+)|\/|;/g, '');
        if (remainder.trim().length != 0) {
            console.log('Other crit effect: ' + remainder);
            multipliers.push('special');
        }
        return multipliers;
    }
    
    static weaponProficiency(entry: ItemEntry) {
        let str = entry.weaponProficiency?.trim();
        if (str == null || str == '---') return null;
        else return str;
    }
    
    static parsePrice(entry: ItemEntry, warn = (parsed: number) => {}) {
        if (typeof entry.cost == 'string') {
            let parsed = parseInt(entry.cost);
            parsed = isNaN(parsed) ? 0 : parsed;
            warn(parsed);
            return parsed;
        }
        else {
            return entry.cost;
        }
    }

    static MagicCategories: string[] = [
        ItemCategory.ArmorQualities,
        ItemCategory.CursedItems,
        ItemCategory.InteligentItem,
        ItemCategory.IounStones,
        ItemCategory.MagicalTattoo,
        ItemCategory.MajorArtifact,
        ItemCategory.MeleeWeaponQualities,
        ItemCategory.MetagameArtifact,
        ItemCategory.MinorArtifact,
        ItemCategory.Potions_Oils,
        ItemCategory.RangedWeaponQualities,
        ItemCategory.Relic,
        ItemCategory.Rods,
        ItemCategory.ShadowPiercing,
        ItemCategory.ShieldQualities,
        ItemCategory.Staves,
        ItemCategory.TransendentArtifact,
        ItemCategory.UniqueArmor,
        ItemCategory.UniqueShields,
        ItemCategory.UniqueWeapon,
        ItemCategory.WondrousItems
    ];
    static isMagic(entry: ItemEntry) {
        return ItemFields.MagicCategories.includes(entry.itemCategory);
    }

    static AlchemicalCategories: string[] = [
        ItemCategory.AlchemicalAmmunition,
        ItemCategory.AlchemicalRemedies,
        ItemCategory.AlchemicalTools,
        ItemCategory.AlchemicalWeapons,
        ItemCategory.Concoctions,
        ItemCategory.Herbs,
        ItemCategory.Tinctures
    ];
    static isAlchemical(entry: ItemEntry) {
        return ItemFields.AlchemicalCategories.includes(entry.itemCategory);
    }

    static isMundane(entry: ItemEntry) {
        return !(ItemFields.isMagic(entry) || ItemFields.isAlchemical(entry));
    }

    static isIntelligent(entry: ItemEntry) {
        // TODO: Search for ### Statistics section
        return entry.int && entry.wis && entry.cha;
    }

    static ConsumableCategories: string[] = [
        // ItemCategory.WondrousItems with a slot of None are usually consumable
        ItemCategory.MagicalTattoo,
        ItemCategory.Potions_Oils,
        
        ItemCategory.AlchemicalRemedies,
        ItemCategory.AlchemicalTools,
        ItemCategory.Concoctions,
        ItemCategory.Tinctures,
        ItemCategory.Herbs,
        
        ItemCategory.Entertainment,
        ItemCategory.Food_Drink,
        
        ItemCategory.AlchemicalWeapons,
        ItemCategory.Explosives,
        
        ItemCategory.Ammunition,
        ItemCategory.SiegeAmmunition,
        ItemCategory.FirearmsAmmunition,
        ItemCategory.AlchemicalAmmunition,
    ];

    static WeaponCategories: string[] = [
        ItemCategory.UniqueWeapon,
        ItemCategory.MeleeWeaponQualities,
        ItemCategory.RangedWeaponQualities,
        ItemCategory.WeaponMods,
        
        ItemCategory.AlchemicalWeapons,
        ItemCategory.SiegeFirearms,
        ItemCategory.SiegeWeapons,
        ItemCategory.Explosives,
        ItemCategory.Firearms,
        ItemCategory.Weapons,
        
        ItemCategory.Ammunition,
        ItemCategory.SiegeAmmunition,
        ItemCategory.FirearmsAmmunition,
        ItemCategory.AlchemicalAmmunition,
    ];

    static ArmorCategories: string[] = [
        ItemCategory.UniqueShields,
        ItemCategory.UniqueArmor,
        ItemCategory.ShieldQualities,
        ItemCategory.ArmorQualities,
        ItemCategory.ArmorMods,
        ItemCategory.Shields,
        ItemCategory.Armor,
    ];

    static WondrousCategories: string[] = [
        ItemCategory.WondrousItems,
        ItemCategory.Staves,
        ItemCategory.Rods,
        ItemCategory.IounStones,
        ItemCategory.ShadowPiercing,
    ];

    static MiscCategories: string[] = [
        ItemCategory.Altars,
        ItemCategory.Favours,

        ItemCategory.AdventuringGear,
        ItemCategory.AnimalGear,
        ItemCategory.BlackMarket,
        ItemCategory.ChannelFoci,
        ItemCategory.Clothing,
        ItemCategory.Dragoncraft,
        ItemCategory.Kits,
        ItemCategory.Tools,
        ItemCategory.FirearmsGear,
        ItemCategory.Vehicles,
        ItemCategory.TortureImplements,
        ItemCategory.Entertainment,
        ItemCategory.Food_Drink,
    ];
    
    static simpleCategories(entry: ItemEntry): SimpleCategories[] {
        // TODO: Account for modded items - scrolls, wands, etc.
        let cats = [] as SimpleCategories[];
        
        if (ItemFields.ConsumableCategories.includes(entry.itemCategory)) cats.push(SimpleCategories.Consumables);
        else if (entry.itemCategory == ItemCategory.WondrousItems && entry.itemSlot == undefined) cats.push(SimpleCategories.Consumables);

        if (ItemFields.WondrousCategories.includes(entry.itemCategory)) cats.push(SimpleCategories.WondrousItems);
        if (ItemFields.WeaponCategories.includes(entry.itemCategory)) cats.push(SimpleCategories.Weapons);
        if (ItemFields.ArmorCategories.includes(entry.itemCategory)) cats.push(SimpleCategories.Armor);
        if (ItemFields.MiscCategories.includes(entry.itemCategory)) cats.push(SimpleCategories.Misc);

        return cats;
    }
}

/** Calculated values that are used for card displays.
 * These methods handle formatting and markdown (when appropriate) for displaying text to the user - they are not used by the Advanced Search Engine.
 */
export class ItemDisplays {
    static sortAlgorithms: SortAlgo<ItemEntry>[] = [
        {name: "Default", func: 0},
        {name: "Cost", func: (a: ItemEntry, b: ItemEntry) => Utils.toInt(a.cost) - Utils.toInt(b.cost)},
        {name: "Name", func: (a: ItemEntry, b: ItemEntry) => Utils.strComp(a.name, b.name)},
    ];

    static searchTerm(entry: ItemEntry): string {
        let lower = entry.name.toLowerCase();
        let start = ['fine', 'diminutive', 'tiny', 'small', 'medium', 'large', 'huge', 'gargantuan', 'colossal'].filter(x => lower.startsWith(x)).shift();
        return 'Fantasy ' + (start ? entry.name.substring(start.length).trim() : entry.name);
    }

    static getCardConfig(item: ItemEntry): CardConfig {
        return {
            name: item.name,
            sources: item.sources,
            titleTags: ItemDisplays.titleTags(item),
            tabs: [
                {
                    name: "Details",
                    md: ItemDisplays.md(item),
                }
            ],

            links: item.links,
        }
    }

    static titleTags(entry: ItemEntry): TagEntry[] {
        return [
            {
                label: "PFS*",
                labelHover: ItemDisplays.pfsConditionalText(entry),
            },
            {
                label: "PFS",
                labelHover: ItemDisplays.pfsText(entry),
            },
            {
                label: "PFS-",
                labelHover: ItemDisplays.pfsDisallowedText(entry),
                styleClass: 'cancel',
            },
            {
                label: "3.5",
                labelHover: ItemDisplays.is35Text(entry),
            }
        ].filter(x => x.labelHover.length > 0);
    }

    static md(entry: ItemEntry): string {
        let md = entry.md;
        if (md == null || md.length == 0) return '';

        md = md.replace(/(<sup>)|(<\/sup>)/g, '^').replace(/(<sub>)|(<\/sub>)/g, '~');
        if (entry.isModdedItem && ItemFields.isIntelligent(entry)) {
            let statSection = `### Sentience\n\nThis item has added intelligence. It has mental ability scores, and is considered sentient by all metrics.`;
            statSection += `\n\n**Alignment** ${entry.alignment}; **Ego** ${entry.ego}\n\n**Senses** ${entry.senses.join(', ')}\n\n**Int** ${entry.int}, **Wis** ${entry.wis}, **Cha** ${entry.cha}\n\n**Communication** ${entry.communication.join(', ')}\n\n**Languages** ${entry.languages.join(', ')}`;
            if (entry.purpose) statSection += `\n\n**Purpose** ${entry.purpose}`;
            statSection += `\n\n**Powers**\n\n* ${entry.powers.join('\n\n* ')}`;
            if (entry.dedicatedPowers) statSection += `\n\n**Dedicated Powers**\n\n* ${entry.dedicatedPowers.join('\n\n* ')}`;

            let index = md.indexOf('###');
            if (index == md.indexOf('### Statistics')) index = md.indexOf('###', index+3);

            if (index < 0) md += `\n\n${statSection}`;
            else {
                let pre = md.slice(0, index);
                let post = md.slice(index);
                md = `${pre}${statSection}\n\n${post}`;
            }
        }
        return md;
    }

    static pfsText(entry: ItemEntry) {
        let isPFS = entry.tags?.filter(x => x.name == 'PFS').length > 0;
        return isPFS ? 'This item is legal for Pathfinder Society Play' : '';
    }

    static pfsDisallowedText(entry: ItemEntry) {
        let isPFS = (ItemDisplays.pfsConditionalText(entry) + ItemDisplays.pfsText(entry)).length > 0;
        return isPFS ? '' : 'This item is NOT legal for Pathfinder Society Play';
    }

    static is35Text(entry: ItemEntry) {
        let is35 = entry.tags?.filter(x => x.name == '3.5').length > 0;
        return is35 ? 'This item was introduced when Pathfinder was still a 3.5 subsystem' : '';
    }

    static pfsConditionalText(entry: ItemEntry) {
        let isConditionalPFS = entry.tags?.filter(x => x.name == 'PFS (conditional)').length > 0;
        if (isConditionalPFS) {
            let filter = entry.tags?.filter(x => x.name == 'PFS (conditional)') ?? [];
            let conditions = filter.length > 0 ? filter[0].comment : '';
            if (conditions.length == 0) {
                console.warn('Item is marked as conditional, but no condition text was found', entry);
            }

            return 'This item is legal for Pathfinder Society Play under the following conditions:\n\n' + conditions;
        }
        else return '';
    }
}