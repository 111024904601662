





















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ButtonBlock, ConditionalBlock, DropdownButtonBlock, LinkedPair, Span } from '@/ts/gamebooks/parsing/Blocks';
import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';

var icons = require.context('@/assets/icons/gamebooks', true, /\.svg$/);

@Component({
    components: {
    }
})
export default class GamebookDropdownButton extends Vue {
    @Prop({required: true})
    block!: DropdownButtonBlock;

    @Prop({required: true})
    session!: GamebookSession;

    selected = '';

    mounted() {
        this.ensureSelection();
    }

    @Watch('block')
    ensureSelection() {
        let options = this.block.options;
        if (this.selected == '' || options.indexOf(this.selected) < 0) {
            this.selected = options.length == 0 ? '' : options[0];
        }
    }

    get result(): LinkedPair|null {
        let index = this.block.options.indexOf(this.selected);
        if (index < 0) {
            console.warn('DropdownButton Selection not found: ' + this.selected, this.block);
            return null;
        }
        else if (index >= this.block.results.length) {
            console.warn('DropdownButton index/results mismatch.', {selected: this.selected, index, block: this.block});
            return null;
        }
        else {
            return this.block.results[index];
        }
    }

    onclick() {
        // TODO: Show warning to user on error
        if (this.result) {
            this.session.setScene(this.result.refId);
        }
    }

    tryGetIcon(name: string|undefined) {
        if (name == undefined) return undefined;
        let fileName = `./${name}.svg`;
        try {
            return icons(fileName);
        }
        catch (error) {
            console.warn('Icon not found', {fileName, error});
            return undefined;
        }
    }

    get iconUrl() {
        return null;
        // TODO: implement icons; // return this.tryGetIcon(this.block.iconRef);
    }

    get text() {
        return this.result?.buttonText ?? '';
    }

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }
}
