import Randomizer from "@/ts/util/Randomizer";
import MythosParser, { EvalContext } from '@/ts/lang/mythosParser';
import Utils from "../util/Utils";

const parser = new MythosParser();

export class SceneLog {
    entries: SceneLogEntry[] = [];

    push(line: string): SceneLogEntry {
        let entry = new SceneLogEntry(line);
        this.entries.push(entry);
        return entry;
    }

    clear() {
        this.entries.splice(0, this.entries.length);
    }
}

export class SceneLogEntry {
    inlineSegments: (string|DiceRoll)[] = [];
    diceRoll?: DiceRoll;

    constructor(line: string) {
        this.inlineSegments = SceneLogEntry._split(line);
    }

    static _split(line: string): (string|DiceRoll)[] {
        let segments = [] as (string|DiceRoll)[];
        let remainder = line;
        while (remainder.length > 0) {
            let index = remainder.indexOf('[[');
            if (index < 0) {
                segments.push(remainder);
                remainder = '';
            }
            else {
                let pre = remainder.slice(0, index);
                remainder = remainder.slice(index + 2);
                let endIndex = remainder.indexOf(']]');
                if (endIndex < 0) {
                    console.warn('Syntax error: [[ was not properly closed', {line, remainder});
                    pre += '[[' + remainder;
                    remainder = '';
                    segments.push(pre);
                }
                else {
                    let inner = remainder.slice(0, endIndex);
                    remainder = remainder.slice(endIndex + 2);
                    segments.push(pre);
                    segments.push(new DiceRoll(inner));
                    if (pre.includes('[[')) console.warn('Syntax error: [[ ]] was not properly nested', {line, remainder});
                }
            }
        }

        if (segments.length > 0 && typeof (segments[0]) == 'string' && segments[0].length == 0) segments.shift();
        return segments;
    }
}

export class DiceRoll {
    equationString: string;

    /**
     * Each entry is an array of 2 numbers and a boolean that represents the roll of a single dice: [sidesOnDice, rollResult, discard]
     * If discard is true, it means the result is not counted (due to advantage/disadvantage)
     * TODO: replace this with objects
     */
    diceResults: [number, number, boolean][];
    result: number;

    constructor(equationString: string) {
        this.diceResults = [];

        let rnd = Randomizer.Global;
        let diceRegex = /[0-9]*d[0-9]+(k(l?)[0-9]+)?/g;
        let remainder = equationString;
        let processed = '';
        while (remainder.length > 0) {
            let matches = remainder.match(diceRegex);
            if (matches == null || matches.length == 0) {
                processed += remainder;
                remainder = '';
                break;
            }
            else {
                let match = matches[0];
                let index = remainder.indexOf(match);
                processed += remainder.slice(0, index);
                remainder = remainder.slice(index + match.length);

                let split = match.split('d');
                let qty = (split[0] == '') ? 1 : parseInt(split[0]);

                let kSplit = split[1].split('k');
                let diceSize = parseInt(kSplit[0]);
                let kString = kSplit.length > 1 ? kSplit[1] : null;
                let keepLow = kString?.startsWith('l');
                let keepAmount = kString ? parseInt(Utils.trim(kString, 'kl')) : 0;

                let rolls: number[] = [];
                for (let i = 0; i < qty; i++) {
                    let res = rnd.int(1, diceSize) ?? 0;
                    rolls.push(res);
                }

                if (keepAmount > 0 && keepAmount < rolls.length) {
                    rolls = rolls.sort((a, b) => a - b);
                    if (!keepLow) rolls = rolls.reverse();
                }

                let sum = 0;
                for (let i = 0; i < rolls.length; i++) {
                    let discard = keepAmount > 0 && i >= keepAmount;
                    this.diceResults.push([diceSize, rolls[i], discard]);
                    if (!discard) sum += rolls[i];
                }

                processed += `${sum}`;
            }
        }

        this.equationString = equationString;
        this.result = parser.parse(processed).evaluate(null);
    }

    get tooltip() {
        let str = `Rolling: ${this.equationString}\n🠖 ${this.result}`;
        if (this.diceResults.length > 0) {
            str += '\n\n';
            str += `Dice Results:\n${this.diceResults.map(x => `${x[2] ? '~' : ''}(d${x[0]}) 🠖 ${x[1]}`).join('\n')}`;

            if (this.diceResults.some(x => x[2])) {
                str += '\n\n~results have been dropped due to advantage/disadvantage';
            }
        }
        return str;
    }
}