







import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Span } from '@/ts/gamebooks/parsing/Blocks';
import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { LinkDef, RefDef } from '@/ts/gamebooks/models/GamebookData';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';

@Component({
    components: {
    }
})
export default class SpanDisplay extends Vue {
    @Prop({required: true})
    span!: Span;

    @Prop({required: true})
    session!: GamebookSession;

    get gamebook() {
        return this.session.gamebook;
    }

    get classes() {
        let classes = [];
        if (this.reference) classes.push('ref');
        if (this.hoverText) classes.push('info');
        if (this.span.isBold) classes.push('bold');
        if (this.span.isQuote) classes.push('quote');
        if (this.span.isItalic) classes.push('italic');
        if (this.span.linkedPopupId) classes.push('doc-link');
        if (this.text == 'UNKNOWN') classes.push('unknown');
        return classes.join(' ');
    }

    get text(): string {
        let isEmpty = this.span.text == '';
        if (isEmpty) {
            // TODO: Support inlineRollId
            if (this.link) {
                return this.link.text;
            }
            else if (this.reference) {
                if (this.reference.name != null) {
                    return this.reference.name;
                }
                else {
                    console.warn('Inline Reference has no name and no override text', this.span, this.reference);
                    return 'UNKNOWN';
                }
            }
            // TODO: If no text is specified, check for linkedPopupId and use the first title line as text
            else return 'UNKNOWN';
        }
        else {
            return this.span.text;
        }
    }

    get hoverText(): string|undefined {
        let text = this.reference?.description;
        if (this.span.linkedPopupId) {
            text = text ?? '';
            return `Click to open this handout\n\n${text}`.trim();
        }
        return text;
    }

    get reference(): RefDef|undefined {
        let id = this.span.inlineRefId;
        if (id == undefined) return undefined;
        else return this.gamebook.refs[id];
    }

    get link(): LinkDef|undefined {
        let id = this.span.inlineLinkId;
        if (id == undefined) return undefined;
        else return this.gamebook.links[id];
    }

    get href() {
        return this.link?.href;
    }

    onClick() {
        if (this.span.linkedPopupId) {
            this.session.openPopup(this.span.linkedPopupId);
        }
    }
}
