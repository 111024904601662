
























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { CategoryConfig, Filter, Option } from "@/ts/util/filters/_index";
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';

@Component({
    components: {
        ConfigElementPanel,
    }
})
export default class TristateFilterList extends Vue {
    @Prop({required: true})
    filter!: Filter<any>;

    @Prop({required: true})
    showOptionFilter!: boolean;

    @Prop({default: ''})
    listStyle!: string;

    mounted() {
        this.ensureTriStateOptions();
    }

    get config(): CategoryConfig<any> {
        return this.filter.config as CategoryConfig<any>;
    }

    onListInput(group: Filter<any>, newValue: string[]) {
    }

    onListShiftClick(group: Filter<any>, event: any) {
    }

    invokeTristate(slotProps: {option: Option, index: number}) {
        let checkbox: any = this.$refs['tscheckbox' + slotProps.index];
        setTimeout(() => checkbox?.$el?.click(), 1);
    }

    @Watch('filter.options', {deep: true})
    ensureTriStateOptions() {
        let options = this.config.options ?? []
        let map = this.filter.triStateOptions;
        for (let option of options) {
            let key = option.key;
            if (!(key in map)) Vue.set(map, key, null);
        }
    }
}
