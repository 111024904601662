import {Stat} from '@/ts/api/characters/_index';
import {Info} from '@/ts/api/info/_index';

export class SkillInfo extends Info {
    readonly acp: boolean;
    readonly isIndexed: boolean;
    readonly trainedOnly: boolean;

    constructor(json: any, path: string) {
        json = json ?? {};
        super(json, path);

        this.acp = json.acp ?? false;
        this.isIndexed = json.isIndexed ?? false;
        this.trainedOnly = json.trainedOnly ?? false;
    }

    create(): Stat {
        return new Stat({
            refName: this.refName,
            value: {
                type: this.defaultValueType,
                mode: this.defaultValueMode
            }
        });
    }
}