









import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class NavMenu extends Vue {
    get isHome() {
        // return this.$route.name == 'Home';
        return false;
    }

    get menuItems() {
        return [
            {
                label: "Home",
                items: [
                    {
                        label: "Home Page",
                        to: "/",
                    },
                    {
                        label: "Contact",
                        to: "/contact",
                    },
                    {
                        label: "About",
                        to: "/about",
                    },
                    {
                        label: "Attribution & Disclaimers",
                        to: "/disclaimers",
                    },
                ]
            },
            {
                label: "Generators",
                items: [
                    {
                        label: "Encounter Generator",
                        to: "/generators/encounter",
                    },
                    /*
                    {
                        label: "NPC Generator",
                        to: "/simple/generators",
                    },
                    */
                    {
                        label: "Shop Generator",
                        to: "/generate/shops",
                    },
                ],
            },
            {
                label: "Search Tools",
                items: [
                    {
                        label: "Advanced Spell Search",
                        to: "/spells/advanced_search",
                    },
                    {
                        label: "Advanced Monster Search",
                        to: "/bestiary/advanced_search",
                    },
                    {
                        label: "Advanced Item Search",
                        to: "/items/advanced_search",
                    },
                    {
                        label: "Polymorph Search Tool",
                        to: "/tools/polymorph",
                    },
                    /*
                    {
                        label: "Character Vault",
                        to: "/vault/characters",
                    },
                    {
                        label: "Point Buy Calculator",
                        to: "/pointbuy",
                    },
                    */
                ],
            },
            {
                label: "Gamebooks",
                items: [
                    {
                        label: "Gamebook Catalog",
                        to: "/gamebooks",
                    },
                    {
                        label: "FAQ",
                        to: "/gamebooks/faq",
                    },
                ],
            },
            {
                label: "Roadmap",
                items: [
                    {
                        label: "Roadmap",
                        to: "/roadmap",
                    },
                    {
                        label: "Changelog",
                        to: "/changelog",
                    },
                ],
            },
            {
                label: "Support Us",
                to: "/support",
            },
            /*
            {
                label: "Content",
                items: [
                    {
                        label: "Editor",
                        to: "/content/edit",
                    },
                    {
                        label: "Notes",
                        to: "/content/notes",
                    },
                    {
                        label: "Aggregations",
                        to: "/content/aggregations",
                    },
                    {
                        label: "Save All",
                        command: () => (this.$root as any).content.save(),
                    },
                ],
            },
            {
                label: "Game Finder",
                icon: "pi pi-fw pi-users",
                to: "/gamefinder",
            },
            {
                label: "Tools",
                icon: "pi pi-fw pi-briefcase",
                items: [
                    {
                        label: "Generators",
                        items: [
                            {
                                label: "Encounters",
                                to: "/generators/encounter",
                            },
                        ],
                    },
                    {
                        label: "Character Vault",
                        to: "/vault/characters",
                    },
                    {
                        label: "Campaign Manager",
                        to: "/upcoming?status=Coming%20Soon",
                    },
                ],
            }
            */
        ]
    }
}
