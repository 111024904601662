


















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Filter } from "@/ts/util/filters/_index";

@Component
export default class RangeFilterPanel extends Vue {
    @Prop({required: true})
    filter!: Filter<any>;
    
    @Prop({default: 0})
    min!: number;
}
