export class LexToken {
    token_type: string;
    text: string;

    constructor(token_type: string, text: string) {
        this.token_type = token_type;
        this.text = text;
    }

    is_type(token_type: string) {
        return this.token_type.toLowerCase() == token_type.toLowerCase();
    }
}

export class LexPattern {
    token_type: string;
    regex: RegExp;

    constructor(token_type: string, regex: RegExp) {
        this.token_type = token_type;
        this.regex = regex;
    }

    is_type(token_type: string) {
        return this.token_type.toLowerCase() == token_type.toLowerCase();
    }
}

export default class Lexer {
    token_patterns: LexPattern[];
    post_process = (list: LexToken[]) => list;

    constructor() {
        this.token_patterns = [];
    }

    add(token_type: string, regex: RegExp) {
        var pattern = new LexPattern(token_type, regex);
        this.token_patterns.push(pattern);
    }

    lex(string: string): LexToken[] {
        let tokens = [] as LexToken[];
        let index = 0;
        while (index < string.length) {
            let result = this.search(string, index);
            if (result == null) break;
            // TODO: if (result[0] == UNKNOWN)
            let pattern = result[0];
            index = result[1];
            let text = result[2];

            tokens.push(new LexToken(pattern.token_type, text));
        }
        return this.post_process(tokens);
    }

    search(string: string, index: number, ignore_ws=true): [LexPattern, number, string]|null {
        var substring = string.substring(index);
        if (ignore_ws) {
            var length = substring.length;
            substring = substring.trimStart();
            index += length - substring.length;
            substring = substring.trimEnd();
        }

        if (substring.length == 0) return null;

        var earliest_match = -1;
        for (let pattern of this.token_patterns) {
            let matches = substring.match(pattern.regex);
            if (matches != null) {
                let match = matches[0];
                let start = substring.indexOf(match);
                let end = start + match.length;

                if (start == 0) {
                    return [pattern, index + end, match]
                }
                else {
                    earliest_match = start;
                }
            }
        }

        if (earliest_match < 0) {
            earliest_match = substring.length;
        }

        return [UNKNOWN, index + earliest_match, substring.substring(0, earliest_match)];
    }
}

export const UNKNOWN = new LexPattern("UNKNOWN", /.*/);
