import { render, staticRenderFns } from "./DungeonGeneratorMockup.vue?vue&type=template&id=1f2b0a34&scoped=true&"
import script from "./DungeonGeneratorMockup.vue?vue&type=script&lang=ts&"
export * from "./DungeonGeneratorMockup.vue?vue&type=script&lang=ts&"
import style0 from "./DungeonGeneratorMockup.vue?vue&type=style&index=0&id=1f2b0a34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f2b0a34",
  null
  
)

export default component.exports