import { DescriptiveEntry } from "./DescriptiveEntry";

export class GBInventory {
    items: InventoryEntry[] = [];
    coins = {
        pp: 0,
        gp: 0,
        sp: 0,
        cp: 0
    };

    add(name = 'New Item') {
        let item = new InventoryEntry(name);
        this.items.push(item);
        return item;
    }

    removeIndex(index: number) {
        let array = this.items;
        if (index < array.length) array.splice(index, 1);
    }

    clear() {
        this.items.splice(0, this.items.length);
    }
}

export class InventoryEntry implements DescriptiveEntry {
    // TODO: Add id for specially granted items
    name: string;
    qty = 1;
    
    shortDesc = '';
    longDesc = '';

    editing = false;

    constructor(name: string) {
        this.name = name;
    }
}