import {Stat} from '@/ts/api/characters/_index';
import {Info} from '@/ts/api/info/_index';

export class StatInfo extends Info {
    create(): Stat {
        return new Stat({
            refName: this.refName,
            value: {
                type: this.defaultValueType,
                mode: this.defaultValueMode
            }
        });
    }
}