



































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import DataCard from '@/ui/DataCards/DataCard.vue';

import { CardResultsConfig, SortFunction, SortAlgo } from '@/ui/AdvancedSearch/CardResultsConfig';
import { CardConfig } from '@/ui/DataCards/CardConfig';

@Component({
    components: {
        DataCard
    }
})
export default class CardResults<T> extends Vue {
    @Prop({required: true})
    results!: T[];

    @Prop({required: true})
    config!: CardResultsConfig<T>;

    itemsPerPage = this.big ? 4 : 8;
    sort: SortFunction<T>|0 = 0;
    offset = 0;
    
    mounted() {
        let view = this.$route.query.v;
        if (view == 'legacy') {
            this.itemsPerPage = 30;
        }
    }

    get resultView(): T[] {
        let results = this.sort == 0 ? this.results: [...this.results].sort(this.sort);
        return results.slice(this.offset, this.offset + this.itemsPerPage);
    }

    get sortAlgorithms(): SortAlgo<T>[] {
        return this.config.sortAlgorithms;
    }

    get big(): boolean {
        return this.config.big;
    }

    getConfig(entry: T): CardConfig {
        return this.config.getConfig(entry);
    }
}
