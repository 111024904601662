export type StringMap = {[key:string]: string};
export type Dynamic = {[key:string]: any};

/**
 * Utility class for common Array reductions and other generic operations
 */
class Utils {
    static guid(useDashes: boolean = false) {
        let array = new Uint32Array(4);
        window.crypto.getRandomValues(array);
        let string = array[0].toString(16) + array[1].toString(16) + array[2].toString(16) + array[3].toString(16);
        if (useDashes) {
            let withDashes = string.substring(0, 8) + "-" + string.substring(8, 12) + "-" + string.substring(12, 16) + "-" + string.substring(16, 20) + "-" + string.substring(20, 32);
            return withDashes;
        }
        else {
            return string;
        }
    }

    static instanceId() {
        return `i_${Utils.guid()}`;
    }

    static possessiveForm(word: string) {
        // TODO: Adjust for special cases
        return word + "'s"
    }

    static plural(word: string) {
        // TODO: Adjust for more special cases
        let len = word.length;
        if (word.endsWith('y')) {
            let preceeding = word[len-2];
            if ('aeiou'.includes(preceeding)) return word + 's';
            else return word.slice(0, len-1) + 'ies';
        }
        else if (['s', 'x', 'z', 'sh', 'ch'].some(x => word.endsWith(x))) return word + 'es';
        else return word + 's';
    }

    static indexOf<T>(array: T[], matcher: (x: T) => boolean, startIndex = 0) {
        for (let index = startIndex; index < array.length; index++) {
            if (matcher(array[index])) return index;
        }
        return -1;
    }

    static includes<T>(array: T[], matcher: (x: T) => boolean, startIndex = 0) {
        return Utils.indexOf(array, matcher, startIndex) >= 0;
    }

    static sync<S, T>(
        sourceArray: S[],
        targetArray: T[],
        matcher: (s: S, t: T) => boolean,
        sourceChildren: (s: S) => S[],
        targetChildren: (t: T) => T[],
        create: (s: S) => T,
        update?: (source: S, existingNode: T) => void)
    {
        let newNodes: T[] = [];
        for (let source of sourceArray) {
            let existing = targetArray.filter(target => matcher(source, target));
            if (existing.length > 0) {
                let target = existing[0];
                let sChildren: S[] = sourceChildren(source);
                let tChildren: T[] = targetChildren(target);

                if (update) update(source, target);
                Utils.sync(sChildren, tChildren, matcher, sourceChildren, targetChildren, create, update);
                newNodes.push(target);
            }
            else {
                newNodes.push(create(source));
            }
        }

        targetArray.splice(0, targetArray.length, ...newNodes);
    }

    static last<T>(array: T[]): T|undefined {
        return (array.length == 0) ? undefined : array[array.length-1];
    }

    static gtLast<T>(array: T[]): T {
        let last = Utils.last(array);
        if (last == undefined) console.warn('Utils.gtLast() expected array to be non-empty.');
        return last as T;
    }

    static findGroupClose(text: string, open: string, close: string, startIndex=0) {
        let unclosedCount = 0;
        for (let index = startIndex; index < text.length; index++) {
            let char = text[index];
            if (char == open) {
                unclosedCount++;
            }
            else if (char == close) {
                unclosedCount--;
                if (unclosedCount <= 0) {
                    return index;
                }
            }
        }
        return -1;
    }

    static sum(array: number[]): number {
        let sum = 0;
        array.forEach(x => sum+=x);
        return sum;
    }

    static strComp(a: string, b: string) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        if (a < b) return -1;
        else if (a > b) return 1;
        else return 0;
        // sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    }

    static sortAlphaNum(a: string, b: string) {
        return a.localeCompare(b, 'en', { numeric: true });
    }

    static compareCR(cr1: string, cr2: string) {
        if (cr1 == cr2) return 0;
        if (cr2 == null) return 1;
        if (cr1 == null) return -1;
    
        console.assert(typeof cr1 == 'string', 'Expected CR1 to be a string');
        console.assert(typeof cr2 == 'string', 'Expected CR2 to be a string');
    
        return Utils.parseFraction(cr1) - Utils.parseFraction(cr2);
    }
    
    static parseFraction(str: string): number {
        if (str.includes('/')) {
            let parts = str.split('/').map(x => x.trim());
            return parseInt(parts[0]) / parseInt(parts[1]);
        }
        else {
            return parseInt(str.trim());
        }
    }

    static parseRange(str: string): number[] {
        let parts = str.split('-').map(x => x.trim());
        if (parts.length == 1) {
            let x = parseInt(parts[0]);
            if (isNaN(x)) throw 'Unable to parse range ' + str;
            else return [x, x];
        }
        else if (parts.length == 2) {
            let x = parseInt(parts[0]);
            let y = parseInt(parts[1]);
            if (isNaN(x) || isNaN(y)) throw 'Unable to parse range ' + str;
            else return [x, y];
        }
        else {
            throw 'Unable to parse range ' + str;
        }
    }

    static titleCase(str: string, handleDash=false) {
        str = str.toLowerCase().split(' ').map(x=>x.charAt(0).toUpperCase() + x.substring(1)).join(' ');
        if (handleDash) {
            str = str.toLowerCase().split('-').map(x=>x.charAt(0).toUpperCase() + x.substring(1)).join('-');
        }
        return str;
    }

    static trim(string: string, characters: string) {
        if (string == null) return string;
    
        let start = 0;
        let end = string.length;
        for (let i = 0; i < string.length; i++) {
            if (characters.includes(string.charAt(i))) {
                start = i + 1;
            }
            else {
                break;
            }
        }
        for (let i = string.length - 1; i >= 0; i--) {
            if (characters.includes(string.charAt(i))) {
                end = i;
            }
            else {
                break;
            }
        }
    
        return (end > start) ? string.substring(start, end) : "";
    }

    static deepCopy(obj: any): any {
        if (obj == null) {
            return null;
        }
        else if (Array.isArray(obj)) {
            return obj.map(x => Utils.deepCopy(x));
        }
        else if (typeof obj === 'object') {
            let copy: any = {};
            for (let [key, value] of Object.entries(obj)) {
                copy[key] = Utils.deepCopy(value);
            }
            return copy;
        }
        else {
            return obj;
        }
    }

    static isEmpty(value: any) {
        return value == null
        || value === ""
        || (Array.isArray(value) && value.length == 0)
        || (typeof value === 'object' && Object.entries(value).length == 0);
    }

    static deepClean(item: any) {
        if (item == null) return;
        if (Array.isArray(item)) item.forEach(Utils.deepClean);
        else if (typeof item === 'object') {
            for (let [key, value] of Object.entries(item)) {
                Utils.deepClean(value);
                if (typeof value === 'string') item[key] = value.trim();
                if (Utils.isEmpty(value)) delete item[key];
            }
        }
    }

    static overwrite(target: any, data: any) {
        for (let [key, value] of Object.entries(data)) {
            target[key] = value;
        }
    }

    static serialize(self: any, defaultValue: any): any {
        let returnValue: any = {};

        // Only serialize fields that are different from the default
        for (let [key, value] of Object.entries(defaultValue)) {
            if (self[key] != value) {
                returnValue[key] = value;
            }
        }

        return returnValue;
    }

    static invert(obj: StringMap): StringMap {
        let newObj: StringMap = {};
        for (let [key, value] of Object.entries(obj)) {
            if (value in newObj) {
                console.warn('Duplicate values found in object reversal', obj);
            }
            else {
                newObj[value] = key;
            }
        }

        return newObj;
    }

    static createMap<T>(list: T[], keyFunc: (obj: T) => string): {[key: string]: T} {
        let newList: T[] = [];
        let map: {[key: string]: T} = {};
        for (let item of list) {
            let key = keyFunc(item);
            if (key in map) {
                console.warn('Duplicate values found in createMap', key, item, map, list);
            }
            else {
                map[key] = item;
            }
        }

        return map;
    }

    static unique<T>(list: T[]) {
        let newList: T[] = [];
        for (let item of list) {
            if (!newList.includes(item)) {
                newList.push(item);
            }
        }
        return newList;
    }

    static download(filename: string, text: string) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    }

    static decodeHtml(html: string) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
    
    static parseTagStrings(title: string) {
        let tags = [];
        let isParenOpen = false;
        let isBracketOpen = false;
        let tag = "";
    
        for (let c of title) {
            if (isParenOpen) {
                if (c == ')') {
                    tags.push(tag);
                    isParenOpen = false;
                    tag = "";
                }
                else {
                    tag += c;
                }
            }
            else if (isBracketOpen) {
                if (c == ']') {
                    tags.push(tag);
                    isBracketOpen = false;
                    tag = "";
                }
                else {
                    tag += c;
                }
            }
            else if (c == '(') {
                isParenOpen = true;
            }
            else if (c == '[') {
                isBracketOpen = true;
            }
        }
    
        return tags;
    }

    static splitMultiple(string: string, seperators=[',']): string[] {
        let array = [string];
        for (let sep of seperators) {
            array = array.flatMap(x => x.split(sep));
        }

        return array;
    }

    static splitNonGroup(string: string, seperators=[','], group='()'): string[] {
        let str = string;
        let split: string[] = [];

        let start = 0;
        while (str.length > 0) {
            let groupIndex = str.indexOf(group[0], start);
            let targetSep: string|null = null;
            let tIndex = -1;
            for (let sep of seperators) {
                let sIndex = str.indexOf(sep, start);
                if (sIndex >= 0 && (sIndex < groupIndex || groupIndex < 0) && (sIndex < tIndex || tIndex < 0)) {
                    targetSep = sep;
                    tIndex = sIndex;
                }
            }
            
            if (targetSep != null) {
                split.push(str.slice(0, tIndex));
                str = str.slice(tIndex + targetSep.length);
                start = 0;
                continue;
            }
            else if (groupIndex < 0) {
                split.push(str);
                break;
            }
            else {
                start = str.indexOf(group[1], groupIndex);
                console.assert(start > 0, 'Closing parenthesis found without opening', string, start);
                if (start < 0) {
                    split.push(str);
                    break;
                }
            }
        }
    
        return split;
    }

    static removeGroups(string: string): string {
        let str = string;
        let ret = "";

        let open = 0;
        for (let c of str) {
            if (c == '(') {
                open += 1;
            }
            else if (c == ')') {
                open -= 1;
            }
            else if (open <= 0) {
                ret += c;
            }
        }

        return ret;
    }

    static toInt(arg: string|number): number {
        return (typeof arg == 'string') ? parseInt(arg) : arg;
    }

    static parsePairs(pairs: string[], errorContext?: any): any {
        let obj = {} as any;
        for (let pair of pairs) {
            let index = pair.indexOf(':');
            if (index < 1) {
                console.warn('Error parsing pairs. No colon found for key.', {pair, errorContext});
                if (pair in obj) console.warn('Duplicate key found while parsing pairs', {pair, obj, errorContext});
                else obj[pair] = true;
            }
            else {
                let key = pair.slice(0, index);
                let value = pair.slice(index+1).trim();
                
                if (key in obj) console.warn('Duplicate key found while parsing pairs', {pair, obj, errorContext});
                else obj[key] = value;
            }
        }
        return obj;
    }
}

export default Utils;