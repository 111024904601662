










import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import NavMenu from "@/ui/_components/page_layout/NavMenu.vue";

@Component({
    components: {
        NavMenu,
    }
})
export default class App extends Vue {
}
