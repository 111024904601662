










import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SelectionSetting } from '@/ts/util/config/Setting';

@Component
export default class SelectionSettingPanel extends Vue {
    @Prop({required: true})
    setting!: SelectionSetting;
}
