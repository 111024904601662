import { CatFactory, ConfigMap, FilterConfig, FilterSpec, Option } from '@/ts/util/filters/_index';
import { text, cat, range } from '@/ts/util/filters/FilterFactory';
import ItemEntry, { ItemFields } from './ItemEntry';

import itemFilterOptions from '@/data/filterspecs/itemFilters';
import { PFSources } from '@/ts/util/pfmath/PFSources';

export default class ItemFilterFactory {
    static get(items: ItemEntry[], useFilterCache=true): FilterSpec<ItemEntry> {
        let option = (key: string, name: string, description?: string) => new Option(key, name, 0, description);
        let configs: ConfigMap<ItemEntry> = {
            'name': text<ItemEntry>('Name', entry => entry.name),
            'source': cat<ItemEntry>('Source', ItemFields.sourceNames).setToggleGroups([
                {
                    name: 'APs',
                    description: 'Toggle all Adventure Paths',
                    getKeys(options: Option[]) {
                        return options.filter(x => ['pathfinder #', 'ap '].some(start => x.name.toLowerCase().trim().startsWith(start))).map(x => x.key);
                    }
                },
                {
                    name: 'Comics',
                    description: 'Toggle all Official Pathfinder Comic Books',
                    getKeys(options: Option[]) {
                        return options.filter(x => x.name.includes('#') && !['pathfinder #', 'ap '].some(start => x.name.toLowerCase().trim().startsWith(start))).map(x => x.key);
                    }
                },
                {
                    name: 'Bestiaries',
                    description: 'Toggle Bestiary 1-6',
                    getKeys(options: Option[]) {
                        return options.filter(x => x.name.toLowerCase().trim().startsWith('bestiary')).map(x => x.key);
                    }
                },
                {
                    name: 'Inner Sea',
                    description: 'Toggle all Inner Sea guides & setting books',
                    getKeys(options: Option[]) {
                        return options.filter(x => x.name.toLowerCase().trim().includes('inner sea')).map(x => x.key);
                    }
                },
                {
                    name: 'Regional Books',
                    description: 'Toggle all Campaign Setting books that detail a sepcific place in Golarion (e.g. Varisia or Qadira)',
                    getKeys(options: Option[]) {
                        return options.filter(x => PFSources.isRegional(x.name)).map(x => x.key);
                    }
                }
            ]),
            'price': range<ItemEntry>('Price', entry => (typeof entry.cost == 'number') ? entry.cost : -1),
            'weight': range<ItemEntry>('Weight', entry => (typeof entry.weight == 'number') ? entry.weight : -1),
            'size_adjustment_notes': cat<ItemEntry>('Size Adjustments', entry => entry.noteRefs).hideAndOr().setOptions([
                option('pz_in1', 'Small Weighs 1/4', 'These items weigh one-quarter this amount when made for Small characters. Containers for Small characters also carry one-quarter the normal amount.'),
                option('pz_in2', 'Small Weighs 3/4', 'These items weigh approximately three-quarters this amount when made for Small characters. Containers for Small characters also carry one-quarter the normal amount.'),
            ]).hideAndOr().advOnly(),
    
            'item_slot': cat<ItemEntry>('Item Slot', entry => entry.itemSlot).hideAndOr(),
            'category': cat<ItemEntry>('Category', entry => entry.itemCategory).useTitleCase().hideAndOr(),
            'tags': cat<ItemEntry>('Tags', ItemFields.tags),
    
            'weapon_type': cat<ItemEntry>('Weapon Type', entry => entry.weaponCategory?.trim()).hideAndOr().advOnly().condense(),
            'weapon_proficiency': cat<ItemEntry>('Proficiency', ItemFields.weaponProficiency).hideAndOr().advOnly(),
            'damage': cat<ItemEntry>('Damage', ItemFields.damage).hideAndOr().advOnly(),
            'crit_mult': cat<ItemEntry>('Crit Multiplier', ItemFields.critMultiplier).hideAndOr().advOnly(),
            'crit_range': cat<ItemEntry>('Crit Range', ItemFields.critRange).hideAndOr().advOnly(),
    
            'armor_bonus': range<ItemEntry>('Armor Bonus', entry => entry.armorBonus).advOnly(),
            'max_dex': range<ItemEntry>('Max Dex Bonus', entry => entry.maxDexBonus).advOnly(),
            'acp': range<ItemEntry>('Armor Check Penalty', entry => entry.acp).advOnly(),
            'asf': range<ItemEntry>('Arcane Spell Failure Chance', entry => entry.asf).advOnly(),
            'armor_speed': range<ItemEntry>('Armor Speed', entry => entry.armorSpeed).setDescription('Some armors reduce the move speed of a character. This number is the adjusted speed (in ft) of a character with a 30ft base speed.').advOnly(),
            'armor_type': cat<ItemEntry>('Armor Type', entry => entry.armorProficiency?.trim()).hideAndOr().advOnly(),
    
            'capacity': range<ItemEntry>('Capacity', entry => entry.capacity).advOnly(),
            'era': cat<ItemEntry>('Era', entry => entry.era).hideAndOr().advOnly(),
            'miss_range': cat<ItemEntry>('Misfire Range', entry => entry?.misfireRange?.split('(')[0]?.trim()).hideAndOr().advOnly(),
            
            'cl': range<ItemEntry>('Caster Level', entry => entry.casterLevel).advOnly(),
            'craft_dc': range<ItemEntry>('Craft DC', entry => entry.craftDC).advOnly(),
            'deity': cat<ItemEntry>('Deity', ItemFields.deity).hideAndOr().useTitleCase().hideAndOr().advOnly(),
            'ioun_stone_str': cat<ItemEntry>('Ioun Stone Strength', entry => entry.strength).hideAndOr().advOnly(),
    
            'vehicle_type': cat<ItemEntry>('Vehicle Type', entry => entry.vehicleType).hideAndOr().advOnly(),
            'vehicle_size': cat<ItemEntry>('Vehicle Size', entry => entry.vehicleSize).hideAndOr().setOptions(['Large', 'Huge', 'Gargantuan', 'Colossal'].map(x => option(x.toLowerCase(), x))).advOnly(),
            'tsp_cost': range<ItemEntry>('Transport Cost (Per Mile)', entry => entry.transportCostPerMile).advOnly(),
    
            'engine_type': cat<ItemEntry>('Engine Type', entry => entry.engineType).hideAndOr().advOnly(),
            'engine_size': cat<ItemEntry>('Engine Size', entry => entry.engineSize).hideAndOr().advOnly(),
            'aim': range<ItemEntry>('Aim', entry => entry.aim).setDescription('The number of full-round actions required to aim a siege engine. If the siege engine is being controlled by less than its normal crew complement, the number of actions it takes for the crew to aim the siege engine is doubled.').advOnly(),
            'load': range<ItemEntry>('Load', entry => entry.load).setDescription('The number of full-round actions required to load a siege engine').advOnly(),
            'crew': range<ItemEntry>('Crew', entry => entry.crew).setDescription('The number of Medium creatures needed to properly operate the siege engine').advOnly(),
            'engine_speed': range<ItemEntry>('Engine Speed', entry => entry.engineSpeed).advOnly(),
        };
        let tabs = [
            {
                tabName: 'Basic Filters',
                groups: [
                    {
                        groupName: 'Stats',
                        contents: ['source', ['price', 'weight', 'size_adjustment_notes'], 'category', 'tags']
                    },
                    {
                        groupName: 'Misc',
                        contents: [['cl', 'craft_dc'], 'ioun_stone_str']
                    }
                ]
            },
            {
                tabName: 'Arms & Armor',
                groups: [
                    {
                        groupName: 'Weapons',
                        contents: ['damage', ['crit_range', 'weapon_type'], ['crit_mult', 'weapon_proficiency']]
                    },
                    {
                        groupName: 'Armor',
                        contents: [['armor_bonus', 'max_dex', 'acp', 'asf', 'armor_speed'], 'armor_type']
                    },
                    {
                        groupName: 'Firearms',
                        contents: [['capacity', 'era'], 'miss_range']
                    },
                    {
                        groupName: 'Vehicles',
                        contents: [['tsp_cost', 'vehicle_type'], 'vehicle_size']
                    },
                    {
                        groupName: 'Siege Weapons',
                        contents: ['engine_type', 'engine_size', ['aim', 'load', 'crew', 'engine_speed']]
                    },
                ]
            },
        ];
    
        FilterConfig.initConfigMap(configs);
        let preload = useFilterCache ? itemFilterOptions : undefined;
        CatFactory.hydrateAll(configs, items, preload);
        return {configs, layout: {tabs}};
    }
}