















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import TextFilterPanel from '@/ui/_components/config/filters/TextFilterPanel.vue';
import ListFilterPanel from '@/ui/_components/config/filters/ListFilterPanel.vue';
import BoolFilterPanel from '@/ui/_components/config/filters/BoolFilterPanel.vue';
import RangeFilterPanel from '@/ui/_components/config/filters/RangeFilterPanel.vue';

import BoolSettingPanel from '@/ui/_components/config/settings/BoolSettingPanel.vue';
import RangeSettingPanel from '@/ui/_components/config/settings/RangeSettingPanel.vue';
import NumberSettingPanel from '@/ui/_components/config/settings/NumberSettingPanel.vue';
import SelectionSettingPanel from '@/ui/_components/config/settings/SelectionSettingPanel.vue';
import MultiSelectionSettingPanel from '@/ui/_components/config/settings/MultiSelectionSettingPanel.vue';

import { Filter } from "@/ts/util/filters/_index";
import { Setting } from '@/ts/util/config/Setting';

@Component({
    components: {
        TextFilterPanel,
        ListFilterPanel,
        BoolFilterPanel,
        RangeFilterPanel,
        
        BoolSettingPanel,
        RangeSettingPanel,
        NumberSettingPanel,
        SelectionSettingPanel,
        MultiSelectionSettingPanel,
    }
})
export default class ConfigElementPanel extends Vue {
    @Prop({required: true})
    setting!: Setting|Filter<any>;

    // Properties for List Filter
    @Prop({default: null})
    rootList!: any[]|null;

    @Prop({default: false})
    expandedView!: boolean;

    @Prop({default: false})
    fullView!: boolean;

    // Properties for Boolean Setting
    @Prop({default: false})
    reverse!: boolean;
}
