import LoreTemplate, { ConfigOption, ConfigSetting } from "./LoreTemplate";
import Randomizer from "../Randomizer";
import Segment from "./Segment";
import Script from "./Script";
import Markov from "./Markov";
import Utils from "../Utils";
import SanityCheck from "../SanityCheck";
import Toaster from "@/ts/ui_integrations/Toaster";

const Random = Randomizer.Global;
type StringMap = {[key: string]: string};

export default class LoreGen {
    templates: LoreTemplate[] = [];
    toaster?: Toaster;

    constructor(toaster?: Toaster) {
        this.toaster = toaster;
    }

    generateOne(templateName: string, variableOverrides = {} as StringMap): Segment {
        let segment = new Segment(`[${templateName}]`);
        this.evaluate(segment, 0, variableOverrides);
        return segment;
    }

    generate(template: LoreTemplate, amount = 1, variableOverrides = {} as StringMap): Segment[] {
        let results = [] as Segment[];
        for (let i = 0; i < amount; i += 1) {
            let segment = new Segment(`[${template.name}]`);
            this.evaluate(segment, 0, variableOverrides);
            results.push(segment);
        }
        return results;
    }

    evaluate(segment: Segment, currentDepth: number, variableOverrides: StringMap): void {
        const MaxDepth = 10;
        segment.clearChildren();
        let line = segment.original;

        if (currentDepth >= MaxDepth) {
            console.warn('Max depth reached when trying to decode Lore Generator. Results may not be accurate.');
        }
        else if (segment.isLiteral || segment.isError) {
            return;
        }
        else if (!line.startsWith('[') || !line.endsWith(']')) {
            console.warn('Invalid segment format. Expected reference.', segment);
            segment.createAndAddChild('[$FORMAT_ERR]');
        }
        else if (line.startsWith('[$')) {
            // Variable reference
            let inner = line.slice(2, -1);
            let [result, refName] = this.resolve(inner, segment);

            if (result == null) {
                console.warn('Could not evaluate variable', {line, segment, refName});
                this.toaster?.warn('Variable reference not found', refName);
                segment.createAndAddChild('[$VAR_REF_ERR]');
            }
            else {
                this.decode(segment, result.text);
            }
        }
        else {
            // Table Reference
            let inner = line.slice(1, -1);
            let hashIndex = inner.indexOf('#');
            let varIndex = inner.indexOf('$');
            let index = Math.min(...[hashIndex, varIndex, inner.length].filter(x => x >= 0));

            let post = (index < inner.length) ? inner.slice(index) : "";
            let rootName = (index < inner.length) ? inner.slice(0, index) : inner;
            let search = this.findTable(rootName);
            if (!search.result) {
                segment.createAndAddChild(search.error ?? '[$INTERNAL_ERR]');
            }
            else {
                let table = search.result;
                let rawScript = table.data[0];
                if (post.length > 0 && post.startsWith('#')) {
                    let remainder = post.slice(1);
                    if (remainder.startsWith('$')) {
                        remainder = remainder.slice(1);
                        let [result, refName] = this.resolve(remainder, segment);
            
                        if (result == null) {
                            console.warn('Could not evaluate variable', {line, remainder, segment, refName});
                            this.toaster?.warn('Variable reference not found', refName);
                            segment.createAndAddChild('[$VAR_REF_ERR]');
                        }
                        else {
                            let title = result.text;
                            let subtableSearch = SearchResult.create(table.data.filter(x => LoreTemplate.getTitle(x) == title), title, this.toaster);
                            rawScript = subtableSearch.result ?? subtableSearch.error ?? '[$INTERNAL_ERR]';
                        }
                    }
                    else if (remainder.includes('#')) {
                        // TODO: Support multi-nested subtable references (e.g. [Name#Sub1#Sub2])
                        console.warn('Multi-nested subtable references not yet supported', {segment, line});
                        segment.createAndAddChild('[$INTERNAL_ERR]');
                        return;
                    }
                    else {
                        let subtableSearch = SearchResult.create(table.data.filter(x => LoreTemplate.getTitle(x) == remainder), remainder, this.toaster);
                        rawScript = subtableSearch.result ?? subtableSearch.error ?? '[$INTERNAL_ERR]';
                    }
                }
                else if (post.startsWith('$')) {
                    // TODO: Support direct variable references (e.g. [Table$variable])
                    console.warn('Direct variable references not yet supported', {segment, line});
                    segment.createAndAddChild('[$INTERNAL_ERR]');
                    return;
                }
                else if (table.data.length > 1) {
                    let block = this.rollBlock(table);
                    // TODO: Save which block we selected, so we can re-parse the same script later?
                    if (block == null) {
                        segment.createAndAddChild('[$INTERNAL_ERR]');
                        return;
                    }
                    else {
                        rawScript = block;
                    }
                }
                
                let script = Script.parse(rawScript);
                if (script.hasVariables) {
                    let vars = segment.variables ?? {};
                    segment.variables = vars;

                    for (let key of Object.keys(script.variables)) {
                        let fullStringValue = script.variables[key];
                        let varSegment = segment.create(`[$${key}]`);
                        if (key in variableOverrides) varSegment.createAndAddChild(variableOverrides[key])
                        else this.decode(varSegment, fullStringValue);

                        vars[key] = varSegment;
                        varSegment.segments.forEach(x => this.evaluate(x, currentDepth+1, variableOverrides)); // TODO: Pass variableOverrides?
                    }
                }

                let text = script.text;
                if (script.markov) {
                    // Markov ignores empty lines and lead/trail whitespace
                    let lines = script.text.split('\n').map(x => x.trim()).filter(x => x.length > 0);
                    text = Markov.generate(lines, 1)[0] ?? '';
                }
                else if (!script.isBlock) {
                    // Script is lines of individual entries - not a block
                    text = this.rollLine(script);

                    if (script.isTable) {
                        segment.isTableSegment = true;
                        let columns = SanityCheck.notNull(script.tableCols);
                        let split = script.preserveWhitespace ? text.split('|') : text.split('|').map(x => x.trim());

                        // Account for metadata entries
                        if (split.length > columns.length && split[split.length-1].trim().startsWith('[*')) {
                            split.pop();
                        }

                        if (split.length != columns.length) {
                            console.warn(`Generator reference [${inner}] is marked as a table, but the number of columns does not match the data`, {text, script, split, columns});
                            this.toaster?.warn('Table error', `The table [${inner}] has entries with the wrong number of columns`);
                            text = '[$TABLE_ERR]';
                        }
                        else {
                            let vars = segment.variables ?? {};
                            segment.variables = vars;

                            for (let i = 0; i < split.length; i++) {
                                let key = columns[i];
                                let value = split[i];
                                vars[key] = segment.create(value);
                                this.evaluate(vars[key], currentDepth+1, variableOverrides); // TODO: Pass variableOverrides?
                            }
                        }
                    }
                }

                if (!segment.isTableSegment) this.decode(segment, text);
                else this.decode(segment, text.split('|')[0]);
                for (let child of segment.segments) {
                    this.evaluate(child, currentDepth + 1, variableOverrides); // TODO: Pass variableOverrides?
                }
            }
        }
    }

    /** Split a line into Segments, and add them to the specified parent as children */
    decode(parent: Segment, line: string): void {
        const original = line;

        while (line.length > 0) {
            let openIndex = line.indexOf('[');
            let closeIndex = line.indexOf(']');
            if (openIndex < 0 || closeIndex < 0) {
                if (openIndex != closeIndex) {
                    console.warn(`LoreGen attempted to decode string with improper brackets`, {original, text: line, openIndex, closeIndex});
                    this.toaster?.warn('Unexpected formatting detected', original);
                }

                parent.createAndAddChild(line);
                line = "";
                break;
            }
            else {
                let pre = line.slice(0, openIndex);
                let reference = line.slice(openIndex, closeIndex+1); // Ref text, with brackets - e.g. "[Table]"
                line = line.slice(closeIndex+1);
                
                if (pre.length > 0) parent.createAndAddChild(pre);
                parent.createAndAddChild(reference);
            }
        }
    }

    rollBlock(template: LoreTemplate): string|undefined {
        let table = template.data.map(script => {
            let weightLines = script.match(/^\$WEIGHT: [0-9]+/g);
            if (weightLines) {
                let line = weightLines.pop();
                if (line) {
                    return {
                        weight: parseInt(line.split(' ')[1]),
                        text: script
                    }
                }
                else {
                    if (script.includes('$WEIGHT')) {
                        console.warn(`Block weight may not have been parsed properly`, {template, script});
                    }
                    return {
                        weight: 1,
                        text: script
                    }
                }
            }
            else {
                return {
                    weight: 1,
                    text: script
                }
            }
        });

        return Random.randomize(table);
    }

    rollLine(script: Script): string {
        // Determine which line to use
        let table = script.text.trim().split('\n').map(x => {
            if (/^\[%[0-9]+\]/g.test(x)) {
                // Starts with a weight
                let endIndex = x.indexOf(']');
                return {
                    weight: parseInt(x.slice(2, endIndex)),
                    text: x.slice(endIndex+1)
                }
            }
            else {
                return {
                    weight: 1,
                    text: x
                }
            }
        });

        let entry = Random.randomize(table);
        return entry ?? '[$INTERNAL_ERR]';
    }

    findTable(refName: string): SearchResult<LoreTemplate> {
        let search = this.templates.filter(x => x.name == refName);
        return SearchResult.create(search, refName, this.toaster);
    }

    populateSettings(template: LoreTemplate|null) {
        if (template != null && template._settings.length == 0) {
            let script = Script.parse(template.data[0]);
            let userOptions = script.variables.USER_OPTIONS;
            if (userOptions != null) {
                let split = userOptions.split(',').map(x => x.trim());
                for (let varName of split) {
                    let variable = script.variables[varName];
                    if (variable == null) continue;
                    if (!variable.trim().startsWith('[')) continue;

                    let tableName = variable.trim().slice(1, -1);
                    let table = this.findTable(tableName).result;
                    if (!table) continue;

                    let setting = new ConfigSetting(varName);
                    if (table.data.length > 1) {
                        let optionNames = table.data.map(x => LoreTemplate.getTitle(x));
                        setting.rootOptions.push(...Utils.unique(optionNames).map(x => new ConfigOption(x)));
                    }
                    else {
                        let childScript = Script.parse(table.data[0]);
                        if (childScript.markov) continue;
                        else if (childScript.isTable) {
                            let segmentGroups = childScript.text.split('\n').filter(x => x.trim().length > 0).map(x => new Segment(x).tableEntryMetadata?.group ?? 'More');
                            setting.rootOptions.push(...Utils.unique(segmentGroups).map(x => new ConfigOption(x)));

                            if (varName == 'PRONOUNS') {
                                setting.rootOptions.filter(x => x.displayName.startsWith('Extended')).forEach(x => x._value = false);
                            }
                        }
                        else {
                            let lines = childScript.text.split('\n').map(x => x.trim()).filter(x => x != null && !x.startsWith('$'));
                            setting.rootOptions.push(...Utils.unique(lines).map(x => new ConfigOption(x)));
                        }
                    }

                    template._settings.push(setting);
                }
            }
        }
    }

    resolve(inner: string, segment: Segment): [Segment?, string?] {
        let refs = inner.split('$');
        let result: Segment|undefined = segment;
        let refName: string|undefined = SanityCheck.notNull(refs.shift());
        while (refName != null && result != null) {
            result = result.resolveVariable(refName);
            if (result) refName = refs.pop();
        }

        return [result, refName];
    }
}

class SearchResult<T> {
    result?: T;
    error?: string;

    static create<T>(search: T[], refName: string, toaster?: Toaster) {
        let result = new SearchResult<T>();

        if (search.length == 0) {
            console.warn('Table referenced but not found', {refName, search});
            toaster?.warn('Table reference not found', refName);
            result.error = '[$REF_ERR]';
        }
        else if (search.length > 1) {
            console.warn('Multiple matches found', {refName, search});
            toaster?.warn('Multiple matches found', refName);
            result.error = '[$DUP_ERR]';
        }
        else {
            result.result = search[0];
        }

        return result;
    }
}