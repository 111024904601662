
































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class AboutUs extends Vue {
}
