


































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';
import AdvancedSearch from '@/ui/AdvancedSearch/AdvancedSearch.vue';
import TableResults from '@/ui/AdvancedSearch/TableResults.vue';
import CardResults from '@/ui/AdvancedSearch/CardResults.vue';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';
import DataCard from '@/ui/DataCards/DataCard.vue';

import { CardResultsConfig } from '@/ui/AdvancedSearch/CardResultsConfig';
import { SmallMode, TableConfig } from '@/ui/AdvancedSearch/TableConfig';
import { aggregatePolymorphTransforms, PolymorphFields, polymorphTransforms } from '@/ts/api/bestiary/PolymorphFields';
import BestiaryEntry, { BestiaryDisplays } from '@/ts/api/bestiary/BestiaryEntry';
import SpellEntry, { SpellDisplays } from '@/ts/api/spells/SpellEntry';
import { Filter } from '@/ts/util/filters/Filter';
import SanityCheck from '@/ts/util/SanityCheck';
import Utils, { StringMap } from '@/ts/util/Utils';

@Component({
    components: {
        ConfigElementPanel,
        AdvancedSearch,
        TableResults,
        CardResults,
        DataCard,
        Sidebar,
    }
})
export default class PolymorphTool extends Vue {
    // Map each polymorph spell (e.g. Beast Shape) to a list of spell variations (e.g. ['I', 'II', 'III', 'IV'])
    polymorphTypeMap: {[key: string]: string[]} = getPolymorphTypeMap();
    selectedSpell = 'Beast Shape';
    previous = 'Beast Shape';
    spellType = this.polymorphTypeMap[this.selectedSpell][0] ?? '';

    activeTab = 'spell';
    displayModal = false;

    @Watch('selectedSpell')
    updateSelectedType() {
        if (!this.spellOptions.includes(this.selectedSpell)) this.selectedSpell = this.previous;
        this.previous = this.selectedSpell;

        let options = this.spellTypeOptions;
        if (!options.includes(this.spellType)) {
            this.spellType = options.length > 0 ? options[0] : '';
        }
    }

    get spellCardConfig() {
        let name = this.polymorphKey;
        let root = this.$root as any;
        let spells = root.spells as SpellEntry[];
        let result = spells.filter(x => x.name == name).pop();
        if (result) return SpellDisplays.getCardConfig(result);
        else {
            let descriptionMap: StringMap = root.polymorphDescriptions;
            let description = descriptionMap[this.selectedSpell];
            if (description) {
                return {
                    name: this.selectedSpell,
                    sources: [],
                    titleTags: [],
                    tabs: [
                        {
                            name: "Details",
                            md: description,
                        }
                    ],
                }
            }
            else {
                console.warn('Polymorph/Widlshape description not found for ' + this.selectedSpell);
                return null;
            }
        }
    }

    get spellOptions() {
        return Object.keys(this.polymorphTypeMap);
    }

    get spellTypeOptions() {
        return this.polymorphTypeMap[this.selectedSpell] ?? [];
    }

    get showSpellTypes() {
        return this.spellTypeOptions.length > 0;
    }

    get showAdvFilters(): boolean {
        return this.advSearchComponent.showAdvFilters;
    }

    set showAdvFilters(value: boolean) {
        this.advSearchComponent.showAdvFilters = value;
    }

    get sourceFilter(): Filter<BestiaryEntry>|undefined {
        return this.advSearchComponent.sourceFilter;
    }

    get sidebarFilters(): Filter<BestiaryEntry>[] {
        return this.advSearchComponent.sidebarFilters;
    }

    get sidebarFilterGroups(): Filter<BestiaryEntry>[] {
        return this.advSearchComponent.sidebarFilterGroups;
    }

    get polymorphKey() {
        return [this.selectedSpell, this.spellType].join(' ').trim();
    }

    get preFilter(): BestiaryEntry[] {
        let root = this.$root as any;
        return PolymorphFields.filter(root.bestiary, this.polymorphKey);
    }

    cardConfig(big: boolean): CardResultsConfig<BestiaryEntry> {
        let key = this.polymorphKey;
        return {
            big,
            sortAlgorithms: BestiaryDisplays.sortAlgorithms,
            getConfig: (monster: BestiaryEntry) => PolymorphFields.getCardConfig(monster, key),
        };
    }

    tableConfig(): TableConfig<BestiaryEntry> {
        let polyKey = this.polymorphKey;
        let getConfigFunction = (monster: BestiaryEntry) => PolymorphFields.getCardConfig(monster, polyKey);

        return {
            getSearchTerm: BestiaryDisplays.searchTerm,
            getConfig: getConfigFunction,
            getLinks: item => item.links,
            columns: [
                {
                    name: "Name",
                    field: "name",
                    smMode: SmallMode.Compress,
                    display: entry => entry.name,
                    isSortable: true,
                },
                {
                    name: "CR",
                    field: "_cr",
                    smMode: SmallMode.Hide,
                    display: entry => entry.cr,
                    isSortable: true,
                },
                {
                    name: "Type",
                    field: "type",
                    smMode: SmallMode.Hide,
                    display: entry => entry.type,
                    isSortable: true,
                },
                {
                    name: "Size",
                    field: "size",
                    smMode: SmallMode.Hide,
                    display: entry => entry.size,
                    isSortable: true,
                },
                {
                    name: "Sources",
                    field: "",
                    smMode: SmallMode.Hide,
                    display(entry: BestiaryEntry) {
                        return formatSourceList(entry.sources);
                    },
                    isMD: true,
                },
            ],
        };
    }

    get advSearchComponent() {
        return this.$refs.advSearch as any;
    }

    collapseSidebar() {
        this.advSearchComponent.showFilter = false;
    }

    showDetailsPopup(event: any) {
        this.displayModal = true;
    }

    showInstructions() {
        this.activeTab = 'instructions';
        this.advSearchComponent.showFilter = true;
    }

    openSearchWizard() {
        this.advSearchComponent.showFilterModal();
    }
}

function formatSourceList(list: string[]) {
    return list?.map(x => x.split('pg.')[0].trim()).join('; \n\n') ?? '';
}

function getPolymorphTypeMap() {
    let keyMap: {[key: string]: string[]} = {};
    let stagedList: {key: string, value?: string}[] = [];

    let stage = (key: string, value?: string) => {
        stagedList.push({key, value});
    };
    let process = () => {
        for (let {key, value} of stagedList) {
            if (!(key in keyMap)) keyMap[key] = [];
            if (value && !keyMap[key].includes(value)) keyMap[key].push(value);
        }

        stagedList = [];
    };

    for (let key of Object.keys(polymorphTransforms)) {
        if (/ [IV]+$/.test(key)) {
            let split = key.split(' ');
            let type = SanityCheck.notNull(split.pop());
            stage(split.join(' '), type);
        }
        else {
            stage(key);
        }
    }
    stagedList.sort((a, b) => Utils.sortAlphaNum(a.key, b.key));
    process();

    for (let key of Object.keys(aggregatePolymorphTransforms)) {
        if (/ lv[0-9]+$/.test(key)) {
            let split = key.split(' ');
            let type = SanityCheck.notNull(split.pop());
            stage(split.join(' '), type);
        }
        else {
            stage(key);
        }
    }
    process();

    return keyMap;
}
