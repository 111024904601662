












import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BoolSetting } from '@/ts/util/config/Setting';

@Component
export default class BoolSettingPanel extends Vue {
    @Prop({required: true})
    setting!: BoolSetting;

    @Prop({default: false})
    reverse!: boolean;
}
