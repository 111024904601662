type Dynamic = {[key: string]: any};

export class CntCompletionStatus {
    meta: boolean;
    grants: boolean;
    children: boolean;
    classSkills: boolean;
    proficiencies: boolean;
    linkIsCorrect: boolean;

    constructor(json: Dynamic) {
        if (json == null) json = {};

        this.meta = json.meta ?? false;
        this.grants = json.grants ?? false;
        this.children = json.children ?? false;
        this.classSkills = json.classSkills ?? false;
        this.proficiencies = json.proficiencies ?? false;
        this.linkIsCorrect = json.linkIsCorrect ?? false;
    }
}