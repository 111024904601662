import BestiaryEntry from '../api/bestiary/BestiaryEntry';
import { MultiSelectionSetting, NumberSetting, RangeSetting, SelectionOption, SelectionSetting } from '../util/config/Setting';
import { GtdRangeSnapshot, RangeSnapshot } from '../util/config/Snapshots';
import { Filter } from '../util/filters/Filter';

export class CombatConfig {
    crRange = new RangeSetting('CR', 'Generate encounters with a CR between the min and max values (inclusive)', 0, 50);
    enemyCountRange = new RangeSetting('# of Enemies', 'The total number of enemies to generate for each encounter', 1, 16);

    apl = new NumberSetting('Party Level', 'The average Character Level of each member of the party.\n\ne.g. A party of 4 level 2 characters would have a Party Level of 2.', 1, 100).setInitial(1);
    partySize = new NumberSetting('Party Size', 'The number of player characters in your party.', 1, 16).setInitial(4);

    diversity = new MultiSelectionSetting(
        'Combat Diversity',
        'The number of unique enemy types to generate. For instance, if this is set to 1, and the # of enemies is 8, the result will be encounters with 8x one type of monster.\n\nIf diversity is 2, there will be 2 types of monster in each encounter, with quantities of 1/7, 2/6, 3/5, 4/4, etc.',
        ['1', '2', '3', '4+'].map(x => new SelectionOption(x))
    ).setInitialKeys(['1', '2', '3']).setCompact();

    difficulties = new MultiSelectionSetting(
        'Combat Difficulty', 
        'This setting determines what CR encounters will be generated at.\n\nThe listed difficulties and corresponding CRs come from the guidelines described on page 397 of the Pathfinder Core Rulebook.',
        ['Easy', 'Average', 'Challenging', 'Hard', 'Epic'].map(x => new SelectionOption(x, x, getDiffDescription(x)))
    ).setInitialKeys(['Average']);

    settingsMode = new SelectionSetting(
        'Config Mode', 
        'Determines which settings are displayed.\n\nParty: input your party\'s level, size, and difficulty then let us calculate CR.\n\nCR: input the range of CRs you want to generate.',
        ['Party', 'CR'].map(x => new SelectionOption(x))
    ).setInitial('Party');

    filters: {[key: string]: Filter<BestiaryEntry>};
    bestiary: BestiaryEntry[];

    constructor(bestiary: BestiaryEntry[], filters: {[key: string]: Filter<BestiaryEntry>}) {
        this.crRange.min = 1;
        this.crRange.max = 3;
        this.enemyCountRange.min = 1;
        this.enemyCountRange.max = 4;

        this.bestiary = bestiary;
        this.filters = filters;
    }

    get isRelative() {
        return this.settingsMode.selectedKey == 'Party';
    }
    get isAbsolute() {
        return this.settingsMode.selectedKey == 'CR';
    }

    get filteredBestiary() {
        return Filter.filter(this.bestiary, this.filters);
    }

    getSnapshot() {
        return new CombatConfigSnapshot(this);
    }
}

export class CombatConfigSnapshot {
    crRange: GtdRangeSnapshot;
    enemyCountRange: GtdRangeSnapshot;

    apl: number;
    partySize: number;
    diversity: string[];
    difficulties: string[];
    settingsMode: string;

    constructor(config: CombatConfig) {
        this.crRange = config.crRange.getGtdSnapshot();
        this.enemyCountRange = config.enemyCountRange.getGtdSnapshot();
        
        this.apl = config.apl.getGtdSnapshot();
        this.partySize = config.partySize.getGtdSnapshot();
        this.diversity = config.diversity.getSnapshot();
        this.difficulties = config.difficulties.getSnapshot();
        this.settingsMode = config.settingsMode.getSnapshot();
    }
}

function getDiffDescription(difficulty: string) {
    let map: {[key: string]: string} = {
        'Easy': 'CR equals APL-1',
        'Average': 'CR equals APL',
        'Challenging': 'CR equals APL+1',
        'Hard': 'CR equals APL+2',
        'Epic': 'CR equals APL+3'
    };
    return map[difficulty] ?? '';
}