import {Grant} from '@/ts/api/grants/_index';

type StringMap = {[key: string]: string};
type Dynamic = {[key: string]: any};

export class CompanionGrant extends Grant {
    companionType: string; // E.g. familiar, animal_companion, drake_companion, eidolon, unchained_eidolon
    limitations: string; // E.g. 'Horse or dog'
    statAliases: StringMap; // refName to equationString
    automaticSelections: StringMap; // selectionId to selected value or id

    constructor(json: Dynamic) {
        super(json);
        
        this.limitations = json.limitations ?? "";
        this.statAliases = json.statAliases ?? {};
        this.companionType = json.companionType ?? "";
        this.automaticSelections = json.automaticSelections ?? {};
    }
}