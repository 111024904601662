import ItemEntry, { ItemFields } from "../api/items/ItemEntry";
import { BoolSetting, MultiSelectionSetting, NumberSetting, RangeSetting, SelectionOption, SelectionSetting } from "../util/config/Setting";
import { Filter } from "../util/filters/Filter";
import { CategoryWeights } from "./CategoryWeights";

export class LootConfig {
    reduceCoins = new BoolSetting('Consolidate Coins', 'If selected, coins of lower denominations will be converted to higher denomination coins when possible. E.g. 258cp will become 2gp, 5sp, and 8cp').setInitial(true);
    liquidateJunk = new BoolSetting('Liquidate Junk', 'If selected, items worth less than the specified value (default 10gp) will be replaced with coins').setInitial(false);
    junkValue = new NumberSetting('Junk Value', 'Items with a price less than or equal to this number will be considered \'junk\' and replaced with coins.', 0).setInitial(10);

    useABP = new BoolSetting('Auto Bonus Progression', 'If selected, generated loot will be reduced by 50% and will not include any items that are disallowed by the Automatic Bonus Progression (ABP) optional ruleset (Items that only grant bonuses to AC, saving throws, and ability scores don’t exist in this variant).').setInitial(false);
    excludeABPPlus = new BoolSetting('Exclude ABP-RAI', 'Rules as-written, Automatic Bonus Progression excludes items that ONLY grant bonuses to AC, saves, or ability scores. However, there are a number of items that grant an additional bonus or effect (such as a +5 bonus to Acrobatics), but still don\'t represent the spirit of ABP exclusions.\n\nGMs may wish to disable these items in addition to the standard ABP exclusions. Checking this box will do so.').setInitial(true);

    includeNPCGear = new BoolSetting('Include NPC Gear', 'If checked, any NPC gear listed on a monster will be included in the loot hoard in addition to the randomized hoard.').setInitial(true);
    includeNonSpecificItems = new BoolSetting('Include Non-Specific Items', '\'Specific\' items refers to items that have an explicit listing in a published book. For instance, a +1 greatsword is NOT a specific item, but a \'greatsword\' (without modifiers or enchantments) or a +2 Headband of Inspired Wisdom are.\n\nIf this option is selected, both specific and non-specific items will be included in loot piles. (This is the default and recommended setting)');
    
    intelligentChance = new NumberSetting('Intelligent %', 'The chance (in %) that any given encounter will include an intelligent item.\n\nNOTE: Adding intelligence to an item has the potential to wildly exceed an encounter\'s budget. If you want to disable this option, set the chance to 0.', 0, 100).setInitial(0);

    categoryWeights = new CategoryWeights();

    // Budget Settings
    budgetCR = new NumberSetting('CR', 'CR determines the approximate value of a loot hoard, based on the Challenge Rating of an encounter.', 0);
    budgetRange = new RangeSetting('Budget', 'The loot budget (in gp) for each encounter.', 0).setInitial(100, 2000);
    budgetMagnitude = new MultiSelectionSetting('Budget Magnitude', 'When loot is generated, the hoard value is modified based on the Treasure Magnitude specified by the monsters in an encounter.\n\nThis setting allows you to enable/disable magnitudes.\n\nEx: if you disable None and Incidental, then those hoards will use the Standard value. If you disable all options, Standard will be used.', [
        new SelectionOption('none', 'None'),
        new SelectionOption('incidental', 'Incidental'),
        new SelectionOption('standard', 'Standard'),
        new SelectionOption('double', 'Double'),
        new SelectionOption('triple', 'Triple'),
    ]);
    budgetMultiplier = new NumberSetting('Multiplier', 'After calculating the budget for a loot hoard based on the settings you selected, that budget will be multiplied by this number. For instance, if you make the magnitude \'Triple\' and set the multiplier to 2, the loot hoard will have a value 6x higher than what is normal for that CR.', 0).decMode().setInitial(1);
    budgetMode = new SelectionSetting('Budget Mode', getBudgetModeDesc(), [
        new SelectionOption('cr', 'CR', getBudgetModeDesc('cr')),
        new SelectionOption('range', 'Range', getBudgetModeDesc('range')),
    ]);
    progression = new SelectionSetting('Progression', '', [
        new SelectionOption('slow', 'Slow'),
        new SelectionOption('medium', 'Medium'),
        new SelectionOption('fast', 'Fast'),
    ]).setInitial('medium');

    // Omnigen settings
    singleItemValueRange = new RangeSetting('Single Item Value', 'The max/min value of any single item in a hoard.');
    itemCountRange = new RangeSetting('# of Items', 'The max/min number of items in a single hoard. Coins are not counted in this limit.');
    
    itemTypes = new MultiSelectionSetting(
        'Item Types', 
        'This setting determines what types of items (Magic, Mundane, or Alchemical) can be generated. More granular filters for item type can be found in the Category filters by clicking \'More Filters\' below.',
        ['Magic', 'Mundane', 'Alchemical'].map(x => new SelectionOption(x))
    ).setInitialKeys(['Magic', 'Mundane', 'Alchemical']);

    filters: {[key: string]: Filter<ItemEntry>};
    items: ItemEntry[];

    constructor(items: ItemEntry[], filters: {[key: string]: Filter<ItemEntry>}) {
        this.filters = filters;
        this.items = items;
    }

    get isRangeBudget() {
        return this.budgetMode.selected.key == 'range';
    }

    get isCRBudget() {
        return this.budgetMode.selected.key == 'cr';
    }

    getFilteredList(): ItemEntry[] {
        let typeKeys = this.itemTypes.selectedKeys;
        let magic = typeKeys.includes('Magic');
        let mundane = typeKeys.includes('Mundane');
        let alchemical = typeKeys.includes('Alchemical')

        let items = this.items;
        if (typeKeys.length < 3) {
            items = items.filter(x => {
                if (!magic && ItemFields.isMagic(x)) return false;
                if (!mundane && ItemFields.isMundane(x)) return false;
                if (!alchemical && ItemFields.isAlchemical(x)) return false;

                return true;
            });
        }

        if (this.liquidateJunk.value) {
            let junkValue = this.junkValue.value ?? this.junkValue.defaultValue ?? 0;
            if (junkValue > 0) items = items.filter(x => ItemFields.parsePrice(x) > junkValue || ItemFields.parsePrice(x) <= 0 || ItemFields.parsePrice(x) == null);
        }

        if (this.useABP.value) {
            if (this.excludeABPPlus.value) items = items.filter(x => !(x.abpOnly || x.abpPlus))
            else items = items.filter(x => !x.abpOnly); // Remove items that ONLY grant bonuses to AC, saving throws, or ability scores
        }

        return Filter.filter(items, this.filters);
    }

    /*
        Standard (UE) Loot Generation:
        Artemis Loot Generation:
        --
        Budget: None', 'Incidental', 'Standard', 'Double', 'Triple', 'NPC Gear
        --
        Consolidate Coins?:
        Liquidate Misc?:
        Magic Items?:
        Single Item?:

        hoard value
        individual item value
        loot filters
    */
}

function getBudgetModeDesc(key?: string): string {
    if (key == 'cr') {
        return 'the value of each hoard will be determined by the CR of the encounter.';
    }
    else if (key == 'magnitude') {
        return 'select from the magnitudes (Standard, Double, etc.) defined in Ultimate Equipment. Encounters will generate loot as close to their specified magnitude as possible, within the selected magnitudes.';
    }
    else if (key == 'range') {
        return 'specify a budget (in gp) rather than using generalized magnitudes. Use this option if you want more control over how much treasure is generated.';
    }
    else {
        return 'Determines which algorithm to use to generate loot, and which settings to display.' + 
        // TODO: `\n\nMagnitude: ${get('Magnitude')}` + 
        `\n\nCR: ${getBudgetModeDesc('cr')}` + 
        `\n\nRange: ${getBudgetModeDesc('range')}`;
    }
}