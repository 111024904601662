import MDBuilder from "@/ts/util/MDBuilder";
import SanityCheck from "@/ts/util/SanityCheck";
import Utils from "@/ts/util/Utils";
import { CardConfig } from "@/ui/DataCards/CardConfig";
import BestiaryEntry, { AbilitySnippet, BestiaryDisplays, BestiaryFields, DR, drToString, resistanceToString, snippetToString } from "./BestiaryEntry";

export class PolymorphFields {
    static getCardConfig(monster: BestiaryEntry, spellName: string): CardConfig {
        return {
            name: monster.name,
            sources: monster.sources,
            secondaryTitle: `(CR ${monster.cr})`,
            tabs: [
                {
                    name: "This Form Grants...",
                    md: PolymorphFields.md(monster, spellName),
                }
            ],

            searchTerm: BestiaryDisplays.searchTerm(monster),
            links: monster.links,
        }
    }

    static filter(bestiary: BestiaryEntry[], spellName: string): BestiaryEntry[] {
        if (spellName in polymorphTransforms) {
            let transform = polymorphTransforms[spellName];
            if (!transform.allowSwarms) {
                bestiary = bestiary.filter(x => !BestiaryFields.stripedSubtypes(x).includes('swarm'));
            }
            return bestiary.filter(x => transform.validator(x));
        }
        else if (spellName in aggregatePolymorphTransforms) {
            let transform = aggregatePolymorphTransforms[spellName];
            let mods = transform.mods ?? {};
            let validators: ((x: BestiaryEntry) => boolean)[] = [];
            for (let key of (transform.spells ?? [])) {
                if (key in polymorphTransforms) {
                    let mod = mods[key] ?? {};
                    let transform = polymorphTransforms[key];
                    let validator = mod.overrides?.validator ?? transform.validator;
                    let allowSwarms = mod.overrides?.allowSwarms ?? transform.allowSwarms;
                    if (allowSwarms) {
                        validators.push(validator);
                    }
                    else {
                        validators.push((x: BestiaryEntry) => !BestiaryFields.stripedSubtypes(x).includes('swarm') && validator(x));
                    }
                }
                else console.warn('Spell not found', {key, aggregation: spellName})
            }

            return bestiary.filter(x => validators.some(validate => validate(x)));
        }
        else return [];
    }

    static md(monster: BestiaryEntry, spellName: string): string {
        let md = new MDBuilder();
        let grant = polymorphTransforms[spellName];
        let aggregate = aggregatePolymorphTransforms[spellName];
        if (grant == undefined) {
            // TODO: Warn on not found
            if (aggregate == undefined) return 'Polymorph spell not found: ' + spellName;
            else {
                let mods = aggregate.mods ?? {};
                for (let key of (aggregate.spells ?? [])) {
                    let spell = polymorphTransforms[key];
                    // TODO: Warn on not found
                    if (spell) {
                        let mod = mods[key] ?? {};
                        let validator = mod.overrides?.validator ?? spell.validator;
                        if (validator(monster)) {
                            grant = mod? Utils.deepCopy(spell) : spell;
                            if (mod) Utils.overwrite(grant, mod);
                            // TODO: Handle additions & exclusions
    
                            break;
                        }
                    }
                }

                // TODO: Warn on not found
                if (grant == undefined) return `ERROR: Unable to parse stats for ${monster.name} / ${spellName}`;
            }
        }

        let statBonuses = grant.statBonuses(monster, spellName);
        let aan = (/^[aeiou]/gi.test(monster.name)) ? 'an' : 'a';
        let lines = [`Size: ${monster.size.toLowerCase()}`, `+10 bonus on Disguise skill checks to appear as ${aan} ${monster.name}`, ...statBonuses];
        md.md += '* ' + lines.join('\n* ');

        let subtypes = BestiaryFields.stripedSubtypes(monster);
        let subtypeTransforms = grant.subtypeTransforms ?? {};
        for (let subtype of subtypes.filter(x => x in subtypeTransforms)) {
            md.li(subtypeTransforms[subtype]);
        }

        let monsterSpeeds = monster.stats?.speeds ?? {};
        let monsterSpeedNotes = monster.statNotes?.speeds ?? {};

        // If the form you assume does not possess the ability to move, your speed is reduced to 5 feet and you lose all other forms of movement (Plant Shape)
        let numSpeeds = Object.keys(monsterSpeeds).length;
        if (numSpeeds == 0 || (numSpeeds == 1 && monsterSpeeds.base == 0)) {
            md.li('Your speed is reduced to 5 feet and you lose all other forms of movement');
        }
        else {
            if (monsterSpeeds.base) {
                let baseSpeed = Math.max(monsterSpeeds.base, grant.maxBaseSpeed ?? 0);
                if (grant.baseSpeedEnhancement) baseSpeed += grant.baseSpeedEnhancement;
                md.li(`base speed ${baseSpeed}ft.`);
            }
            for (let speed of (grant.speeds ?? [])) {
                let [name, value] = speed.split(' ');
                if (!(name in monsterSpeeds)) continue;
                else if (name == 'fly') {
                    let manuever = '';
                    let oldManuever = Utils.trim(monsterSpeedNotes['fly'] ?? '', '() ');
                    let split = value.split(' ');
                    if (split.length > 1) {
                        value = split[0];
                        manuever = Utils.trim(split[1], '() ');
                    }
    
                    let newSpeed = Math.min(monsterSpeeds[name], parseInt(value));
                    let newManuever = PolymorphFields.minManeuver(manuever, oldManuever);
                    md.li(`${name} speed ${newSpeed}ft. ${newManuever}`.trim());
                }
                else {
                    let newSpeed = Math.min(monsterSpeeds[name], parseInt(value));
                    md.li(`${name} speed ${newSpeed}ft.`);
                    if (name == 'swim' || name == 'burrow') {
                        let presentProgressive = name == 'swim' ? 'swimming' : 'burrowing';
                        md.li(`you maintain the ability to breathe while ${presentProgressive}`);
                    }
                }
            }
        }
        
        let healthAbilities = monster.healthAbilities ?? [];
        for (let abilityStr of (grant.healthAbilities ?? [])) {
            let [name, value] = abilityStr.split(' ');
            let ability = healthAbilities.filter(x => x.name == name).pop();
            if (ability) {
                ability = Utils.deepCopy(ability) as AbilitySnippet;
                if (value) {
                    ability.magnitude = Math.min(parseInt(value), ability.magnitude ?? 9001);
                }
                let snippet = snippetToString(ability);
                md.li(snippet);
            }
        }

        let senses = monster.senses ?? [];
        for (let sense of (grant.senses ?? [])) {
            if (/ [0-9]+$/.test(sense)) {
                let split = sense.split(' ');
                let magnitude = split[split.length-1];
                let name = sense.slice(0, sense.length-magnitude.length-1);
                let match = senses.filter(x => x.startsWith(name)).pop();
                if (match) {
                    let magStr = Utils.trim(match.split(' ')[1], 'ft. ');
                    let index = match.indexOf('(');
                    let notes = (index < 0) ? '' : match.slice(index);
                    magnitude = `${Math.min(parseInt(magStr), parseInt(magnitude))}`;
                    md.li(`${name} ${magnitude} ${notes}`.trim())
                }
            }
            else {
                let hasSense = senses.includes(sense);
                let hasSpecial = (monster.specialAbilities ?? []).some(x => x.name == sense);
                let hasDefensive = (monster.defensiveAbilities ?? []).some(x => x.name == sense);
                if (hasSense || hasSpecial || hasDefensive) {
                    md.li(sense);
                }
            }
        }

        let immunityTransforms = grant.immunityTransforms ?? {};
        let monsterImmunities = monster.immunities ?? [];
        let grantImmunities = grant.immunities ?? [];
        for (let key of monsterImmunities) {
            if (grantImmunities.includes(key)) {
                md.li(`Immunity to ${key}`);
            }
            else if (key in immunityTransforms) {
                md.li(immunityTransforms[key]);
            }
        }

        let resistanceTransforms = grant.resistanceTransforms ?? {};
        let monsterResistances = monster.resistances ?? [];
        let grantResistances = grant.resistances ?? [];
        for (let res of monsterResistances) {
            if (grantResistances.includes(res.name)) {
                md.li(`Resistance to ${resistanceToString(res)}`);
            }
            else if (res.name in resistanceTransforms) {
                md.li(resistanceTransforms[res.name]);
            }
        }

        // Handle DR seperately from other special abilities
        let specialAbilitiyGrants = grant.specialAbilities ?? [];
        let drGrants = specialAbilitiyGrants.filter(x => x.startsWith('dr ') || x == 'dr');
        for (let entry of drGrants) {
            if (entry == 'dr') {
                for (let dr of (monster.dr ?? [])) {
                    md.li(drToString(dr));
                }
            }
            else {
                let str = entry.slice(3) // Remove 'dr ' from the beginning of the string
                let split = str.split('/');
                if (split.length != 2) console.warn('Unexpected format for polymorph DR: ' + entry);
                else {
                    let magnitude = parseInt(split[0]);
                    let overcome = split[1];
                    let filter: DR[] = (monster.dr ?? []).filter(x => x.overcome == overcome).map(x => Utils.deepCopy(x));
                    for (let dr of filter) {
                        if (!isNaN(magnitude)) dr.magnitude = Math.min(magnitude, dr.magnitude);
                        md.li(drToString(dr));
                    }
                }
            }
        }

        // Natural Attacks
        let naturalAttacks = getNaturalAttacks(monster, grant, false); // TODO: Support 'Count touch attacks as natural attacks'
        if (naturalAttacks.length > 0) {
            md.h2('Natural Attacks');
            for (let atk of naturalAttacks) {
                md.li(atk);
            }
        }

        // Handle Rock Throwing seperately from other special abilities
        let monsterRockThrowingGrant = (monster.specialAttacks ?? []).filter(x => x.name.endsWith('rock throwing')).shift();
        let rockThrowingLine = null as string|null;
        if (monsterRockThrowingGrant) {
            let monsterRange = parseInt(Utils.trim(monsterRockThrowingGrant.notes ?? '', '() ft.'));
            let rockThrowingGrant = specialAbilitiyGrants.filter(x => x.startsWith('rock throwing')).pop();
            if (rockThrowingGrant) {
                let str = rockThrowingGrant.slice('rock throwing '.length) // Remove 'rock throwing ' from the beginning of the string
                str = str.trim().slice(1, -1); // Remove lead and trail parenthesis
                let split = str.split(', ');
                if (split.length != 2) console.warn('Unexpected format for polymorph Rock Throwing: ' + rockThrowingGrant);
                else {
                    let damage = split[1];
                    let range = parseInt(split[0]);
                    if (isNaN(range)) range = monsterRange;
                    if (monsterRange && !isNaN(monsterRange)) range = Math.min(range, monsterRange);
                    
                    if (!isNaN(range)) rockThrowingLine = `rock throwing (${range} ft., ${damage} damage)`;
                    else {
                        console.warn('NaN Range (rock throwing)', {monsterRockThrowingGrant, rockThrowingGrant, str, split});
                        rockThrowingLine = `rock throwing (${damage} damage)`;
                    }
                }
            }
        }
        
        // Handle Rend seperately from other special abilities
        let monsterRendGrant = (monster.specialAttacks ?? []).filter(x => x.name == 'rend').shift();
        let rendLine = null as string|null;
        if (monsterRendGrant) {
            let rendGrant = specialAbilitiyGrants.filter(x => x.startsWith('rend')).pop();
            if (rendGrant) rendLine = rendGrant;
        }

        // Check specialAbilities, specialAttacks, specialQualities, and defensiveAbilities - sometimes abilities are only listed under one of these groups, without a seperate ability description
        let grantAbilities = specialAbilitiyGrants.filter(x => !drGrants.includes(x) && !x.startsWith('rock throwing') && !x.startsWith('rend'));
        let specialAttacks = monster.specialAttacks ?? [];
        let specialAbilities = monster.specialAbilities ?? [];
        let specialQualities = monster.specialQualities ?? [];
        let defensiveAbilities = monster.defensiveAbilities ?? [];

        // Account for notes format
        defensiveAbilities = defensiveAbilities.filter(x => grantAbilities.includes(x.name.split('(')[0]));
        specialAbilities = specialAbilities.filter(x => grantAbilities.includes(x.name.split('(')[0]));
        specialQualities = specialQualities.filter(x => grantAbilities.includes(x.split('(')[0]));
        specialAttacks = specialAttacks.filter(x => grantAbilities.includes(x.name.split('(')[0]));

        let exclusions = specialAbilities.map(x => x.name);
        
        specialAttacks = specialAttacks.filter(x => !exclusions.includes(x.name));
        exclusions.push(...specialAttacks.map(x => x.name));
        defensiveAbilities = defensiveAbilities.filter(x => !exclusions.includes(x.name));
        exclusions.push(...defensiveAbilities.map(x => x.name));
        specialQualities = specialQualities.filter(x => !exclusions.includes(x));

        if (specialAttacks.length > 0 || rockThrowingLine || rendLine) {
            md.h2('Special Attacks');

            if (rockThrowingLine) md.li(rockThrowingLine);
            if (rendLine) md.li(rendLine);
            for (let ability of specialAttacks) {
                let snippet = snippetToString(ability);
                md.li(snippet);
            }
        }

        if (defensiveAbilities.length > 0) {
            md.h2('Defensive Abilities');
            for (let ability of defensiveAbilities) {
                let snippet = snippetToString(ability);
                md.li(snippet);
            }
        }

        if (specialQualities.length > 0) {
            md.h2('Special Qualities');
            for (let ability of specialQualities) {
                md.li(ability);
            }
        }

        if (specialAbilities.length > 0) {
            BestiaryDisplays.addSpecialAbilities(md, specialAbilities);
        }

        // Weaknesses
        if (grant.includeAttackVulnerabilities || grant.includeEnergyVulnerabilities || grant.includeWeaknesses) {
            let weaknesses = (monster.weaknesses ?? []).filter(weakness => {
                if (grant.includeWeaknesses) return true;
                else if (!['vulnerable to', 'vulnerability'].some(x => weakness.includes(x))) return false; // This weakness is not a vulnerability
                else if (grant.includeEnergyVulnerabilities && elements.some(x => weakness.includes(x))) return true; // This weakness is an energy vulnerability 
                else if (grant.includeAttackVulnerabilities && !elements.some(x => weakness.includes(x))) return true; // This weakness is an attack vulnerability 
                else return false;
            });
            for (let weakness of weaknesses) {
                md.li(weakness);
            }
        }

        // Grants that the form ALWAYS grants
        if (grant.always) {
            for (let line of grant.always) {
                md.li(line);
            }
        }

        return md.build();
    }

    static minManeuver(m1: string, m2: string) {
        const maneuvers = ['clumsy', 'poor', 'average', 'good', 'perfect'];
        let i1 = maneuvers.indexOf(m1);
        let i2 = maneuvers.indexOf(m2);

        let index = -1;
        if (i1 < 0) index = i2;
        else if (i2 < 0) index = i1;
        else index = Math.min(i1, i2);

        return (index < 0) ? '' : `(${maneuvers[index]})`;
    }
}

function getNaturalAttacks(monster: BestiaryEntry, grant: SpellTransform, includeExplicitTouch: boolean) {
    let attacksMap: {[key: string]: {qty: number, damage: string, isTouch: boolean}} = {};

    let list = monster.meleeAttacks ? monster.meleeAttacks.flat() : [];
    let specialAbilitiyGrants = grant.specialAbilities ?? [];
    for (let entry of list) {
        let split = entry.split('(');
        let atkString = split[0].trim();
        let dmgString = split.length > 1 ? `(${split[1]}` : '';
        dmgString = dmgString.replace(/\+[0-9]+/, ''); // Remove damage modifier
        if (dmgString.includes(' plus ')) {
            let split2 = dmgString.split(' plus ');
            let pre = SanityCheck.notNull(split2.shift());
            let post = split2.join(', ');
            if (post.length > 0) {
                post = post.slice(0, post.length - 1); // Remove trailing parenthesis
                SanityCheck.warnIf(split2.length != 1, 'Unexpected: multiple "plus" entries in damage string', {dmgString});
                
                let extras = Utils.splitMultiple(post, [',', ' and ', ' or ']).map(x => x.trim());
                let matches = extras.filter(x => specialAbilitiyGrants.includes(x));

                let missing = specialAbilitiyGrants.filter(x => x != 'dr' && !matches.includes(x) && post.includes(x));
                SanityCheck.warnIf(missing.length > 0, 'Possible data error: grant may be missing abilities from attack.', {monster, grant, dmgString, extras, matches, missing});

                if (matches.length == 0) {
                    dmgString = `${pre})`
                }
                else if (matches.length == 1) {
                    dmgString = `${pre} plus ${matches[0]})`
                }
                else if (matches.length == 2) {
                    dmgString = `${pre} plus ${matches.join(' and ')})`
                }
                else if (matches.length > 1) {
                    matches[matches.length - 1] = 'and ' + matches[matches.length - 1];
                    dmgString = `${pre} plus ${matches.join(', ')})`
                }
            }
        }

        split = atkString.split(' ');
        let isTouch = false;
        if (/[0-9]+(\/[0-9]+)* touch$/.test(atkString)) {
            isTouch = true;
            split.pop();
            atkString = split.join(' ');
        }
        let hasToHit = /[0-9]+(\/[0-9]+)*$/.test(atkString);
        let hasQty = /^[0-9]+ /.test(atkString);

        let qtyString = hasQty ? SanityCheck.notNull(split.shift()) : '1';
        let toHit = hasToHit ? split.pop() : '';

        if (/^\+[0-9]+(\/[0-9]+)*$/.test(split[0])) {
            if (!hasToHit) toHit = split.shift();
            else split.shift();
        }

        let name = split.join(' ').toLowerCase();

        let isNot = ['wand', 'rod', 'whip', 'sword', 'staff', 'black flame', 'trident', 'brilliant energy', 'katana', 'kama', 'rapier', 'lance', 'mwk', 'ribbon'].some(x => name.startsWith(x));
        isNot = isNot || ['-bane'].some(x => name.includes(x));
        isNot = isNot || ['voidsedge', 'wrappings', 'winter\'s heart', 'wrecking ball', 'usher of the black rain', 'the eclipsing eye', 'surcease of sorrows', 'baba yaga\'s besom', 'ahriman\'s scourge', 'nine lives stealer', 'lamentation of the faithless', 'govel of abadar', 'riftcarver', 'ramithaine', 'entropic drain'].includes(name);
        isNot = isNot || ['shield', 'cane', 'urgrosh', 'greatsword', 'greataxe', 'trident', 'swarm', 'troop', 'mace', 'rapier', 'longsword', 'dagger', 'falchion', 'halberd', 'morningstar', 'scimitar', 'spear', 'whip', 'rod', 'flail', 'maul', 'sword', 'staff', 'saw', 'warhammer', 'waraxe', 'guisarme', 'ranseur', 'scythe', 'aklys', 'hook', 'aizerghaul', 'blade', 'drill', 'spike', 'blackaxe', 'impaler', 'chain', 'razor', 'battleaxe', 'hammer', 'terbutje', 'syringe', 'gauntlet', 'bayonet', 'pick', 'katana', 'handaxe', 'tetsubo', 'glaive', 'cicatrix', 'kukri', 'sickle', 'dogslicer', 'khopesh', 'throwing axe', 'sai', 'sap', 'sabre', 'siangham', 'naginata', 'needle', 'nunchaku', 'double axe', 'pliers', 'chisel', 'greatclub', 'fire poker', 'fishing pole', 'flindbar', 'flurry of blows', 'fork', 'frying pan', 'greatclub', 'club', 'lance', 'starknife', 'horsechopper', 'weapon', 'torch', 'injectors', 'kama', 'kusarigama', 'javelin', 'urumi', 'variable', 'thorn bracer', 'spiked armor', 'eyjatas', 'scepter', 'maul of the titans'].some(x => name.endsWith(x) || name.endsWith(x + 's'));
        if (isNot) continue;
        if (!includeExplicitTouch && (name.endsWith('touch') || name.endsWith('touches'))) continue;

        if (name.endsWith('s') && !name.endsWith('ss')) name = name.slice(0, name.length-1);
        if (!(name in attacksMap)) attacksMap[name] = { qty: 0, damage: dmgString, isTouch: isTouch };
        let mapEntry = attacksMap[name];

        SanityCheck.warnIf(mapEntry.damage != dmgString, 'Multiple attacks found with the same name but different damage', monster);
        SanityCheck.warnIf(mapEntry.isTouch != isTouch, 'Multiple attacks found with the same name but different isTouch values', monster);

        let qty = parseInt(qtyString);
        if (isNaN(qty)) {
            console.warn('Attack quantity is NaN', {monster, entry});
            qty = 1;
        }
        mapEntry.qty = Math.max(qty, mapEntry.qty);
    }

    let strings = [];
    for (let [name, value] of Object.entries(attacksMap)) {
        if (value.qty == 0) continue;

        if (value.isTouch) {
            if (value.damage.length == 0) value.damage = '(touch)';
            else value.damage = `(touch; ${value.damage.slice(1)}`;
        }

        if (value.qty == 1) {
            strings.push(`${name} ${value.damage}`);
        }
        else {
            strings.push(`${value.qty} ${name}s ${value.damage}`);
        }
    }

    return strings;
}

function getDamage(size: string) {
    if (size == 'diminutive') return '(d2)';
    else if (size == 'tiny') return '(d3)';
    else if (size == 'small') return '(d4)';
    else if (size == 'medium') return '(d6)';
    else if (size == 'large') return '(d8)';
    else if (size == 'huge') return '(2d6)';
    else {
        console.warn('Unexpected size for damage calculation: ' + size);
        return '';
    }
}

function atk(name: string, size: string) {
    let dmg = getDamage(size);
    return `${name} ${dmg}`.trim();
}

const Bonuses = {
    verminShape: {
        small: ['+2 size bonus to Dexterity', '+2 natural armor bonus'],
        medium: ['+2 size bonus to Strength', '+3 natural armor bonus'],
        large: ['+4 size bonus to Strength', '-2 penalty to Dexterity', '+5 natural armor bonus'],
        tiny: ['+4 size bonus to Dexterity', '-2 penalty to Strength', '+1 natural armor bonus'],
    },
    alterSelf: {
        small: ['+2 size bonus to Dexterity'],
        medium: ['+2 size bonus to Strength'],
    },
    feyForm: {
        small: ['+2 size bonus to Dexterity', '+2 size bonus to Constitution'],
        medium: ['+2 size bonus to Strength', '+2 size bonus to Constitution'],
        tiny: ['+6 size bonus to Dexterity', '-2 penalty to Strength'],
        large: ['+4 size bonus to Strength', '+4 size bonus to Constitution', '-2 penalty to Dexterity'],
        diminutive: ['+8 size bonus to Dexterity', '-4 penalty to Strength'],
        huge: ['+6 size bonus to Strength', '+6 size bonus to Constitution', '-4 penalty to Dexterity'],
    },
    undeadAnatomy: {
        small: ['+2 size bonus to Dexterity', '+1 natural armor bonus'],
        medium: ['+2 size bonus to Strength', '+2 natural armor bonus'],
        tiny: ['+4 size bonus to Dexterity', '-2 penalty to Strength', '+1 natural armor bonus'],
        large: ['+4 size bonus to Strength', '-2 penalty to Dexterity', '+4 natural armor bonus'],
        diminutive: ['+6 size bonus to Dexterity', '-4 penalty to Strength', '+1 natural armor bonus'],
        huge: ['+6 size bonus to Strength', '-4 penalty to Dexterity', '+6 natural armor bonus'],
    },
    undeadAnatomy4: {
        tiny: ['+8 size bonus to Dexterity', '-2 penalty to Strength', '+3 natural armor bonus'],
        large: ['+6 size bonus to Strength', '-2 penalty to Dexterity', '+2 size bonus to Constitution', '+6 natural armor bonus'],
    },
    giantForm: {
        large: ['+6 size bonus to Strength', '+4 size bonus to Constitution', '-2 penalty to Dexterity', '+4 natural armor bonus', 'low-light vision'],
        huge: ['+8 size bonus to Strength', '+6 size bonus to Constitution', '-2 penalty to Dexterity', '+6 natural armor bonus', 'low-light vision'],
    },
    plantShape: {
        small: ['+2 size bonus to Constitution', '+2 natural armor bonus'],
        medium: ['+2 size bonus to Strength', '+2 enhancement bonus to Constitution', '+2 natural armor bonus'],
        large: ['+4 size bonus to Strength', '+2 size bonus to Constitution', '+4 natural armor bonus'],
        huge: ['+8 size bonus to Strength', '+4 size bonus to Constitution', '-2 penalty to Dexterity', '+6 natural armor bonus'],
    },
    monstrousPhysique: {
        small: ['+2 size bonus to Dexterity', '+1 natural armor bonus'],
        medium: ['+2 size bonus to Strength', '+2 natural armor bonus'],
        large: ['+4 size bonus to Strength', '-2 penalty to Dexterity', '+4 natural armor bonus'],
        huge: ['+6 size bonus to Strength', '-4 penalty to Dexterity', '+6 natural armor bonus'],
        tiny: ['+4 size bonus to Dexterity', '-2 penalty to Strength', '+1 natural armor bonus'],
        diminutive: ['+6 size bonus to Dexterity', '-4 penalty to Strength', '+1 natural armor bonus'],
    },
    animalBeastShape: {
        small: ['+2 size bonus to Dexterity', '+1 natural armor bonus'],
        medium: ['+2 size bonus to Strength', '+2 natural armor bonus'],
        large: ['+4 size bonus to Strength', '-2 penalty to Dexterity', '+4 natural armor bonus'],
        huge: ['+6 size bonus to Strength', '-4 penalty to Dexterity', '+6 natural armor bonus'],
        tiny: ['+4 size bonus to Dexterity', '-2 penalty to Strength', '+1 natural armor bonus'],
        diminutive: ['+6 size bonus to Dexterity', '-4 penalty to Strength', '+1 natural armor bonus'],
    },
    magicalBeastShape: {
        small: ['+4 size bonus to Dexterity', '+2 natural armor bonus'],
        medium: ['+4 size bonus to Strength', '+4 natural armor bonus'],
        tiny: ['+8 size bonus to Dexterity', '-2 penalty to Strength', '+3 natural armor bonus'],
        large: ['+6 size bonus to Strength', '+2 size bonus to Constitution', '-2 penalty to Dexterity', '+6 natural armor bonus'],
        diminutive: ['+10 size bonus to Dexterity', '-4 penalty to Strength'],
        huge: ['+8 size bonus to Strength', '+2 size bonus to Constitution', '-4 penalty to Dexterity', '+7 natural armor bonus'],
    },
    elementalFormAir: {
        small: ['+2 size bonus to Dexterity', '+2 natural armor bonus', 'fly 60 ft. (perfect)', 'darkvision 60 ft.'],
        medium: ['+4 size bonus to Dexterity', '+3 natural armor bonus', 'fly 60 ft. (perfect)', 'darkvision 60 ft.'],
        large: ['+4 size bonus to Dexterity', '+2 size bonus to Strength', '+4 natural armor bonus', 'fly 60 ft. (perfect)', 'darkvision 60 ft.', 'immune to bleed damage, critical hits, and sneak attacks'],
        huge: ['+6 size bonus to Dexterity', '+4 size bonus to Strength', '+4 natural armor bonus', 'fly 120 ft. (perfect)', 'darkvision 60 ft.', 'immune to bleed damage, critical hits, and sneak attacks', 'DR 5/-'],
    },
    elementalFormFire: {
        small: ['+2 size bonus to Dexterity', '+2 natural armor bonus', 'darkvision 60 ft.', 'resist fire 20', 'vulnerability to cold'],
        medium: ['+4 size bonus to Dexterity', '+3 natural armor bonus', 'darkvision 60 ft.', 'resist fire 20', 'vulnerability to cold'],
        large: ['+4 size bonus to Dexterity', '+2 size bonus to Constitution', '+4 natural armor bonus', 'darkvision 60 ft.', 'resist fire 20', 'vulnerability to cold', 'immune to bleed damage, critical hits, and sneak attacks'],
        huge: ['+6 size bonus to Dexterity', '+4 size bonus to Constitution', '+4 natural armor bonus', 'darkvision 60 ft.', 'resist fire 20', 'vulnerability to cold', 'immune to bleed damage, critical hits, and sneak attacks', 'DR 5/-'],
    },
    elementalFormEarth: {
        small: ['+2 size bonus to Strength', '+4 natural armor bonus', 'darkvision 60 ft.'],
        medium: ['+4 size bonus to Strength', '+5 natural armor bonus', 'darkvision 60 ft.'],
        large: ['+6 size bonus to Strength', '+2 size bonus to Constitution', '-2 penalty to Dexterity', '+6 natural armor bonus', 'darkvision 60 ft.', 'immune to bleed damage, critical hits, and sneak attacks'],
        huge: ['+8 size bonus to Strength', '+4 size bonus to Constitution', '-2 penalty to Dexterity', '+6 natural armor bonus', 'darkvision 60 ft.', 'immune to bleed damage, critical hits, and sneak attacks'],
    },
    elementalFormWater: {
        small: ['+2 size bonus to Constitution', '+4 natural armor bonus', 'swim 60 ft.', 'darkvision 60 ft.', 'you gain the ability to breathe water'],
        medium: ['+4 size bonus to Constitution', '+5 natural armor bonus', 'darkvision 60 ft.', 'swim 60 ft.', 'you gain the ability to breathe water'],
        large: ['+6 size bonus to Constitution', '+2 size bonus to Strength', '-2 penalty to Dexterity', '+6 natural armor bonus', 'darkvision 60 ft.', 'swim 60 ft.', 'you gain the ability to breathe water', 'immune to bleed damage, critical hits, and sneak attacks'],
        huge: ['+8 size bonus to Constitution', '+4 size bonus to Strength', '-2 penalty to Dexterity', '+6 natural armor bonus', 'darkvision 60 ft.', 'swim 120 ft.', 'you gain the ability to breathe water', 'immune to bleed damage, critical hits, and sneak attacks'],
    },
    dragonForm: {
        medium: ['+4 size bonus to Strength', '+2 size bonus to Constitution', '+4 natural armor bonus', 'fly 60 ft. (poor)', 'darkvision 60 ft.', 'bite (1d8)', 'two claws (1d6)', 'two wing attacks (1d4)', 'You can only use the breath weapon once per casting of this spell.', 'All breath weapons deal 6d8 points of damage and allow a Reflex save for half damage.', 'Cones are 30ft; lines are 60ft.'],
        large: ['+6 size bonus to Strength', '+4 size bonus to Constitution', '+6 natural armor bonus', 'fly 90 ft. (poor)', 'darkvision 60 ft.', 'DR 5/magic', 'bite (2d6)', 'two claws (d8)', 'two wing attacks (d6)', 'tail slap (d8)', 'You can only use the breath weapon twice per casting of this spell.', 'You must wait 1d4 rounds between uses of your breath weapon.', 'All breath weapons deal 8d8 points of damage and allow a Reflex save for half damage.', 'Cones are 40ft; lines are 80ft.'],
        huge: ['+10 size bonus to Strength', '+8 size bonus to Constitution', '+8 natural armor bonus', 'fly 120 ft. (poor)', 'blindsense 60 ft.', 'darkvision 120 ft.', 'DR 10/magic', 'frightful presence (DC equal to the spell DC)', 'bite (2d8)', 'two claws (2d6)', 'two wing attacks (d8)', 'tail slap (2d6)', 'You can use the breath weapon as often as you like.', 'You must wait 1d4 rounds between uses of your breath weapon.', 'All breath weapons deal 12d8 points of damage and allow a Reflex save for half damage.', 'Cones are 50ft; lines are 100ft.'],

        black: ['breath weapon: line of acid', 'resist acid 20', 'swim 60 ft.'],
        blue: ['breath weapon: line of electricity', 'resist electricity 20', 'burrow 20 ft.'],
        green: ['breath weapon: cone of acid', 'resist acid 20', 'swim 40 ft.'],
        red: ['breath weapon: cone of fire', 'resist fire 30', 'vulnerability to cold'],
        white: ['breath weapon: cone of cold', 'resist cold 20', 'swim 60 ft.', 'vulnerability to fire'],
        brass: ['breath weapon: line of fire', 'resist fire 20', 'burrow 30 ft.', 'vulnerability to cold'],
        bronze: ['breath weapon: line of electricity', 'resist electricity 20', 'swim 60 ft.'],
        copper: ['breath weapon: line of acid', 'resist acid 20', 'spider climb (always active)'],
        gold: ['breath weapon: cone of fire', 'resist fire 20', 'swim 60 ft.'],
        silver: ['breath weapon: cone of cold', 'resist cold 30', 'vulnerability to fire'],
    }
}

function getElementalStatFunction(element: string) {
    if (element == 'air') return PolyFunctions.stats(Bonuses.elementalFormAir);
    else if (element == 'fire') return PolyFunctions.stats(Bonuses.elementalFormFire);
    else if (element == 'earth') return PolyFunctions.stats(Bonuses.elementalFormEarth);
    else if (element == 'water') return PolyFunctions.stats(Bonuses.elementalFormWater);
    else {
        console.warn('Unexpected element for Elemental Form: ' + element);
        return (monster: BestiaryEntry) => ['ERROR: Unexpected element: ' + element];
    }
}

class PolyFunctions {
    static validator(...specs: {sizes: string[], types: string[]}[]) {
        return (monster: BestiaryEntry) => {
            return specs.some(spec => {
                let monsterTypes = BestiaryFields.stripedTypes(monster).map(x => x.toLowerCase());
                let monsterSize = monster.size.toLowerCase();
    
                if (spec.sizes.length > 0 && !spec.sizes.includes(monsterSize)) return false;
                else if (spec.types.length > 0 && !spec.types.some(x => monsterTypes.includes(x))) return false;
                else return true;
            });
        };
    }
    
    static elementalValidator({sizes, elements}: {sizes: string[], elements: string[]}) {
        return (monster: BestiaryEntry) => {
            let monsterSize = monster.size.toLowerCase();
            let monsterName = monster.name.toLowerCase();

            let compounds = elements.map(x => `${x} elemental`);
            let names = sizes.flatMap(size => compounds.map(ext => `${size} ${ext}`));

            return (sizes.includes(monsterSize) && names.includes(monsterName));
        };
    }
    
    static dragonValidator({sizes, types}: {sizes: string[], types: string[]}) {
        return (monster: BestiaryEntry) => {
            let monsterSize = monster.size.toLowerCase();
            let monsterTypes = BestiaryFields.stripedTypes(monster).map(x => x.toLowerCase());

            if (!sizes.includes(monsterSize)) return false;
            else if (!monsterTypes.includes('dragon')) return false;
            else {
                let groups = (monster.groups ?? []).map(x => x.toLowerCase()).filter(x => x.startsWith('dragon')).map(x => Utils.trim(x.replace(/dragon/, ''), '() '));
                let type = groups.shift();
    
                if (!type) return false;
                else return types.includes(type);
            }
        };
    }

    static stats(map: {[key: string]: string[]}) {
        return (monster: BestiaryEntry, spellName: string) => {
            let size = monster.size.toLowerCase();
            if (size in map) return map[size];
            else {
                console.warn(`Unexpected size for ${spellName}: ${size}`);
                return ['Unexpected size detected. Verify that this is a valid target for this spell.'];
            }
        };
    }

    static elementalFormStats() {
        return (monster: BestiaryEntry, spellName: string) => {
            let element = monster.name.toLowerCase().trim().split(' ')[1];
            let func = getElementalStatFunction(element);
            return func(monster, spellName);
        };
    }

    static dragonFormStats() {
        let map: {[key: string]: string[]} = Bonuses.dragonForm;
        return (monster: BestiaryEntry, spellName: string) => {
            let size = monster.size.toLowerCase();
            let groups = (monster.groups ?? []).map(x => x.toLowerCase()).filter(x => x.startsWith('dragon')).map(x => Utils.trim(x.replace(/dragon/, ''), '() '));
            let type = groups.shift();

            if (!type) {
                console.warn(`Type group not found for ${spellName} / ${monster.name}`);
                return ['Type group not found. Verify that this is a valid target for this spell.'];
            }
            else if (!(type in map)) {
                console.warn(`Unrecognized type group for ${spellName} / ${monster.name}: ${type}`);
                return ['Unrecognized type group. Verify that this is a valid target for this spell.'];
            }
            else if (!(size in map)) {
                console.warn(`Unexpected size for ${spellName}: ${size}`);
                return ['Unexpected size detected. Verify that this is a valid target for this spell.'];
            }
            else {
                let typeGrants = map[type];
                let sizeGrants = map[size];

                let grants = [...sizeGrants, ...typeGrants];
                if (spellName == 'Form of the Dragon III') return grants.map(x => x.startsWith('resist') ? `${x.split(' ')[1]} immunity` : x);
                else return grants;
            }
        };
    }

    static beastShapeStats() {
        return (monster: BestiaryEntry, spellName: string) => {
            let type = monster.type.split('(')[0].trim().toLowerCase();
            let isMagicalBeast = type == 'magical beast';
            let isAnimal = type == 'animal';
            if (!(isMagicalBeast || isAnimal)) {
                console.warn(`Unexpected type for ${spellName}: ${type}`);
                return ['Unexpected type detected. Verify that this is a valid target for this spell.'];
            }

            let map: {[key: string]: string[]} = isAnimal ? Bonuses.animalBeastShape : Bonuses.magicalBeastShape;
            let size = monster.size.toLowerCase();
            if (size in map) return map[size];
            else {
                console.warn(`Unexpected size for ${spellName}: ${size}`);
                return ['Unexpected size detected. Verify that this is a valid target for this spell.'];
            }
        };
    }

    static undeadAnatomyStats(use4: boolean) {
        let map: {[key: string]: string[]} = Bonuses.undeadAnatomy;
        let map4: {[key: string]: string[]} = Bonuses.undeadAnatomy4;
        return (monster: BestiaryEntry, spellName: string) => {
            let size = monster.size.toLowerCase();
            let boons = ['darkvision 60', atk('bite', size), atk('2 claws or slams', size)];
            if (use4) {
                // TODO: if the form is incorporeal, update the boons to use the correct attacks
            }

            if (use4 && (size in map4)) return [...map4[size], ...boons];
            else if (size in map) return [...map[size], ...boons];
            else {
                console.warn(`Unexpected size for ${spellName}: ${size}`);
                return ['Unexpected size detected. Verify that this is a valid target for this spell.'];
            }
        };
    }

    static isDinoOrMegafauna(monster: BestiaryEntry): boolean {
        let groups = monster.groups ?? [];
        return groups.includes('Dinosaur') || groups.includes('Megafauna');
    }
}

type AggregateMap = {[key: string]: AggregateTransform};
class WildShape {
    static plusMinus2({archetypeName, levelAdjustment, start, end, step}: {archetypeName: string, levelAdjustment: (monster: BestiaryEntry) => number, start?: number, end?: number, step?: number}): AggregateMap {
        let lv4 = ['Beast Shape I'];
        let lv6 = ['Beast Shape II', 'Elemental Body I'];
        let lv8 = ['Beast Shape III', 'Elemental Body II', 'Plant Shape I'];
        let lv10 = ['Beast Shape III', 'Elemental Body III', 'Plant Shape II'];
        let lv12 = ['Beast Shape III', 'Elemental Body IV', 'Plant Shape III'];
        let all = Utils.unique([...lv4, ...lv6, ...lv8, ...lv10, ...lv12]);

        let getList = (level: number) => {
            if (level < 4) return [];
            else if (level < 6) return lv4;
            else if (level < 8) return lv6;
            else if (level < 10) return lv8;
            else if (level < 12) return lv10;
            else return lv12;
        };
        let createValidator = (baseLevel: number, spellName: string) => {
            return (monster: BestiaryEntry) => {
                let level = baseLevel + levelAdjustment(monster);
                let list = getList(level);
                if (!list.includes(spellName)) return false;
                else {
                    let transform = polymorphTransforms[spellName];
                    if (transform) return transform.validator(monster);
                    else {
                        console.warn('Aggregate polymorph spell not found: ' + spellName);
                        return false;
                    }
                }
            };
        };

        let map = {} as AggregateMap;
        for (let i = (start ?? 6); i <= (end ?? 14); i += (step ?? 2)) {
            let name = `Wild Shape (${archetypeName}) lv${i}`;
            let mods = {} as any;
            for (let x of all) {
                let spellName = x;
                let baseLevel = i;
                mods[spellName] = {
                    overrides: {
                        validator: createValidator(baseLevel, spellName)
                    }
                };
            }
            let entry: AggregateTransform = {
                spells: all,
                mods: mods,
            };

            map[name] = entry;
        }
        return map;
    }
}

export interface SpellTransform {
    speeds?: string[];
    maxBaseSpeed?: number;
    baseSpeedEnhancement?: number;

    specialAbilities?: string[];
    healthAbilities?: string[];
    resistances?: string[];
    immunities?: string[];
    senses?: string[];

    always?: string[];
    includeWeaknesses?: boolean;
    includeEnergyVulnerabilities?: boolean;
    includeAttackVulnerabilities?: boolean;

    subtypeTransforms?: {[key: string]: string};
    immunityTransforms?: {[key: string]: string};
    resistanceTransforms?: {[key: string]: string};
    
    statBonuses: (monster: BestiaryEntry, spellName: string) => string[];
    validator: (monster: BestiaryEntry) => boolean;
    allowSwarms?: boolean; // TODO: This should be part of each validator
}

export type TransformOverride = Omit<SpellTransform, 'statBonuses' | 'validator'> & {
    statBonuses?: (monster: BestiaryEntry, spellName: string) => string[];
    validator?: (monster: BestiaryEntry) => boolean;
};

export interface AggregateTransform {
    spells?: string[];
    mods?: {[key: string]: {
        exclusions?: TransformOverride,
        inclusions?: TransformOverride,
        overrides?: TransformOverride,
    }};
}

// TODO: Support shifter wild shape & shifter archetypes
// TODO: Support shifter favored class bonus (Ghoran)
export const aggregatePolymorphTransforms: AggregateMap = {
    "Polymorph": {
        spells: ['Alter Self', 'Beast Shape II', 'Elemental Body I'],
    },
    "Polymorph, Greater": {
        spells: ['Alter Self', 'Beast Shape IV', 'Elemental Body III', 'Plant Shape II', 'Form of the Dragon I'],
    },

    "Wild Shape lv4": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape lv6": {
        spells: ['Beast Shape II', 'Elemental Body I'],
    },
    "Wild Shape lv8": {
        spells: ['Beast Shape III', 'Elemental Body II', 'Plant Shape I'],
    },
    "Wild Shape lv10": {
        spells: ['Beast Shape III', 'Elemental Body III', 'Plant Shape II'],
    },
    "Wild Shape lv12": {
        spells: ['Beast Shape III', 'Elemental Body IV', 'Plant Shape III'],
    },
    
    "Wild Shape (Avenging Beast)": {
        spells: ['Beast Shape I'],
    },
    
    "Improved Shifting Companion (Chameleon Adept)": {
        spells: ['Beast Shape I'],
    },

    "Fey Shape (Progenitor) lv4": {
        spells: ['Beast Shape I'],
    },
    "Fey Shape (Progenitor) lv6": {
        spells: ['Beast Shape II', 'Fey Form I'],
    },
    "Fey Shape (Progenitor) lv8": {
        spells: ['Beast Shape III', 'Fey Form II', 'Plant Shape I'],
    },
    "Fey Shape (Progenitor) lv10": {
        spells: ['Beast Shape III', 'Fey Form III', 'Plant Shape II'],
    },
    "Fey Shape (Progenitor) lv12": {
        spells: ['Beast Shape III', 'Fey Form III', 'Plant Shape III'],
    },
    "Fey Shape (Progenitor) lv14": {
        spells: ['Beast Shape III', 'Fey Form IV', 'Plant Shape III'],
    },

    "Flower's Form (Grasping Vine) lv8": {
        spells: ['Plant Shape I'],
    },
    "Flower's Form (Grasping Vine) lv12": {
        spells: ['Plant Shape II'],
    },
    "Flower's Form (Grasping Vine) lv16": {
        spells: ['Plant Shape III'],
    },

    "Wild Shape (Tempest Tamer) lv4": {
        spells: ['Elemental Body I'],
        mods: {
            'Elemental Body I': {
                overrides: {
                    validator: PolyFunctions.elementalValidator({
                        sizes: ['small'],
                        elements: ['air', 'water']
                    }),
                },
            },
        },
    },
    "Wild Shape (Tempest Tamer) lv6": {
        spells: ['Elemental Body I'],
        mods: {
            'Elemental Body I': {
                overrides: {
                    validator: PolyFunctions.elementalValidator({
                        sizes: ['small'],
                        elements: ['air', 'water']
                    }),
                },
            },
        },
    },
    "Wild Shape (Tempest Tamer) lv8": {
        spells: ['Elemental Body II'],
        mods: {
            'Elemental Body II': {
                overrides: {
                    validator: PolyFunctions.elementalValidator({
                        sizes: ['small', 'medium'],
                        elements: ['air', 'water']
                    }),
                },
            },
        },
    },
    "Wild Shape (Tempest Tamer) lv10": {
        spells: ['Elemental Body III'],
        mods: {
            'Elemental Body III': {
                overrides: {
                    validator: PolyFunctions.elementalValidator({
                        sizes: ['small', 'medium', 'large'],
                        elements: ['air', 'water']
                    }),
                },
            },
        },
    },
    "Wild Shape (Tempest Tamer) lv12": {
        spells: ['Elemental Body IV'],
        mods: {
            'Elemental Body IV': {
                overrides: {
                    validator: PolyFunctions.elementalValidator({
                        sizes: ['small', 'medium', 'large', 'huge'],
                        elements: ['air', 'water']
                    }),
                },
            },
        },
    },

    "Wild Shape (Goliath Druid) lv4": {
        spells: ['Beast Shape I'],
        mods: {
            'Beast Shape I': {
                overrides: {
                    validator: (monster: BestiaryEntry) => {
                        let baseValidator = polymorphTransforms['Beast Shape I'].validator
                        return PolyFunctions.isDinoOrMegafauna(monster) && baseValidator(monster);
                    }
                }
            },
        },
    },
    "Wild Shape (Goliath Druid) lv6": {
        spells: ['Beast Shape II', 'Alter Self'],
        mods: {
            'Beast Shape II': {
                overrides: {
                    validator: (monster: BestiaryEntry) => {
                        let baseValidator = polymorphTransforms['Beast Shape II'].validator
                        return PolyFunctions.isDinoOrMegafauna(monster) && baseValidator(monster);
                    }
                }
            },
            // At 6th level, the goliath druid can use wild shape to become a Large humanoid of the giant subtype. This functions as the alter self spell, except the goliath druid gains a +4 size bonus to Strength, a –2 penalty to Dexterity, and a +1 natural armor bonus. If the Large humanoid form she takes has rock throwing, she gains rock throwing (range 40 feet, 1d8 damage). If the form has the aquatic subtype, she gains the aquatic and amphibious subtypes.
            'Alter Self': {
                overrides: {
                    validator: (monster: BestiaryEntry) => {
                        let baseValidator = PolyFunctions.validator({sizes: ['large'], types: ['humanoid']});
                        let subtypes = BestiaryFields.stripedSubtypes(monster).map(x => x.toLowerCase());
                        return subtypes.includes('giant') && baseValidator(monster);
                    },
                    statBonuses: () => ['+4 size bonus to Strength', '-2 penalty to Dexterity', '+1 natural armor bonus'],
                },
                inclusions: {
                    specialAbilities: ['rock throwing (40, d8)'],
                    subtypeTransforms: {
                        'aquatic': 'you gain the aquatic and amphibious subtypes'
                    },
                }
            },
        },
    },
    "Wild Shape (Goliath Druid) lv8": {
        spells: ['Beast Shape III', 'Alter Self'],
        mods: {
            'Beast Shape III': {
                overrides: {
                    validator: (monster: BestiaryEntry) => {
                        let baseValidator = polymorphTransforms['Beast Shape III'].validator
                        return PolyFunctions.isDinoOrMegafauna(monster) && baseValidator(monster);
                    }
                }
            },
            'Alter Self': {
                overrides: {
                    validator: (monster: BestiaryEntry) => {
                        let baseValidator = PolyFunctions.validator({sizes: ['large'], types: ['humanoid']});
                        let subtypes = BestiaryFields.stripedSubtypes(monster).map(x => x.toLowerCase());
                        return subtypes.includes('giant') && baseValidator(monster);
                    },
                    statBonuses: () => ['+4 size bonus to Strength', '-2 penalty to Dexterity', '+1 natural armor bonus'],
                },
                inclusions: {
                    specialAbilities: ['rock throwing (40, d8)'],
                    subtypeTransforms: {
                        'aquatic': 'you gain the aquatic and amphibious subtypes'
                    },
                }
            },
        },
    },
    "Wild Shape (Goliath Druid) lv12": {
        spells: ['Beast Shape III', 'Giant Form I'],
        mods: {
            'Beast Shape III': {
                overrides: {
                    validator: (monster: BestiaryEntry) => {
                        let baseValidator = polymorphTransforms['Beast Shape III'].validator
                        return PolyFunctions.isDinoOrMegafauna(monster) && baseValidator(monster);
                    }
                }
            },
        },
    },
    "Wild Shape (Goliath Druid) lv14": {
        spells: ['Beast Shape III', 'Giant Form II'],
        mods: {
            'Beast Shape III': {
                overrides: {
                    validator: (monster: BestiaryEntry) => {
                        let baseValidator = polymorphTransforms['Beast Shape III'].validator
                        return PolyFunctions.isDinoOrMegafauna(monster) && baseValidator(monster);
                    }
                }
            },
        },
    },
    
    "Wild Shape (Treesinger) lv4": {
        spells: ['Plant Shape I'],
        mods: {
            "Plant Shape I": {
                exclusions: {
                    specialAbilities: ['constrict', 'poison'],
                }
            }
        },
    },
    "Wild Shape (Treesinger) lv8": {
        spells: ['Plant Shape I'],
    },
    "Wild Shape (Treesinger) lv10": {
        spells: ['Plant Shape II'],
    },
    "Wild Shape (Treesinger) lv12": {
        spells: ['Plant Shape III'],
    },
    
    "Wild Shape (Leshy Warden) lv6": {
        spells: ['Plant Shape I'],
    },
    "Wild Shape (Leshy Warden) lv8": {
        spells: ['Plant Shape II'],
    },
    "Wild Shape (Leshy Warden) lv10": {
        spells: ['Plant Shape III'],
    },
    
    "Wild Shape (Feyspeaker) lv6": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape (Feyspeaker) lv8": {
        spells: ['Beast Shape II'],
    },
    "Wild Shape (Feyspeaker) lv10": {
        spells: ['Beast Shape III', 'Plant Shape I'],
    },
    "Wild Shape (Feyspeaker) lv12": {
        spells: ['Beast Shape III', 'Plant Shape II'],
    },
    "Wild Shape (Feyspeaker) lv14": {
        spells: ['Beast Shape III', 'Plant Shape III'],
    },

    "Wild Shape (Mountain Druid) lv6": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape (Mountain Druid) lv8": {
        spells: ['Beast Shape II', 'Elemental Body I'],
    },
    "Wild Shape (Mountain Druid) lv10": {
        spells: ['Beast Shape III', 'Elemental Body II'],
    },
    "Wild Shape (Mountain Druid) lv12": {
        spells: ['Beast Shape III', 'Elemental Body III', 'Giant Form I'],
    },
    "Wild Shape (Mountain Druid) lv14": {
        spells: ['Beast Shape III', 'Elemental Body IV', 'Giant Form I'],
    },
    "Wild Shape (Mountain Druid) lv16": {
        spells: ['Beast Shape III', 'Elemental Body IV', 'Giant Form II'],
    },
    
    "Wild Shape (Rot Warden) lv6": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape (Rot Warden) lv8": {
        spells: ['Beast Shape II', 'Vermin Shape I'],
    },
    "Wild Shape (Rot Warden) lv10": {
        spells: ['Beast Shape III', 'Plant Shape I', 'Vermin Shape II'],
    },
    "Wild Shape (Rot Warden) lv12": {
        // TODO: At 12th level, he can take the form of a Huge vermin as if using vermin shape III; but vermin shape III doesn't exist
        spells: ['Beast Shape III', 'Plant Shape II', 'Vermin Shape II'],
    },
    "Wild Shape (Rot Warden) lv14": {
        // TODO: At 12th level, he can take the form of a Huge vermin as if using vermin shape III; but vermin shape III doesn't exist
        spells: ['Beast Shape III', 'Plant Shape III', 'Vermin Shape II'],
    },
    
    "Wild Shape (Toxicologist) lv4": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape (Toxicologist) lv6": {
        spells: ['Beast Shape II'],
    },
    "Wild Shape (Toxicologist) lv8": {
        spells: ['Beast Shape III', 'Vermin Shape I'],
    },
    "Wild Shape (Toxicologist) lv10": {
        spells: ['Beast Shape III', 'Vermin Shape II'],
    },
    "Wild Shape (Toxicologist) lv12": {
        spells: ['Beast Shape III'],
    },

    "Wild Shape (Feral Hunter) lv4": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape (Feral Hunter) lv6": {
        spells: ['Beast Shape II'],
    },
    "Wild Shape (Feral Hunter) lv8": {
        spells: ['Beast Shape III'],
    },

    "Wild Shape (Feral Champion) lv7": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape (Feral Champion) lv9": {
        spells: ['Beast Shape II'],
    },
    "Wild Shape (Feral Champion) lv11": {
        spells: ['Beast Shape III'],
    },
    
    "Wild Shape (Mantella) lv4": {
        spells: ['Beast Shape I'],
        mods: {
            'Beast Shape I': {
                inclusions: {
                    specialAbilities: ['poison']
                }
            }
        }
    },
    "Wild Shape (Mantella) lv6": {
        spells: ['Beast Shape II'],
        mods: {
            'Beast Shape II': {
                inclusions: {
                    specialAbilities: ['poison']
                }
            }
        }
    },

    "Wild Shape (Mantella) lv8": {
        spells: ['Beast Shape III', 'Plant Shape I'],
    },
    "Wild Shape (Mantella) lv10": {
        spells: ['Beast Shape III', 'Plant Shape II'],
    },
    "Wild Shape (Mantella) lv12": {
        spells: ['Beast Shape III', 'Plant Shape III'],
    },
    
    "Wild Shape (River Druid) lv6": {
        spells: ['Beast Shape I'],
    },
    "Wild Shape (River Druid) lv8": {
        spells: ['Beast Shape II', 'Elemental Body I'],
    },
    "Wild Shape (River Druid) lv10": {
        spells: ['Beast Shape III', 'Elemental Body II', 'Plant Shape I'],
    },
    "Wild Shape (River Druid) lv12": {
        spells: ['Beast Shape III', 'Elemental Body III', 'Plant Shape II'],
    },
    "Wild Shape (River Druid) lv14": {
        spells: ['Beast Shape III', 'Elemental Body IV', 'Plant Shape III'],
    },

    ...WildShape.plusMinus2({
        archetypeName: 'Dragon Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if ((monster.groups ?? []).map(x => x.toLowerCase()).includes('lizard') || monster.name.toLowerCase().includes('lizard')) return 0;
            else return -4;
        },
        start: 4,
        end: 16,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Ape Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if ((monster.groups ?? []).includes('Ape')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Bear Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if ((monster.groups ?? []).includes('Bear')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Boar Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if ((monster.groups ?? []).includes('Boar')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Bat Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if (monster.name == 'Bat' || (monster.groups ?? []).includes('Bat')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Eagle Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if (monster.name == 'Eagle' || (monster.groups ?? []).includes('Eagle')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Lion Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if (monster.name == 'Lion' || (monster.groups ?? []).includes('Lion')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Saurian Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            let targetGroups = ["Lizard", "Dinosaur", "Snake"]; // TODO: Add reptiles
            let monsterGroups = monster.groups ?? [];
            if (targetGroups.some(x => monster.name == x || monsterGroups.includes(x))) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Serpent Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if (monster.name == 'Snake' || (monster.groups ?? []).includes('Snake')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Shark Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            if (monster.name == 'Shark' || (monster.groups ?? []).includes('Shark')) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Wolf Shaman',
        levelAdjustment: (monster: BestiaryEntry) => {
            let targetGroups = ["Dog", "Wolf", "Jackal"];
            let monsterGroups = monster.groups ?? [];
            if (targetGroups.some(x => monster.name == x || monsterGroups.includes(x))) return 2;
            else return -2;
        },
        start: 6,
        end: 14,
    }),
    ...WildShape.plusMinus2({
        // TODO: At 9th level, the climb and fly speeds of forms the aerie protector assumes with her wild shape ability (if any) increase by 10 feet. The maneuverability of her flying forms improves by one category.
        archetypeName: 'Aerie Protector',
        levelAdjustment: (monster: BestiaryEntry) => {
            let isAnimal = BestiaryFields.stripedTypes(monster).map(x => x.toLowerCase()).includes('animal');
            let hasFlight = 'fly' in monster.stats.speeds;
            if (isAnimal && hasFlight) return 2;
            else return -2;
        },
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Sky Druid',
        levelAdjustment: (monster: BestiaryEntry) => {
            let hasFlight = 'fly' in monster.stats.speeds;
            if (hasFlight) return 1;
            else return 0;
        },
        step: 1,
        start: 6,
        end: 12,
    }),

    ...WildShape.plusMinus2({
        archetypeName: 'Jungle Druid',
        levelAdjustment: (monster: BestiaryEntry) => {return -2},
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Arctic Druid',
        levelAdjustment: (monster: BestiaryEntry) => {return -2},
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Plains Druid',
        levelAdjustment: (monster: BestiaryEntry) => {return -2},
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Reincarnated Druid',
        levelAdjustment: (monster: BestiaryEntry) => {return -2},
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Swamp Druid',
        levelAdjustment: (monster: BestiaryEntry) => {return -2},
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Aquatic Druid',
        levelAdjustment: (monster: BestiaryEntry) => {return -2},
    }),
    ...WildShape.plusMinus2({
        archetypeName: 'Urban Druid',
        levelAdjustment: (monster: BestiaryEntry) => {return -4},
        start: 8,
        end: 16,
    }),
};

export const elements = ['electricity', 'fire', 'cold', 'sonic', 'acid'];
export const energyRes20 = {
    'electricity': 'electricity resistance 20',
    'fire': 'fire resistance 20',
    'cold': 'cold resistance 20',
    'acid': 'acid resistance 20',
    'sonic': 'sonic resistance 20',
};
export const energyRes30 = {
    'electricity': 'electricity resistance 30',
    'fire': 'fire resistance 30',
    'cold': 'cold resistance 30',
    'acid': 'acid resistance 30',
    'sonic': 'sonic resistance 30',
};

export const polymorphTransforms: {[key: string]: SpellTransform} = {
    "Elemental Body I": {
        specialAbilities: ['whirlwind', 'burn', 'vortex', 'earth glide'],
        statBonuses: PolyFunctions.elementalFormStats(),
        validator: PolyFunctions.elementalValidator({
            sizes: ['small'],
            elements: ['air', 'earth', 'fire', 'water']
        })
    },
    "Elemental Body II": {
        specialAbilities: ['whirlwind', 'burn', 'vortex', 'earth glide'],
        statBonuses: PolyFunctions.elementalFormStats(),
        validator: PolyFunctions.elementalValidator({
            sizes: ['small', 'medium'],
            elements: ['air', 'earth', 'fire', 'water']
        })
    },
    "Elemental Body III": {
        specialAbilities: ['whirlwind', 'burn', 'vortex', 'earth glide'],
        statBonuses: PolyFunctions.elementalFormStats(),
        validator: PolyFunctions.elementalValidator({
            sizes: ['small', 'medium', 'large'],
            elements: ['air', 'earth', 'fire', 'water']
        })
    },
    "Elemental Body IV": {
        specialAbilities: ['whirlwind', 'burn', 'vortex', 'earth glide'],
        statBonuses: PolyFunctions.elementalFormStats(),
        validator: PolyFunctions.elementalValidator({
            sizes: ['small', 'medium', 'large', 'huge'],
            elements: ['air', 'earth', 'fire', 'water']
        })
    },
    "Form of the Dragon I": {
        statBonuses: PolyFunctions.dragonFormStats(),
        validator: PolyFunctions.dragonValidator({
            sizes: ['medium'],
            types: ['black', 'blue', 'green', 'red', 'white', 'brass', 'bronze', 'copper', 'gold', 'silver']
        })
    },
    "Form of the Dragon II": {
        statBonuses: PolyFunctions.dragonFormStats(),
        validator: PolyFunctions.dragonValidator({
            sizes: ['medium', 'large'],
            types: ['black', 'blue', 'green', 'red', 'white', 'brass', 'bronze', 'copper', 'gold', 'silver']
        })
    },
    "Form of the Dragon III": {
        statBonuses: PolyFunctions.dragonFormStats(),
        validator: PolyFunctions.dragonValidator({
            sizes: ['medium', 'large', 'huge'],
            types: ['black', 'blue', 'green', 'red', 'white', 'brass', 'bronze', 'copper', 'gold', 'silver']
        })
    },
    "Alter Self": {
        speeds: ['swim 30'],
        senses: ['darkvision 60', 'low-light vision', 'scent'],
        statBonuses: PolyFunctions.stats(Bonuses.alterSelf),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium'],
            types: ['humanoid'],
        }),
    },
    "Vermin Shape I": {
        speeds: ['climb 30', 'fly 30 (average)', 'swim 30'],
        senses: ['darkvision 60', 'low-light vision', 'scent'],
        specialAbilities: ['lunge'],
        immunityTransforms: {
            'mind-affecting effects': '+2 resistance bonus on all saving throws against mind-affecting effects'
        },
        statBonuses: PolyFunctions.stats(Bonuses.verminShape),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium'],
            types: ['vermin'],
        }),
    },
    "Vermin Shape II": {
        speeds: ['climb 60', 'fly 60 (good)', 'swim 60', 'burrow 30'],
        senses: ['darkvision 60', 'low-light vision', 'scent', 'tremorsense 30'],
        specialAbilities: ['lunge', 'blood drain', 'constrict', 'grab', 'poison', 'pull', 'trample', 'web'],
        immunityTransforms: {
            'mind-affecting effects': '+4 bonus on all saving throws against mind-affecting effects'
        },
        statBonuses: PolyFunctions.stats(Bonuses.verminShape),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large'],
            types: ['vermin'],
        }),
    },
    "Plant Shape I": {
        specialAbilities: ['constrict', 'grab', 'poison'],
        senses: ['darkvision 60', 'low-light vision'],
        includeEnergyVulnerabilities: true,
        statBonuses: PolyFunctions.stats(Bonuses.plantShape),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium'],
            types: ['plant'],
        }),
    },
    "Plant Shape II": {
        specialAbilities: ['constrict', 'grab', 'poison'],
        senses: ['darkvision 60', 'low-light vision'],
        includeEnergyVulnerabilities: true,
        immunityTransforms: energyRes20,
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.stats(Bonuses.plantShape),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'large'],
            types: ['plant'],
        }),
    },
    "Plant Shape III": {
        healthAbilities: ['regeneration 5'],
        specialAbilities: ['constrict', 'grab', 'poison', 'trample', 'dr'],
        senses: ['darkvision 60', 'low-light vision'],
        includeEnergyVulnerabilities: true,
        immunityTransforms: energyRes20,
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.stats(Bonuses.plantShape),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'large', 'huge'],
            types: ['plant'],
        }),
    },
    "Monstrous Physique I": {
        speeds: ['climb 30', 'fly 30 (average)', 'swim 30'],
        senses: ['darkvision 60', 'low-light vision', 'scent'],
        // TODO: Include details for aquatic & amphibious subtypes
        subtypeTransforms: {
            'aquatic': 'you gain the aquatic and amphibious subtypes'
        },
        statBonuses: PolyFunctions.stats(Bonuses.monstrousPhysique),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium'],
            types: ['monstrous humanoid'],
        }),
    },
    "Monstrous Physique II": {
        speeds: ['climb 60', 'fly 60 (good)', 'swim 60'],
        specialAbilities: ['freeze', 'grab', 'leap attack', 'mimicry', 'pounce', 'sound mimicry', 'speak with sharks', 'trip', 'undersized weapons'],
        senses: ['darkvision 60', 'low-light vision', 'scent'],
        subtypeTransforms: {
            'aquatic': 'you gain the aquatic and amphibious subtypes'
        },
        statBonuses: PolyFunctions.stats(Bonuses.monstrousPhysique),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large'],
            types: ['monstrous humanoid'],
        }),
    },
    "Monstrous Physique III": {
        speeds: ['climb 90', 'fly 90 (good)', 'swim 90', 'burrow 30'],
        specialAbilities: ['freeze', 'grab', 'leap attack', 'mimicry', 'pounce', 'sound mimicry', 'speak with sharks', 'trip', 'undersized weapons', 'blood frenzy', 'cold vigor', 'constrict', 'ferocity', 'horrific appearance', 'jet', 'natural cunning', 'overwhelming', 'poison', 'rake', 'trample', 'web'],
        senses: ['darkvision 60', 'low-light vision', 'scent', 'all-around vision', 'blindsense 30'],
        // TODO: Data error? "horrific appearance" is sometimes listed in auras, and other times in Special Abilites
        subtypeTransforms: {
            'aquatic': 'you gain the aquatic and amphibious subtypes'
        },
        statBonuses: PolyFunctions.stats(Bonuses.monstrousPhysique),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large', 'huge', 'diminutive'],
            types: ['monstrous humanoid'],
        }),
    },
    "Monstrous Physique IV": {
        speeds: ['climb 90', 'fly 120 (good)', 'swim 120', 'burrow 60'],
        specialAbilities: ['freeze', 'grab', 'leap attack', 'mimicry', 'pounce', 'sound mimicry', 'speak with sharks', 'trip', 'undersized weapons', 'blood frenzy', 'cold vigor', 'constrict', 'ferocity', 'horrific appearance', 'jet', 'natural cunning', 'overwhelming', 'poison', 'rake', 'trample', 'web', 'breath weapon', 'rend', 'roar', 'spikes'],
        senses: ['darkvision 90', 'low-light vision', 'scent', 'all-around vision', 'blindsense 60', 'tremorsense 60'],
        // TODO: Data error? "horrific appearance" is sometimes listed in auras, and other times in Special Abilites
        includeEnergyVulnerabilities: true,
        subtypeTransforms: {
            'aquatic': 'you gain the aquatic and amphibious subtypes'
        },
        immunityTransforms: {
            ...energyRes20,
            'poison': '+8 bonus on saves against poison',
        },
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.stats(Bonuses.monstrousPhysique),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large', 'huge', 'diminutive'],
            types: ['monstrous humanoid'],
        }),
    },
    "Beast Shape I": {
        speeds: ['climb 30', 'fly 30 (average)', 'swim 30'],
        senses: ['darkvision 60', 'low-light vision', 'scent'],
        statBonuses: PolyFunctions.beastShapeStats(),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium'],
            types: ['animal'],
        }),
    },
    "Beast Shape II": {
        speeds: ['climb 60', 'fly 60 (good)', 'swim 60'],
        specialAbilities: ['grab', 'pounce', 'trip'],
        senses: ['darkvision 60', 'low-light vision', 'scent'],
        statBonuses: PolyFunctions.beastShapeStats(),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large'],
            types: ['animal'],
        }),
    },
    "Beast Shape III": {
        speeds: ['climb 90', 'fly 90 (good)', 'swim 90', 'burrow 30'],
        specialAbilities: ['grab', 'pounce', 'trip', 'constrict', 'ferocity', 'jet', 'poison', 'rake', 'trample', 'web'],
        senses: ['darkvision 60', 'low-light vision', 'scent', 'blindsense 30'],
        statBonuses: PolyFunctions.beastShapeStats(),
        validator: PolyFunctions.validator(
            {
                sizes: ['small', 'medium', 'tiny', 'large', 'diminutive', 'huge'],
                types: ['animal'],
            },
            {
                sizes: ['small', 'medium'],
                types: ['magical beast'],
            }
        ),
    },
    "Beast Shape IV": {
        speeds: ['climb 90', 'fly 120 (good)', 'swim 120', 'burrow 60'],
        specialAbilities: ['grab', 'pounce', 'trip', 'constrict', 'ferocity', 'jet', 'poison', 'rake', 'trample', 'web', 'breath weapon', 'rend', 'roar', 'spikes'],
        senses: ['darkvision 90', 'low-light vision', 'scent', 'blindsense 60', 'tremorsense 60'],
        includeEnergyVulnerabilities: true,
        immunityTransforms: energyRes20,
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.beastShapeStats(),
        validator: PolyFunctions.validator(
            {
                sizes: ['small', 'medium', 'tiny', 'large', 'diminutive', 'huge'],
                types: ['animal'],
            },
            {
                sizes: ['small', 'medium', 'tiny', 'large'],
                types: ['magical beast'],
            }
        ),
    },
    "Magical Beast Shape": {
        speeds: ['climb 90', 'fly 120 (good)', 'swim 120', 'burrow 60'],
        healthAbilities: ['fast healing 5'],
        specialAbilities: ['grab', 'pounce', 'trip', 'constrict', 'ferocity', 'jet', 'poison', 'rake', 'trample', 'web', 'breath weapon', 'rend', 'roar', 'spikes', 'hold breath', 'no breath', 'powerful charge', 'pull'],
        senses: ['darkvision 90', 'low-light vision', 'scent', 'blindsense 60', 'tremorsense 60', 'blindsight 30', 'see in darkness', 'blood drain', 'blood frenzy'],
        includeEnergyVulnerabilities: true,
        immunityTransforms: {
            ...energyRes20,
            'poison': '+8 resistance bonus on saves against poison',
        },
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.beastShapeStats(),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large', 'diminutive', 'huge'],
            types: ['magical beast'],
        }),
    },
    "Fey Form I": {
        speeds: ['climb 30', 'fly 30 (average)', 'swim 30'],
        maxBaseSpeed: 60,
        specialAbilities: ['boot stomp'],
        senses: ['darkvision 60', 'low-light vision', 'scent'],
        includeWeaknesses: true,
        always: ['If a listed ability depends on an item (as is the case with boot stomp), this spell transforms the nearest counterpart among your worn gear into that item.'],
        // TODO: You can more easily cast spells that the creature has as spell-like abilities, although you must still cast them as normal for your class. When you cast a spell that the creature has as a spell-like ability, it requires no verbal or somatic components and can’t be countered.
        subtypeTransforms: {
            'aquatic': 'you can breathe air and water'
        },
        statBonuses: PolyFunctions.stats(Bonuses.feyForm),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium'],
            types: ['fey'],
        }),
    },
    "Fey Form II": {
        speeds: ['climb 90', 'fly 60 (good)', 'swim 60', 'burrow 30'],
        maxBaseSpeed: 90,
        specialAbilities: ['grab', 'pounce', 'trip', 'boot stomp', 'abduct', 'animated hair', 'bleed', 'blood rage', 'burn', 'compression', 'constrict', 'crushing leap', 'dr 2/cold iron', 'heavy weapons', 'icewalking', 'kneecapper', 'nasal spray', 'no shadow', 'oversized weapons', 'poison', 'putrid vomit', 'sound mimicry', 'trackless step', 'trample', 'tree meld', 'undersized weapons', 'woodland stride', 'rock throwing (50, 1d6)'],
        senses: ['darkvision 60', 'low-light vision', 'scent', 'all-around vision', 'blindsense 30', 'see in darkness'],
        includeWeaknesses: true,
        always: ['If a listed ability depends on an item (as is the case with boot stomp), this spell transforms the nearest counterpart among your worn gear into that item.'],
        // TODO: You can more easily cast spells that the creature has as spell-like abilities, although you must still cast them as normal for your class. When you cast a spell that the creature has as a spell-like ability, it requires no verbal or somatic components and can’t be countered.
        subtypeTransforms: {
            'aquatic': 'you can breathe air and water'
        },
        immunityTransforms: {
            'poison': '+4 resistance bonus on saves against poison',
            'mind-affecting effects': '+4 resistance bonus on all saving throws against mind-affecting effects',
        },
        statBonuses: PolyFunctions.stats(Bonuses.feyForm),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large'],
            types: ['fey'],
        }),
    },
    "Fey Form III": {
        speeds: ['climb 90', 'fly 90 (good)', 'swim 90', 'burrow 60'],
        maxBaseSpeed: 90,
        specialAbilities: ['grab', 'pounce', 'trip', 'boot stomp', 'abduct', 'animated hair', 'bleed', 'blood rage', 'burn', 'compression', 'constrict', 'crushing leap', 'dr 5/cold iron', 'heavy weapons', 'icewalking', 'kneecapper', 'nasal spray', 'no shadow', 'oversized weapons', 'poison', 'putrid vomit', 'sound mimicry', 'trackless step', 'trample', 'tree meld', 'undersized weapons', 'woodland stride', 'fear aura', 'frightful presence', 'luminous', 'rend', 'supernatural speed', 'tear shadow', 'rock throwing (100, 2d6)'],
        senses: ['darkvision 90', 'low-light vision', 'scent', 'all-around vision', 'blindsense 60', 'see in darkness', 'blindsight 30', 'tremorsense 60'],
        includeWeaknesses: true,
        always: ['If a listed ability depends on an item (as is the case with boot stomp), this spell transforms the nearest counterpart among your worn gear into that item.'],
        // TODO: You can more easily cast spells that the creature has as spell-like abilities, although you must still cast them as normal for your class. When you cast a spell that the creature has as a spell-like ability, it requires no verbal or somatic components and can’t be countered.
    
        subtypeTransforms: {
            'aquatic': 'you can breathe air and water'
        },
        immunityTransforms: {
            ...energyRes20,
            'poison': '+8 resistance bonus on saves against poison',
            'mind-affecting effects': '+8 resistance bonus on all saving throws against mind-affecting effects',
        },
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.stats(Bonuses.feyForm),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large', 'diminutive', 'huge'],
            types: ['fey'],
        }),
    },
    "Fey Form IV": {
        speeds: ['climb 90', 'fly 120 (good)', 'swim 120', 'burrow 60'],
        specialAbilities: ['grab', 'pounce', 'trip', 'boot stomp', 'abduct', 'animated hair', 'bleed', 'blood rage', 'burn', 'compression', 'constrict', 'crushing leap', 'dr 5/cold iron', 'heavy weapons', 'icewalking', 'kneecapper', 'nasal spray', 'no shadow', 'oversized weapons', 'poison', 'putrid vomit', 'sound mimicry', 'trackless step', 'trample', 'tree meld', 'undersized weapons', 'woodland stride', 'fear aura', 'frightful presence', 'luminous', 'rend', 'supernatural speed', 'tear shadow', 'beguiling aura', 'fast healing 5', 'hide in plain sight', 'transparency', 'vault', 'rock throwing (120, 2d10)'],
        senses: ['darkvision 90', 'low-light vision', 'scent', 'all-around vision', 'blindsense 60', 'see in darkness', 'blindsight 30', 'tremorsense 60'],
        // TODO: If the creature has spell resistance, you gain spell resistance 6 + your caster level.
        includeWeaknesses: true,
        always: ['If a listed ability depends on an item (as is the case with boot stomp), this spell transforms the nearest counterpart among your worn gear into that item.'],
        // TODO: You can more easily cast spells that the creature has as spell-like abilities, although you must still cast them as normal for your class. When you cast a spell that the creature has as a spell-like ability, it requires no verbal or somatic components and can’t be countered.
    
        subtypeTransforms: {
            'aquatic': 'you can breathe air and water'
        },
        immunityTransforms: {
            ...energyRes30,
            'poison': '+8 resistance bonus on saves against poison',
            'mind-affecting effects': '+8 resistance bonus on all saving throws against mind-affecting effects',
        },
        resistanceTransforms: energyRes30,
        statBonuses: PolyFunctions.stats(Bonuses.feyForm),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large', 'diminutive', 'huge'],
            types: ['fey'],
        }),
    },
    "Giant Form I": {
        healthAbilities: ['regeneration 5'],
        specialAbilities: ['rock catching', 'rock throwing (60, 2d6)', 'rend (2d6)'],
        senses: ['darkvision 60'],
        includeEnergyVulnerabilities: true,
        immunityTransforms: energyRes20,
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.stats(Bonuses.giantForm),
        validator: PolyFunctions.validator({
            sizes: ['large'],
            types: ['humanoid'],
        }),
    },
    "Giant Form II": {
        speeds: ['swim 60'],
        baseSpeedEnhancement: 10,
        healthAbilities: ['regeneration 5'],
        specialAbilities: ['rock catching', 'rock throwing (120, 2d10)', 'rend (2d8)'],
        senses: ['darkvision 60'],
        immunities: [...elements],
        resistances: [...elements],
        includeEnergyVulnerabilities: true,
        statBonuses: PolyFunctions.stats(Bonuses.giantForm),
        validator: PolyFunctions.validator({
            sizes: ['large', 'huge'],
            types: ['humanoid'],
        }),
    },
    "Undead Anatomy I": {
        speeds: ['climb 30', 'fly 30 (average)', 'swim 30'],
        senses: ['low-light vision', 'scent'],
        // TODO: Must be corporeal
        // TODO: Must be "vaguely humanoid-shaped"
        always: ['you detect as an undead creature (such as with detect undead, but not with magic that reveals your true form, such as true seeing) and are treated as undead for the purposes of channeled energy, cure spells, and inflict spells, but not for other effects that specifically target or react differently to undead (such as searing light).'],
        statBonuses: PolyFunctions.undeadAnatomyStats(false),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium'],
            types: ['undead'],
        }),
    },
    "Undead Anatomy II": {
        speeds: ['climb 60', 'fly 60 (good)', 'swim 60'],
        specialAbilities: ['blood drain', 'dr 5/bludgeoning', 'freeze', 'grab', 'mimicry', 'pounce', 'shadowless', 'sound mimicry', 'trip'],
        senses: ['low-light vision', 'scent'],
        // TODO: Must be corporeal
        includeAttackVulnerabilities: true,
        always: [
            'you gain a +4 bonus on saves against mind-affecting effects, disease, poison, sleep, and stunning.',
            'you detect as an undead creature (such as with detect undead, but not with magic that reveals your true form, such as true seeing) and are treated as undead for the purposes of channeled energy, cure spells, and inflict spells, but not for other effects that specifically target or react differently to undead (such as searing light).',
        ],
        statBonuses: PolyFunctions.undeadAnatomyStats(false),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large'],
            types: ['undead'],
        }),
    },
    "Undead Anatomy III": {
        speeds: ['climb 90', 'fly 90 (good)', 'swim 90', 'burrow 30'],
        specialAbilities: ['blood drain', 'dr 5/-', 'freeze', 'grab', 'mimicry', 'pounce', 'shadowless', 'sound mimicry', 'trip', 'constrict', 'disease', 'fear aura', 'jet', 'natural cunning', 'overwhelming', 'poison', 'rake', 'trample', 'unnatural aura', 'web'],
        senses: ['low-light vision', 'scent', 'blindsense 30', 'all-around vision'],
        // TODO: Must be corporeal
        includeEnergyVulnerabilities: true,
        includeAttackVulnerabilities: true,
        always: [
            'you gain a +8 bonus on saves against mind-affecting effects, disease, poison, sleep, and stunning.',
            'you detect as an undead creature (such as with detect undead, but not with magic that reveals your true form, such as true seeing) and are treated as undead for the purposes of channeled energy, cure spells, and inflict spells, but not for other effects that specifically target or react differently to undead (such as searing light).',
        ],
        immunityTransforms: energyRes20,
        resistanceTransforms: energyRes20,
        statBonuses: PolyFunctions.undeadAnatomyStats(false),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large', 'diminutive', 'huge'],
            types: ['undead'],
        }),
    },
    "Undead Anatomy IV": {
        speeds: ['climb 90', 'fly 120 (good)', 'swim 120', 'burrow 60'],
        healthAbilities: ['fast healing 5'],
        specialAbilities: ['blood drain', 'dr 5/-', 'dr 10/magic and silver', 'dr 15/bludgeoning and magic', 'freeze', 'grab', 'mimicry', 'pounce', 'shadowless', 'sound mimicry', 'trip', 'constrict', 'disease', 'fear aura', 'jet', 'natural cunning', 'overwhelming', 'poison', 'rake', 'trample', 'unnatural aura', 'web', 'breath weapon', 'fiery death', 'fire aura', 'incorporeal', 'rend', 'roar', 'spikes'],
        senses: ['low-light vision', 'scent', 'blindsense 60', 'darkvision 90', 'all-around vision', 'lifesense 60', 'tremorsense 60'],
        // TODO: If the creature’s form is incorporeal, the spell’s duration is in rounds per level instead of minutes per level and your bite and claw (or slam) attacks are incorporeal touch attacks.
        includeEnergyVulnerabilities: true,
        includeAttackVulnerabilities: true,
        always: [
            'you gain a +8 bonus on saves against mind-affecting effects, disease, poison, sleep, and stunning.',
            'you detect as an undead creature (such as with detect undead, but not with magic that reveals your true form, such as true seeing) and are treated as undead for the purposes of channeled energy, cure spells, and inflict spells, but not for other effects that specifically target or react differently to undead (such as searing light).',
        ],
        immunityTransforms: energyRes30,
        resistanceTransforms: energyRes30,
        statBonuses: PolyFunctions.undeadAnatomyStats(true),
        validator: PolyFunctions.validator({
            sizes: ['small', 'medium', 'tiny', 'large', 'diminutive', 'huge'],
            types: ['undead'],
        }),
    },
};