










































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import Toaster from '@/ts/ui_integrations/Toaster';
import { AllTemplateTypes, ElementTemplate, TemplateGroup, TemplateOrGroup, TemplateTypes } from '@/ts/util/loregen2/Templates';

@Component({
    components: {
    }
})
export default class GroupDisplay extends Vue {
    @Prop({required: true})
    group!: TemplateGroup;

    toaster = new Toaster(this.$toast.add);

    addGroup() {
        let path = TemplateGroup.getPath(this.group);
        this.group.childGroups.push(new TemplateGroup(path));
    }

    addTemplate() {
        let path = TemplateGroup.getPath(this.group);
        this.group.children.push(new ElementTemplate(TemplateTypes.Line, path));
    }

    @Watch('group')
    makeGuarantees() {
        if (this.group.childGroups == undefined) Vue.set(this.group, 'childGroups', []);
        if (this.group.children == undefined) Vue.set(this.group, 'children', []);
    }

    mounted() {
        this.makeGuarantees();
    }

    deleteChild(slotProps: {index: number, option: TemplateOrGroup}) {
        let array = (slotProps.option.isGroup) ? this.group.childGroups : this.group.children;
        array.splice(slotProps.index, 1);
    }
}
