type Dynamic = {[key: string]: any};

export class Grant {
    templateId: string;
    grantType: string; // bonus, content, selection, etc

    name: string;
    levelStart: number;
    description: string; // This is the explaination to the question: 'why do I have this?'
    
    associatedFeature: string; // guid:name of the feature or grant which this grant is associated with. This grant will be displayed along with the description of the associatedFeature.

    notes: string; // This is for internal purposes, at present
    _completionStatus: boolean; // Indicators for data entry - whether or not all data was properly added. This is separate from validation/verification signoff.

    constructor(json: Dynamic) {
        if (json == null) json = {};

        this.templateId = json.templateId ?? "";
        this.grantType = json.grantType ?? "";

        this.name = json.name ?? "";
        this.notes = json.notes ?? "";
        this.levelStart = json.levelStart ?? 0;
        this.description = json.description ?? "";
        
        this.associatedFeature = json.associatedFeature ?? "";

        this._completionStatus = json._completionStatus ?? false;
    }
}