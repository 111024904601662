































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import { Gamebook, ParsedEnemy } from '@/ts/gamebooks/parsing/GamebookParser';
import { CombatSession, EnemyInstance } from '@/ts/gamebooks/CombatSession';
import { GamebookSession, PlayerStats } from '@/ts/gamebooks/GamebookSession';
import { ImageDef } from '@/ts/gamebooks/models/GamebookData';

var images = require.context('@/assets/gamebooks/', true, /\.((jpeg)|(jpg)|(png))$/);

@Component({
    components: {
    }
})
export default class PlayerDisplay extends Vue {
    @Prop({required: true})
    session!: GamebookSession;

    @Prop({default: false})
    noTitle!: boolean;

    showStats = false;
    editingName = false;

    get player(): PlayerStats {
        return this.session.player;
    }

    get currentHp() {
        return this.player.currentHp;
    }

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    get healthbarWidth() {
        if (this.currentHp <= 0) return '0px';
        let max = this.player.maxHp;
        let ratio = this.currentHp / max;
        let percent = Math.round(ratio*100);
        if (percent < 0) percent = 0;
        if (percent > 100) percent = 100;
        return `${percent}%`;
    }

    get ltText() {
        return (this.currentHp < 0) ? '<-' : '';
    }

    get gtText() {
        let max = this.player.maxHp;
        return (this.currentHp > max) ? '+>' : '';
    }

    get hpHoverText() {
        let max = this.player.maxHp;
        let current = this.currentHp;
        return `HP:\n${current} / ${max}`
    }

    onInput(event: any) {
        if (event.keyCode == 13) {
            // Enter Pressed
            this.editingName = false;
        }
    }
}
