















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ButtonBlock, ConditionalBlock, Span } from '@/ts/gamebooks/parsing/Blocks';
import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import Toaster from '@/ts/ui_integrations/Toaster';
import axios from 'axios';

@Component({
    components: {
    }
})
export default class ReviewWidget extends Vue {
    // TODO: Add support for editing your review
    // TODO: If a user has already reviewed a gamebook, do not show the submit form again

    @Prop({required: true})
    session!: GamebookSession;

    toaster = new Toaster(this.$toast.add);
    rating: number|null = null;
    review = "";
    
    reviewSubmitted = false;
    submitting = false;
    success = true;
    message = "";

    async submit(mobile: boolean) {
        if (this.rating == null) {
            this.success = false;
            this.message = '*Set the star rating before submitting';
        }
        else {
            this.submitting = true;

            let body = {
                mobile,
                review: this.review,
                rating: this.rating,
                gamebookId: this.gamebook?.meta.refId,
                gamebookName: this.gamebook?.meta.title,
            };
            let response = await axios.post('/api/review/gamebooks', body, {validateStatus: null});
            if (response.status < 400) {
                this.message = `Your review was submitted!`;
                this.reviewSubmitted = true;
                this.success = true;
            }
            else {
                this.message = `There was an error submitting your review. If this problem persists, please report the issue to artemistabletop@gmail`;
                this.success = false;
            }

            this.submitting = false;
        }
    }

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    soon() {
        this.toaster.info('Coming Soon!', 'Review functionality is not yet operational', 7500);
    }
}
