import { CardConfig, StatEntry, TagEntry } from "@/ui/DataCards/CardConfig";
import { SortAlgo } from "@/ui/AdvancedSearch/CardResultsConfig";
import { StringMap } from "@/ts/api/sharedModels/Types";
import Links from "@/ts/api/sharedModels/Links";
import MDBuilder from "@/ts/util/MDBuilder";
import Utils from "@/ts/util/Utils";

export default interface BestiaryEntry {
    // These fields are filled by the client, and are not present in the original data
    _cr: number;
    _hdCount: number;
    hd: string; // The HD string, e.g. "(10d8+12)" (as of now, this is in statNotes.hp)
    _environments: string[];
    _locations: string[];
    _climates: string[];

    // Identification & Metadata
    id: string;
    name: string;
    links: Links;
    groups: string[];
    sources: string[];

    cr: string;
    xp: number;
    mr: number;

    // Grid Stats
    stats: Stats;
    statNotes: StatNotes;

    type: string; // Type and subtypes e.g. 'outsider (native)'
    size: string;
    auras: Aura[];
    alignment: string;

    // Lore
    boon: string;
    treasure: string;
    languages: string[];
    advancement: string;
    environment: string;
    organization: string;
    favoredClass: string;
    levelAdjustment: string;
    race_class: string; // "Race Class Level" e.g. Human bard 19. TODO: Parse race and classes from this line

    descriptor: string;
    description: string;
    physicalDescription: string;
    
    ecology: string; // Paragraph text
    adventureHooks: string; // Paragraph text
    habitatAndSociety: string; // Paragraph text
    
    combat: string; // TODO: This appears to be a parse error, and only appears on Dragon pages
    tactics: string; // TODO: There are some parse errors here - base statistics should not be listed under tactics
    
    gear: string[]; // list of all items listed in Gear, Combat Gear, and Other Gear

    // Actions
    psychicMagic: PsychicMagic;
    meleeAttacks: string[][]; // Represents groups of attacks capable by the monster. For instance, if this contains 3 lists, the monster may make all attacks in any ONE of the three lists
    rangedAttacks: string[][]; // Represents groups of attacks capable by the monster. For instance, if this contains 3 lists, the monster may make all attacks in any ONE of the three lists
    castingSections: Casting[]; // Each entry holds details for the casting capabilities of a monster, including the type of spells and number of castings per day, CL, DC, etc.
    magicDetailLines: {[key: string]: string}; // Each entry holds details for casting class elements (e.g. bloodline, domain, psychic discipline, etc)
    
    // Abilities
    movementAbilities: AbilitySnippet[]; // E.g. "air walk, earth glide"
    defensiveAbilities: AbilitySnippet[]; // E.g. "leaden weariness, reflect spell;"
    healthAbilities: AbilitySnippet[]; // E.g. "regeneration 15 (electricity)"
    specialAttacks: AbilitySnippet[]; // E.g. "banish darkness, consume light"
    specialAbilities: SpecialAbility[];
    specialQualities: string[];

    // Other [Defenses & Build Information]
    resistances: Resistance[];
    immunities: string[];
    weaknesses: string[];
    senses: string[];
    dr: DR[];

    feats: string[];
    racialModifiers: string[];
}

export interface Resistance {
    name: string;
    notes: string;
    magnitude: number;
}

export interface DR {
    name: string;
    notes: string;
    overcome: string;
    magnitude: number;
}

export interface Aura {
    name: string;
    notes: string;
    radius: number;
    dc: number;
}

export interface SpecialAbility {
    name: string;
    description: string;
    abilityType: string;
}

export interface AbilitySnippet {
    name: string;
    notes: string;
    magnitude: number;
    useMagnitudePrefix: boolean;
}

export interface Casting {
    name: string; // e.g. 'Spells Known' or 'spell-like abilities'
    notes: string; // e.g. '(CL 1st)'
    entries: {[key:string]: string[]}; // key (e.g. '0 (at will)') to list of spells/powers in that category (e.g. 'detect magic, guidance, stabilize')
}

export interface PsychicMagic {
    cl: number;
    notes: string; // e.g. 'Wisdom-based'
    concentration: number;
    psychicEnergy: number;
    entries: string[]; // list of psychic powers e.g. '_aversion_ (2 PE, DC 17)'
}

export interface Stats {
    init: number;
    init2: number;
    ac: number;
    ac_flat: number;
    ac_touch: number;
    hp: number;
    fort: number;
    ref: number;
    will: number;

    sr: number;
    space: number;
    reach: number;

    str: number;
    dex: number;
    con: number;
    int: number;
    wis: number;
    cha: number;
    
    bab: number;
    cmb: number;
    cmd: number;
    grapple: number;

    speeds: {
        base: number;
        [key:string]: number;
    }

    skills: {
        [key:string]: number;
    }
}

export interface StatNotes {
    init: string;
    init2: string;
    hp: string;
    fort: string;
    ref: string;
    will: string;

    sr: string;
    space: string;
    reach: string;

    saves: string;
    acComponents: string;
    acConditionals: string;

    str: string;
    dex: string;
    con: string;
    int: string;
    wis: string;
    cha: string;
    
    bab: string;
    cmb: string;
    cmd: string;
    grapple: string;

    speeds: {
        base: string;
        [key:string]: string;
    }

    skills: {
        [key:string]: string;
    }
}

function formatAbilityName(name: string) {
    return name?.replace(/[0-9]+( rounds)?\/day$/g, '').replace(/ [\+\-]?[0-9]+(d[0-9]+)?$/g, '').split('(')[0].trim();
}

function formatSenseName(name: string) {
    return name?.replace(/[0-9]+ ft\.?/g, '').split('(')[0].trim();
}

/** Calculated values that are used for Advanced Search functionality */
export class BestiaryFields {
    static sourceNames(entry: BestiaryEntry) {
        return (entry.sources ?? []).map(source => source.split('pg.')[0].trim());
    }

    static numAttacks(entry: BestiaryEntry) {
        let melee = entry.meleeAttacks ?? [];
        let ranged = entry.rangedAttacks ?? [];
        let attackGroups = melee.concat(ranged);

        let max = 0;
        for (let attacks of attackGroups) {
            let count = 0;
            for (let atk of attacks) {
                if (/^[0-9+]/g.test(atk)) {
                    count += parseInt(atk.split(' ')[0]);
                }
                else {
                    count += 1;
                }
            }
            if (count > max) max = count;
        }
        return max;
    }

    static maxAtk(entry: BestiaryEntry) {
        let melee = entry.meleeAttacks ?? [];
        let ranged = entry.rangedAttacks ?? [];
        let attackGroups = melee.concat(ranged);

        let max = -999;
        for (let attacks of attackGroups) {
            for (let atk of attacks) {
                let proc = Utils.removeGroups(atk);
                let matches = proc.match(/-?[0-9]+/g)?.map(x => parseInt(x)).filter(x => x!= null && !isNaN(x)) ?? [];
                for (let match of matches) {
                    if (match > max) max = match;
                }
            }
        }
        return max;
    }

    static spellAndSLANames(entry: BestiaryEntry) {
        let spells = [] as string[];

        let lists = entry?.castingSections?.flatMap(x => Object.values(x.entries ?? {}).flat()) ?? [];
        for (let value of lists) {
            let split = Utils.splitNonGroup(value);
            for (let str of split) {
                let spell = str.split('(')[0].trim(); // Remove things like '(DC 16)'
                spell = spell.replace(/<sup>[^<]*<\/sup>/g, '').trim(); // Remove superscripts
                spell = spell.replace(/\\\*/g, '').trim(); // Remove asteriks
                // spell = spell.replace(/[A-Z]+[0-9]?$/g, '').trim(); // Old form for removing superscript artifacts

                // Hacky exclusions:
                spell = spell.replace(/additional abilities based on mephit type/g, '').trim();
                spell = spell.replace(/[aA]ny ((one)|(three)) of the following:?/g, '').trim();
                spell = spell.replace(/^quickened /g, '').trim();
                spell = spell.replace(/^empowered /g, '').trim();
                spell = spell.replace(/^extended /g, '').trim();
                spell = spell.replace(/^heightened /g, '').trim();
                spell = spell.replace(/one additional ability based on alignment/g, '').trim();

                if (!/[0-9]+ [mM]ore/.test(spell) && !spells.includes(spell)) spells.push(spell); // Do not include '2 More' '3 More' etc.
            }
        }

        return spells.filter(x => x.length > 0).map(x => Utils.titleCase(x));
    }

    static healthAbilitiesNames(entry: BestiaryEntry) {
        let abilities: string[] = (entry?.healthAbilities ?? []).map(x => formatAbilityName(x.name));
        return abilities;
    }

    static defensiveAbilitiesNames(entry: BestiaryEntry) {
        let abilities: string[] = (entry?.defensiveAbilities ?? []).map(x => formatAbilityName(x.name));
        return abilities;
    }

    static specialAttacksNames(entry: BestiaryEntry) {
        let abilities: string[] = (entry?.specialAttacks ?? []).map(x => formatAbilityName(x.name));
        return abilities;
    }

    static specialAbilitiesNames(entry: BestiaryEntry) {
        let abilities: string[] = (entry?.specialAbilities ?? []).map(x => formatAbilityName(x.name));
        return abilities;
    }

    static specialQualitiesNames(entry: BestiaryEntry) {
        let qualities: string[] = (entry?.specialQualities ?? []).map(x => formatAbilityName(x));
        return qualities;
    }

    static senseNames(entry: BestiaryEntry) {
        let senses: string[] = (entry?.senses ?? []).map(x => formatSenseName(x)).filter(x => !/[\+\-][0-9]+/.test(x)); // Remove skills (+ and -)
        return senses;
    }

    static stripedTypes(entry: BestiaryEntry) {
        let types = [];
        let type = entry.type.split('(_')[0]; // TODO: This should be removed at the scraping level, not here. // This cleans the non-standard entry "outsider (extraplanar) (_Pathfinder RPG Bestiary 3_ 152)"
        let split = Utils.splitNonGroup(type);
        for (let str of split) {
            let index = str.indexOf('(');
            console.assert(index < 0 || str.indexOf('(', index+1) < 0, 'Did not expect double parenthesis in type', type, entry);
            types.push(Utils.titleCase((index < 0 ? str : str.slice(0, index)).trim()));
        }
        return types;
    }

    static stripedSubtypes(entry: BestiaryEntry) {
        let subtypes = [];
        let type = entry.type.split('(_')[0]; // TODO: This should be removed at the scraping level, not here. // This cleans the non-standard entry "outsider (extraplanar) (_Pathfinder RPG Bestiary 3_ 152)"
        let split = Utils.splitNonGroup(type);
        for (let str of split) {
            let index = str.indexOf('(');
            if (index > 0) {
                let all = str.slice(index+1, -1).split(',').flatMap(x => {
                    let lower = x.toLowerCase().trim();
                    if (lower.startsWith('augmented ')) return 'augmented';
                    else if (lower.startsWith('or ')) return lower.slice(3);
                    else if (lower == 'cold or fire') return ['cold', 'fire'];
                    else return lower;
                });
                subtypes.push(...all);
            }
        }
        return subtypes;
    }

    static stripedFeats(entry: BestiaryEntry) {
        let feats = entry.feats ?? [];
        return feats.map(x => x.replace(/<sup>[^<]*<\/sup>/gi, '').split('(')[0].trim());
    }

    static stripedMovementAbilities(entry: BestiaryEntry) {
        let abilities = entry.movementAbilities ?? [];
        return abilities.map(x => x.name);
    }

    static movementTypes(entry: BestiaryEntry) {
        let types = [];
        for (let key of Object.keys(entry.stats?.speeds ?? {})) {
            let lower = key.toLowerCase().split('(')[0].trim();
            if (lower == 'base') types.push('land');
            else types.push(lower);
        }

        return types;
    }

    static alignments(entry: BestiaryEntry) {
        let string = entry.alignment?.trim() ?? '';
        string = string.split('(')[0].trim();
        if (/any alignment/gi.test(string)) return ['LG', 'LN', 'LE', 'NG', 'N', 'NE', 'CG', 'CN', 'CE'];

        for (let ignore of ['always', 'usually']) {
            if (string.toLowerCase().startsWith(ignore)) string = string.slice(ignore.length).trim();
        }
        return string.split('or').map(x => x.trim());
    }

    static speeds(entry: BestiaryEntry) {
        return Object.values(entry.stats?.speeds ?? {}).map(x => `${x} ft.`);
    }

    static reachValues(entry: BestiaryEntry) {
        let reach = entry.stats?.reach;
        if (reach == null) return ['Fine', 'Diminutive', 'Tiny'].includes(entry.size) ? '0 ft.' : '5 ft.';

        let notes = entry.statNotes?.reach;
        if (notes == null) return `${reach} ft.`;

        let vals = [reach];
        [...notes.matchAll(/[0-9]+ ?ft/g)].flat().forEach(x => {
            let v = parseInt(x);
            if (isNaN(v)) console.warn('Unexpcted reach format found', {match: v, notes});
            else vals.push(v);
        });
        return vals.map(x => `${x} ft.`);
    }

    static treasureMagnitudes(entry: BestiaryEntry) {
        if (entry.treasure) {
            // TODO: Support half
            if (/^none or incidental/gi.test(entry.treasure)) return ['none', 'incidental'];
            if (/^incidental/gi.test(entry.treasure)) return ['incidental'];
            if (/^double/gi.test(entry.treasure)) return ['double'];
            if (/^triple/gi.test(entry.treasure)) return ['triple'];
            if (/^none/gi.test(entry.treasure)) return ['none'];
            else return ['standard'];
        }
        else return ['standard'];
    }
}

/** Calculated values that are used for card displays.
 * These methods handle formatting and markdown (when appropriate) for displaying text to the user - they are not used by the Advanced Search Engine.
 */
export class BestiaryDisplays {
    static sortAlgorithms: SortAlgo<BestiaryEntry>[] = [
        {name: "Default", func: 0},
        {name: "CR", func: (a: BestiaryEntry, b: BestiaryEntry) => Utils.compareCR(a.cr, b.cr)},
        {name: "Name", func: (a: BestiaryEntry, b: BestiaryEntry) => Utils.strComp(a.name, b.name)},
        {name: "Alignment", func: (a: BestiaryEntry, b: BestiaryEntry) => Utils.strComp(BestiaryFields.alignments(a).pop() as string, BestiaryFields.alignments(b).pop() as string)},
    ];

    static type(entry: BestiaryEntry): string {
        return (entry.type ?? '').trim();
    }

    static size(entry: BestiaryEntry): string {
        let space = entry.stats.space == null ? '' : `${entry.stats.space} ft.`;
        let spaceNotes = entry.statNotes.space == null ? '' : `${entry.statNotes.space}`;
        let spaceString = (space.length > 0 && spaceNotes.length > 0) ? `${space}; ${spaceNotes}` : `${space}${spaceNotes}`.trim();
        if (spaceString.length > 0) spaceString = `(${spaceString})`;
        let sizeString = entry.size ?? '';
        return `${sizeString} ${spaceString}`.trim();
    }

    static aura(entry: BestiaryEntry): string {
        let auras = entry.auras ?? [];
        return auras.map(aura => {
            let comps: string[] = [
                aura.dc ? `DC ${aura.dc}` : '',
                aura.radius ? `${aura.radius} ft.` : '',
                aura.notes ?? ''
            ].filter(x => x.length > 0);
            
            let compDsp = comps.length > 0 ? `(${comps.join(', ')})` : '';
            return `${aura.name ?? ''} ${compDsp}`.trim();
        }).join('\n');
    }

    static speed(entry: BestiaryEntry): string {
        let speeds = entry.stats?.speeds ?? {};
        let notes = entry.statNotes?.speeds ?? {};
        return Object.keys(speeds).map(key => {
            let speed = speeds[key];
            let note = notes[key];

            let speedStr = (key == 'base') ? `${speed} ft.` : `${key} ${speed} ft.`;
            return (note == null) ? speedStr : `${speedStr} ${note}`;
        }).join('\n');
    }

    static reach(entry: BestiaryEntry): string {
        let stat = entry.stats?.reach ?? (['Fine', 'Diminutive', 'Tiny'].includes(entry.size) ? 0 : undefined);
        let notes = entry.statNotes?.reach;

        if (stat == null && notes != null) {
            console.warn('Monster has no reach entry, but reach notes were found', entry);
            return notes;
        }
        else if (stat == null) {
            return '';
        }
        else {
            return `${stat} ft. ${notes ?? ''}`.trim();
        }
    }

    static init(entry: BestiaryEntry): string {
        let init = entry.stats?.init ?? 0;
        if (init >= 0) return '+' + init;
        else return '' + init;
    }

    static alignment(entry: BestiaryEntry): string {
        let alignment = entry.alignment?.trim() ?? '';
        if (alignment.length > 0) return 'Alignment: ' + entry.alignment;
        else return '';
    }

    static gridStats(entry: BestiaryEntry): StatEntry[] {
        return [
            {
                label: "AC",
                labelHover: entry.statNotes.acComponents,
                value: entry.stats?.ac ?? 0,
                valueHover: entry.statNotes.acConditionals,
            },
            {
                label: "HP",
                labelHover: entry.hd,
                value: entry.stats?.hp ?? 0,
                valueHover: entry.statNotes.hp,
            },
            {
                label: "Fort",
                value: entry.stats?.fort ?? 0,
                valueHover: BestiaryDisplays.hoverText(entry, 'fort'),
            },
            {
                label: "TAC",
                value: entry.stats?.ac_touch ?? 0,
                valueHover: entry.statNotes.acConditionals,
            },
            {
                label: "CMD",
                value: entry.stats?.cmd ?? 0,
                valueHover: entry.statNotes?.cmd,
            },
            {
                label: "Ref",
                value: entry.stats?.ref ?? 0,
                valueHover: BestiaryDisplays.hoverText(entry, 'ref'),
            },
            {
                label: "FFAC",
                value: entry.stats?.ac_flat ?? 0,
                valueHover: entry.statNotes.acConditionals,
            },
            {
                label: "CMB",
                value: entry.stats?.cmb ?? 0,
                valueHover: entry.statNotes?.cmb,
            },
            {
                label: "Will",
                value: entry.stats?.will ?? 0,
                valueHover: BestiaryDisplays.hoverText(entry, 'will'),
            }
        ];
    }
    
    static tagStats(entry: BestiaryEntry): TagEntry[] {
        return [
            {
                label: "Init",
                labelHover: BestiaryDisplays.init(entry),
            },
            {
                label: "AL",
                labelHover: BestiaryDisplays.alignment(entry),
            },
            {
                label: "Type",
                labelHover: BestiaryDisplays.type(entry),
            },
            {
                label: "Size",
                labelHover: BestiaryDisplays.size(entry),
            },
            {
                label: "Reach",
                labelHover: BestiaryDisplays.reach(entry),
            },
            {
                label: "Speed",
                labelHover: BestiaryDisplays.speed(entry),
            },
            {
                label: "Aura",
                labelHover: BestiaryDisplays.aura(entry),
            }
        ].filter(x => x.labelHover.length > 0);
    }

    static loreMD(x: BestiaryEntry) {
        let md = new MDBuilder();

        md.field('', x.physicalDescription);
        md.field('', x.descriptor?.split('(_')[0].trim());
        md.field('Favored Class', x.favoredClass?.split('(_')[0].trim());
        md.field('Environment', x.environment);
        md.field('Organization', x.organization);
        md.field('Languages', x.languages?.join(', '));
        md.div();
        md.field('Treasure', x.treasure);
        md.field('Gear', x.gear?.join(', '));
        md.div();
        md.field('', x.description, 'No description is available.');
        md.field('Adventure Hooks', x.adventureHooks);
        md.field('Hooks', x.ecology);

        return md.build();
    }

    static actionsMD(x: BestiaryEntry) {
        let md = new MDBuilder();

        md.h3('Attacks');
        md.field('Melee', joinStringLists(x.meleeAttacks));
        md.field('Ranged', joinStringLists(x.rangedAttacks));
        md.field('Special', listIfLong(joinSnippets(x.specialAttacks)));
        md.div();
        md.field('Special Qualities', listIfLong(x.specialQualities.join(', ')));
        md.field('Movement', listIfLong(joinSnippets(x.movementAbilities)));
        md.field('Defenses', listIfLong(joinSnippets(x.defensiveAbilities)));

        let hasMagicDetails = x.magicDetailLines && Object.keys(x.magicDetailLines).length > 0;
        let hasCastingSections = x.castingSections && x.castingSections.length > 0;
        if (hasMagicDetails || hasCastingSections) {
            md.h2('Magic');
        }

        if (hasMagicDetails) {
            for (let [k, v] of Object.entries(x.magicDetailLines)) {
                md.field(Utils.titleCase(k), v);
            }
        }

        // TODO: Psychic Magic
        if (hasCastingSections) {
            for (let casting of x.castingSections) {
                md.h3(Utils.titleCase(casting.name));
                md.h4(casting.notes);
                md.field('', castingEntriesToMD(casting.entries));
            }
        }

        BestiaryDisplays.addSpecialAbilities(md, x.specialAbilities);
        return md.build();
    }

    static addSpecialAbilities(md: MDBuilder, specialAbilities: SpecialAbility[]) {
        if (specialAbilities && specialAbilities.length > 0) {
            md.h2('Special Abilities');
            for (let ability of specialAbilities) {
                let title = Utils.titleCase(ability.name);
                if (ability.abilityType) title += ` ${ability.abilityType}`; // TODO: strip parenthesis from root data; add them here; and capitalize Ex, Su, Sp.
                md.h3(Utils.titleCase(title));
                md.field('', ability.description);
            }
        }
    }

    static otherMD(x: BestiaryEntry) {
        let md = new MDBuilder();

        md.field('DR', x.dr.map(y => drToString(y)).join(', '));
        md.field('Senses', x.senses.join(', ').replace(/_/g, '')); // TODO: This should be done at scraping time - not here
        md.field('Weaknesses', x.weaknesses.join(', '));
        md.field('Immunities', x.immunities.join(', '));
        md.field('Resistances', joinResistances(x.resistances));
        
        md.field('Feats', listIfLong(x.feats.join(', ')));
        md.field('Skills', listIfLong(joinSkills(x)));
        md.field('Racial Modifiers', (x.racialModifiers && x.racialModifiers.length > 0) ? `(${x.racialModifiers})` : '');

        return md.build();
    }

    static otherGridStats(entry: BestiaryEntry): StatEntry[] {
        return ['str', 'dex', 'con', 'int', 'wis', 'cha'].map(key => {
            let stats: any = entry.stats;
            let notes: any = entry.statNotes;
            let stat = stats == null ? null : stats[key];

            return {
                label: Utils.titleCase(key),
                value: stat ?? 0,
                valueHover: notes == null ? null : notes[key],
            }
        });
    }

    static hoverText(entry: BestiaryEntry, key: string): string {
        let notes: any = entry.statNotes;
        return [notes ? notes[key] : null, entry.statNotes?.saves].filter(x => x != null).join('\n').trim();
    }

    static searchTerm(entry: BestiaryEntry): string {
        let lower = entry.name.toLowerCase();
        let start = ['fine', 'diminutive', 'tiny', 'small', 'medium', 'large', 'huge', 'gargantuan', 'colossal'].filter(x => lower.startsWith(x)).shift();
        return 'Pathfinder ' + (start ? entry.name.substring(start.length).trim() : entry.name);
    }

    static getCardConfig(monster: BestiaryEntry): CardConfig {
        return {
            name: monster.name,
            sources: monster.sources,
            secondaryTitle: `(CR ${monster.cr})`,
            topGrid: BestiaryDisplays.gridStats(monster),
            topTags: BestiaryDisplays.tagStats(monster),
            tabs: [
                {
                    name: "Lore",
                    md: BestiaryDisplays.loreMD(monster),
                },
                {
                    name: "Actions & Abilities",
                    md: BestiaryDisplays.actionsMD(monster),
                },
                {
                    name: "Other",
                    gridStats: BestiaryDisplays.otherGridStats(monster),
                    md: BestiaryDisplays.otherMD(monster),
                }
            ],

            searchTerm: BestiaryDisplays.searchTerm(monster),
            links: monster.links,
        }
    }
}

function stringMapToMD(map: StringMap) {
    if (map == null) return '';
    let sections: string[] = [];
    for (let [key, value] of Object.entries(map)) {
        let valueComponents = value.split(',');
        let section = (valueComponents.length >= 2)
            ? `_${key}:_\n* ` + valueComponents.map(x => x.trim()).join('\n* ')
            : `_${key}:_ ${value}`;
        sections.push(section);
    }
    return sections.join('\n\n');
}

function castingEntriesToMD(map: {[key:string]: string[]}) {
    if (map == null) return '';
    let sections: string[] = [];
    for (let [key, valueComponents] of Object.entries(map)) {
        let section = (valueComponents.length >= 2)
            ? `_${key}:_\n* ` + valueComponents.map(x => x.trim()).join('\n* ')
            : `_${key}:_ ${valueComponents[0]}`;
        sections.push(section);
    }
    return sections.join('\n\n');
}

function listIfLong(field: string) {
    if (field.length < 40) return field;
    else {
        let split = Utils.splitNonGroup(field);
        if (split.length <= 1) return field;
        else return '\n* ' + split.join('\n* ')
    }
}

function joinStringLists(groups: string[][]) {
    if (groups == null) return '';
    return groups.map(x => format(x)).join('; or ');
}

function format(group: string[]) {
    if (group.length <= 2) return group.join(' and ');
    else {
        let pre = group.slice(0, -1);
        let last = group[group.length - 1];
        return pre.join(', ') + ', and ' + last;
    }
}

function joinSnippets(list: AbilitySnippet[]) {
    return list.map(x => snippetToString(x)).join(', ');
}

export function snippetToString(snippet: AbilitySnippet) {
    let str = snippet.name;
    if (snippet.magnitude) str += ` ${snippet.useMagnitudePrefix ? '+' : ''}${snippet.magnitude}`;
    if (snippet.notes) str = `${str} ${snippet.notes}`;

    return str;
}

function joinResistances(list: Resistance[]) {
    return list.map(x => resistanceToString(x)).join(', ');
}

export function resistanceToString(snippet: Resistance) {
    let str = snippet.name;
    if (snippet.magnitude) str += ` ${snippet.magnitude}`;
    if (snippet.notes) str += snippet.notes;

    return str;
}

export function drToString(dr: DR) {
    if (dr.name) console.warn('DR with name', dr);

    let str = dr.magnitude + '/';
    str += dr.overcome ?? '-';
    if (dr.notes) str += dr.notes;

    return str;
}

function joinSkills(entry: BestiaryEntry) {
    let skills = entry?.stats?.skills ?? {};
    let notes = entry?.statNotes?.skills ?? {};

    let list = [];
    for (let [k, v] of Object.entries(skills)) {
        let note = notes[k] ?? '';
        let name = Utils.titleCase(k);

        list.push(`${name} +${v} ${note}`.trim());
    }

    return list.join(', ');
}