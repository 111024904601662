import {Grant, GrantFactory} from '@/ts/api/grants/_index';

type GrantMap = {[key: string]: Grant};
type Dynamic = {[key: string]: any};

export class ConditionGrant extends Grant {
    grants: GrantMap;
    refName: string; // The reference name of the condition, to be used by other grants
    
    constructor(json: Dynamic) {
        super(json);

        this.grants = GrantFactory.initMap(json.grants);
        this.refName = json.refName ?? "";
    }
}