






















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import EnemyDisplay from '@/ui/Gamebooks/combat_components/EnemyDisplay.vue';

import { Block, RefBlock, RefTypes, Span } from '@/ts/gamebooks/parsing/Blocks';
import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { ImageDef, LinkDef } from '@/ts/gamebooks/models/GamebookData';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { EnemyInstance } from '@/ts/gamebooks/CombatSession';

var images = require.context('@/assets/gamebooks/', true, /\.((jpeg)|(jpg)|(png))$/);

@Component({
    components: {
        EnemyDisplay,
    }
})
export default class RefBlockDisplay extends Vue {
    @Prop({required: true})
    block!: RefBlock;

    @Prop({required: true})
    session!: GamebookSession;

    state = ''; // '', 'success', or 'fail'
    selectedTier: string|null = null;

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    get isImage() {
        return this.block.refType == RefTypes.Image;
    }

    get isEnemy() {
        return this.block.refType == RefTypes.Enemy;
    }

    get isRoll() {
        return this.block.refType == RefTypes.Roll;
    }

    get isInfo() {
        return this.block.refType == RefTypes.Info;
    }

    get isHandout() {
        return this.block.refType == RefTypes.Handout;
    }

    get imgUrl() {
        let meta = this.session.gamebook.meta;
        let ref = this.img;
        let id = meta.refId;
        return ref ? images(`./${id}/${ref.filename}`) : undefined;
    }

    get img(): ImageDef|undefined {
        return this.isImage ? this.gamebook.images[this.block.refId] : undefined;
    }

    get doc() {
        let id = this.block.refId;
        if (this.isHandout) return this.gamebook.handouts[id];
        else if (this.isInfo) return this.gamebook.infoDocs[id];
        else return undefined;
    }

    get roll() {
        return this.isRoll ? this.gamebook.rolls[this.block.refId] : undefined;
    }

    get isRollYesNo() {
        return this.roll ? (this.roll.name == '#yesno') : false;
    }

    get isTiered() {
        return this.tierKeys.length > 0;
    }

    get tierKeys() {
        return Object.keys(this.roll?.tieredBlocks ?? {});
    }

    get rollResultBlocks(): Block[] {
        if (this.isTiered) {
            if (!this.roll?.tieredBlocks || !this.selectedTier) return [];
            else return this.roll.tieredBlocks[this.selectedTier];
        }
        else if (this.state == 'success') {
            return this.roll?.successBlocks ?? [];
        }
        else if (this.state == 'fail') {
            return this.roll?.failBlocks ?? [];
        }
        else return [];
    }

    get enemyInstance() {
        let enemy = this.isEnemy ? this.gamebook.enemies[this.block.refId] : undefined;
        return (enemy == undefined) ? undefined : new EnemyInstance(enemy);
    }

    get enemyCombatLink() {
        return this.block.linkedCombatId;
    }

    onRollSuccess() {
        this.state = 'success';
    }

    onRollFail() {
        this.state = 'fail';
    }

    startCombat() {
        if (this.enemyCombatLink) this.session.setScene(this.enemyCombatLink);
    }
}
