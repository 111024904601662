












import { RoadmapEntry } from '@/ts/api/roadmap/RoadmapEntry';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import PhaseDisplay from '@/ui/_components/PhaseDisplay.vue';

@Component({
    components: {
        PhaseDisplay,
    }
})
export default class Roadmap extends Vue {
    get phases(): RoadmapEntry[] {
        return (this.$root as any).roadmapPhases.current;
    }

    get isLoading() {
        return (this.$root as any).loadingStatus.roadmapCurrent;
    }
}
