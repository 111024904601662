import ImageDetails from "../api/images/ImageDetails";
import Utils from "../util/Utils";
import { DescriptiveEntry } from "./DescriptiveEntry";

export class EntityLog {
    characters: Entity[] = [];
    locations: Entity[] = [];
    events: Entity[] = [];

    add(type: EntityType) {
        let entity = new Entity(type, `New ${Utils.titleCase(type)}`);
        this.getArray(type).push(entity);
        return entity;
    }

    removeIndex(type: EntityType, index: number) {
        let array = this.getArray(type);
        if (index < array.length) array.splice(index, 1);
    }

    clear() {
        // TODO
    }

    private getArray(type: EntityType) {
        if (type == EntityType.Character) return this.characters;
        else if (type == EntityType.Location) return this.locations;
        else if (type == EntityType.Event) return this.events;
        else {
            console.warn('Unexpected entity type: ' + type);
            return [];
        }
    }
}

export class Entity implements DescriptiveEntry {
    type: EntityType;
    name: string;

    image?: ImageDetails;
    shortDesc = '';
    longDesc = '';

    editing = false;

    constructor(type: EntityType, name: string) {
        this.type = type;
        this.name = name;
    }
}

export enum EntityType {
    Character = 'character',
    Location = 'location',
    Event = 'event',
}