

































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import LoreTemplate, { ConfigOption, ConfigSetting } from '@/ts/util/loregen/LoreTemplate';
import Toaster from '@/ts/ui_integrations/Toaster';
import LoreGen from '@/ts/util/loregen/LoreGen';
import Segment from '@/ts/util/loregen/Segment';
import Script from '@/ts/util/loregen/Script';
import Utils from '@/ts/util/Utils';

@Component({
    components: {
        Sidebar,
    }
})
export default class SimpleGenerators extends Vue {
    current: LoreTemplate|null = null;
    results: Segment[] = [];

    toaster = new Toaster(this.$toast.add);
    setting: ConfigSetting|null = null;

    overlayIndex = 1;

    get loreGen(): LoreGen {
        return (this.$root as any).loreGen;
    }

    get templates(): LoreTemplate[] {
        return this.loreGen.templates.filter(template => template.data[0].includes('$CARD'));
        // return this.loreGen.templates.filter(template => template.name == 'NPC');
    }

    get isLoading() {
        return false;
    }

    get settings() {
        return this.current?._settings;
    }

    mounted() {
        this.ensureSelection();
    }

    generate() {
        this.results = [];
        if (!this.current) {
        }
        else {
            this.results = this.loreGen.generate(this.current, 5);
        }
    }

    toggleExp(result: Segment) {
        Vue.set(result, '_exp', !(result._exp === true));
    }

    archive(result: Segment) {
        this.toaster.info('Coming Soon!')
    }

    edit(result: Segment) {
        this.toaster.info('Coming Soon!')
    }

    adjustSetting(setting: ConfigSetting) {
        this.toaster.info('Coming Soon!');
    }

    ensureSelection() {
        if (this.current == null && this.templates.length > 0) {
            this.current = this.templates[0];
        }
    }

    @Watch('current')
    onCurrentChanged() {
        this.ensureSelection();
        this.loreGen.populateSettings(this.current);
    }

    @Watch('templates')
    onTemplatesChanged() {
        this.ensureSelection();
    }

    toggle(event: any, setting: ConfigSetting) {
        let newIndex = this.overlayIndex == 2 ? 1 : 2;
        let oldOverlay: any = this.$refs[`op_${this.overlayIndex}`];
        let newOverlay: any = this.$refs[`op_${newIndex}`];

        if (Array.isArray(oldOverlay)) oldOverlay = oldOverlay[0];
        if (Array.isArray(newOverlay)) newOverlay = newOverlay[0];
        
        if (this.setting == setting) {
            oldOverlay.show(event);
        }
        else {
            oldOverlay?.hide();
            newOverlay.show(event);

            this.overlayIndex = newIndex;
            this.setting = setting;
        }
    }
}
