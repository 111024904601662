


















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Utils from "@/ts/util/Utils";

@Component
export default class Upcoming extends Vue {
    get message() {
        let status = (this as any).$route.query.status;
        return Utils.isEmpty(status) ? null : `This feature is ${status}!`;
    }
}
