



















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ButtonBlock, ConditionalBlock, Span } from '@/ts/gamebooks/parsing/Blocks';
import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import axios from 'axios';

@Component({
    components: {
    }
})
export default class NewsletterWidget extends Vue {
    @Prop({required: true})
    session!: GamebookSession;

    success = true;
    message = "";
    email = "";

    async signup(mobile: boolean) {
        let response = await axios.post('/api/newsletter', {email: this.email.trim(), mobile}, {validateStatus: null});
        if (response.status < 400) {
            this.message = `Added '${this.email.trim()}' to the mailing list!`;
            this.success = true;
        }
        else {
            this.message = `Unable to add '${this.email}' to the mailing list.`;
            this.success = false;
        }
    }

    onInput(event: any, mobile: boolean) {
        if (event.keyCode == 13) {
            // Enter Pressed
            this.signup(mobile);
        }
    }

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }
}
