import BestiaryEntry from '@/ts/api/bestiary/BestiaryEntry';
import Toaster from '@/ts/ui_integrations/Toaster';
import SanityCheck from '@/ts/util/SanityCheck';
import Randomizer from "@/ts/util/Randomizer";
import CRS from '@/ts/util/pfmath/CRS';
import { MultiSelectionSetting, NumberSetting, RangeSetting, SelectionOption, SelectionSetting } from '../util/config/Setting';
import { GtdRangeSnapshot, RangeSnapshot } from '../util/config/Snapshots';
import { CombatConfig, CombatConfigSnapshot } from './CombatConfig';

export default class CombatGenerator {
    config: CombatConfig;
    random: Randomizer;
    toaster?: Toaster;

    constructor(config: CombatConfig, seed="", toaster?: Toaster) {
        this.random = new Randomizer(seed);
        this.config = config;
        this.toaster = toaster;
    }

    generate(): Combat {
        let config = this.config.getSnapshot();
        let bestiary = this.config.filteredBestiary;
        let possibleDiversities = config.diversity.length == 0 ? [1, 2, 3, 4, 5, 6] : config.diversity.flatMap(x => (x == '4+') ? [4, 5, 6] : parseInt(x));

        let cr = this.getCR();
        let xpIndex = cr + 4;
        if (cr < 1) {
            const crs = [1/8, 1/6, 1/4, 1/3, 1/2];
            xpIndex = (cr == 0) ? this.random.int(5) : crs.indexOf(cr);
            console.assert(xpIndex >= 0, 'Expected xpIndex to be non-negative', {cr, xpIndex});
            if (cr == 0) cr = crs[xpIndex];
        }
        let xpBudget = CRS.AllXPS[xpIndex];
        let numEnemies = this.getNumEnemies(possibleDiversities);

        let options = CRS.getCombinations(numEnemies, xpBudget);
        let components = [] as CombatComponent[];
        if (options.length == 0) {
            // TODO: If CR is a range, try autocorrecting
            console.warn(`Enemy count of [${numEnemies}] is too restrictive for xpBudget [${xpBudget}]`);
        }
        else {
            // Filter for diversity setting
            let filter = options;
            let diversity = 1;
            do {
                diversity = SanityCheck.notNull(this.random.selection(possibleDiversities));
                possibleDiversities = possibleDiversities.filter(x => x != diversity);
                filter = options.filter(x => x.entries.length <= diversity && x.totalQTY >= diversity);
            } while(possibleDiversities.length > 0 && filter.length == 0); // Autocorrect if necessary
            
            let combination = this.random.selection(filter);
            if (combination == null) {
                // TODO: Attempt to correct for diversities other than 1 (e.g. if xpBudget or numEnemies can be changed)
                console.warn(`Diversity setting [${config.diversity.join(',')}] is too restrictive for xpBudget [${xpBudget}] and enemyCount [${numEnemies}]`);
                combination = SanityCheck.notNull(this.random.selection(options));
                diversity = combination.entries.length;
            }
            
            SanityCheck.warnIf(diversity < combination.entries.length, 'Unexpected generator state: diversity is less than creature count.', {combination, diversity, config, rootConfig: this.config});
            if (diversity > combination.entries.length) {
                combination = combination.clone();
                let numSplits = diversity - combination.entries.length;
                let singles = combination.entries.filter(x => x.qty <= 1);
                let splitTargets = combination.entries.filter(x => x.qty > 1);
                for (let i = 0; i < numSplits; i += 1) {
                    this.random.shuffle(splitTargets);
                    let target = SanityCheck.notNull(splitTargets.pop());
                    let qty1 = this.random.int(1, target.qty - 1);
                    let qty2 = target.qty - qty1;
                    let target2 = target.clone();
                    target.qty = qty1;
                    target2.qty = qty2;

                    if (qty1 == 1) singles.push(target);
                    else splitTargets.push(target);
                    if (qty2 == 1) singles.push(target2);
                    else splitTargets.push(target2);
                }

                combination.entries = singles.concat(splitTargets);
            }
            
            for (let entry of combination.entries) {
                // TODO: Account for entry.qty and ecology
                let monster = this.random.selection(bestiary.filter(x => x.xp == entry.xp));

                if (monster != null) components.push(new CombatComponent(entry.qty, monster));
                else console.warn(`Unable to find monster matching entry`, entry, this);
            }
        }

        return new Combat(components, cr, xpBudget);
    }

    getCR() {
        if (this.config.isAbsolute) {
            return this.config.crRange.roll(this.random, [0, 50]);
        }
        else {
            let config = this.config.getSnapshot();
            let dfcOptions = this.config.difficulties.options.map(x => x.key);
            let difficulty = this.random.selection(config.difficulties) ?? dfcOptions[1];
            let difficultyAdjustment = dfcOptions.indexOf(difficulty) - 1;
            let partySizeAdjustment = this.getPartySizeAdjustment();

            let apl = Math.max(config.apl, 0) + difficultyAdjustment + partySizeAdjustment;
            return Math.max(apl, 0);
        }
    }

    getPartySizeAdjustment(): number {
        let config = this.config.getSnapshot();
        let size = config.partySize;
        if (size == null) return 0;
        else if (size < 4) return -1;
        else if (size > 5) return 1;
        else return 0;
    }

    getNumEnemies(possibleDiversities: number[]) {
        let min = this.config.enemyCountRange.min ?? 1;
        let max = this.config.enemyCountRange.max ?? 16;

        // Whatever the diversity is, there has to be at least that many enemies.
        // If that's not possible (i.e. maxEnemies < minDiversity), ignore possibleDiversities. Otherwise, set the minimum numEnemies to the minDiversity.
        if (max >= Math.min(...possibleDiversities) && min < Math.min(...possibleDiversities)) {
            min = Math.min(...possibleDiversities);
        }

        let numEnemies = this.random.int(min, max);

        if (numEnemies == null) {
            console.log('Selecting a random number of enemies');
            let roll = this.random.int(1, 100);
            numEnemies = (roll <= 50) ? 1 : (roll <= 75) ? 2 : this.random.int(3, 16);
        }
        if (numEnemies > 16) {
            console.warn('Capping enemy count at 16', this.config.enemyCountRange);
            numEnemies = 16;
        }
        if (numEnemies <= 0) {
            numEnemies = 1;
        }

        if ((numEnemies % 2 == 1) && (numEnemies > 2) && possibleDiversities.length == 1 && possibleDiversities[0] == 1) {
            numEnemies -= 1;
        }

        return numEnemies;
    }
}

export class Combat {
    components: CombatComponent[];
    cr: number;
    xp: number;

    constructor(components: CombatComponent[], cr: number, xp: number) {
        this.components = components;
        this.cr = cr;
        this.xp = xp;
    }
    
    get fullName() {
        return this.components.map(x => `${x.element.name} x${x.qty}`).join(', ');
    }
}

export class CombatComponent {
    qty: number;
    element: BestiaryEntry;

    constructor(qty: number, element: BestiaryEntry) {
        this.qty = qty;
        this.element = element;
    }
}