





















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import { Gamebook, ParsedEnemy } from '@/ts/gamebooks/parsing/GamebookParser';
import { EnemyInstance } from '@/ts/gamebooks/CombatSession';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { ImageDef } from '@/ts/gamebooks/models/GamebookData';

var images = require.context('@/assets/gamebooks/', true, /\.((jpeg)|(jpg)|(png))$/);

@Component({
    components: {
    }
})
export default class EnemyDisplay extends Vue {
    @Prop({required: true})
    session!: GamebookSession;

    @Prop({required: true})
    enemyInstance!: EnemyInstance;

    @Prop({default: null})
    qty!: number|null;

    showStats = false;

    @Watch('currentHp')
    checkForDefeat() {
        if (this.currentHp <= 0) {
            // TODO: Check for user-setting "Auto Defeat"
            this.enemyInstance.isDefeated = true;
        }
    }

    get enemy(): ParsedEnemy {
        return this.enemyInstance.enemy;
    }

    get currentHp() {
        return this.enemyInstance.currentHp;
    }

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    get imgUrl() {
        let meta = this.session.gamebook.meta;
        let ref = this.img;
        let id = meta.refId;
        return ref ? images(`./${id}/${ref.filename}`) : undefined;
    }

    get img(): ImageDef|undefined {
        let refId = this.enemy.imageRef;
        return refId ? this.gamebook.images[refId] : undefined;
    }

    get healthbarWidth() {
        if (this.currentHp <= 0) return '0px';
        let max = this.enemy.hp;
        let ratio = this.currentHp / max;
        let percent = Math.round(ratio*100);
        if (percent < 0) percent = 0;
        if (percent > 100) percent = 100;
        return `${percent}%`;
    }

    get ltText() {
        return (this.currentHp < 0) ? '<-' : '';
    }

    get gtText() {
        let max = this.enemy.hp;
        return (this.currentHp > max) ? '+>' : '';
    }

    get hpHoverText() {
        let max = this.enemy.hp;
        let current = this.currentHp;
        return `HP:\n${current} / ${max}`
    }

    get acTooltip() {
        // TODO: Show touch & flat-footed AC
        return `AC: ${this.enemy.ac}`;
    }

    get title() {
        let list = [this.enemy.name];
        if (this.qty) list.push(`(x${this.qty})`);
        return list.join(' ');
    }

    get infoLine() {
        let x = this.enemy;
        return [x.alignment, x.size, x.type, `[AC ${x.ac}]`].filter(x => x != undefined).join(' ');
    }

    get specialDetails() {
        let details = this.enemy.specialDetails?.replace(/\n/g, '\n\n') ?? '';
        let attacks = Object.values(this.enemy.attacks ?? {}).filter(x => x.roll != null && x.roll.length > 0);

        let attackStrings = [] as string[];
        let attackNames = [] as string[];
        for (let attack of attacks) {
            if (attackNames.includes(attack.name)) continue;
            else {
                attackNames.push(attack.name);
                let damageType = `${attack.dmgType ?? ''} damage`.trim();
                if (attack.condition) damageType += ` & ${attack.condition}`;
                attackStrings.push(`**${attack.name}**: ${attack.roll ?? 'auto-hit'} (${attack.dmg} ${damageType}) / ${attack.range}`);
            }
        }
        return `${attackStrings.join('\n\n')}\n\n${details}`.trim();
    }

    openContextMenu(event: any) {
        let $this = this;
        let notepadItem = this.enemyInstance.showNotepad ? 
            {
                label: 'Hide Notepad',
                icon: 'pi pi-fw pi-eye-slash',
                command: () => {
                    $this.enemyInstance.showNotepad = false;
                }
            } :
            {
                label: 'Show Notepad',
                icon: 'pi pi-fw pi-eye',
                command: () => {
                    $this.enemyInstance.showNotepad = true;
                }
            };
        let distanceItem = this.enemyInstance.showDistanceTracker ? 
            {
                label: 'Hide Distance Tracker',
                icon: 'pi pi-fw pi-eye-slash',
                command: () => {
                    $this.enemyInstance.showDistanceTracker = false;
                }
            } :
            {
                label: 'Show Distance Tracker',
                icon: 'pi pi-fw pi-eye',
                command: () => {
                    $this.enemyInstance.showDistanceTracker = true;
                }
            };
        let toggleAll = (this.enemyInstance.showDistanceTracker && this.enemyInstance.showNotepad) ? 
            {
                label: 'Hide All',
                icon: 'pi pi-fw pi-eye-slash',
                command: () => {
                    $this.enemyInstance.showDistanceTracker = false;
                    $this.enemyInstance.showNotepad = false;
                }
            } :
            {
                label: 'Show All',
                icon: 'pi pi-fw pi-eye',
                command: () => {
                    $this.enemyInstance.showDistanceTracker = true;
                    $this.enemyInstance.showNotepad = true;
                }
            };
        let items = [
            {
                label: 'Show/Hide',
                items: [notepadItem, distanceItem, toggleAll]
            },
            {
                label: 'Move to Defeated',
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    $this.enemyInstance.isDefeated = true;
                }
            },
            {
                separator:true
            },
            {
                label: 'Modifiers',
                items: [
                    {
                        label: 'Advantage',
                        command: () => {
                            $this.enemyInstance.hasAdvantage = true;
                            $this.enemyInstance.hasDisadvantage = false;
                        },
                        icon: $this.enemyInstance.hasAdvantage ? 'pi pi-fw pi-check' : '',
                        style: $this.enemyInstance.hasAdvantage ? 'background-color: lightgray;' : '',
                    },
                    {
                        label: 'Disadvantage',
                        command: () => {
                            $this.enemyInstance.hasAdvantage = false;
                            $this.enemyInstance.hasDisadvantage = true;
                        },
                        icon: $this.enemyInstance.hasDisadvantage ? 'pi pi-fw pi-check' : '',
                        style: $this.enemyInstance.hasDisadvantage ? 'background-color: lightgray;' : '',
                    },
                    {
                        label: 'None',
                        command: () => {
                            $this.enemyInstance.hasAdvantage = false;
                            $this.enemyInstance.hasDisadvantage = false;
                        },
                        icon: ($this.enemyInstance.hasDisadvantage || $this.enemyInstance.hasAdvantage) ? '' : 'pi pi-fw pi-check',
                        style: ($this.enemyInstance.hasDisadvantage || $this.enemyInstance.hasAdvantage) ? '' : 'background-color: lightgray;',
                    },
                ]
            },
        ];
        (this as any).$toggleContextMenu(event, items);
    }
}
