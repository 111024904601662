import {Grant, GrantFactory} from '@/ts/api/grants/_index';

type GrantMap = {[key: string]: Grant};
type Dynamic = {[key: string]: any};

/** This is a group of grants to be used in conjunction with ChoiceGrant or ConditionGrant */
export class MultiGrant extends Grant {
    grants: GrantMap;
    
    constructor(json: Dynamic) {
        super(json);

        this.grants = GrantFactory.initMap(json.grants);
    }
}