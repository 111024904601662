// TODO: import pf1e_character_template from '@/data/pf1e_character_template.json';
import pointbuy_character_placeholder from '@/data/pointbuy_character_placeholder.json';
import {Info, InfoFactory} from '@/ts/api/info/_index';
import { ValueMode, ValueType } from '@/ts/api/characters/Value';
import Utils from '@/ts/util/Utils';
import Vue from 'vue';

type InfoMap = {[key:string]: Info};
type TemplateMap = {[key:string]: any};

export class InfoManager {
    private map: InfoMap = {};
    isLoaded = false;

    get<T extends Info>(path: string, suppressWarnings=false): T { // TODO: return T|undefined
        console.assert(this.isLoaded, `InfoManager.get(${path}) was called before initialization was complete`);
        let info = this.map[path];
        if (info == null && !suppressWarnings) {
            // TODO: If path references a level bonus (e.g. barbarian_level), it should return an Info object, and not result in a warning
            console.warn('Definition not found: ' + path);
        }

        return info as T;
    }

    async init() {
        /* TODO:
        for (let [refName, stat] of Object.entries(Utils.deepCopy(pf1e_character_template.stats))) {
            await this._init(stat, refName);
        }
        */
        for (let [refName, stat] of Object.entries(Utils.deepCopy(pointbuy_character_placeholder.stats))) {
            await this._init(stat, refName);
        }

        // TODO: Replace this with 'additional_templates' in pf1e_character_template
        for (let refName of ['acid_resistance', 'cold_resistance', 'electricity_resistance', 'fire_resistance', 'darkvision']) {
            let template = {
                _template: {
                    refName: refName,
                    shortName: Utils.titleCase(refName.replace('_', ' ')),
                    defaultValueType: ValueType.INT,
                    groupName: (refName == 'darkvision') ? 'Senses' : 'Resistances',
                    usePrefix: false
                }
            };
            if (refName == 'darkvision') (template._template as any).unit = 'ft';

            await this._init(template, refName);
        }
        for (let refName of ['str', 'dex', 'con', 'int', 'wis', 'cha']) {
            let template = {
                _template: {
                    refName: 'racial',
                    shortName: `Race Bonus to ${Utils.titleCase(refName)}`,
                    defaultValueType: ValueType.INT,
                    defaultValueMode: ValueMode.DIRECT,
                    usePrefix: true
                }
            };

            await this._init(template, `${refName}.racial`);
        }

        this.isLoaded = true;
    }

    async _init(stat: any, path: string, infoType?: string) {
        let infoTemplate: any = stat._template;
        if (infoTemplate) {
            console.assert(!infoTemplate.infoType, stat);
            infoTemplate.infoType = infoType ?? (infoTemplate.groupName == 'Skill') ? 'skill' : 'stat';

            if (Utils.isEmpty(infoTemplate.refName)) {
                let split = path.split('.');
                infoTemplate.refName = split.pop();
            }
    
            Vue.set(this.map, path, InfoFactory.new(infoTemplate, path));
            let info = this.map[path];
    
            try {
                let fileName = Utils.isEmpty(info.mdRef) ? `stats/_${path}` : info.mdRef;
                let md = (await import(`@/data/rules/${fileName}.md`)).default;
                Vue.set(info, 'longDescription', md);
            }
            catch {
                // console.warn('MD not found: ' + path);
            }
        }
        else {
            console.warn('Info template not found for ' + path);
        }

        let derivations: TemplateMap = stat.derivations;
        let components: TemplateMap = stat.components;
        let bonuses: TemplateMap = stat.bonuses;

        for (let children of [derivations, components]) {
            if (children == null) continue;
            for (let [refName, stat] of Object.entries(children)) {
                await this._init(stat, `${path}.${refName}`);
            }
        }
        if (bonuses != null) {
            for (let [refName, bonus] of Object.entries(bonuses)) {
                await this._init(bonus, `${path}.${refName}`, 'bonus');
            }
        }
    }
}