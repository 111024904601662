































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { RoadmapEntry, RoadmapEntryTypes } from '@/ts/api/roadmap/RoadmapEntry'
import { RoadmapDAO } from '@/ts/api/roadmap/RoadmapDAO'
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';
import PhaseDisplay from '@/ui/_components/PhaseDisplay.vue';
import Toaster from '@/ts/ui_integrations/Toaster';
import Utils from '@/ts/util/Utils';

@Component({
    components: {
        PhaseDisplay,
        Sidebar,
    }
})
export default class RoadmapCMS extends Vue {
    current: RoadmapEntry|null = null;

    toaster = new Toaster(this.$toast.add);
    contextItems: any[] = [];
    contextIndex = 1;
    authInput = "";

    selectionKeys = {} as any;
    expandedKeys = {} as any;
    displayModal = false;
    showComplete = true;

    iconNames = ['None', 'Encounter Gen', 'Shop Gen', 'Item Search', 'Monster Search', 'Spell Search', 'Other'];

    @Watch('current')
    scrollCenter() {
        this.$nextTick(() => {
            let pageFrame: any = this.$refs.pageFrame;
            let scrollPane: any = pageFrame.$refs.scrollPane;
            scrollPane.scrollTop = 0;
        });
    }

    get childTitle() {
        if (this.current?.type == RoadmapEntryTypes.Phase) return 'Releases';
        if (this.current?.type == RoadmapEntryTypes.Release) return 'Features';
        else return 'Children';
    }

    get isFeature() {
        return this.current?.type == RoadmapEntryTypes.Feature;
    }

    get isPhase() {
        return this.current?.type == RoadmapEntryTypes.Phase;
    }

    nodeSelected(node: {data: RoadmapEntry}) {
        this.current = node.data;
    }

    canHaveChildren(entry: RoadmapEntry) {
        return entry.type != RoadmapEntryTypes.Feature;
    }

    newPhase() {
        this.phases.push(new RoadmapEntry('Phase X', RoadmapEntryTypes.Phase));
    }

    newRelease(parent: RoadmapEntry) {
        parent.children.push(new RoadmapEntry('X.X', RoadmapEntryTypes.Release));
        this.expandedKeys[parent.id] = true;
    }

    newFeature(parent: RoadmapEntry) {
        parent.children.push(new RoadmapEntry('Feature Name', RoadmapEntryTypes.Feature));
        this.expandedKeys[parent.id] = true;
    }

    newChild() {
        if (this.current == null) return;
        else if (this.current.type == RoadmapEntryTypes.Phase) this.newRelease(this.current);
        else if (this.current.type == RoadmapEntryTypes.Release) this.newFeature(this.current);
    }

    findParentList(targetId: string) {
        let listStack = [] as RoadmapEntry[][];
        let list = this.phases as RoadmapEntry[]|undefined;
        while (list) {
            if (list.some(x => x.id == targetId)) return list;

            let childLists = list.map(x => x.children);
            listStack.push(...childLists);
            list = listStack.shift();
        }

        return undefined;
    }

    deleteEntry(data: RoadmapEntry) {
        let id = data?.id;
        if (id == undefined) {
            // TODO: Warn
            return;
        }

        let list = this.findParentList(id);
        if (list == undefined) {
            // TODO: Warn
            return;
        }
        else {
            let index = Utils.indexOf(list, x => x.id == id);
            if (index < 0) {
                console.warn('Could not delete entry. Index = -1.');
                this.toaster.warn('Unable to delete entry.', 'Internal error. Try refreshing the page and trying again.');
                return;
            }

            let removed = list.splice(index, 1);
            let oldCurrent = this.current;
            let $this = this;

            setTimeout(() => {
                let oldId = oldCurrent?.id;
                let currentId = $this.current?.id;
                let removedId = removed[0]?.id;

                if (currentId == removedId) {
                    $this.current = (oldId == removedId) ? null : oldCurrent;
                }
            }, 1);
        }
    }

    save() {
        if (this.authInput?.trim().length > 0) {
            RoadmapDAO.patch(this.phases, this.authInput).then(x => {
                if (x.status == 204) {
                    this.toaster.success('Saved!');
                }
                else if (x.status == 200) {
                    this.toaster.success('Up to Date!');
                }
                else {
                    this.toaster.warn(`${x.status}`, x.statusText);
                }
            })
            .catch(x => {
                this.toaster.error('Save failed');
                console.error(x);
            });
        }
        else {
            this.toaster.warn('Enter Auth Code First')
        }
    }

    openTreeContextMenu(event: any, context?: RoadmapEntry) {
        let $this = this;
        let items = [
            {
                label: 'New Phase (in root)',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                    $this.newPhase();
                }
            },
        ];

        if (context != null) {
            if (context.type == RoadmapEntryTypes.Phase) {
                items.unshift(
                    {
                        label: `Release`,
                        icon: 'pi pi-fw pi-plus',
                        command: () => {
                            $this.newRelease(context);
                        }
                    }
                );
            }
            else if (context.type == RoadmapEntryTypes.Release) {
                items.unshift(
                    {
                        label: `Feature`,
                        icon: 'pi pi-fw pi-plus',
                        command: () => {
                            $this.newFeature(context);
                        }
                    }
                );
            }
        }

        this.toggleContextMenu(event, items);
    }

    toggleContextMenu(event: any, items: any[]) {
        let newIndex = this.contextIndex == 2 ? 1 : 2;
        let oldOverlay: any = this.$refs[`context_menu_${this.contextIndex}`];
        let newOverlay: any = this.$refs[`context_menu_${newIndex}`];

        if (Array.isArray(oldOverlay)) oldOverlay = oldOverlay[0];
        if (Array.isArray(newOverlay)) newOverlay = newOverlay[0];
        
        if (this.contextItems == items) {
            oldOverlay.show(event);
        }
        else {
            oldOverlay?.hide();
            newOverlay.show(event);

            this.contextIndex = newIndex;
            this.contextItems = items;
        }
    }

    get phases(): RoadmapEntry[] {
        return (this.$root as any).roadmapPhases.next;
    }

    get noPhases() {
        return this.phases.length == 0;
    }

    get isLoading() {
        return (this.$root as any).loadingStatus.roadmapNext;
    }

    get nodes() {
        return this.phases.map(x => node(x));
    }
}

function node(data: RoadmapEntry): any {
    return {
        key: data.id,
        label: data.name,
        data: data,
        children: data.children?.map(x => node(x)) ?? [],
        leaf: data.type == RoadmapEntryTypes.Feature || data.children.length == 0,
    };
}
