import Randomizer from "../Randomizer";
import { GtdRangeSnapshot, RangeSnapshot } from "./Snapshots";

export class SelectionOption {
    key: string;
    name: string;
    description: string; // Text used for hover context

    _value: any;

    constructor(key: string, name?: string, description='') {
        this.key = key;
        this.name = name ?? key;
        this.description = description;
    }

    get value(): any {
        return this._value ?? this.key;
    }

    setValue(value: any) {
        this._value = value;
    }
}

export abstract class Setting {
    abstract type: SettingTypes;

    description: string; // Text used for hover context
    name: string;

    constructor(name: string, description='') {
        this.name = name;
        this.description = description;
    }

    get isMultiSelectionSetting() { return this.type == SettingTypes.MultiSelection; }
    get isSelectionSetting() { return this.type == SettingTypes.Selection; }
    get isNumberSetting() { return this.type == SettingTypes.Number; }
    get isRangeSetting() { return this.type == SettingTypes.Range; }
    get isBoolSetting() { return this.type == SettingTypes.Bool; }
}

export class MultiSelectionSetting extends Setting {
    type = SettingTypes.MultiSelection;
    options: SelectionOption[];

    selected: SelectionOption[];
    compact = false;

    constructor(name: string, description: string, options: SelectionOption[]) {
        super(name, description);
        this.options = options;
        this.selected = [...options];
    }

    get selectedKeys() {
        return this.selected.map(x => x.key);
    }

    getSnapshot() {
        return this.selected.map(x => x.key);
    }

    setInitialKeys(keys: string[]) {
        this.selected = this.options.filter(x => keys.includes(x.key));
        return this;
    }

    setCompact(compact=true) {
        this.compact = compact;
        return this;
    }
}

export class SelectionSetting extends Setting {
    type = SettingTypes.Selection;
    options: SelectionOption[];

    selected: SelectionOption;

    constructor(name: string, description: string, options: SelectionOption[]) {
        super(name, description);
        this.options = options;
        this.selected = options[0];
    }

    get selectedKey() {
        return this.selected.key;
    }

    getSnapshot() {
        return this.selected.key;
    }

    setInitial(key: string) {
        let search = this.options.filter(x => x.key == key);
        if (search.length != 1) console.warn('Unknow option key: ' + key, this);
        else this.selected = search[0];

        return this;
    }
}

export class NumberSetting extends Setting {
    type = SettingTypes.Number;
    minBound?: number;
    maxBound?: number;

    defaultValue?: number; // If this is set, null/undedfined values will be treated as this value instead
    value?: number;

    integer = true;

    constructor(name: string, description: string, minBound?: number, maxBound?: number) {
        super(name, description);
        this.minBound = minBound;
        this.maxBound = maxBound;
    }

    getSnapshot() {
        // TODO: Assert range
        return this.value;
    }

    getGtdSnapshot(defaultValue?: number) {
        return this.getSnapshot() ?? defaultValue ?? this.defaultValue ?? 0;
    }

    setDefault(defaultValue: number) {
        if (this.value == undefined) this.value = defaultValue;
        
        this.defaultValue = defaultValue;
        return this;
    }

    setInitial(value: number) {
        this.value = value;
        return this;
    }

    decMode() {
        this.integer = false;
        return this;
    }
}

export class RangeSetting extends Setting {
    type = SettingTypes.Range;
    minBound?: number;
    maxBound?: number;

    min?: number;
    max?: number;

    constructor(name: string, description: string, minBound?: number, maxBound?: number) {
        super(name, description);
        this.minBound = minBound;
        this.maxBound = maxBound;
    }

    getSnapshot(): RangeSnapshot {
        return {
            min: this.min,
            max: this.max
        };
    }

    getGtdSnapshot(min?: number, max?: number): GtdRangeSnapshot {
        // TODO: Assert non-nulls
        return {
            min: this.min ?? min ?? 0,
            max: this.max ?? max ?? 0,
        };
    }

    setInitial(min?: number, max?: number) {
        this.min = min;
        this.max = max;
        return this;
    }

    roll(rnd: Randomizer, nullAdjustment=[0, 100], warnOnNull=true) {
        if (warnOnNull && (this.min == null || this.max == undefined)) console.warn('RangeSetting has undefined min/max value. Defaulting to 0/100', this);
        let [min, max] = [this.min??this.minBound??nullAdjustment[0], this.max??this.maxBound??nullAdjustment[1]];
        return rnd.int(min, max);
    }
}

export class BoolSetting extends Setting {
    type = SettingTypes.Bool;
    mode = BooleanMode.Binary;
    value: boolean|null;

    constructor(name: string, description: string, mode = BooleanMode.Binary) {
        super(name, description);
        this.mode = mode;
        this.value = (mode == BooleanMode.Binary) ? false : null;
    }

    setInitial(value: boolean) {
        this.value = value;
        return this;
    }

    get isBinary() {
        return this.mode == BooleanMode.Binary;
    }
}

export enum SettingTypes {
    MultiSelection,
    Selection,
    Number,
    Range,
    Bool,
}

export enum BooleanMode {
    TriState,
    Binary,
}