export class Format {
    static currency(amount: number, unit: string|null=null, useSpaces=false): string {
        let sep = useSpaces ? ' ' : '';
        if (unit == null) {
            return (amount < 0.01) ? `0${sep}gp` : Format.currency(amount, 'gp', useSpaces);
        }
        else if (amount < 0.01) {
            return '';
        }
        else if (unit == 'pp') {
            let primary = Math.floor(amount);
            let remainder = (amount-primary)*10;
            return `${primary}${sep}${unit} ` + Format.currency(remainder, 'gp', useSpaces);
        }
        else if (unit == 'gp') {
            let primary = Math.floor(amount);
            let remainder = (amount-primary)*10;
            return `${primary}${sep}${unit} ` + Format.currency(remainder, 'sp', useSpaces);
        }
        else if (unit == 'sp') {
            let primary = Math.floor(amount);
            let remainder = (amount-primary)*10;
            return `${primary}${sep}${unit} ` + Format.currency(remainder, 'cp', useSpaces);
        }
        else if (unit == 'cp') {
            let primary = Math.floor(amount);
            return `${primary}${sep}${unit} `;
        }
        else {
            console.warn('Error converting value to string', amount, unit);
            return `${amount} ${unit}`;
        }
    }
}