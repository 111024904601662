import {Grant} from '@/ts/api/grants/_index';

type Dynamic = {[key: string]: any};

// TODO: Replace Pool with Tracker + Bonus
export class PoolGrant extends Grant {
    usesRefName: string; // The name of the stat to represent the number of uses remaining (if multiple grants reference the same stat, they will share uses-per-day). This stat will always be manual-enty. If this is unset, the action will not have a limited number of uses.
    maxUsesRefName: string; // The name of the stat to represent the max uses (usually *per day*). If multiple grants reference the same stat, they will stack (depending on ruleset). If this is unset, the action will not have a max-uses stat.
    maxUsesEquation: string; // The number of uses (generally per day) granted by this grant. This equation will be added as a bonus to the stat defined by maxUsesRefName.

    constructor(json: Dynamic) {
        super(json);
        
        this.usesRefName = json.usesRefName ?? "";
        this.maxUsesRefName = json.maxUsesRefName ?? "";
        this.maxUsesEquation = json.maxUsesEquation ?? "";
    }
}