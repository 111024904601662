import {Info, BonusInfo, SkillInfo, StatInfo} from '@/ts/api/info/_index';
import SanityCheck from '@/ts/util/SanityCheck';

export class InfoFactory {
    static new(json: any, path: string): Info {
        let type = json.infoType;
        if (type == null) {
            console.warn('infoType not found. Defaulting to \'stat\'', json);
            json.type = type = 'stat';
        }

        let refName = json.refName;
        if (refName == null) {
            let split = path.split('.');
            json.refName = refName = split.pop();
        }

        if (type == 'stat') return check(new StatInfo(json, path), json);
        else if (type == 'bonus') return check(new BonusInfo(json, path), json);
        else if (type == 'skill') return check(new SkillInfo(json, path), json);
        else {
            console.warn('Unknown infoType: ' + type + '. Defaulting to \'stat\'');
            return check(new StatInfo(json, path), json);
        }
    }
}

function check<T>(info: T, json: any) {
    SanityCheck.objFieldCompare(info, json, 'Info');
    return info;
}