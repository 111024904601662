import { AttackDef, EventDef } from "@/ts/gamebooks/models/GamebookData";
import Randomizer from "../util/Randomizer";
import SanityCheck from "../util/SanityCheck";
import Utils from "../util/Utils";
import { Block } from "./parsing/Blocks";
import { GamebookParser, ParsedEnemy } from "./parsing/GamebookParser";
import { DiceRoll } from "./SceneLog";

export class CombatSession {
    private _playerTurn = 0;
    
    enemies: EnemyInstance[];
    events: EventInstance[];
    playerInitiative = 0;
    round = 1;
    turn = 0;
    
    attack: AttackDef|null = null;
    attackRoll: DiceRoll|null = null;
    damageRoll: DiceRoll|null = null;

    event: EventInstance|null = null;

    simulcap: number|null = null;
    enemyActionsThisRound = 0;

    constructor(enemies: ParsedEnemy[], events: EventDef[], simulcap?: number) {
        this.enemies = enemies.map(x => new EnemyInstance(x));
        this.events = events.map(x => new EventInstance(x));
        this.simulcap = simulcap ?? null;
    }

    get playerTurn(): number {
        return this._playerTurn;
    }

    set playerTurn(value: number) {
        this._playerTurn = value;
        this.updateAttackData();
    }

    private updateAttackData() {
        let active = this.currentEnemy;
        if (active) {
            this.enemyActionsThisRound += 1;
            if (this.simulcap && this.enemyActionsThisRound > this.simulcap) {
                // TODO: Support custom description text
                // TODO: If an enemy dies while acting, should they count towards the simulcap?
                this.attack = {
                    description: `The second ${active.name.toLowerCase()} is blocked behind their allies, and is unable to get a clean path to you.`,
                    name: 'Clamouring',
                    cooldown: 0,
                    enemyId: '',
                    range: '',
                    dmg: 0,
                };
                this.attackRoll = null;
                this.damageRoll = null;
            }
            else {
                this.attack = Randomizer.Global.selection(Object.values(active.enemy.attacks));
                if (this.attack?.roll) {
                    let rollString = this.attack.roll;
                    if (active.hasAdvantage || active.hasDisadvantage) {
                        let ext = active.hasAdvantage ? 'k' : 'kl';
                        if (/[2-9][0-9]*d20+/.test(rollString)) {
                            // TODO: support multi-advantage/disadvantage
                            console.warn('Skipping advantage/disadvantage application for enemy attack roll, because the attack roll already includes multiple dice', {active, rollString});
                        }
                        else {
                            rollString = rollString.replace(/1?d20/gi, `2d20${ext}1`);
                        }
                    }
    
                    this.attackRoll = new DiceRoll(rollString);
                }
                if (this.attack?.dmg) {
                    let isCrit = this.attackRoll?.diceResults.some(x => x[0] == x[1]) ?? false;
                    let rollString = `${this.attack.dmg}`;
                    if (isCrit) {
                        let matches = rollString.match(/[0-9]*d[0-9]+/g) ?? [];
                        if (matches.length > 0) {
                            rollString += ` + (${matches.join('+')})`;
                        }
                    }
                    this.damageRoll = new DiceRoll(rollString);
                }
            }
        }
        if (this.isPlayersTurn) {
            this.attack = null;
            this.attackRoll = null;
            this.damageRoll = null;
        }
    }

    nextTurn() {
        let maxTurn = this.enemies.length;
        this.turn += 1;
        if (this.turn > maxTurn) {
            this.turn = 0;
            this.round += 1;
            this.enemyActionsThisRound = 0;
        }
        
        if (this.currentEnemy?.isDefeated) this.nextTurn();
        else {
            this.updateAttackData();

            let session = this;
            if (this.event) {
                this.event.isConsumed = true;
            }
            this.events.forEach(x => x.test(session));

            // TODO: Check event.timing
            let applicableEvents = this.events.filter(x => x.conditionsMet && !x.isConsumed);
            this.event = applicableEvents.pop() ?? null;
        }
    }

    sortInitiatives() {
        this.enemies.sort((a, b) => b.initiative - a.initiative); // Sort highest-to-lowest
        this.enemyActionsThisRound = 0;
        this.turn = 0;
        for (let i = 0; i < this.enemies.length; i++) {
            if (this.playerInitiative >= this.enemies[i].initiative) {
                this.playerTurn = i;
                return;
            }
        }

        this.playerTurn = this.enemies.length;
    }

    resetTurnCounter() {
        this.enemyActionsThisRound = 0;
        this.round = 1;
        this.turn = 0;
        this.updateAttackData();
    }

    get isUnsortable() {
        return this.playerInitiative == 0 && this.enemies.every(x => x.initiative == 0);
    }

    get isPlayersTurn() {
        return this.turn == this.playerTurn;
    }

    get currentEnemy() {
        if (this.turn < this.playerTurn) {
            return this.enemies[this.turn];
        }
        else if (this.turn == this.playerTurn) {
            return undefined;
        }
        else {
            return this.enemies[this.turn - 1];
        }
    }
}

export class EnemyInstance {
    enemy: ParsedEnemy;
    currentHp: number;
    initiative: number;
    currentDistance: number;
    instanceNotes: string = "";
    showDistanceTracker = false;
    showNotepad = false;
    isDefeated = false;
    hasAdvantage = false;
    hasDisadvantage = false;
    nameOverride: string|null = null;

    constructor(enemy: ParsedEnemy) {
        this.enemy = enemy;
        this.initiative = 0;
        this.currentDistance = 0;
        this.currentHp = this.enemy.hp;
    }

    get name() {
        return this.nameOverride ?? this.enemy.name;
    }
}

export class EventInstance {
    event: EventDef;
    isConsumed = false;
    conditionsMet = false;
    choiceBlocks: Block[];

    constructor(event: EventDef) {
        this.event = event;
        this.choiceBlocks = GamebookParser.parseBlocks(event.choices);
    }

    test(session: CombatSession) {
        // TODO: Event trigger are currently hard coded. Replace this with a proper parser, and support for more conditions
        let ands = this.event.trigger.split('&&').map(x => x.trim());
        for (let condition of ands) {
            if (/^\#enemies == [0-9]+$/.test(condition)) {
                let numEnemies = session.enemies.filter(x => !x.isDefeated).length;
                let targetNumber = parseInt(SanityCheck.notNull(condition.split(' ').pop()));
                if (numEnemies != targetNumber) return false;
            }
            else if (/^\$enemy_health <= 50%$/.test(condition)) {
                let current = Utils.sum(session.enemies.filter(x => !x.isDefeated).map(x => x.currentHp));
                let max = Utils.sum(session.enemies.filter(x => !x.isDefeated).map(x => x.enemy.hp));
                if ((current/max) > 0.5) return false
            }
            else {
                console.warn('Unsupported condition found in event trigger', {condition, session, event: this.event});
                return false;
            }
        }

        this.conditionsMet = true;
        return true;
    }
}