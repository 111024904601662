import { CatFactory, ConfigMap, FilterConfig, FilterSpec, Option } from '@/ts/util/filters/_index';
import { text, cat, range } from '@/ts/util/filters/FilterFactory';
import BestiaryEntry, { BestiaryFields } from './BestiaryEntry';

import bestiaryFilterOptions from '@/data/filterspecs/bestiaryFilters';
type Key = string|number;

export default class BestiaryFilterFactory {
    static get(bestiary: BestiaryEntry[], useFilterCache=true): FilterSpec<BestiaryEntry> {
        let option = (key: string, name: string) => new Option(key, name, 0);
        let configs: ConfigMap<BestiaryEntry> = {
            'name': text<BestiaryEntry>('Name', entry => entry.name),
            'source': cat<BestiaryEntry>('Source', BestiaryFields.sourceNames),
            'cr': range<BestiaryEntry>('CR', entry => entry._cr),
            'alignment': cat<BestiaryEntry>('Alignment', BestiaryFields.alignments),
            'type': cat<BestiaryEntry>('Type', BestiaryFields.stripedTypes),
            'subtype': cat<BestiaryEntry>('Subtype', BestiaryFields.stripedSubtypes).useTitleCase().condense(),
            'environment': cat<BestiaryEntry>('Environment', entry => entry._environments).useTitleCase().condense()
                .setToggleGroups([
                    {
                        name: 'All',
                        description: 'Toggle all Environments',
                        getKeys(options: Option[]) {
                            return options.map(x => x.key);
                        }
                    },
                    {
                        name: 'Land Biome',
                        description: 'Toggle all land types, including desert, hills, jungles, etc.',
                        getKeys(options: Option[]) {
                            const landKeys: Key[] = ['any', 'coast', 'desert', 'forest', 'graveyards', 'hills', 'islands', 'jungle', 'land', 'mountains', 'plains', 'ruins', 'underground', 'urban', 'volcanic'];
                            return options.filter(x => landKeys.includes(x.key)).map(x => x.key);
                        }
                    },
                    {
                        name: 'Water Biome',
                        description: 'Toggle all water types, including lakes, rivers, etc.',
                        getKeys(options: Option[]) {
                            const waterKeys: Key[] = ['any', 'coast', 'freshwater', 'islands', 'lakes', 'marshes', 'ocean', 'river', 'swamp', 'water'];
                            return options.filter(x => waterKeys.includes(x.key)).map(x => x.key);
                        }
                    }
                ]),
            'group': cat<BestiaryEntry>('Group', entry => entry.groups).useTitleCase().advOnly().condense(),
            'location': cat<BestiaryEntry>('Location/Plane', entry => entry._locations).advOnly().condense(),
            'climate': cat<BestiaryEntry>('Climate', entry => entry._climates).useTitleCase().advOnly(),
            'speed': cat<BestiaryEntry>('Speed', BestiaryFields.speeds).hideAndOr().advOnly(),
            'move_types': cat<BestiaryEntry>('Movement Types', BestiaryFields.movementTypes).useTitleCase().advOnly(),
            'move_abilities': cat<BestiaryEntry>('Movement Abilities', BestiaryFields.stripedMovementAbilities).useTitleCase().advOnly().condense(),
            'size': cat<BestiaryEntry>('Size', entry => entry.size).useTitleCase().hideAndOr().advOnly(),
            'reach': cat<BestiaryEntry>('Reach', BestiaryFields.reachValues).advOnly(),
            'health_abilities': cat<BestiaryEntry>('Health Abilities', BestiaryFields.healthAbilitiesNames).advOnly().condense(),
            'hd_size': cat<BestiaryEntry>('HD Size', entry => entry.hd.match(/d(4|6|8|10|12)/g))
                .setOptions([4, 6, 8, 10, 12].map(x => option(`d${x}`, `d${x}`))).advOnly(),
            'hd': range<BestiaryEntry>('HD Count', entry => entry._hdCount).advOnly(),
            'hp': range<BestiaryEntry>('HP', entry => entry.stats.hp).advOnly(),
            'num_attacks': range<BestiaryEntry>('# of Attacks', BestiaryFields.numAttacks).advOnly(),
            'max_atk': range<BestiaryEntry>('Max ATK Bonus', BestiaryFields.maxAtk).advOnly(),
            'spells': cat<BestiaryEntry>('Spells & SLAs', BestiaryFields.spellAndSLANames).advOnly(),
            'defensive_abilities': cat<BestiaryEntry>('Defensive Abilities', BestiaryFields.defensiveAbilitiesNames).advOnly().condense(),
            'special_attacks': cat<BestiaryEntry>('Special Attacks', BestiaryFields.specialAttacksNames).advOnly().condense(),
            'special_abilities': cat<BestiaryEntry>('Special Abilities', BestiaryFields.specialAbilitiesNames).advOnly().condense(),
            'special_qualities': cat<BestiaryEntry>('Special Qualities', BestiaryFields.specialQualitiesNames).advOnly().condense(),
            'senses': cat<BestiaryEntry>('Senses', BestiaryFields.senseNames).advOnly().condense(),
            'feats': cat<BestiaryEntry>('Feats', BestiaryFields.stripedFeats).useTitleCase().advOnly(),
            'aura': cat<BestiaryEntry>('Aura', entry => (entry.auras ?? []).map(x => x.name.toLowerCase())).useTitleCase().advOnly().condense(),
            
            'cmb': range<BestiaryEntry>('CMB', entry => entry.stats?.cmb).advOnly().setGroup('Stats'),
            'init': range<BestiaryEntry>('Initiative', entry => entry.stats?.init).advOnly().setGroup('Stats'),
            'ac': range<BestiaryEntry>('AC', entry => entry.stats?.ac).advOnly().setGroup('Stats'),
            'ffac': range<BestiaryEntry>('Flat-Footed AC', entry => entry.stats?.ac_flat).advOnly().setGroup('Stats'),
            'tac': range<BestiaryEntry>('Touch AC', entry => entry.stats?.ac_touch).advOnly().setGroup('Stats'),
            'cmd': range<BestiaryEntry>('CMD', entry => entry.stats?.cmd).advOnly().setGroup('Stats'),
            'fort': range<BestiaryEntry>('Fort', entry => entry.stats?.fort).advOnly().setGroup('Stats'),
            'ref': range<BestiaryEntry>('Ref', entry => entry.stats?.ref).advOnly().setGroup('Stats'),
            'will': range<BestiaryEntry>('Will', entry => entry.stats?.will).advOnly().setGroup('Stats'),
            'sr': range<BestiaryEntry>('SR', entry => entry.stats?.sr).advOnly().setGroup('Stats'),
            'str': range<BestiaryEntry>('Str', entry => entry.stats?.str).advOnly().setGroup('Stats'),
            'dex': range<BestiaryEntry>('Dex', entry => entry.stats?.dex).advOnly().setGroup('Stats'),
            'con': range<BestiaryEntry>('Con', entry => entry.stats?.con).advOnly().setGroup('Stats'),
            'int': range<BestiaryEntry>('Int', entry => entry.stats?.int).advOnly().setGroup('Stats'),
            'wis': range<BestiaryEntry>('Wis', entry => entry.stats?.wis).advOnly().setGroup('Stats'),
            'cha': range<BestiaryEntry>('Cha', entry => entry.stats?.cha).advOnly().setGroup('Stats'),
            'ecology': text<BestiaryEntry>('Ecology', entry => entry.ecology).advOnly().setGroup('Text Fields'),
            'race_class': text<BestiaryEntry>('Race/Class', entry => entry.race_class).advOnly().setGroup('Text Fields'),
            'organization': text<BestiaryEntry>('Organization', entry => entry.organization).advOnly().setGroup('Text Fields'),
            'treasure': text<BestiaryEntry>('Treasure', entry => entry.treasure).advOnly().setGroup('Text Fields'),
            'physicalDescription': text<BestiaryEntry>('Physical Description', entry => entry.physicalDescription).advOnly().setGroup('Text Fields'),
            'description': text<BestiaryEntry>('General Description', entry => entry.description).advOnly().setGroup('Text Fields'),
            'habitatAndSociety': text<BestiaryEntry>('Habitat & Society', entry => entry.habitatAndSociety).advOnly().setGroup('Text Fields'),
        };
        let tabs = [
            {
                tabName: 'Sources & Difficulty',
                groups: [
                    {
                        groupName: 'Sources',
                        contents: ['source']
                    },
                    {
                        groupName: 'Health & CR',
                        contents: [['cr', 'hp', 'hd'], 'hd_size']
                    },
                    {
                        groupName: 'Offense',
                        contents: [['num_attacks', 'max_atk', 'cmb', 'init']]
                    },
                    {
                        groupName: 'Defense',
                        contents: [['ac', 'ffac', 'tac', 'cmd'], ['fort', 'ref', 'will', 'sr']]
                    },
                    {
                        groupName: 'Ability Scores',
                        contents: [['str', 'dex', 'con'], ['int', 'wis', 'cha']]
                    },
                    {
                        groupName: 'Magic',
                        contents: ['spells']
                    },
                ]
            },
            {
                tabName: 'More Filters',
                groups: [
                    {
                        groupName: 'Habitat',
                        contents: ['environment', 'location', 'climate']
                    },
                    {
                        groupName: 'Classification',
                        contents: ['type', 'subtype', 'alignment', 'group']
                    },
                    {
                        groupName: 'Movement',
                        contents: ['move_types', 'move_abilities', 'speed']
                    },
                    {
                        groupName: 'Other',
                        contents: ['size', 'reach', 'aura', 'senses', 'feats']
                    },
                ]
            },
        ];
    
        FilterConfig.initConfigMap(configs);
        let preload = useFilterCache ? bestiaryFilterOptions : undefined;
        CatFactory.hydrateAll(configs, bestiary, preload);
        return {configs, layout: {tabs}};
    }
}