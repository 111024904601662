import {Grant} from '@/ts/api/grants/_index';

type Dynamic = {[key: string]: any};

export class CastingGrant extends Grant {
    spellLists: string[]; // Each entry is the name of a spell list (e.g. 'wizard' or 'cleric') // TODO: Support selector statements (e.g. WHERE x.spellLevel < 6)
    progression: string; // full, 3/4, 1/2
    castingStat: string; // refName of a stat (e.g. wis, int, cha)
    castingType: string; // arcane, divine, phycic
    castingMechanic: string; // prepared, spontaneous
    
    zeroth: string; // none, cantrips, orisons, other
    spellsKnown: string; // all, limited, spellbook

    constructor(json: Dynamic) {
        super(json);
        
        this.spellLists = json.spellLists ?? [];

        this.zeroth = json.zeroth ?? "";
        this.spellsKnown = json.spellsKnown ?? "";
        this.progression = json.progression ?? "";
        this.castingStat = json.castingStat ?? "";
        this.castingType = json.castingType ?? "";
        this.castingMechanic = json.castingMechanic ?? "";
    }
}