



























































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import RerollIcon from '@/ui/_components/icons/RerollIcon.vue';
import DataCard from '@/ui/DataCards/DataCard.vue';

import BestiaryEntry, { BestiaryDisplays } from "../../ts/api/bestiary/BestiaryEntry";
import {CombatComponent, Combat} from '@/ts/generators/CombatGenerator';
import { Loot } from '@/ts/generators/Loot';
import { Format } from '@/ts/util/Format';
import Toaster from '@/ts/ui_integrations/Toaster';
import CRS from '@/ts/util/pfmath/CRS';
import Randomizer from '@/ts/util/Randomizer';
import ItemEntry, { ItemFields } from '@/ts/api/items/ItemEntry';
import { CardConfig } from '../DataCards/CardConfig';

@Component({
    components: {
        RerollIcon,
        DataCard
    }
})
export default class EncounterView extends Vue {
    @Prop({required: true})
    combat!: Combat;
    
    @Prop({required: true})
    loot!: Loot;
    
    @Prop({required: true})
    filteredBestiary!: BestiaryEntry[];
    
    @Prop({required: true})
    toggle!: any;

    toaster = new Toaster(this.$toast.add);
    expandedRows = [] as any[];
    customize = false;

    random = new Randomizer();
    
    get lootTables() {
        return (this.$root as any).lootTables;
    }

    get coinString(): string {
        let coins = this.loot.coins;
        let strs = [`${coins.pp}pp`, `${coins.gp}gp`, `${coins.sp}sp`, `${coins.cp}cp`].filter(x => /^[1-9]/g.test(x));
        let concat = strs.join(', ').trim();
        if (concat.length > 0) return `Coins: ${concat}`;
        else return concat;
    }

    get items() {
        return this.loot.items;
    }

    get parchments() {
        return ['parchment-a', 'parchment-b', 'parchment-c', 'parchment-d', 'parchment-e'];
    }

    get lootMaxHeight() {
        let len = this.combat?.components?.length ?? 0;
        if (len <= 2) return 405;
        else if (len <= 4) return 843;
        else return 1281;
    }

    valueString(arg: number|ItemEntry) {
        if (typeof arg == 'number') return Format.currency(arg).trim().replace(/^0[pgsc]p/, '').replace(/ 0[pgsc]p/, '').replace(/  /, ' ').trim();
        else return Format.currency(ItemFields.parsePrice(arg)).trim().replace(/^0[pgsc]p/, '').replace(/ 0[pgsc]p/, '').replace(/  /, ' ').trim();
    }

    isIntelligent(item: ItemEntry) {
        return ItemFields.isIntelligent(item);
    }

    soon() {
        this.toaster.info('This feature is Coming Soon!');
    }

    crDisplay(cr: number): string {
        return CRS.getCRString(cr);
    }

    removeMonster(slotProps: {index: number, data: CombatComponent}) {
        this.combat.components.splice(slotProps.index, 1)
    }

    rerollMonster(slotProps: {index: number, data: CombatComponent}) {
        let current = slotProps.data.element;
        let candidates = this.filteredBestiary.filter(x => x.xp == current.xp && x.id != current.id);
        let newEntry = this.random.selection(candidates);
        if (newEntry) {
            slotProps.data.element = newEntry;
        }
        else {
            this.toaster.warn('Filters too strict', 'The only monster with this CR that matches your current filters is already selected. Use less strict filters, or reroll all enemies with the Reroll button on the combat card.')
        }
    }

    getMonsterConfig(monster: BestiaryEntry): CardConfig {
        return BestiaryDisplays.getCardConfig(monster);
    }

    get lootClipboard() {
        let $this = this;
        let coinLine = this.coinString;
        let itemLines = this.items.map(x => `${x.name} [${$this.valueString(x)}]`);
        let lastLine = '\nTotal Value:' + this.valueString(this.loot.totalValue);

        let ret = [coinLine, ...itemLines, lastLine].join('\n');
        return ret;
    }

    @Watch('combat.components', {deep: true})
    updateCR_XP() {
        let xp = 0;
        this.combat.components.forEach(x => xp += (x.element.xp ?? 0) * x.qty);
        this.combat.xp = xp;
        this.combat.cr = CRS.getCRFromXP(xp);
    }
}
