

































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { MultiSelectionSetting } from '@/ts/util/config/Setting';

@Component
export default class MultiSelectionSettingPanel extends Vue {
    @Prop({required: true})
    setting!: MultiSelectionSetting;
}
