type Key = string|number;

export class Option {
    key: Key;
    name: string;
    count: number;
    description: string; // Text used for hover context

    constructor(key: Key, name: string, count: number, description='') {
        this.key = key;
        this.name = name;
        this.count = count;
        this.description = description;
    }
}