import {RacialTraitTemplate, ClassFeatureTemplate, ContentTemplate, ClassTemplate, AppliedContent, FeatTemplate, RaceTemplate, AppliedClass} from '@/ts/api/content/_index';
import SanityCheck from '@/ts/util/SanityCheck';
import DataUtils from '@/ts/util/DataUtils';
import Utils from '@/ts/util/Utils';

type Dynamic = {[key: string]: any};

export class ContentFactory {
    static createTemplate(json: Dynamic): ContentTemplate {
        let constructors: {[key: string]: (x: Dynamic) => ContentTemplate} = {
            'race': x => new RaceTemplate(x),
            'class': x => new ClassTemplate(x),
            'feat': x => new FeatTemplate(x),
            'racialTrait': x => new RacialTraitTemplate(x),
            'classFeature': x => new ClassFeatureTemplate(x),
            'manual': x => new ContentTemplate(x)
        };

        let type = json.contentType;
        if (type != null && type in constructors) {
            return check(constructors[type](json), json);
        }
        else {
            console.warn(`Unknown content type: ${type}`, json);
            return check(new ContentTemplate(json), json);
        }
    }

    static createInstance(template: ContentTemplate|Dynamic, sourceId: string, suppressWarnings=false): AppliedContent {
        let copy = Utils.deepCopy(template);
        if (!Utils.isEmpty(copy.instanceId)) {
            if (!suppressWarnings) console.warn('Unexpected instanceId during AppliedContent instantiation', template, copy, copy.instanceId);
        }
        else {
            copy.instanceId = Utils.instanceId();
        }
        if (!Utils.isEmpty(copy.sourceId)) {
            if (!suppressWarnings) console.warn('Unexpected sourceId during AppliedContent instantiation', template, copy, copy.sourceId);
        }
        else {
            copy.sourceId = sourceId;
        }
        
        let constructors: {[key: string]: (x: Dynamic) => AppliedContent} = {
            'race': x => new AppliedContent(x),
            'class': x => new AppliedClass(x),
            'feat': x => new AppliedContent(x),
            'racialTrait': x => new AppliedContent(x),
            'classFeature': x => new AppliedContent(x),
            'manual': x => new AppliedContent(x)
        };

        let type = template.contentType;
        if (type != null && type in constructors) {
            let content = constructors[type](copy);
            return (template instanceof ContentTemplate) ? content : check(content, template);
        }
        else {
            console.warn(`Unknown content type: ${type}`, copy);
            let content = new AppliedContent(copy);
            return (template instanceof ContentTemplate) ? content : check(content, template);
        }
    }

    static getManualTemplate() {
        return ContentFactory.createTemplate({name: 'Manual Entry', contentType: 'manual'});
    }

    static initTemplateMap(obj: any): {[key: string]: ContentTemplate} {
        return DataUtils.initMap(obj, 'templateId', x => ContentFactory.createTemplate(x), 'ContentTemplate', false);
    }

    static initAppliedMap(obj: any): {[key: string]: AppliedContent} {
        return DataUtils.initMap(obj, 'instanceId', x => ContentFactory.createInstance(x, "", true), 'AppliedContent', false);
    }

    static setContentTypes(children: {[key: string]: ContentTemplate}, typeHierarchy: string[]) {
        if (children == null || typeHierarchy.length == 0) return;
        let type = SanityCheck.guaranteeShift(typeHierarchy.shift());
        for (let key of Object.keys(children)) {
            let child = children[key];
            if (!Utils.isEmpty(child.contentType) && child.contentType != type) {
                console.warn('Setting contentType on child with existing content type.', {child, type});
            }
            child.contentType = type;
            ContentFactory.setContentTypes(child.children, [...typeHierarchy]);
        }
    }
}

function check<T>(content: T, json: any) {
    SanityCheck.objFieldCompare(content, json, 'Content');
    return content;
}