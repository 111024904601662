import Parser, { ParseToken } from "./parser";
import Lexer, { LexToken } from "./lexer";
import Utils from '@/ts/util/Utils';
type Token = (LexToken|ParseToken);

function children(token: Token): ParseToken[] {
    if (token instanceof ParseToken) {
        return token.children;
    }
    else {
        console.warn('Parser searched for children on LexToken', token);
        return [];
    }
}

function convert(...tokens: Token[]): ParseToken[] {
    return tokens as ParseToken[];
}

export interface EvalContext {
    functions: {[key:string]:(children: ParseToken[], context: EvalContext) => number|string|Array<number|string>};
    variables: {[key:string]:number|string|Array<number|string>};
}

export default class MythosParser {
    parser: Parser;

    constructor() {
        let lexer = new Lexer();
        lexer.add("string_literal", /'[^']*'/g);
        lexer.add("group_open", /[\(\[\{]/g);
        lexer.add("group_close", /[\)\]\}]/g);
        lexer.add("identifier", /[a-zA-Z_][a-zA-Z0-9_]*/g);
        lexer.add("number", /[0-9]+/g);
        lexer.add("pls", /\+/g);
        lexer.add("min", /-/g);
        lexer.add("mul", /\*/g);
        lexer.add("div", /\//g);
        lexer.add("exp", /\^/g);
        lexer.add(":", /:/g);
        lexer.add(".", /\./g);

        // Workaround until Safari supports negative lookbehind
        lexer.post_process = (tokens: LexToken[]) => {
            const lookbehind = ['group_open', 'pls', 'min', 'mul', 'div', 'exp', null];
            let prev: LexToken|null = null;

            for (let token of tokens) {
                if (token.token_type == 'min' && (prev == null || lookbehind.includes(prev.token_type))) {
                    token.token_type = 'neg';
                }
                prev = token;
            }

            return tokens;
        };

        let parser = new Parser(lexer);
        parser.define("FUNCTION", /identifier GROUP\(/g,
                        (args:Token[]) => [children(args[1]), args[0].text],
                        this.evalFunction,
                        /*priority=*/11);
        parser.define("STRING", /string_literal/g,
                        (args:Token[]) => [[], Utils.trim(args[0].text, "'")],
                        (token, context) => token.text,
                        /*priority=*/10);
        parser.define("VARIABLE", /identifier( (\.|:) identifier)*/g,
                        (args:Token[]) => [[], args.map(x => x.text).join('')],
                        this.evalVariable,
                        /*priority=*/10);
        parser.define("NUMBER", /number/g,
                        (args:Token[]) => [[], args[0].text],
                        (token, context) => parseInt(token.text, 10),
                        /*priority=*/10);
        parser.define("EVAL", /(GROUP[\(\[\{]?|VARIABLE|NUMBER|NEGATIVE|OPERATION|FUNCTION)/g,
                        (args:Token[]) => [convert(args[0]), ''],
                        (token, context) => token.children[0].evaluate(context),
                        /*priority=*/10);
        parser.define("NEGATIVE", /neg EVAL/g, /* Lookbehind not supported by Safari: /((?<=pls |min |mul |div |exp )min EVAL)|((?<=^)min EVAL)/g */
                        (args:Token[]) => [convert(args[1]), ''],
                        (token, context) => -1 * token.children[0].evaluate(context),
                        /*priority=*/5);
        parser.define("OPERATION", /EVAL (pls|min) EVAL/g,
                        (args:Token[]) => [convert(args[0], args[2]), args[1].text],
                        this.eval_operation,
                        /*priority=*/1);
        parser.define("OPERATION", /EVAL (mul|div) EVAL/g,
                        (args:Token[]) => [convert(args[0], args[2]), args[1].text],
                        this.eval_operation,
                        /*priority=*/2);
        parser.define("OPERATION", /EVAL exp EVAL/g,
                        (args:Token[]) => [convert(args[0], args[2]), args[1].text],
                        this.eval_operation,
                        /*priority=*/3);

        this.parser = parser;
    }

    eval_operation(token: ParseToken, context: any): any {
        let op = token.text;
        let left = token.children[0].evaluate(context);
        let right = token.children[1].evaluate(context);

        if (op == '+') return left + right;
        else if (op == '-') return left - right;
        else if (op == '/') return left / right;
        else if (op == '*') return left * right;
        else if (op == '^') return Math.pow(left, right);
        else throw "Unknown operator: " + op;
    }

    evalVariable(token: ParseToken, context: EvalContext): any {
        // TODO: There are multiple ways to reference a nested stat/bonus - all aliases need to be covered in variabled OR we need to do this a different way
        let refName = token.text;
        let value = context.variables[refName];
        if (value == null) {
            console.warn('Could not evaluate variable: ' + refName);
            return 0;
        }
        else return value;
    }

    evalFunction(token: ParseToken, context: EvalContext): any {
        let refName = token.text;
        let func = context.functions[refName];
        // TODO: If func is null, try to determine the correct return type
        if (func == null) {
            console.warn('Could not evaluate function: ' + refName);
            return 0;
        }
        else return func(token.children, context);
    }

    parse(string: string): ParseToken {
        let results = this.parser.parse(string);
        if (results.length == 0) throw {message: `Could not parse expression. No tokens found.`, results: results, input: string};
        else if (results.length > 1) throw {message: `Could not parse expression. Too many tokens found.`, results: results, input: string};
        else if (results[0] instanceof ParseToken) return results[0];
        else throw {message: `Could not parse expression. Expected ParseToken but found another type.`, results: results, input: string};
    }
}