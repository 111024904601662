













import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Filter } from "@/ts/util/filters/_index";

@Component
export default class BoolFilterPanel extends Vue {
    @Prop({required: true})
    filter!: Filter<any>;

    get isTriState() {
        return this.filter.isTriState;
    }
}
