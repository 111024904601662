import axios, { AxiosResponse } from 'axios';
import SpellEntry from '@/ts/api/spells/SpellEntry';
import d20sourceMap from '@/data/d20sourceMap.json';
import Links from "@/ts/api/sharedModels/Links";
import Utils from '@/ts/util/Utils';
import Vue from 'vue';

export class SpellDAO {
    static async getIndex(): Promise<SpellEntry[]> {
        // TODO: Check local storage first, then commence update
        let res: AxiosResponse<SpellEntry[]> = await axios.get('/api/spells/index');
        let spells = res.data;
        spells.forEach(x => process(x));

        return spells.map(x => new Proxy(x, {
            get: function (target: any, prop, receiver) {
                if (target.inheritedFields == undefined) return target[prop];
                else if (!(prop in target.inheritedFields)) return target[prop];
                else return target.inheritedFields[prop];
            }
        }) as SpellEntry);
    }

    static async fill(index: SpellEntry[]): Promise<SpellEntry[]> {
        // TODO: Check local storage first, then commence update
        let res: AxiosResponse<{[key: string]: SpellEntry}> = await axios.get('/api/spells/fills');
        let fills = res.data;

        for (let entry of index) {
            let fill = fills[entry.id];
            if (fill == null) {
                console.warn(`Fill not found: ${entry.id}`);
                continue;
            }

            for (let [k, v] of Object.entries(fill)) {
                Vue.set(entry, k, v);
            }

            // Replace url with Links // TODO: Move to process() function?
            let dyn = entry as any;
            if (dyn.url) {
                if (entry.links) console.warn('Unexpected links transition for item', {from: entry.links, to: dyn.url});
                Vue.set(entry, 'links', {aon: dyn.url});
                Vue.delete(entry, 'url');
            }
        }

        return index;
    }

    static splitList(entry: string): string[] {
        if (!entry) return [];
        else return Utils.splitNonGroup(entry).map(x => {
            let index = x.lastIndexOf('(');
            return index >=0 ? x.slice(0, index).trim() : x;
        });
    }
    private static splitListLevels(entry: string): number[] {
        if (!entry) return [];
        else {
            let list = Utils.splitNonGroup(entry).map(x => {
                let index = x.lastIndexOf('(');
                if (index < 0) {
                    console.warn('List level entry has no number', entry);
                    return null;
                }

                let i2 = x.indexOf(')', index);
                if (i2 < 0) {
                    console.warn('List level entry has no number', entry);
                    return null;
                }

                let lvlString = x.slice(index+1, i2);
                console.assert(/[0-9]+/g.test(lvlString), 'Unexpected number format in spell level list: ' + lvlString);
                return parseInt(lvlString);
            }).filter(x => x != null);

            return list as number[];
        }
    }
}

function process(spell: SpellEntry) {
    // Replace _id with id
    let dyn = spell as any;
    if (dyn._id) {
        if (spell.id) console.warn('Unexpected id transition for item', {from: spell.id, to: dyn._id});
        spell.id = dyn._id;
        delete dyn['_id'];
    }

    // Convert source to sourceName and sourceURL
    if (spell.source) {
        let filter = d20sourceMap.filter(x => x.source_code.toLowerCase() == spell.source.toLowerCase());
        if (filter.length > 0) {
            spell.sourceName = filter[0].name;
            spell.sourceURL = filter[0].url;

            if (spell.source.startsWith('AP')) {
                spell.sourceName = `${spell.source}: ${spell.sourceName}`;
            }
        }
        else {
            spell.sourceName = spell.source;
            console.warn('Credit link not found for ' + spell.source);
        }
    }

    // Get min/max spell level
    // let levels = [spell.domain, spell.patron, spell.bloodline].flatMap(x => SpellDAO.splitListLevels(x)).concat(Object.values(spell.spellLists ?? {}));
    let levels = Object.values(spell.spellLists ?? {});
    if (levels?.length > 0) {
        spell.minLevel = Math.min(...levels);
        spell.maxLevel = Math.max(...levels);

        let targets = Utils.unique(levels);
        let count = 0;
        let target = 0;
        for (let t of targets) {
            let c = levels.filter(x => x==t).length;
            if (c > count || (c==count && t > target)) {
                count = c;
                target = t;
            }
        }

        spell.primaryLevel = target;
    }
}