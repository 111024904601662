import { BoolConfig, TextConfig, RangeConfig, CategoryConfig, Filter, Value } from "./_index";

export function bool<T>(name: string, getValues: (entry: T, filters?: {[key: string]: Filter<T>}) => Value|Value[]) {
    return new BoolConfig<T>(name, getValues);
}
export function text<T>(name: string, getValues: (entry: T, filters?: {[key: string]: Filter<T>}) => Value|Value[]) {
    return new TextConfig<T>(name, getValues);
}
export function range<T>(name: string, getValues: (entry: T, filters?: {[key: string]: Filter<T>}) => Value|Value[]) {
    return new RangeConfig<T>(name, getValues);
}
export function cat<T>(name: string, getValues: (entry: T, filters?: {[key: string]: Filter<T>}) => Value|Value[]) {
    return new CategoryConfig<T>(name, getValues);
}