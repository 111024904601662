import Links from "@/ts/api/sharedModels/Links";
import { CardConfig } from "../DataCards/CardConfig";

export interface TableConfig<T> {
    getSearchTerm?: (entry: T) => string,
    getLinks?: (entry: T) => Links,
    
    getConfig: (entry: T) => CardConfig,
    columns: Column<T>[],
}

export interface Column<T> {
    name: string,
    field: string,
    smMode: SmallMode,
    display: (entry: T) => string,

    isMD?: boolean,
    isSortable?: boolean,
}

export enum SmallMode {
    Compress,
    Default,
    Hide,
}