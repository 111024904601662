
































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class PageFrame extends Vue {
    @Prop({required: true})
    isLoading!: boolean;

    @Prop({default: false})
    showSidebar!: boolean;

    @Prop({default: false})
    showSidebarRight!: boolean;

    @Prop({default: ""})
    wrapperClass!: string;

    animated = false;

    mounted() {
        this.onLoadingChange();
    }

    @Watch('isLoading')
    onLoadingChange() {
        if (!this.isLoading && !this.animated) {
            let $this = this;
            setTimeout(() => $this.animated = true, 1500);
        }
    }
}
