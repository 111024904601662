import {ContentTemplate, ContentFactory} from '@/ts/api/content/_index';

type Dynamic = {[key: string]: any};

export class RaceTemplate extends ContentTemplate {
    racePoints: number;

    constructor(json: Dynamic) {
        ContentFactory.setContentTypes(json.children, ['racialTrait']);
        if (json.racePoints == '?') json.racePoints = -1;

        super(json);
        this.racePoints = json.racePoints ?? 0;
    }
}