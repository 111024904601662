import { Filter, NONE_KEY, OTHER_KEY } from "@/ts/util/filters/_index";
import Links from "@/ts/api/sharedModels/Links";
import Utils from "@/ts/util/Utils";
import { SortAlgo } from "@/ui/AdvancedSearch/CardResultsConfig";
import { CardConfig, TagEntry } from "@/ui/DataCards/CardConfig";
import MDBuilder from "@/ts/util/MDBuilder";
import { BoolSetting, Setting } from "@/ts/util/config/Setting";

export default interface SpellEntry {
    // These fields are filled by the client, and are not present in the original data
    minLevel: number;
    maxLevel: number;
    primaryLevel: number;

    sourceName: string;
    sourceURL: string;

    // Identification & Metadata
    id: string;
    name: string;
    links: Links;
    tags: MdTag[]; // Tags are the visual symbols next to an entry (e.g. 3.5 or PFS)

    description: string;
    short_description: string;

    isDismissible: boolean;
    isShapeable: boolean;
    components: string[];
    componentCosts: number;
    descriptors: string[];
    noteRefs: string[];
    spellLists: {[key: string]: number}; // The name of the spell list this spell appears on, and what level it appears at (for that list)

    exclusiveCount: number; // The number of spell lists this spell appears on. Hybrid lists (like hunter or arcanist) are excluded from this list
    poCount: number; // Number of lists with this spell as lv 3 or lower (for potions & oils). Hybrid lists (like hunter or arcanist) are excluded from this list. Note that not all spells with a poCount are elegible for potions/oils.
    permanency: string; // 'Yes' or 'Yes*' to indicate availability only on an object/location
    permanencyCL: number;
    permanencyCost: number;
    spellLikeLevel: number;

    allerseelenRating: number;

    school: string;
    subschool: string;
    castingTime: string;
    range: string;
    area: string;
    effect: string;
    targets: string;
    duration: string;
    savingThrow: string;
    spellResistance: string;
    source: string;

    deity: string;
    race: string;
    domain: string;
    bloodline: string;
    patron: string;
    mythicText: string;
    augmented: string;
    hauntStatistics: string;
    
    /**
     * Some spells reference other spells (e.g. "This spell functions as...").
     * This is a map of all fields that are defined by ANY ANCESTOR, that are NOT defined explicitly by this spell.
     */
     inheritedFields: {[key: string]: any};
}

/**
 * A markdown tag is any icon or additional marker that is presented in an index title or description text.
 * Each tag has an image url (which is scrubbed before making it to the client), comment (which typically functions as hover text), and a human-readable name describing the tag
 */
export interface MdTag {
    name: string;
    comment: string;
}

export class SpellFields {
    static spellLists(entry: SpellEntry, filters?: {[key: string]: Filter<SpellEntry>}, settings?: {[key: string]: Setting}) {
        let lists = entry.spellLists ? Object.keys(entry.spellLists) : [];
        if (filters == null) return lists;

        let discountOnly = (settings?.discount as BoolSetting)?.value;
        if (discountOnly) {
            let spellListFilter = filters.lists;
            console.assert(spellListFilter != null, 'Expected non-null filter for spellListFilter (key: lists)', filters);
    
            // TODO: Assert tristate status, and use .selectedOptions if this is not tristate
            let selectedLists = Object.keys(spellListFilter.triStateOptions).filter(x => spellListFilter.triStateOptions[x] && x != NONE_KEY && x != OTHER_KEY);
    
            if (entry.spellLists) {
                let max = Math.max(...Object.values(entry.spellLists));
                if (selectedLists.length == 0) return lists;
                else return lists.filter(key => entry.spellLists[key] < max);
            }
            else return lists;
        }
        else {
            return lists;
        }
    }
    
    static saveEffects(entry: SpellEntry, filters?: {[key: string]: Filter<SpellEntry>}, settings?: {[key: string]: Setting}) {
        let line = entry.savingThrow?.toLowerCase() ?? '';
        let effects = [];
        if (['disbelief', 'disbelieve', 'disbelieves'].some(x => line.includes(x))) effects.push('disbelief');
        if (line.includes('partial')) effects.push('partial');
        if (line.includes('half')) effects.push('half');
        if (effects.length == 0) effects.push('full');

        if (filters == null) return effects.map(x => Utils.titleCase(x));
        let save = filters.saving_throw;
        // TODO: Only return effects that trigger on the selected save
        return effects.map(x => Utils.titleCase(x));
    }

    static tags(entry: SpellEntry) {
        let tags = entry.tags ?? [];
        return tags.map(x => x.name);
    }

    static races(entry: SpellEntry) {
        return entry.race ? entry.race.split(',').map(x => x.trim()) : null;
    }
    
    static subschools(entry: SpellEntry) {
        return entry.subschool?.split(',').map(x => x.trim());
    }
    
    static permanency(entry: SpellEntry) {
        return entry.permanency?.toLowerCase().startsWith('yes') ? 'yes' : 'no';
    }
    
    static duration(entry: SpellEntry) {
        let str = entry.duration?.toLowerCase() ?? '';
        let ands = Utils.splitNonGroup(str, [' and ', ' + ', ' then ', ' up to '])
            .map(x => x.split(' or ')[0].split('(')[0].split(',')[0].trim())
            .map(x => (x == '5 rounds') ? 'rounds' : x)
            .map(x => (x == 'special') ? 'see text' : x)
            .map(x => x.replace(/\/[1-9] /g, '/').replace(/levels/g, 'level'));
        if (str.includes('see text')) ands.push('see text');

        return Utils.unique(ands.filter(x => x.length > 0));
    }

    static spellLevels(entry: SpellEntry, filters?: {[key: string]: Filter<SpellEntry>}) {
        if (filters == null) {
            let targets = (entry.spellLists) ? Object.entries(entry.spellLists) : [];
            return Utils.unique(Object.values(targets.map(x => x[1])));
        }
        else if (entry.spellLists) {
            let spellListFilter = filters.lists;
            console.assert(spellListFilter != null, 'Expected non-null filter for spellListFilter (key: lists)', filters);

            // TODO: Assert tristate status, and use .selectedOptions if this is not tristate
            let keys = Object.keys(spellListFilter.triStateOptions).filter(x => spellListFilter.triStateOptions[x]);
            let targets = (keys.length == 0) ? Object.entries(entry.spellLists) : Object.entries(entry.spellLists).filter(x => keys.includes(x[0].toLowerCase()));
            return Utils.unique(Object.values(targets.map(x => x[1])));
        }
        else return [];
    }
}

/** Calculated values that are used for card displays.
 * These methods handle formatting and markdown (when appropriate) for displaying text to the user - they are not used by the Advanced Search Engine.
 */
export class SpellDisplays {
    static sortAlgorithms: SortAlgo<SpellEntry>[] = [
        {name: "Default", func: 0},
        {name: "Level", func: (a: SpellEntry, b: SpellEntry) => a.primaryLevel - b.primaryLevel},
        {name: "Name", func: (a: SpellEntry, b: SpellEntry) => Utils.strComp(a.name, b.name)},
    ];

    static getCardConfig(spell: SpellEntry): CardConfig {
        return {
            name: spell.name,
            sources: spell.sourceName ? [spell.sourceName] : [],
            titleTags: SpellDisplays.titleTags(spell),
            tabs: [
                {
                    name: "Details",
                    md: SpellDisplays.md(spell),
                }
            ],

            links: spell.links,
        }
    }

    static md(x: SpellEntry) {
        let md = new MDBuilder();
        let hasComps = x.components && x.components.length > 0;
        
        md.field('School', x.school + (x.subschool ? ` (${x.subschool})` : ''));
        md.field('Level', Object.entries(x.spellLists ?? {}).map(x => x.join(' ')).join(', '));
        md.field('Components', hasComps ? x.components.join(', ') : 'None');
        if (x.componentCosts && x.componentCosts > 0) md.field('Component Costs', `${x.componentCosts}gp`);

        md.field('Deity', x.deity);
        md.field('Race', x.race);

        md.field('Range', x.range);
        md.field('Area', x.area);
        md.field('Effect', x.effect);
        md.field('Targets', x.targets);

        md.field('Duration', x.duration);
        md.field('Casting Time', formatCastingTime(x.castingTime));

        md.field('Saving Throw', x.savingThrow);
        md.field('SR', x.spellResistance);

        md.h2('Description (Short)');
        md.field('', x.short_description);
        md.h2('Description (Full)');
        md.field('', x.description);

        return md.build();
    }
    
    static titleTags(entry: SpellEntry): TagEntry[] {
        return [
            {
                label: "PFS*",
                labelHover: SpellDisplays.pfsConditionalText(entry),
            },
            {
                label: "PFS",
                labelHover: SpellDisplays.pfsText(entry),
            },
            {
                label: "PFS-",
                labelHover: SpellDisplays.pfsDisallowedText(entry),
                styleClass: 'cancel',
            },
            {
                label: "3.5",
                labelHover: SpellDisplays.is35Text(entry),
            },
            {
                label: SpellDisplays.levelLabelText(entry),
                labelHover: SpellDisplays.levelHoverText(entry),
                styleClass: SpellDisplays.levelGroup(entry),
            }
        ].filter(x => x.labelHover.length > 0);
    }

    static levelGroup(entry: SpellEntry) {
        let lvl = entry.primaryLevel;
        if (lvl == null) return '';
        else return (lvl <= 4) ? 'low' : (lvl <= 6) ? 'mid' : 'high';
    }

    static levelRange(entry: SpellEntry) {
        let range = Utils.unique([entry.minLevel, entry.maxLevel].filter(x => x != null));
        return (range.length == 2) ? ` (${range[0]}-${range[1]})` : '';
    }

    static levelLabelText(entry: SpellEntry) {
        if (entry.primaryLevel == undefined) return '';

        let range = SpellDisplays.levelRange(entry);
        if (range.includes('-')) return `Lv${entry.primaryLevel}*`;
        else return `Lv${entry.primaryLevel}`;
    }

    static levelHoverText(entry: SpellEntry) {
        if (entry.primaryLevel == undefined) return '';

        let range = SpellDisplays.levelRange(entry);
        if (range.includes('-')) return `This spell appears at different levels on different spell lists. It is most commonly level ${entry.primaryLevel}, but ranges from${range}`;
        else return `This is a level ${entry.primaryLevel} spell`;
    }

    static pfsText(entry: SpellEntry) {
        let isPFS = entry.tags?.filter(x => x.name == 'PFS').length > 0;
        return isPFS ? 'This spell is legal for Pathfinder Society Play' : '';
    }

    static pfsDisallowedText(entry: SpellEntry) {
        let isPFS = (SpellDisplays.pfsConditionalText(entry) + SpellDisplays.pfsText(entry)).length > 0;
        return isPFS ? '' : 'This spell is NOT legal for Pathfinder Society Play';
    }

    static is35Text(entry: SpellEntry) {
        let is35 = entry.tags?.filter(x => x.name == '3.5').length > 0;
        return is35 ? 'This spell was introduced when Pathfinder was still a 3.5 subsystem' : '';
    }

    static pfsConditionalText(entry: SpellEntry) {
        let isConditionalPFS = entry.tags?.filter(x => x.name == 'PFS (conditional)').length > 0;
        if (isConditionalPFS) {
            let filter = entry.tags?.filter(x => x.name == 'PFS (conditional)') ?? [];
            let conditions = filter.length > 0 ? filter[0].comment : '';
            if (conditions.length == 0) {
                console.warn('Spell is marked as conditional, but no condition text was found', entry);
            }

            return 'This spell is legal for Pathfinder Society Play under the following conditions:\n\n' + conditions;
        }
        else return '';
    }
}

function formatCastingTime(str: string) {
    if (str == null) return '';

    let orSplit = str.split(' or ');
    orSplit = orSplit.map(x => {
        let index = x.indexOf('(');
        let i2 = x.indexOf(')');
        if (index >= 0) {
            let before = x.slice(0, index).trim();
            let after = x.slice(i2+1).trim();
            let middle = x.slice(index+1, i2).trim();

            if (!/[0-9]+/g.test(middle)) console.warn('Unexpected format: ' + str);
            if (middle == '1' && before.endsWith('s')) before = before.slice(0, -1);
            let ret = [middle, before, after].join(' ');
            return ret;
        }
        else {
            return x;
        }
    });
    return orSplit.join(' or ');
}