import { Bonus, ValueType } from '@/ts/api/characters/_index';
import {Grant} from '@/ts/api/grants/_index';
import Utils from '@/ts/util/Utils';

type Dynamic = {[key: string]: any};

export class BonusGrant extends Grant {
    refName: string;
    target: string; // refName of the stat that is to be given the bonus
    equation: string;
    bonusType: string;
    valueType: string; // The expected type of the equation after it has been evaluated; e.g. string, number, etc.
    targetValueType: string; // The expected type of the target stat; e.g. string, number, etc.

    // This bonus will only apply if the following condition blocks are met. This includes 'for the purpose of' style conditions
    conditionStrings: string[];

    morphs: { level: string; equation: string; }[]; // Maps a level or condition string to an equation string representing the new equation to use at different levels

    constructor(json: Dynamic) {
        super(json);
        
        this.target = json.target ?? "";
        this.equation = json.equation ?? "";
        this.bonusType = json.bonusType ?? "";
        this.valueType = json.valueType ?? "";
        this.targetValueType = json.targetValueType ?? "";
        
        this.refName = json.refName ?? this.templateId ?? ""; // TODO: log warning if this defaults?
        this.conditionStrings = json.conditionStrings ?? [];
        this.morphs = json.morphs ?? [];
    }

    createBonus(sourceId: string) : Bonus {
        let bonus = Bonus.blank(this.refName, sourceId);
        bonus.equation = this.equation;
        bonus.valueType = Utils.isEmpty(this.valueType) ? ValueType.INT : this.valueType as ValueType;

        return bonus;
    }
}