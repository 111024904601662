






















































































































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import PlayerDisplay from '@/ui/Gamebooks/combat_components/PlayerDisplay.vue';
import EntryDisplay from '@/ui/Gamebooks/sidebar_components/EntryDisplay.vue';
import DicePanel from '@/ui/Gamebooks/sidebar_components/DicePanel.vue';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';
import SceneDisplay from '@/ui/Gamebooks/SceneDisplay.vue';
import CoverPage from '@/ui/Gamebooks/CoverPage.vue';

import Toaster from '@/ts/ui_integrations/Toaster';
import { SceneLog } from '@/ts/gamebooks/SceneLog';
import { DiceMacro, GamebookSession, MacroComponent } from '@/ts/gamebooks/GamebookSession';

import { ColumnWidths, SidebarDisplayType, StatDisplayType, UserSettings } from './UserSettings';
import { GamebookParser } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookMeta } from '@/ts/gamebooks/models/GamebookData';
import { Entity, EntityLog } from '@/ts/gamebooks/EntityLog';
import { GBInventory } from '@/ts/gamebooks/GBInventory';
import { GBJournal } from '@/ts/gamebooks/GBJournal';
import Utils from '@/ts/util/Utils';

var images = require.context('@/assets/gamebooks/', true, /\.((jpeg)|(jpg)|(png))$/);
var icons = require.context('@/assets/icons/gamebooks', true, /\.svg$/);
import directory from '@/data/gamebooks/directory';
import SanityCheck from '@/ts/util/SanityCheck';
import { Scene } from '@/ts/gamebooks/parsing/Scene';

@Component({
    components: {
        PlayerDisplay,
        EntryDisplay,
        SceneDisplay,
        CoverPage,
        DicePanel,
        Sidebar,
    }
})
export default class GamebookUI extends Vue {
    gamebookId = ''; // This is set by the router, via the url
    session: GamebookSession|null = null;

    settings = new UserSettings(); // TODO: Load from localStorage and update localStorage on settings changes
    toaster = new Toaster(this.$toast.add);
    columnWidthOptions = [ColumnWidths.Narrow, ColumnWidths.Wide, ColumnWidths.Full, ColumnWidths.Custom];
    statDisplayOptions = [StatDisplayType.Top, StatDisplayType.Bottom, StatDisplayType.StatsTab, StatDisplayType.NotesTabTop, StatDisplayType.NotesTabBottom, StatDisplayType.CombatOnly];

    contextItems: any[] = [];
    contextIndex = 1;

    displayMacroEditor = false;
    editMacroContext: DiceMacro|null = null;

    activeTab = 'notes';
    preloadSources: string[] = [];

    Dual = SidebarDisplayType.DualSidebar;
    LeftPad = SidebarDisplayType.LeftPadded;
    RightPad = SidebarDisplayType.RightPadded;
    LeftStretch = SidebarDisplayType.LeftStretch;
    RightStretch = SidebarDisplayType.RightStretch;

    hideSidebar = false;

    @Watch('gamebookId')
    async onGamebookChange() {
        let srcs = [];
        if (this.gamebookId.length == 0) {
            console.warn('GamebookId not set', this);
        }
        else {
            let meta: GamebookMeta|undefined = directory[this.gamebookId];
            let dataId = meta?.dataId;
            if (dataId) {
                let gamescript = (await import(`@/data/gamebooks/${dataId}/script.md`)).default;
                let gamedata = (await import(`@/data/gamebooks/${dataId}/data.json`)).default;
                this.session = new GamebookSession(GamebookParser.parse(gamescript, gamedata));
            }
            SanityCheck.warnIf(!dataId, 'Directory entry not found for gamebook with id: ' + this.gamebookId, {meta});

            for (let ref of Object.values(this.session?.gamebook?.images ?? [])) {
                try {
                    let src = images(`./${this.gamebookId}/${ref.filename}`);
                    srcs.push(src);
                }
                catch (e) {
                    console.warn('Gamebook image referenced but not found', {name: ref.filename, gamebookId: this.gamebookId, ref});
                }
            }
            for (let name of ['attack', 'combat', 'dialogue', 'journal', 'location', 'lose', 'win', 'miss', 'roll']) {
                let src = icons(`./${name}.svg`);
                srcs.push(src);
            }
        }

        let preloads = this.preloadSources;
        srcs.filter(x => !preloads.includes(x)).forEach(x => preloads.push(x));
    }

    get isLoading() {
        return this.session == null;
    }

    get rootClasses() {
        let classes = 'gamebook-ui';
        if (this.hideSidebar) {
            classes += ' collapsed';
        }
        else if (!this.session?.canUndo && this.session?.currentScene?.id == 'instructions') {
            // For mobile devices, if we click How to Play, do not show the sidebars
            this.hideSidebar = true;
            classes += ' collapsed';
        }
        return classes;
    }
    
    @Watch('session.currentScene')
    restoreSidebars() {
        // A workaround for the instructions screen on mobile devices. When we return to the cover page, reset the sidebar visibility so that it will be shown when they click Start.
        if (this.session?.currentScene == null) this.hideSidebar = false;
    }

    get minColumnWidth() {
        return UserSettings.MinColumnWidth;
    }

    get wrapperClass() {
        if (this.settings.padLeft) return 'pad-left';
        else if (this.settings.padRight) return 'pad-right';
        else return '';
    }

    get showSidebar() {
        let hide = this.hideSidebar && (this.$root as any).windowWidth <= 960;
        return this.session?.currentScene != null && this.settings.showLeftSidebar && !hide;
    }
    get showSidebarRight() {
        let hide = this.hideSidebar && (this.$root as any).windowWidth <= 960;
        return this.session?.currentScene != null && this.settings.showRightSidebar && !hide;
    }

    get gamebook() {
        return this.session?.gamebook;
    }

    get location() {
        return this.session?.currentScene?.location;
    }

    get sceneLog(): SceneLog|undefined {
        return this.session?.sceneLog;
    }

    get isPopupOpen() {
        if (!this.session) return false;
        return this.session.popupStack.length > 0;
    }

    get popupScene() {
        if (!this.session) return undefined;
        let len = this.session.popupStack.length;
        return len <= 0 ? undefined : this.session.popupStack[len-1];
    }

    get entityLog(): EntityLog|undefined {
        return this.session?.entityLog;
    }

    get inventory(): GBInventory|undefined {
        return this.session?.player.inventory;
    }

    get journal(): GBJournal|undefined {
        return this.session?.player.journal;
    }

    get errorScene(): Scene {
        // TODO: Add a button to automatically report the issue
        let scene = new Scene('_missing_scene');
        let blocks = GamebookParser.parseBlocks([
            '### Missing Scene',
            '---',
            '',
            'You found a bug! Let us know about it at **artemistabletop@gmail.com**',
            '',
            'Then use the back button to return from whence you came.'
        ]);
        scene.blocks.push(...blocks);
        scene.align = 'center';
        return scene;
    }

    mounted() {
        this.updateGamebookId();
    }

    @Watch('gamebookId')
    updateGamebookId() {
        this.gamebookId = this.$route.params.gamebookId;
    }

    @Watch('session.currentScene')
    @Watch('session.popupStack')
    scrollCenter() {
        this.$nextTick(() => {
            let pageFrame: any = this.$refs.pageFrame;
            let scrollPane: any = pageFrame.$refs.scrollPane;
            scrollPane.scrollTop = 0;
        });
    }

    collapseSidebar() {
        this.hideSidebar = true;
    }

    expandSidebar() {
        this.hideSidebar = false;
    }

    addNewComponent() {
        let macro = this.editMacroContext;
        if (macro == null) return;

        let component = new MacroComponent('New Roll', '');
        let baseKey = component.key;
        let index = 1;
        while (macro.components.filter(x => x.key == component.key).length > 0) {
            index += 1;
            component.key = `${baseKey}_${index}`;
        }
        macro.components.push(component);
    }

    __evHandler(eventName: string, macro: DiceMacro, ...args: any[]) {
        if (eventName == 'new-dice-macro') {
            let macro = new DiceMacro('New Macro');
            macro.components.push(new MacroComponent('Attack Roll', 'd20+2', 'atk'), new MacroComponent('Damage Roll', '2d6+2', 'dmg'));
            this.session?.macros.push(macro);
            this.showMacroEditor(macro);
            return true;
        }
        else if (eventName == 'show-dice-macro-editor') {
            this.showMacroEditor(macro);
            return true;
        }
        else {
            return false;
        }
    }
    
    showMacroEditor(macro: DiceMacro) {
        this.editMacroContext = macro;
        this.displayMacroEditor = true;
    }

    toggleContextMenu(event: any, items: any[]) {
        let newIndex = this.contextIndex == 2 ? 1 : 2;
        let oldOverlay: any = this.$refs[`context_menu_${this.contextIndex}`];
        let newOverlay: any = this.$refs[`context_menu_${newIndex}`];

        if (Array.isArray(oldOverlay)) oldOverlay = oldOverlay[0];
        if (Array.isArray(newOverlay)) newOverlay = newOverlay[0];
        
        if (this.contextItems == items) {
            oldOverlay.show(event);
        }
        else {
            oldOverlay?.hide();
            newOverlay.show(event);

            this.contextIndex = newIndex;
            this.contextItems = items;
        }
    }
}
