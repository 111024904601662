import {AppliedContent} from '@/ts/api/content/_index';

type Dynamic = {[key: string]: any};

export class AppliedClass extends AppliedContent {
    level: number;

    constructor(json: Dynamic) {
        if (json == null) json = {};
        super(json);

        this.level = json.level ?? 0;
    }
}