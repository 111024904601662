













import Vue from 'vue';
import Utils from '@/ts/util/Utils';

export default Vue.extend({
    name: "StringListEditor",
    props: {
        value: Array,
        title: String,
        tooltip: String,
        placeholder: String,
    },
    data() {
        return {
            data: this.value.map(x => {return {id: Utils.guid(), value: x}})
        };
    },
    watch: {
        value: {
            handler: function() {
                if (this.value.length != this.data.length) {
                    this.data = this.value.map(x => {return {id: Utils.guid(), value: x}});
                }
            },
            deep: true
        },
        data: {
            handler: function() {
                let target = this.data.map(x => x.value);
                for (let i = 0; i < target.length; i++) {
                    let item = target[i];
                    let index = this.value.indexOf(item, i);
                    if (index < 0) {
                        this.value.splice(i, 0, item);
                    }
                    else if (index != i) {
                        this.value.splice(i, index-i);
                    }
                }
                if (this.value.length > target.length) {
                    this.value.splice(target.length, this.value.length - target.length);
                }
                console.assert(JSON.stringify(target) == JSON.stringify(this.value));
            },
            deep: true
        }
    }
});
