import {Grant, GrantFactory} from '@/ts/api/grants/_index';

type GrantMap = {[key: string]: Grant};
type Dynamic = {[key: string]: any};

/** A ChoiceGrant is similar to a SelectionGrant except the options available are from a list of Grant objects, rather than a pool of Content objects */
export class ChoiceGrant extends Grant {
    extends: string; // If a grant gives extra options to an existing choice, this field will be set to the corresponding id path
    grants: GrantMap; // This is the list of options - the name and type must be consistent with other grant-containing classes like ConditionGrant or Content

    numSelections: string; // Equation string for the number of selections granted (e.g. '1' or 'int.mod + 3')
    indSelectionLimits: string; // The maximum number of times each option may be selected (typically 1), unless otherwise stated. If this is left blank, no limit will be imposed.

    recurrences: { level: string; numSelections: string; }[]; // Maps a level to an equation string representing the number of additional selections that can be made at that level

    constructor(json: Dynamic) {
        super(json);
        
        this.extends = json.extends ?? "";
        this.numSelections = json.numSelections ?? "";
        this.indSelectionLimits = json.indSelectionLimits ?? "";
        
        this.recurrences = json.recurrences ?? [];
        this.grants = GrantFactory.initMap(json.grants);
    }
}