import {BaseContent} from '@/ts/api/content/_index';

type Dynamic = {[key: string]: any};

export class AppliedContent extends BaseContent {
    instanceId: string;
    sourceId: string;

    showUses: boolean;
    showMaxUses: boolean;
    showNumeric: boolean;
    showRollAction: boolean;
    
    usesRef: string;
    maxUsesRef: string;
    numericRef: string;
    displayGroup: string;

    constructor(json: Dynamic) {
        if (json == null) json = {};
        super(json);

        this.instanceId = json.instanceId ?? "";
        this.sourceId = json.sourceId ?? "";

        this.showUses = json.showUses ?? false;
        this.showMaxUses = json.showMaxUses ?? false;
        this.showNumeric = json.showNumeric ?? false;
        this.showRollAction = json.showRollAction ?? false;
        
        this.usesRef = json.usesRef ?? "";
        this.maxUsesRef = json.maxUsesRef ?? "";
        this.numericRef = json.numericRef ?? "";
        this.displayGroup = json.displayGroup ?? "";
    }
}