











































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import GamebookCombatManager from '@/ui/Gamebooks/combat_components/GamebookCombatManager.vue';
import PlayerDisplay from '@/ui/Gamebooks/combat_components/PlayerDisplay.vue';
import EnemyDisplay from '@/ui/Gamebooks/combat_components/EnemyDisplay.vue';

import { CombatScene, Scene, SectionTypes } from '@/ts/gamebooks/parsing/Scene';
import { Gamebook, GamebookParser } from '@/ts/gamebooks/parsing/GamebookParser';
import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { CombatSession, EventInstance } from '@/ts/gamebooks/CombatSession';
import { AttackDef } from '@/ts/gamebooks/models/GamebookData';
import { DiceRoll } from '@/ts/gamebooks/SceneLog';
import Randomizer from '@/ts/util/Randomizer';
import { UserSettings } from './UserSettings';

@Component({
    components: {
        GamebookCombatManager,
        PlayerDisplay,
        EnemyDisplay,
    }
})
export default class SceneDisplay extends Vue {
    @Prop({required: true})
    scene!: Scene;

    @Prop({required: true})
    settings!: UserSettings;

    @Prop({required: true})
    session!: GamebookSession;

    mounted() {
        this.checkAndTransition();
    }

    @Watch('scene')
    checkAndTransition() {
        let session = this.session;
        let sceneId = this.scene.transit;
        if (sceneId) {
            let id: string = sceneId;
            setTimeout(() => {
                session.setScene(id);
            }, 5);
        }
    }

    get rootStyles() {
        if (this.isCombatScene) return '';
        else {
            let styles = 'padding: 0px 16px; align-self: center; width: 100%;';
            if (this.settings.useColumnWidth) styles += ` max-width: ${this.settings.maxColumnWidth}px;`;
            return styles;
        }
    }

    get isCenterAligned() {
        let align = this.scene.align ?? this.gamebook.meta.defaultAlign;
        return align == 'center';
    }

    get isCombatScene() {
        return this.scene.type == SectionTypes.Combat;
    }

    get combatSession(): CombatSession|null {
        return this.session.combatSession;
    }

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    get attack(): AttackDef|null {
        return this.combatSession?.attack ?? null;
    }

    get attackRoll(): DiceRoll|null {
        return this.combatSession?.attackRoll ?? null;
    }

    get damageRoll(): DiceRoll|null {
        return this.combatSession?.damageRoll ?? null;
    }

    get event(): EventInstance|null {
        return this.combatSession?.event ?? null;
    }

    get eventBlocks() {
        if (this.combatSession == null) return [];
        else if (this.event) {
            let textBlocks = GamebookParser.parseBlocks(this.event.event.text);
            return [...textBlocks, ...this.event.choiceBlocks];
        }
        else return [];
    }

    get hasLivingEnemies() {
        let len = this.combatSession?.enemies.filter(x => !x.isDefeated).length;
        return len ? len > 0 : false;
    }

    get bottomBlocks() {
        if (this.combatSession == null) return [];
        else {
            let x = this.scene as CombatScene;
            return [x.winBlock, x.loseBlock, ...x.altChoices].filter(x => x != undefined);
        }
    }

    get playerTurnBlocks() {
        if (this.combatSession == null) return [];
        else if (this.hasLivingEnemies) {
            return GamebookParser.parseBlocks([
                'You may take any actions allowed in the core rules (such as moving, attacking, or casting a spell), as well as those listed on your character sheet.',
                'If you deem it appropriate for the scene, you may also decide to exercise DM fiat to adjust the rules, or take actions not explicitly described in the rulebook.',
                'For more information, see [info:game_mechanics|How to Play] and [info:combat_instructions|Combat Instructions]'
            ]);
        }
        else {
            let x = this.scene as CombatScene;
            return [x.winBlock, ...x.altChoices].filter(x => x != undefined);
        }
    }

    get playerTurnLabel() {
        return this.hasLivingEnemies ? "It's your turn!" : "All enemies have been defeated!";
    }

    get isPlayersTurn() {
        return this.combatSession?.isPlayersTurn || !this.hasLivingEnemies;
    }

    get atkRollClasses() {
        let isCritFail = this.isCritFail;
        let isCrit = this.isCrit;

        if (isCrit && isCritFail) return 'crit-mixed';
        else if (isCritFail) return 'crit-fail';
        else if (isCrit) return 'crit-success';
        else return '';
    }

    get isCrit() {
        return this.attackRoll?.diceResults.some(x => x[0] == x[1]) ?? false;
    }

    get isCritFail() {
        return this.attackRoll?.diceResults.some(x => 1 == x[1]) ?? false;
    }

    get hitLabel() {
        if (this.isCrit) return 'Critical Hit!';
        else if (this.isCritFail) return 'Crit Fail!';
        else return 'To Hit';
    }

    /**
     * Returns true if a monster attack is set, is not a crit fail, and [the to-hit roll is a nat20 OR less than the player's AC]; otherwise false.
     */
    get isMiss() {
        if (this.isCrit) return false;
        else if (this.isCritFail) return true;

        let toHit = this.attackRoll?.result ?? 0;
        let playerAC = this.session.player.ac ?? 0;
        return toHit < playerAC;
    }

    /**
     * Whether or not the system should automatically decide if an attack hit or missed. If true, the hit and miss icons will be displayed based on the player's AC value.
     */
    get autoCalc() {
        return this.session.settings.autoCalcMonsterAttacks;
    }

    get damageTooltip() {
        let tooltip = this.damageRoll?.tooltip;
        if (tooltip && this.isCrit) {
            tooltip = `**All dice are doubled for crit damage rolls. Extra dice are shown in parenthesis.\n\n${tooltip}`;
        }
        return tooltip;
    }

    nextTurn() {
        this.combatSession?.nextTurn();
    }
}
