



























































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';

import { Filter, FilterSpec, Option, TabLayout } from "@/ts/util/filters/_index";
import Randomizer from "@/ts/util/Randomizer";
import Utils from '@/ts/util/Utils';
import Toaster from '@/ts/ui_integrations/Toaster';

@Component({
    components: {
        ConfigElementPanel,
    }
})
export default class SearchWizard<T> extends Vue {
    @Prop({required: true})
    rootContent!: T[];

    @Prop({required: true})
    filterSpec!: FilterSpec<T>;
    
    @Prop({required: true})
    filterMap!: {[key: string]: Filter<T>}

    @Prop({required: true})
    resultCount!: number;

    displayModal = false;

    activeTabIndex = 0;
    prefixTabs = ['Instructions'];
    suffixTabs = []; // ['Adv. Filters'];
    
    toaster = new Toaster(this.$toast.add);

    get tabLayouts(): TabLayout[] {
        return this.filterSpec.layout.tabs;
    }

    get showClearAll() {
        return this.resultCount != this.rootContent?.length;
    }

    nextTab() {
        if (this.isLastTab()) {
            // Return to the first filter tab (not the instructions tab) and close the modal
            this.activeTabIndex = 1;
            this.displayModal = false;
        }
        else {
            this.activeTabIndex += 1;
        }
    }

    previousTab() {
        if (!this.isFirstTab()) {
            this.activeTabIndex -= 1;
        }
    }

    isLastTab() {
        let totalTabs = this.prefixTabs.length + this.suffixTabs.length + this.tabLayouts.length;
        return this.activeTabIndex == totalTabs - 1;
    }

    isFirstTab() {
        return this.activeTabIndex <= 1; // Return true if we are the first filter, or the instructions tab
    }

    isArray(x: any) {
        return Array.isArray(x);
    }
}
