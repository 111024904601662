





















































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';
import DataCard from '@/ui/DataCards/DataCard.vue';

import LoreTemplate, { ConfigOption, ConfigSetting } from '@/ts/util/loregen/LoreTemplate';
import ShopGenerator, { SettlementSize, Shop, ShopConfig, ShopType } from '@/ts/generators/ShopGenerator';
import Toaster from '@/ts/ui_integrations/Toaster';
import LoreGen from '@/ts/util/loregen/LoreGen';
import Segment from '@/ts/util/loregen/Segment';
import ItemEntry, { ItemDisplays, ItemFields } from '@/ts/api/items/ItemEntry';
import { Format } from '@/ts/util/Format';
import Randomizer from '@/ts/util/Randomizer';

@Component({
    components: {
        ConfigElementPanel,
        DataCard,
        Sidebar,
    }
})
export default class ShopGeneratorPage extends Vue {
    current: LoreTemplate|null = null;
    results: Shop[] = [];

    toaster = new Toaster(this.$toast.add);
    setting: ConfigSetting|null = null;

    overlayIndex = 1;

    notableTab = true;
    itemTarget: ItemEntry|null = null;
    typeTarget = new ConfigOption('Magic');
    recentTarget = new ConfigOption('Magic');
    randomOption = new ConfigOption('Random');

    shopGenerator = new ShopGenerator();
    shopConfig = new ShopConfig(this.loreGen);

    itemWeightsTab = false;
    showSidebarRight = true;
    showSidebar = true;

    isMobile = false;

    mounted() {
        this.ensureSelection();
        this.onTypeTargetChanged();
        
        let reclac = this.updateIsMobile;
        window.addEventListener('resize', reclac);
        reclac();
    }

    updateIsMobile() {
        this.isMobile = window.innerWidth <= 960;
        if (this.isMobile) {
            this.showSidebarRight = false;
        }
        else {
            this.showSidebar = true;
            this.showSidebarRight = true;
        }
    }

    sidebarGenerateButton() {
        if (window.innerWidth <= 960) this.showSidebar = false;
        this.generate();
    }

    generate() {
        // TODO: Save last 5 results;
        this.results = [];
        if (this.typeTarget.displayName == 'Random') {
            if (this.setting) this.shopConfig.shopType = Randomizer.Global.selection(this.setting.rootOptions)?.displayName as ShopType;
        }
        
        if (!this.current) {
        }
        else {
            this.results.push(this.shopGenerator.generate(this.shopConfig, this.items));
        }

        if (!(this.shopConfig.magicItems || this.shopConfig.mundaneItems || this.shopConfig.alchemicalItems)) {
            this.toaster.warn('Your filters are too restrictive', 'One or more of Magic, Mundane, or Alchemical items must be selected in order to generate any inventory.')
        }
    }

    toggle(event: any, item: ItemEntry) {
        let newIndex = this.overlayIndex == 2 ? 1 : 2;
        let oldOverlay: any = this.$refs[`op_${this.overlayIndex}`];
        let newOverlay: any = this.$refs[`op_${newIndex}`];

        if (Array.isArray(oldOverlay)) oldOverlay = oldOverlay[0];
        if (Array.isArray(newOverlay)) newOverlay = newOverlay[0];
        
        if (this.itemTarget == item) {
            oldOverlay.show(event);
        }
        else {
            oldOverlay?.hide();
            newOverlay.show(event);

            this.overlayIndex = newIndex;
            this.itemTarget = item;
        }
    }

    archive(result: Segment) {
        this.toaster.info('Coming Soon!')
    }

    edit(result: Segment) {
        this.toaster.info('Coming Soon!')
    }

    adjustSetting(setting: ConfigSetting) {
        this.toaster.info('Coming Soon!');
    }

    ensureSelection() {
        if (this.current == null && this.templates.length > 0) {
            this.current = this.templates.filter(x => x.name == 'Shops 2').pop() ?? null;
        }
    }

    soon() {
        this.toaster.info('This feature is Coming Soon!');
    }

    rerollInventory() {
        let shop = this.shopGenerator.generate(this.shopConfig, this.items);
        let len = this.shop.inventory.length;
        if (this.notableTab) {
            this.shop.inventory.splice(0, len, ...shop.inventory);
        }
        else {
            this.shop.basicInventory.splice(0, len, ...shop.basicInventory);
        }
    }

    @Watch('current')
    onCurrentChanged() {
        this.ensureSelection();
        this.loreGen.populateSettings(this.current);
        this.setting = this.current?._settings[0] ?? null;
    }

    @Watch('templates')
    onTemplatesChanged() {
        this.ensureSelection();
    }

    @Watch('typeTarget')
    onTypeTargetChanged() {
        if (this.typeTarget == null) this.typeTarget = this.recentTarget;
        this.recentTarget = this.typeTarget;
        
        this.shopConfig.shopType = this.typeTarget.displayName as ShopType;
    }

    get settlementOptions() {
        return Object.values(SettlementSize);
    }

    get boolSettings() {
        return [
            {
                key: "magicItems",
                label: "Magic Items",
                hover: "",
            },
            {
                key: "mundaneItems",
                label: "Mundane Items",
                hover: "",
            },
            {
                key: "alchemicalItems",
                label: "Alchemical Items",
                hover: "",
            },
            {
                key: "regionalItems",
                label: "Regional Items",
                hover: "Include items that are mentioned ONLY in a regional setting book (e.g. items that are exclusive to 'Qadira, Gateway to the East' or 'Varisia, Birthplace of Legends').\n\nThese can provide interesting plot hooks, but some GMs may find them inappropriate for non-Golarion settings. Uncheck this box to disable them.",
            },
            {
                key: "earlyFirearms",
                label: "Early Firearms",
                hover: "",
            },
            {
                key: "advancedFirearms",
                label: "Advanced Firearms",
                hover: "",
            },
            {
                key: "modernFirearms",
                label: "Modern Firearms",
                hover: "",
            },
        ];
    }

    get loreGen(): LoreGen {
        return (this.$root as any).loreGen;
    }

    get templates(): LoreTemplate[] {
        return this.loreGen.templates.filter(template => template.data[0].includes('$CARD'));
    }

    get isLoading() {
        return (this.$root as any).loadingStatus.items;
    }

    get genText() {
        return this.typeTarget ? `Generate ${this.typeTarget.displayName} Shop` : 'Generate'
    }

    get items(): ItemEntry[] {
        return (this.$root as any).items;
    }

    get shop() {
        if (this.results.length == 0) this.generate();
        return this.results[this.results.length - 1];
    }

    get inventory() {
        return (this.notableTab) ? this.shop.inventory : this.shop.basicInventory;
    }

    get lore() {
        return this.shop.lore;
    }

    get cardConfig() {
        return this.itemTarget ? ItemDisplays.getCardConfig(this.itemTarget) : null;
    }

    get lootClipboard() {
        let items = (this.notableTab) ? this.shop.inventory : this.shop.basicInventory;
        let cost = this.cost;
        return items.map(x => `${x.name} [${cost(x)}]`).join('\n');
    }

    get hover() {
        let base = "Base Value represents items that can be easily found in (or quickly procured by) this shop. There is typically a 75% change to find any item with a value less than or equal to the base value, though GMs can adjust or waive this value as they see fit.\n\nSample items within the base value of this shop can be generated by clicking the icon next to 'Base Value'";
        let cast = `The shop keep can provide, or introduce you to someone who can provide spellcasting services for spells of level ${this.shop.spellcastingLimit} or lower.`;
        let buy = "The purchase limit is the most money this shop can spend to purchase any single item from the PCs. If the PCs wish to sell an item worth more than the purchase limit, they'll either need to settle for a lower price, travel to A larger city, or (with the GM's permission) search for a specific buyer in the city with deeper pockets.";
        let barter = "Sometimes PCs may wish to barter with NPCs to get a better deal. Barter Limit is a value to help GMs decide what the maximum discount NPCs are willing to accept.\n\nGMs may wish to reward normal bartering attemts with half of the discount value, and particularly good bartering attempts with the full discount.\n\nAlternatively, GMs may choose to use this value to determine how much NPCs *raise* prices, and allow bartering to reduce the price down to normal, though care should be taken to make sure players are okay with this, and that martial characters don't fall behind because of it.";
        return {
            base1: base,
            base2: base,
            cast1: cast,
            cast2: cast,
            buy1: buy,
            buy2: buy,
            barter1: barter,
            barter2: barter,
        }
    }

    cost(item: ItemEntry) {
        return typeof item.cost == 'number' ? Format.currency(item.cost).trim() : item.cost;
    }

    isIntelligent(item: ItemEntry) {
        return ItemFields.isIntelligent(item);
    }
}
