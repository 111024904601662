




















































































import Toaster from '@/ts/ui_integrations/Toaster';
import { CoverData } from '@/ts/gamebooks/models/CoverData';
import axios from 'axios';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

var images = require.context('@/assets/images/gamebook_covers/', true, /\.((jpeg)|(jpg)|(png))$/);

@Component
export default class GamebooksAbout extends Vue {
    toaster = new Toaster(this.$toast.add);

    email = "";
    success = true;
    message = "";

    covers: CoverData[] = [
        {
            comingSoon: false,
            refId: "g001",
            title: "The Saint's Tomb",
            author: "Seth Jones",
            forLine: "",
            systemIcons: ['5e']
        },
        {
            comingSoon: false,
            refId: "g002",
            title: "The Longest Outbreak",
            author: "John Smith",
            forLine: "",
            systemIcons: ['5e']
        },
        {
            comingSoon: true,
            refId: "g003",
            title: "An Ill Fated Junket",
            author: "Artemis",
            forLine: "pathfinder",
            systemIcons: ['1e', '2e']
        },
    ];

    async signup (mobile: boolean) {
        let response = await axios.post('/api/newsletter', {email: this.email.trim(), mobile}, {validateStatus: null});
        if (response.status < 400) {
            this.message = `Added '${this.email.trim()}' to the mailing list!`;
            this.success = true;
        }
        else {
            this.message = `Unable to add '${this.email}' to the mailing list.`;
            this.success = false;
        }
    }

    onInput(event: any, mobile: boolean) {
        if (event.keyCode == 13) {
            // Enter Pressed
            this.signup(mobile);
        }
    }

    alert(cover: CoverData) {
        if (cover.comingSoon) this.soon(cover.title);
    }

    soon(title: string) {
        this.toaster.info(
            'Coming Soon!',
            `${title} is still in development, and will be released at a later date.\n\nIf you would like to receive notifications, sign up for our newsletter.`,
            7500);
    }

    iconURL(icon: string) {
        return images(`./icon_${icon}.png`);
    }
}
