










import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class GamebooksFAQ extends Vue {
    markdown: string|null = null;

    async mounted() {
        this.markdown = (await import(`@/data/info/partner_faq.md`)).default;
    }
}
