








import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class Sidebar extends Vue {
    @Prop({default: false})
    showSidebar!: boolean;

    @Prop({default: false})
    showSidebarRight!: boolean;
}
