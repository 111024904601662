import {Grant} from '@/ts/api/grants/_index';

type Dynamic = {[key: string]: any};

export class TrackerGrant extends Grant {
    refName: string; // The name of the stat to represent the number of uses remaining (if multiple grants reference the same stat, they will share uses-per-day). This stat will always be manual-enty.

    constructor(json: Dynamic) {
        super(json);
        this.refName = json.refName ?? "";
    }
}