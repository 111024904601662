import {ContentTemplate, ContentFactory} from '@/ts/api/content/_index';

type Dynamic = {[key: string]: any};

export class ClassTemplate extends ContentTemplate {
    alignment: string;
    classSkills: string[];
    proficiencies: string[];
    parentClasses: string[];

    bab: string;
    saves: string[];
    hitDie: string;
    skillRanks: number;
    startingWealth: string;

    _csNotes: string; // This is for internal purposes and will be removed at a future date
    _profNotes: string; // This is for internal purposes and will be removed at a future date
    _profDescription: string; // This is for internal purposes and will be removed at a future date

    constructor(json: Dynamic) {
        ContentFactory.setContentTypes(json.children, ['classFeature']);

        super(json);
        this.alignment = json.alignment ?? "";
        this.classSkills = json.classSkills ?? [];
        this.proficiencies = json.proficiencies ?? [];
        this.parentClasses = json.parentClasses ?? [];

        this.bab = json.bab ?? "";
        this.saves = json.saves ?? [];
        this.hitDie = json.hitDie ?? "";
        this.skillRanks = json.skillRanks ?? 0;
        this.startingWealth = json.startingWealth ?? "";
        
        this._csNotes = json._csNotes ?? "";
        this._profNotes = json._profNotes ?? "";
        this._profDescription = json._profDescription ?? "";

        if (typeof this.skillRanks == 'string') this.skillRanks = parseInt(this.skillRanks);
    }
}