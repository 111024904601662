import { CatFactory, ConfigMap, FilterConfig, FilterSpec, Option, OTHER_KEY } from '@/ts/util/filters/_index';
import { text, cat, range, bool } from '@/ts/util/filters/FilterFactory';
import SpellEntry, { SpellFields } from './SpellEntry';

import spellFilterOptions from '@/data/filterspecs/spellFilters';
import { BoolSetting } from '@/ts/util/config/Setting';
import { SpellDAO } from './SpellDAO';
import Utils from '@/ts/util/Utils';

export default class SpellFilterFactory {
    static get(spells: SpellEntry[], useFilterCache=true): FilterSpec<SpellEntry> {
        let option = (key: string, name: string, description?: string) => new Option(key, name, 0, description);
        let spellNotes = {
            'y': ['Has Mythic Version', 'This spell has a Mythic version.'],
            'm': ['Has Special Material', 'This spell has a material component not normally included in a spell component pouch.'],
            'f': ['Has Special Focus', 'This spell has a focus component not normally included in a spell component pouch.'],
            'r': ['Has Race/Religion Requirement', 'Spell requires a requisite religion or race. If religion, spellcaster must worship the listed deity to utilize the spell. If race, the spell might only target members of the listed race (the spell will say this if it does), but often are just the race\'s guarded secrets. Members of other races can learn to cast them with GM permission.'],
            't': ['Requires Ritual to Torag', 'In order to prepare any of these spells, the caster must spend an hour performing a ritual in which he beseeches Torag (or a member of his family) for the aid of one of his divine family members. For 24 hours after the ritual, the caster may prepare spells of the requested deity. The caster may only attune himself to one additional deity at a time.'],
        };
        let castingTimes = [
            'immediate action', 'swift action', 'standard action', 'full-round action', 'rounds', 'minutes', 'hours', 'other'
        ];
    
        // TODO: Add area filter
        let configs: ConfigMap<SpellEntry> = {
            'name': text<SpellEntry>('Name', entry => entry.name),
            'description': text<SpellEntry>('Description', entry => entry.description).advOnly(),
            'source': cat<SpellEntry>('Source', entry => entry.sourceName),
            'lists': cat<SpellEntry>('Class Spell Lists', SpellFields.spellLists).noNone()
                .addSetting('discount', new BoolSetting('Discount Spells Only', 'If enabled, only spells that appear at a higher level on other lists will be shown. I.e., only show spells that are offered at a discount by your class.\n\nIf multiple classes are selected, only show results that are discounted by all selected classes (OR will include non-overlapping spells - AND will only show spells that appear on all selected lists at a discount)'))
                .setToggleGroups([
                    {
                        name: 'All',
                        description: 'Toggle all Spell Lists',
                        getKeys(options: Option[]) {
                            return options.map(x => x.key);
                        }
                    }
                ]),
            'levels': cat<SpellEntry>('Spell Level', SpellFields.spellLevels).noNone(),
            'school': cat<SpellEntry>('School', entry => entry.school).useTitleCase().hideAndOr(),
            'subschool': cat<SpellEntry>('Subschool', SpellFields.subschools).hideAndOr(),
            'race': cat<SpellEntry>('Race', SpellFields.races).advOnly(),
            'deity': cat<SpellEntry>('Deity', entry => entry.deity).advOnly(),
            'domain': cat<SpellEntry>('Domain', entry => SpellDAO.splitList(entry.domain)).advOnly(),
            'bloodline': cat<SpellEntry>('Bloodline', entry => SpellDAO.splitList(entry.bloodline)).advOnly(),
            'patron': cat<SpellEntry>('Patron', entry => SpellDAO.splitList(entry.patron)).advOnly(),
            'descriptors': cat<SpellEntry>('Descriptors', entry => entry.descriptors),
            'components': cat<SpellEntry>('Components', entry => entry.components).advOnly(),
            'permanency': cat<SpellEntry>('Permanency', SpellFields.permanency)
                .hideAndOr()
                .setOptions([option('yes', 'Yes'), option('no', 'No')])
                .setDescription('Whether or not this spell can be made permanent by casting permanency')
                .advOnly(),
            'tags': cat<SpellEntry>('Tags', SpellFields.tags),
            'notes': cat<SpellEntry>('Notes', entry => entry.noteRefs).setOptions(Object.entries(spellNotes).map(x => option(x[0], x[1][0], x[1][1]))),
            'duration': cat<SpellEntry>('Duration', SpellFields.duration).advOnly().condense(),
            'casting_time': cat<SpellEntry>(
                'Casting Time', entry => {
                    let filter = castingTimes.filter(x => entry.castingTime?.includes(x));
                    return filter.length == 0 ? OTHER_KEY : filter;
                })
                .setOptions(castingTimes.map(x => option(x, x))).advOnly().useOther(),
            'shapeable': bool<SpellEntry>('Is Shapeable', entry => entry.isShapeable).setTriState().advOnly(),
            'dismissible': bool<SpellEntry>('Is Dismissible', entry => entry.isDismissible).setTriState().advOnly(),
            'cmp_cost': range<SpellEntry>('Component Costs', entry => entry.componentCosts).advOnly().setDescription('The cost (in gp) of all material components required for the spell'),
            'exclusivity': range<SpellEntry>('Exclusivity', entry => entry.exclusiveCount).advOnly().setDescription('The number of spell lists the spell appears on. Hybrid lists (like hunter or arcanist) are excluded from this count'),
            'po_commonality': range<SpellEntry>('Potion/Oil Commonality', entry => entry.poCount).advOnly().setDescription('Number of lists with the spell as lv 3 or lower (for potions & oils). Hybrid lists (like hunter or arcanist) are excluded from this count.\n\nNote that not all spells with a non-zero number are elegible for potions/oils.'),
            'saving_throw': cat<SpellEntry>(
                'Saving Throw', entry => {
                    let line = entry.savingThrow?.toLowerCase() ?? '';
                    return ['fortitude', 'reflex', 'will'].filter(x => line.includes(x)).map(x => Utils.titleCase(x));
                })
                .advOnly(),
            'save_effect': cat<SpellEntry>('Effect of Save', SpellFields.saveEffects).advOnly().noNone(),
            'sr': cat<SpellEntry>(
                'Spell Resistance', entry => {
                    let line = entry.spellResistance?.toLowerCase() ?? '';
                    let sr = ['yes', 'no'].filter(x => line.includes(x));
                    return (sr.length == 0) ? ['no'] : sr;
                })
                .setOptions([option('yes', 'Yes'), option('no', 'No')])
                .advOnly(),
            'targets': cat<SpellEntry>('Targets', entry => entry.targets).advOnly(),
            'range': cat<SpellEntry>('Range', entry => entry.range).advOnly(),
            'area': cat<SpellEntry>('Area', entry => entry.area).advOnly(),
            'effect': cat<SpellEntry>('Effect', entry => entry.effect).advOnly(),
        };
        let tabs = [
            {
                tabName: 'Sources & Schools',
                groups: [
                    {
                        groupName: 'Sources',
                        contents: ['source']
                    },
                    {
                        groupName: 'Classes',
                        contents: ['lists', 'levels', 'discount']
                    },
                    {
                        groupName: 'Schools',
                        contents: ['school', 'subschool']
                    },
                    {
                        groupName: 'Restrictions',
                        contents: ['race', 'deity', 'domain', 'bloodline', 'patron']
                    },
                ]
            },
            {
                tabName: 'More Filters',
                groups: [
                    {
                        groupName: 'Details',
                        contents: ['descriptors', 'components', 'permanency', 'tags', 'notes', ['cmp_cost', 'exclusivity', 'po_commonality']]
                    },
                    {
                        groupName: 'Mechanics',
                        contents: ['saving_throw', 'sr', 'targets', 'duration', 'casting_time']
                    },
                ]
            },
        ];
    
        FilterConfig.initConfigMap(configs);
        let preload = useFilterCache ? spellFilterOptions : undefined;
        CatFactory.hydrateAll(configs, spells, preload);
        return {configs, layout: {tabs}};    
    }
}