import {CntCompletionStatus, ContentFactory, BaseContent} from '@/ts/api/content/_index';
import {Grant, GrantFactory} from '@/ts/api/grants/_index';

type ContentMap = {[key: string]: ContentTemplate};
type StringMap = {[key: string]: string};
type GrantMap = {[key: string]: Grant};
type Dynamic = {[key: string]: any};

export class ContentTemplate extends BaseContent {
    copyright: string;
    publisher: string;
    authors: string[];
    links: StringMap;
    
    grants: GrantMap;
    children: ContentMap;
    replaces: string[];
    prerequisites: string[];

    notes: string; // This is for internal purposes, at present
    otherData: Dynamic; // This is mostly scraping data that we don't yet support, or haven't yet processed
    _completionStatus: CntCompletionStatus; // Indicators for data entry - whether or not all data was properly added. This is separate from validation/verification signoff.

    /**
     * This application relies on the data in this.grants to automatically add and apply the effects described in this.description (and elsewhere).
     * If this value is false, it indicates that the system does not have enough information to fully apply all effects (although some might still be applied).
     * A value of false indicates that the user may have to manually add some stats, bonuses, features, or actions - as dedscribed by this.grantSupportDetails.
     */
    hasFullGrantSupport: boolean;
    grantSupportDetails: string;

    constructor(json: Dynamic) {
        if (json == null) json = {};
        super(json);

        this.children = ContentFactory.initTemplateMap(json.children);
        this.grants = GrantFactory.initMap(json.grants);

        this.copyright = json.copyright ?? "";
        this.publisher = json.publisher ?? "";
        this.authors = json.authors ?? [];
        this.links = json.links ?? {};
        this.links.d20pfsrd = this.links.d20pfsrd ?? "";
        
        this.replaces = json.replaces ?? [];
        this.prerequisites = json.prerequisites ?? [];
        
        this.hasFullGrantSupport = json.hasFullGrantSupport ?? false;
        this.grantSupportDetails = json.grantSupportDetails ?? "";

        this.notes = json.notes ?? "";
        this.otherData = json.otherData ?? {};
        this._completionStatus = new CntCompletionStatus(json?._completionStatus);
    }
}