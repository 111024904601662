import seedrandom from 'seedrandom';
import Utils from '@/ts/util/Utils';
import SanityCheck from './SanityCheck';

export default class Randomizer {
    static Global = new Randomizer();

    rnd: seedrandom.prng;
    seed: string;

    constructor(seed="") {
        if (seed == null || seed.length == 0) {
            seed = Utils.guid();
        }

        this.seed = seed;
        this.rnd = seedrandom(seed);
    }

    /**
     * Returns a random int between [x, y] inclusive.
     * If y is not specified, returns a random int between [0, x) exclusive
     * @param x 
     * @param y 
     * @returns [x, y] inclusive OR [0, x) exclusive
     */
    int(x: number|number[], y?: number) {
        if (Array.isArray(x)) {
            y = x[1];
            x = x[0];
        }

        if (y == null) {
            return Math.floor(this.rnd() * x); // Return a # between 0 and x (exclusive)
        }
        else {
            let range = y - x + 1;
            return Math.floor(this.rnd() * range) + x; // Return a # between x and y (inclusive)
        }
    }

    bool() {
        return this.int(0, 1) == 1;
    }

    chance(percent: number) {
        if (percent >= 100) return true;

        let number = this.int(1, 100);
        return number <= percent;
    }

    selection<T>(array: Array<T>): T|null {
        if (array.length == 0) return null;
        
        let index = this.int(array.length);
        return array[index];
    }

    /**
     * Randomly select and return an element from the given array.
     * 'gt' stands for 'guaranteed' (unlike Randomizer.selection(), this method assumes the result is non-null, and typecasts accordingly).
     * 
     * The given array must have at least one element, and all elements must be non-null. If these conditions are not met, an error will be logged to the console, and the actual result of this call may be null.
     * @param array A non-empty array of non-null elements
     * @returns A random entry from the given array
     */
    gtSelection<T>(array: Array<T>): T {
        return SanityCheck.notNull(this.selection(array));
    }

    roll(diceString: string) {
        let split = diceString.split('d');
        if (split.length == 1) {
            return parseInt(split[0]);
        }
        else {
            let isEmpty = split[0].trim() == '';
            let qty = isEmpty ? 1 : parseInt(split[0]);
            let dice = parseInt(split[1]);
            let sum = 0;
            for (let i = 0; i < qty; i++) {
                sum += this.int(1, dice);
            }

            return sum;
        }
    }

    shuffle<T>(array: T[]) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(this.rnd() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    randomize<T>(table: {weight: number, text: T}[]): T|undefined {
        let max = Utils.sum(table.map(x => x.weight));
        let roll = this.int(1, max);
        for (let entry of table) {
            // TODO: ensure positive weight
            roll -= entry.weight;
            if (roll <= 0) {
                return entry.text;
            }
        }
    
        console.warn('Randomizer.randomize() resulted in 0 results', {table});
    }
}