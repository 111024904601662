
















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';
import SearchWizard from '@/ui/AdvancedSearch/SearchWizard.vue';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import { Filter, FilterSpec, Option, TabLayout } from "@/ts/util/filters/_index";
import Randomizer from "@/ts/util/Randomizer";
import Utils from '@/ts/util/Utils';
import Toaster from '@/ts/ui_integrations/Toaster';
import SanityCheck from '@/ts/util/SanityCheck';
import { BoolSetting, MultiSelectionSetting, NumberSetting, SelectionOption, SelectionSetting, Setting } from '@/ts/util/config/Setting';

@Component({
    components: {
        ConfigElementPanel,
        SearchWizard,
        Sidebar,
    }
})
export default class DungeonGeneratorMockup extends Vue {
    generationSettings: Setting[] = [
        new SelectionSetting('System', '', ['5e', 'PF1e', 'PF2e'].map(x => new SelectionOption(x))),
        new NumberSetting('# of Floors', '', 1, 100).setDefault(1)
    ];
    featureSettings: Setting[] = [
        new SelectionSetting('Combat', '', ['Frequent', 'Standard', 'Infrequent', 'Rare', 'None'].map(x => new SelectionOption(x))).setInitial('Standard'),
        new SelectionSetting('Non-Combat', '', ['Frequent', 'Standard', 'Infrequent', 'Rare', 'None'].map(x => new SelectionOption(x))).setInitial('Standard'),
        new SelectionSetting('Hazards', '', ['Frequent', 'Standard', 'Infrequent', 'Rare', 'None'].map(x => new SelectionOption(x))).setInitial('Standard'),
        new SelectionSetting('Extra Loot', '', ['Frequent', 'Standard', 'Infrequent', 'Rare', 'None'].map(x => new SelectionOption(x))).setInitial('None'),
    ];
    mapStyleSettings: Setting[] = [
        new BoolSetting('Interior Shadows', '').setInitial(false),
        new BoolSetting('Exterior Shadows', '').setInitial(false),
        new BoolSetting('Tile Debris', '').setInitial(false),
        new BoolSetting('Detail Tiles', '').setInitial(false),
        new BoolSetting('Doors', '').setInitial(false),
        new BoolSetting('Exterior Grid', '').setInitial(false),
    ];

    stats = [
        {name: 'Encounters', value: 4},
        {name: 'Non-Combat Encounters', value: 1},
        {name: 'Hazards', value: 3},
        {name: 'Extra Loot', value: 1},
    ];
    encounters = [
        {name: 'Kobold', qty: 4, cr: 1, xp: 400, gp: 254},
        {name: 'Flumph', qty: 1, cr: 1, xp: 400, gp: 150},
    ];

    color1 = 'ffffff';
    color2 = '5e5e5e';

    width = 20;
    height = 15;

    count = 0;
}
