import ItemEntry, { ItemFields } from "../api/items/ItemEntry";
import Randomizer from "../util/Randomizer";

export class Loot {
    items = [] as ItemEntry[];
    coins = new Coins();
    totalValue = 0;

    updateTotalValue() {
        let sum = 0;
        this.items.forEach(x => sum += ItemFields.parsePrice(x));
        this.totalValue = sum;
    }
}

export class Coins {
    pp = 0;
    gp = 0;
    sp = 0;
    cp = 0;

    addRandomAssortment(gp: number, rnd = new Randomizer()) {
        if (gp > 10) {
            let pp = rnd.int(0, Math.floor(gp/10));
            this.pp += pp;
            gp -= pp*10;
        }

        if (gp > 0) {
            let sp = rnd.int(0, gp);
            gp -= sp;
            this.gp += gp;
            if (sp > 0) {
                sp *= 10;
                let cp = rnd.int(0, sp);
                sp -= cp;
                cp *= 10;

                this.sp += sp;
                this.cp += cp;
            }
        }
    }

    reduce() {
        this.sp += Math.floor(this.cp/10);
        this.cp %= 10;

        this.gp += Math.floor(this.sp/10);
        this.sp %= 10;

        this.pp += Math.floor(this.gp/10);
        this.gp %= 10;
    }
}

export interface ModdedItem extends ItemEntry {
    base: ItemEntry;
    mods: ItemEntry;
}

export function createModdedItem(item: ItemEntry) {
    let target = {
        base: item as any,
        mods: {} as any
    }
    let proxy = new Proxy(target, {
        get(target, key, receiver) {
            if (key == 'isModdedItem') return Object.keys(target.mods).length > 0;
            else if (key == 'base') return target.base;
            else if (key == 'mods') return target.mods;
            else return (key in target.mods) ? target.mods[key] : target.base[key];
        },

        set(target, key, value, receiver) {
            return target.mods[key] = value;
        },
        
        deleteProperty(target, key) {
            return delete target.mods[key];
        },
    }) as any;

    return proxy as ModdedItem;
}