import Utils from "../Utils";
import { BoolConfig, BooleanMode, CategoryConfig, ConfigMap, FilterConfig, FilterTypes, NONE_KEY, OTHER_KEY, RangeConfig } from "./FilterConfig";
import { FilterSpec } from "./_index";
import { Option } from "./Option";
export type Value = null|string|number|boolean;

export enum FilterMode {
    AND = "and",
    OR = "or",
}

export class Filter<T> {
    config: FilterConfig<T>;

    // Instance Data - Boolean
    isEnabled: boolean|null;
    
    // Instance Data - Range
    min = 0;
    max = 0;
    
    // Instance Data - Text
    searchTerm = "";

    // Instance Data - Category
    filterMode = FilterMode.OR;
    selectedOptions: Option[] = [];
    triStateOptions: {[key: string]: boolean|null} = {};
    triStateFilterCache: { enabled: string[], disabled: string[] } = { enabled: [], disabled: [] }

    updateTriStateFilterCache() {
        // TODO: The only reason this doesn't result in an infinite update loop is because Vue is smart enough to prevent it. This may indicate a design issue that we should resolve.
        let cache = this.triStateFilterCache;
        cache.enabled.splice(0, cache.enabled.length);
        cache.disabled.splice(0, cache.disabled.length);

        for (let [key, value] of Object.entries(this.triStateOptions)) {
            if (value === true) cache.enabled.push(key);
            else if (value === false) cache.disabled.push(key);
        }
    }

    setSelected(keys: string[]) {
        if (this.isTriStateList) {
            for (let key of keys) {
                this.triStateOptions[key] = true;
            }
            this.updateTriStateFilterCache();
        }
        else if (this.isListFilter) {
            let config = this.config as CategoryConfig<T>;
            let options = config.options ?? [];
            for (let key of keys) {
                let optionFilter = options.filter(x => x.key == key);
                if (optionFilter.length != 1) {
                    console.warn('Invalid search result for option with key: ' + key, this);
                }
                else {
                    let option = optionFilter[0];
                    if (!this.selectedOptions.includes(option)) this.selectedOptions.push(option);
                }
            }
        }
        else {
            console.warn('Filter.setSelected is only supported for list filters', this);
        }
    }

    constructor(config: FilterConfig<T>) {
        this.config = config;
        this.isEnabled = this.isTriState ? null : false;
    }

    get isListFilter() { return this.config.type == FilterTypes.Category; }
    get isRangeFilter() { return this.config.type == FilterTypes.Range; }
    get isTextFilter() { return this.config.type == FilterTypes.Text; }
    get isBoolFilter() { return this.config.type == FilterTypes.Bool; }
    
    get name() { return this.config.name; }
    get description() { return this.config.description; }
    get isAdvanced() { return this.config.adv ?? false; }
    get filterGroup() { return this.config.filterGroup; }

    get boolConfig() { return this.config as BoolConfig<T>; }
    get rangeConfig() { return this.config as RangeConfig<T>; }
    get categoryConfig() { return this.config as CategoryConfig<T>; }
    
    get isTriState() { return this.boolConfig.boolMode == BooleanMode.TriState; }
    get isExclusive() { return this.boolConfig.boolMode == BooleanMode.ExclusiveFalse; }

    get toggleGroups() { return this.categoryConfig.toggleGroups; }
    get hideFilterToggle() { return this.categoryConfig.hideFilterToggle; }
    get isTriStateList() { return !this.hideFilterToggle; }
    
    get orMode() { return this.filterMode == FilterMode.OR; }
    get andMode() { return this.filterMode == FilterMode.AND; }

    get isActive(): boolean {
        if (this.isListFilter) {
            if (this.isTriStateList) return Object.values(this.triStateOptions).some(x => x!== null);
            else return this.selectedOptions.length > 0;
        }
        else if (this.isRangeFilter) {
            return (this.min != 0) || (this.max != 0);
        }
        else if (this.isTextFilter) {
            return this.searchTerm.length > 0;
        }
        else if (this.isBoolFilter) {
            if (this.isEnabled == null) return false;
            else return this.isEnabled || this.isExclusive || this.isTriState;
        }
        else {
            console.warn('Unknown filter type: ' + this.config.type, this.config);
            return false;
        }
    }

    _getKey(value: Value) {
        if (value == null) return NONE_KEY;
        else {
            let options = this.categoryConfig.options ?? [];
            let keys = options.map(x => x.key.toString());
            let lower = value.toString().trim().toLowerCase();
            if (keys.includes(lower)) return lower;
            else if (keys.includes(lower)) return lower;
            else return OTHER_KEY;
        }
    }

    isMatch(entry: T, filters: {[key: string]: Filter<T>}): boolean {
        let settings = (this.isListFilter) ? this.categoryConfig.settings : undefined;
        let temp = this.config.getValues(entry, filters, settings);
        let values = Array.isArray(temp) ? temp : (temp == null) ? [] : [temp];
        // TODO: support config.useNullConfig

        if (this.isListFilter) {
            let entryKeys = values.map(x => this._getKey(x), this);
            if (this.isTriStateList) {
                let enabled = this.triStateFilterCache.enabled;
                let disabled = this.triStateFilterCache.disabled;

                if (entryKeys.some(key => disabled.includes(key))) {
                    return false;
                }
                else if (entryKeys.length == 0 && disabled.includes(NONE_KEY)) {
                    return false;
                }

                if (enabled.length == 0) return true;
                else if (entryKeys.length == 0) {
                    return (this.orMode || enabled.length <= 1) && enabled.includes(NONE_KEY);
                }
                else if (this.andMode) return enabled.every(key => entryKeys.includes(key));
                else return enabled.some(key => entryKeys.includes(key));
            }
            else {
                if (values.length == 0) return (this.selectedOptions.length == 0) || ((this.orMode || this.selectedOptions.length <= 1) && this.selectedOptions.includes(this.categoryConfig.noneOption as Option));
                else if (this.selectedOptions.length == 0) return true;
                else if (this.andMode) return this.selectedOptions.every(
                    option => values.some(value => {
                        if (value == null) {
                            return option.key == NONE_KEY;
                        }
                        else {
                            let valueKey = (typeof value == 'string') ? value.trim().toLowerCase() : value;
                            return option.key == valueKey;
                        }
                    })
                );
                else return values.some(value => {
                    if (value == null) {
                        return this.selectedOptions.includes(this.categoryConfig.noneOption as Option);
                    }
                    else {
                        let valueKey = (typeof value == 'string') ? value.trim().toLowerCase() : value;
                        return this.selectedOptions.some((option: Option) => option.key == valueKey);
                    }
                });
            }
        }
        else if (this.isRangeFilter) {
            if (values.length == 0) values = [0];

            if (this.rangeConfig.stringConverter != undefined) {
                let convert = this.rangeConfig.stringConverter;
                values = values.map(x => (typeof x == 'string') ? convert(x) : x);
            }

            return values.some(v => {
                let value = v as number;
    
                if (this.min == null || this.min == 0) return (this.max == null || this.max == 0) ? true: value <= this.max;
                else if (this.max == null || this.max == 0) return value >= this.min;
                else return value >= this.min && value <= this.max;
            });
        }
        else if (this.isTextFilter) {
            if (this.searchTerm.length == 0) return true;
            else if (values.length == 0) return false;

            let wholeTerm = this.searchTerm.toLowerCase();
            let splitTerms = wholeTerm.split(' ');
            let lastIndex = splitTerms.length - 1;
            if (splitTerms[lastIndex].length == 0) splitTerms[lastIndex-1] = splitTerms[lastIndex-1] + ' ';
            if (splitTerms[0].length == 0 && splitTerms.length > 1) splitTerms[1] = ' ' + splitTerms[1];

            return values.some(value => {
                if (value == null) return false;
                let lowerValue = value.toString().toLowerCase();
                return lowerValue.includes(wholeTerm) || splitTerms.every(x => lowerValue.includes(x));
            });
        }
        else if (this.isBoolFilter) {
            if (values.length == 0) {
                if (!this.isTriState) console.warn('BoolFilter attempted to match empty list');
                else values = [false];
            }

            let targetValue = this.isEnabled;
            if (this.isTriState) return (targetValue == null) || values.some(x => x === targetValue);
            else if (this.isExclusive) return values.some(x => x === targetValue);
            else return (targetValue === false) || values.some(x => x === targetValue);
        }
        else {
            console.warn('Unknown filter type: ' + this.config.type, this.config);
            return true;
        }
    }

    clear() {
        this.min = 0;
        this.max = 0;
        this.selectedOptions = [];
        this.searchTerm = "";
        this.isEnabled = this.isTriState ? null : false;

        let tsos = this.triStateOptions;
        Object.keys(tsos).forEach(k => tsos[k] = null);
        this.updateTriStateFilterCache();
    }

    static filter<T>(list: T[], filterMap: {[key: string]: Filter<T>}): T[] {
        let filters = Object.values(filterMap).filter(x => x.isActive);
        filters.forEach(x => x.updateTriStateFilterCache());
        return list.filter(entry => filters.every(x => x.isMatch(entry, filterMap)));
    }
}

export class CatFactory {
    static hydrateAll<T>(map: ConfigMap<T>, allEntries: T[], preloaded?: {[key: string]: Option[]}) {
        if (preloaded == null) {
            for (let config of Object.values(map)) {
                if (config.type == FilterTypes.Category) {
                    CatFactory.hydrateOptions(config as CategoryConfig<T>, allEntries);
                }
            }
        }
        else {
            for (let [key, value] of Object.entries(map)) {
                if (value.type == FilterTypes.Category) {
                    let config = value as CategoryConfig<T>;
                    if (key in preloaded) {
                        config.options = preloaded[key];
                    }
                    else {
                        console.warn(`PL options not found for filter [${key}]`);
                        CatFactory.hydrateOptions(config, allEntries);
                    }
                }
            }
        }
    }

    static hydrateOptions<T>(config: CategoryConfig<T>, allEntries: T[]) {
        if (config.type != FilterTypes.Category) {
            console.warn(`hydrateOptions() called for non-category filter: [${config.type}]`, config);
        }

        // Skip non-list filters, and filters that have pre-calculated option counts
        if (config.options == null || config.options.length == 0 || config.options[0].count <= 0) {
            let options = [...(config.options ?? CatFactory.getAllOptions(config, allEntries))];
            if (config.noneOption != null && options.filter(x => x.key == NONE_KEY).length == 0) {
                options.unshift(config.noneOption);
            }
            if (config.otherOption != null && options.filter(x => x.key == OTHER_KEY).length == 0) {
                options.push(config.otherOption);
            }
    
            let optionIndex = {} as {[key: string]: Option};
            for (let option of options) {
                optionIndex[option.key.toString()] = option;
            }

            config.options = options;
            CatFactory.recountOptions(config, allEntries, optionIndex);

            // this.options = this.options.filter(x => x.count > 0);
            let index = 0;
            while (index < options.length) {
                let count = options[index].count;
                let threshold = config.otherThreshold ?? 1;
                if (count < threshold && options[index].key != OTHER_KEY && !(options[index].key == NONE_KEY && count > 0)) {
                    options.splice(index, 1);
                }
                else {
                    index += 1;
                }
            }

            // Recount OTHER option
            let other = config.otherOption;
            if (other) {
                // Reset other count
                other.count = 0;
            
                // Reset optionIndex
                optionIndex = {} as {[key: string]: Option};
                for (let option of options) {
                    optionIndex[option.key.toString()] = option;
                }

                for (let entry of allEntries) {
                    let values = config.getValues(entry, undefined, undefined); // undefined indicates this is a __getAll Call
                    values = Array.isArray(values) ? values : [values];
                    let keys = values.map(v => v?.toString().trim().toLowerCase());
                    let hasOther = keys.some(x => x != null && !(x in optionIndex));
                    if (hasOther) other.count += 1;
                }

                if (other.count == 0) {
                    let index = options.indexOf(other);
                    if (index >= 0) options.splice(index, 1);
                }
            }
        }
    }

    static recountOptions<T>(config: CategoryConfig<T>, allEntries: T[], optionIndex: {[key: string]: Option}) {
        config.options?.forEach(x => x.count = 0);
        for (let entry of allEntries) {
            let values = config.getValues(entry, undefined, undefined); // undefined indicates this is a __getAll Call
            if (values == null || (Array.isArray(values) && values.length == 0)) {
                if (config.noneOption) config.noneOption.count += 1;
            }
            else {
                values = Array.isArray(values) ? Utils.unique(values) : [values];
                for (let v of values) {
                    if (v == null) {
                        if (config.noneOption) config.noneOption.count += 1;
                    }
                    else {
                        let key = v.toString().trim().toLowerCase();
                        if (key in optionIndex) {
                            optionIndex[key].count += 1;
                        }
                        else if (config.otherOption) config.otherOption.count += 1;
                        else console.warn(`Filter [${config.name}] does not have option with key [${key}]`, {config, entry, value: v});
                    }
                }
            }
        }
    }

    static getAllOptions<T>(config: CategoryConfig<T>, allEntries: T[]): Option[] {
        let map = {} as {[key:string]: Option};
        for (let entry of allEntries) {
            let temp = config.getValues(entry, undefined, undefined); // undefined indicates this is a __getAll Call
            if (temp == null) continue;
            
            let values = Array.isArray(temp) ? temp : [temp];
            if (values.includes(null)) {
                console.warn('Found null mixed with other values', {filterName: config.name, entry, values});
            }

            for (let value of values) {
                if (value == null) continue;
                else if (!['number', 'string'].includes(typeof value)) {
                    console.warn(`Expected string or number but found ${typeof value}`, {filterName: config.name, entry, value, values});
                    continue;
                }

                let key = (typeof value == 'number') ? value : value.toString().trim().toLowerCase();
                if (!(key in map)) {
                    let optionName = (config.titleCase && typeof value == 'string') ? Utils.titleCase(value) : value.toString();
                    map[key] = new Option(key, optionName, 0);
                }
            }
        }

        return Object.values(map).sort((a, b) => Utils.sortAlphaNum(a.name, b.name));
    }

    static downloadFilterCache<T>(spec: FilterSpec<T>, catName: string) { // e.g. 'bestiary'
        let exp = {} as any;
        for (let [k, filter] of Object.entries(spec.configs)) {
            if (filter.type == FilterTypes.Category) {
                exp[k] = (filter as CategoryConfig<T>).options;
            }
        }
        Utils.download(`${catName}Filters.json`, JSON.stringify(exp, null, 4));
    }
}