import {Grant} from '@/ts/api/grants/_index';

type StringMap = {[key: string]: string};
type Dynamic = {[key: string]: any};

export class ActionGrant extends Grant {
    usesRefName: string; // The name of the stat to represent the number of uses remaining (if multiple grants reference the same stat, they will share uses-per-day). This stat will always be manual-enty. If this is unset, the action will not have a limited number of uses.
    maxUsesRefName: string; // The name of the stat to represent the max uses (usually *per day*). If multiple grants reference the same stat, they will stack (depending on ruleset). If this is unset, the action will not have a max-uses stat.
    maxUsesEquation: string; // The number of uses (generally per day) granted by this grant. This equation will be added as a bonus to the stat defined by maxUsesRefName.

    usesAmmo: boolean;
    provokes: boolean;
    rollGroups: RollGroup[];

    actionType: string;
    outsourceId: string; // If set, this action will serve as a reference to a different action, such as a spell.
    statAliases: StringMap; // refName to equationString

    constructor(json: Dynamic) {
        super(json);
        
        this.usesRefName = json.usesRefName ?? "";
        this.maxUsesRefName = json.maxUsesRefName ?? "";
        this.maxUsesEquation = json.maxUsesEquation ?? "";
        
        this.usesAmmo = json.usesAmmo ?? false;
        this.provokes = json.provokes ?? false;
        this.rollGroups = (json.rollGroups ?? []).map((x: Dynamic) => new RollGroup(x));
        
        this.actionType = json.actionType ?? "";
        this.outsourceId = json.outsourceId ?? "";
        this.statAliases = json.statAliases ?? {};
    }
}

export class RollGroup {
    refName: string;

    name: string; // This is displayed to users (e.g. "Attack", "To Hit", "Damage", "DC")
    versus: string; // This is displayed as subtext (if set), E.g. "Touch AC", "Fort", "CMD")
    dmgTypes: string; // This is displayed as subtext (if set), E.g. "B, P, and S", "Bludgeoning", "Fire")
    subtextDetails: string; // This is displayed as a tooltip over 'versus' or 'dmgTypes' if either is set
    baseValueEquation: string;

    diceType: string; // Dice enum, e.g. 'd6', 'd8', 'd10', etc
    numDice: string; // number of dice to roll

    constructor(json: Dynamic) {
        this.refName = json.refName ?? "";
        
        this.name = json.name ?? "";
        this.versus = json.versus ?? "";
        this.dmgTypes = json.dmgTypes ?? "";
        this.subtextDetails = json.subtextDetails ?? "";
        this.baseValueEquation = json.baseValueEquation ?? "";
        
        this.diceType = json.diceType ?? "";
        this.numDice = json.numDice ?? "";
    }
}