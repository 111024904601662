
























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { CategoryConfig, Filter, Option } from "@/ts/util/filters/_index";
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';

@Component({
    components: {
        ConfigElementPanel,
    }
})
export default class BistateFilterList extends Vue {
    @Prop({required: true})
    filter!: Filter<any>;

    @Prop({required: true})
    showOptionFilter!: boolean;

    @Prop({default: ''})
    listStyle!: string;

    get config(): CategoryConfig<any> {
        return this.filter.config as CategoryConfig<any>;
    }

    onListInput(group: Filter<any>, newValue: string[]) {
    }

    onListShiftClick(group: Filter<any>, event: any) {
    }
}
