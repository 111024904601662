import ItemEntry from '@/ts/api/items/ItemEntry';
import Links from "@/ts/api/sharedModels/Links";
import axios, { AxiosResponse } from 'axios';
import abpIndex from '@/data/abpIndex.json';
import Vue from 'vue';

export class ItemDAO {
    static async getIndex(): Promise<ItemEntry[]> {
        // TODO: Check local storage first, then commence update
        let res: AxiosResponse<ItemEntry[]> = await axios.get('/api/items/index');
        let items = res.data;
        items.forEach(x => process(x));

        return items;
    }

    static async fill(index: ItemEntry[]): Promise<ItemEntry[]> {
        // TODO: Check local storage first, then commence update
        let res: AxiosResponse<{[key: string]: string}> = await axios.get('/api/items/fills');
        let fills = res.data;

        for (let entry of index) {
            let markdown = fills[entry.id];
            if (markdown == null) {
                console.warn(`Fill not found: ${entry.id} (${entry.name})`);
                continue;
            }
            else if (markdown.startsWith('$')) {
                let id = markdown.slice(1);
                markdown = fills[id];
                if (markdown == null) {
                    console.warn(`MD reference (${id}) not found for [${entry.id}]`);
                    continue;
                }
            }

            Vue.set(entry, 'md', markdown);
        }

        return index;
    }
}

function process(item: ItemEntry) {
    // Replace _id with id
    let dyn = item as any;
    if (dyn._id) {
        if (item.id) console.warn('Unexpected id transition for item', {from: item.id, to: dyn._id});
        item.id = dyn._id;
        delete dyn['_id'];
    }

    // Populate Automatic Bonus Progression flags
    if (abpIndex.abpOnly.includes(item.id)) item.abpOnly = true;
    if (abpIndex.abpPlus.includes(item.id)) item.abpPlus = true;

    // Replace url with Links
    if (dyn.url) {
        if (item.links) console.warn('Unexpected links transition for item', {from: item.links, to: dyn.url});
        item.links = {} as Links;
        item.links.aon = dyn.url;
        delete dyn['url'];
    }

    // Reformat Ioun Stone names
    if (item.itemCategory == 'Ioun Stones') {
        let name = item.name;
        if (item.shape) name += ' ' + item.shape;
        if (item.shape != 'Stone') name += ' Ioun Stone';
        if (item.strength) name += ` (${item.strength})`;

        item.name = name;
    }
}