import { Gamebook, GamebookParser } from "./parsing/GamebookParser";
import { CombatScene, Scene, SectionTypes } from "./parsing/Scene";
import SanityCheck from "../util/SanityCheck";
import { SceneLog } from "./SceneLog";
import Vue from "vue";
import { ConditionalBlock, ScriptBlock, ScriptInstruction } from "./parsing/Blocks";
import { CombatSession } from "./CombatSession";
import { EntityLog } from "./EntityLog";
import { GBInventory } from "./GBInventory";
import { GBJournal } from "./GBJournal";

export class GamebookSession {
    private _currentScene: Scene|null = null;
    combatSession: CombatSession|null = null;
    popupStack: Scene[] = [];

    player: PlayerStats;
    settings: GamebookSettings;
    gamebook: Gamebook;
    sceneLog: SceneLog;
    sceneHistoryStack: string[] = [];
    
    macros: DiceMacro[] = [];
    entityLog: EntityLog = new EntityLog();
    variables: {[key: string]: boolean} = {};

    reset() {
        this.currentScene = null;
        this.combatSession = null;

        this.sceneLog.clear();
        this.entityLog.clear();
        this.popupStack.splice(0, this.popupStack.length);
        this.sceneHistoryStack.splice(0, this.sceneHistoryStack.length);

        Vue.set(this, 'variables', {});
    }

    constructor(gamebook: Gamebook) {
        this.gamebook = gamebook;
        this.sceneLog = new SceneLog();
        this.player = new PlayerStats();
        this.settings = new GamebookSettings();
    }

    get currentScene(): Scene|null {
        return this._currentScene;
    }

    set currentScene(value: Scene|null) {
        this._currentScene = value;
        this.updateCombatSession();
    }

    private updateCombatSession() {
        // TODO: Save and restore combat sessions from already visited scenes (e.g. if a user presses the back button, they return to the same combat session)
        if (this.currentScene?.type == SectionTypes.Combat) {
            let combat = this.currentScene as CombatScene;
            let gamebook = this.gamebook;
            let enemies = combat.enemyIds.map(id => gamebook.enemies[id]);
            let events = combat.eventIds.map(id => gamebook.events[id]);
            
            SanityCheck.warnIf(enemies.some(x => x == undefined), 'EnemyId not found', {ids: combat.enemyIds, enemies});
            SanityCheck.warnIf(events.some(x => x == undefined), 'EventId not found', {ids: combat.eventIds, events});
            this.combatSession = new CombatSession(
                enemies.filter(x => x != undefined),
                events.filter(x => x != undefined),
                combat.simulcap
            );
        }
        else {
            this.combatSession = null;
        }
    }
    
    setScene(sceneId: string, isUndo=false) {
        let scenes = this.gamebook.scenes;
        let combats = this.gamebook.combats;

        if (this.currentScene && !isUndo) this.sceneHistoryStack.push(this.currentScene.id);
        
        let target = (sceneId in scenes) ? scenes[sceneId] : combats[sceneId];
        if (target == undefined) console.warn('Scene not found', sceneId);
        Vue.set(this, 'currentScene', target);
    }

    undo() {
        let lastSceneId = this.sceneHistoryStack.pop();
        SanityCheck.notNull(lastSceneId, 'Undo() was called on gamebook, but no last scene has been registered.');
        if (lastSceneId != null) {
            this.setScene(lastSceneId, true);
        }
    }

    backToCover() {
        this.currentScene = null;
    }

    execute(block: ScriptBlock) {
        // TODO: Only execute once
        // TODO: Save this context and make it undoable with the back button
        if (block.instruction == ScriptInstruction.Set) {
            if (!/^\$[a-zA-Z][a-zA-Z0-9]*$/g.test(block.target)) {
                console.warn('Unsupported set target format', block);
            }
            else {
                Vue.set(this.variables, block.target, block.value);
            }
        }
        else {
            console.warn('Unsupported ScriptInstruction: ' + block.instruction, block);
        }
    }

    evaluate(block: ConditionalBlock) {
        // TODO: if we seperate script executions from block displays, remember to execute any scripts in the resulting conditional blocks
        let condition = block.condition;
        // TODO: support variable comparisons (e.g. $someVariable is $someOtherVariable)
        // TODO: support non-boolean values
        if (/^\$[a-zA-Z][a-zA-Z0-9]* is ((true)|(false))$/g.test(condition)) {
            let split = condition.split(' is ');
            let varValue = this.variables[split[0]] ?? false;
            let targetValue = split[1] == 'true';
            return varValue == targetValue;
        }
        else if (/^round [<>=]=? [0-9]+$/g.test(condition)) {
            let split = condition.split(' ');
            let comparator = split[1];
            let round = this.combatSession?.round;
            let value = parseInt(split[2]);
            if (round == undefined) {
                console.warn('Attempted to evaluate round conditional with no active CombatSession', {session: this, condition, split, block});
                return false;
            }
            else if (value == null || isNaN(value)) {
                console.warn('Parse error for conditional value', {condition, split, block});
                return false;
            }
            else if (comparator == '>') return round > value;
            else if (comparator == '<') return round < value;
            else if (comparator == '>=') return round >= value;
            else if (comparator == '<=') return round <= value;
            else if (comparator == '=' || comparator == '==') return round == value;
            else {
                console.warn('Unsupported conditional format', {condition, block});
                return false;
            }
        }
        else {
            console.warn('Unsupported conditional format', {condition, block});
            return false;
        }
    }

    openPopup(documentId: string) {
        let isInfo = documentId in this.gamebook.infoDocs;
        let isHandout = documentId in this.gamebook.handouts;

        if (!isInfo && !isHandout) {
            console.warn('DocumentId not found: ' + documentId, {gamebook: this.gamebook, session: this});
        }
        else {
            let script = isInfo ? `[info:${documentId}]` : `[handout:${documentId}]`;
            let blocks = GamebookParser.parseBlocks(script);
            let scene = new Scene('');
            scene.align = 'left';
            scene.blocks.push(...blocks);
            this.popupStack.push(scene);
        }
    }

    closePopup() {
        this.popupStack.pop();
    }

    get canUndo() {
        return this.sceneHistoryStack.length > 0;
    }
}

export class DiceMacro {
    components: MacroComponent[] = [];
    name: string;

    constructor(name: string) {
        this.name = name;
    }
}

export class MacroComponent {
    name: string;
    key: string;
    equationString: string;

    constructor(name: string, equation: string, key?: string) {
        this.name = name;
        this.key = key ?? name.toLowerCase().replace(/ +/g, '_');
        this.equationString = equation;
    }
}

export class PlayerStats {
    name = "Player's Character";
    inventory = new GBInventory();
    journal = new GBJournal();
    currentHp = 10;
    maxHp = 10;
    init = 0;
    ac = 0;
}

export class GamebookSettings {
    /** If true, enemy attacks will automatically decide if they hit or miss based on the player's AC */
    autoCalcMonsterAttacks = true;

    /** If true, enemy attacks that hit will automatically subtract their damage from the player's hp */
    autoApplyDamageFromMonsters = false;
}