























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SmallMode, TableConfig } from '@/ui/AdvancedSearch/TableConfig';
import DataCard from '@/ui/DataCards/DataCard.vue';

@Component({
    components: {
        DataCard
    }
})
export default class TableResults<T> extends Vue {
    @Prop({required: true})
    results!: T[];
    
    @Prop({required: true})
    config!: TableConfig<T>;

    itemsPerPage = 10;
    expandedRows = [] as any[];

    windowWidth = 0;
    
    mounted() {
        this.windowWidth = window.innerWidth;
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

    bodyClass(smMode: SmallMode) {
        if (smMode == SmallMode.Compress) return 'sm-compress';
        else if (smMode == SmallMode.Hide) return 'sm-none';
        else return '';
    }

    imageSearch(entry: T) {
        let getTerm = this.config.getSearchTerm;
        let term = getTerm ? getTerm(entry) : null;
        if (term) window.open(`https://google.com/search?tbm=isch&q=${encodeURIComponent(term)}`, '_blank');
    }

    redirect(entry: T) {
        let getLinks = this.config.getLinks;
        let link = getLinks ? (getLinks(entry).aon ?? '') : '';

        // TODO: Use user-settings to determine which link (AON or D20) to use
        let domainCheck = ['https://www.aonprd.com', 'https://aonprd.com'].some(x => link.startsWith(x));
        console.assert(domainCheck, 'External link failed domain check', {config: this.config, link});
        if (domainCheck) window.open(link, '_blank');
    }
}
