export default class Toaster {
    add: (toastObj: any) => void;

    constructor(add: (toastObj: any) => void) {
        this.add = add;
    }

    private toast(severity: string, summary: string, detail: string|null, life: number, group: Position) {
        // TODO: support 'group' argument
        this.add({severity, summary, detail, life, group});
    }

    todo(featureName: string, life=3000) {
        this.info('Coming Soon!', `Functionality for ${featureName} will be added in a future release.`, life);
    }

    info(summary: string, detail: string|null = null, life=3000, group = Position.TopRight) {
        this.toast('info', summary, detail, life, group);
    }

    success(summary: string, detail: string|null = null, life=3000, group = Position.TopRight) {
        this.toast('success', summary, detail, life, group);
    }

    warn(summary: string, detail: string|null = null, life=3000, group = Position.TopRight) {
        this.toast('warn', summary, detail, life, group);
    }

    error(summary: string, detail: string|null = null, life=3000, group = Position.TopRight) {
        this.toast('error', summary, detail, life, group);
    }
}

export enum Position {
    BottomRight = 'br',
    TopRight = 'tr'
}