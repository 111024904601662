















































































































































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ConfigElementPanel from '@/ui/_components/config/ConfigElementPanel.vue';
import EncounterView from '@/ui/Generators/EncounterView.vue';
import RerollIcon from '@/ui/_components/icons/RerollIcon.vue';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';
import DataCard from '@/ui/DataCards/DataCard.vue';

import CombatGenerator, {Combat} from '@/ts/generators/CombatGenerator';
import LootGenerator from '@/ts/generators/LootGenerator';

import { Filter, FilterSpec, FilterLayout, FilterConfig, BoolConfig } from '@/ts/util/filters/_index';
import BestiaryEntry from '@/ts/api/bestiary/BestiaryEntry';
import { LootConfig } from '@/ts/generators/LootConfig';
import { Loot } from '@/ts/generators/Loot';
import Toaster from '@/ts/ui_integrations/Toaster';
import { Format } from '@/ts/util/Format';
import CRS from '@/ts/util/pfmath/CRS';
import ItemEntry, { ItemDisplays } from '@/ts/api/items/ItemEntry';
import { NumberSetting, RangeSetting } from '@/ts/util/config/Setting';
import { CombatConfig } from '@/ts/generators/CombatConfig';

type Result = {encounter: Combat, loot: Loot};
const defaultFilterConfig = new BoolConfig<BestiaryEntry>('UNINSTANTIATED', (x, y) => false);

@Component({
    components: {
        ConfigElementPanel,
        EncounterView,
        RerollIcon,
        DataCard,
        Sidebar,
    }
})
export default class EncounterGenerator extends Vue {
    category = (this as any).$route.query.category;
    systemName = (this as any).$route.query.system ?? "PF1E";

    lootConfig = new LootConfig([], {});
    combatConfig = new CombatConfig(this.bestiary ?? [], {
        source: new Filter(defaultFilterConfig),
        environment: new Filter(defaultFilterConfig),
        type: new Filter(defaultFilterConfig),
        subtype: new Filter(defaultFilterConfig),
    });

    get environmentFilter(): Filter<BestiaryEntry> { return this.combatConfig.filters['environment'] }
    get creatureTypeFilter(): Filter<BestiaryEntry> { return this.combatConfig.filters['type'] }
    get creatureSubtypeFilter(): Filter<BestiaryEntry> { return this.combatConfig.filters['subtype'] }
    
    results = [] as Result[];
    expandedRows = [] as any[];

    itemTarget: ItemEntry|null = null;
    showMoreBestiaryFilters = false;
    showMoreLootFilters = false;
    displayModal = false;
    overlayIndex = 1;

    lootWeightsTab = false;
    showSidebarRight = true;
    showSidebar = true;
    
    iterations = new NumberSetting('# to generate', 'The number of times you want to run the generator', 1, 50).setInitial(10);
    encounterCountRange = new RangeSetting('Generate', 'The number of encounters to generate', 1, 25); // TODO: This is unused
    displayOptions = ['Table', 'Cards'];
    display = 'Table';

    generatorOptions = ['Encounter']; // ['Encounter', 'Combat', 'Loot'];
    systemOptions = ['Pathfinder (1E)'];

    selectedGenerator = 'Encounter';
    selectedSystem = 'Pathfinder (1E)';

    toaster = new Toaster(this.$toast.add);
    itemsPerPage = 10;

    mounted() {
        this.updateConfigLinks();
        this.updateConfigItems();
        this.updateConfigBestiary();
        
        if (window.innerWidth > 960) {
            this.showSidebar = true;
            this.showSidebarRight = true;
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 960) {
                this.showSidebar = true;
                this.showSidebarRight = true;
            }
        });
    }

    @Watch('$root.filters.bestiary', {deep: true})
    @Watch('$root.filters.items', {deep: true})
    updateConfigLinks() {
        let root: any = this.$root;
        updateFilterMap(root.filters.bestiary, this.combatConfig.filters);
        updateFilterMap(root.filters.items, this.lootConfig.filters);
    }

    @Watch('$root.items', {deep: false})
    updateConfigItems() {
        this.lootConfig.items = this.items;
        // TODO: Set filters
    }

    @Watch('$root.bestiary', {deep: false})
    updateConfigBestiary() {
        this.combatConfig.bestiary = this.bestiary;
        // TODO: Set filters
    }

    mobileSidebarButton() {
        if (this.showSidebar && this.showSidebarRight) {
            this.showSidebarRight = false;
        }
        else {
            this.showSidebarRight = false;
            this.showSidebar = false;
            this.generate();
        }
    }

    toggle(event: any, item: ItemEntry) {
        let newIndex = this.overlayIndex == 2 ? 1 : 2;
        let oldOverlay: any = this.$refs[`op_${this.overlayIndex}`];
        let newOverlay: any = this.$refs[`op_${newIndex}`];

        if (Array.isArray(oldOverlay)) oldOverlay = oldOverlay[0];
        if (Array.isArray(newOverlay)) newOverlay = newOverlay[0];
        
        if (this.itemTarget == item) {
            oldOverlay.show(event);
        }
        else {
            oldOverlay?.hide();
            newOverlay.show(event);

            this.overlayIndex = newIndex;
            this.itemTarget = item;
        }
    }

    get isLoading() {
        return this.bestiary?.length == 0;
    }

    get bestiary(): BestiaryEntry[] {
        return (this.$root as any).bestiary;
    }

    get items(): ItemEntry[] {
        return (this.$root as any).items;
    }

    get filteredBestiary(): BestiaryEntry[] {
        return this.combatConfig.filteredBestiary;
    }

    get budgetModeOptions() {
        return this.lootConfig.budgetMode.options;
    }
    
    get budgetMagnitudeOptions() {
        return this.lootConfig.budgetMagnitude.options;
    }

    get allSources() {
        let sources: string[] = [];
        for (let source of (this.$root as any).bestiary.map((x:any) => x.source)) {
            if (!sources.includes(source)) {
                sources.push(source);
            }
        }
        sources.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        return sources;
    }

    get parchments() {
        return ['parchment-a', 'parchment-b', 'parchment-c', 'parchment-d', 'parchment-e'];
    }

    get budgetModeDescription() {
        return this.lootConfig.budgetMode.description;
    }

    get otherBestiaryFilters() {
        if (!this.showMoreBestiaryFilters) return [];
        else {
            let exclude = [this.environmentFilter, this.creatureTypeFilter, this.creatureSubtypeFilter];
            return Object.values(this.combatConfig?.filters ?? {}).filter(x => !exclude.includes(x) && x.name != 'Name');
        }
    }

    get otherLootFilters() {
        return this.showMoreLootFilters ? Object.values(this.lootConfig?.filters ?? {}).filter(x => x.name != 'Name') : [];
    }

    get cardConfig() {
        return this.itemTarget ? ItemDisplays.getCardConfig(this.itemTarget) : null;
    }

    crDisplay(cr: number): string {
        return CRS.getCRString(cr);
    }
    
    generate() {
        let combatGen = new CombatGenerator(this.combatConfig);
        let lootGen = new LootGenerator('');

        // Clear results
        this.results.splice(0, this.results.length);

        // Generate new results
        let maxTries = 100;
        for (let i = 0; i < maxTries && this.results.length < this.iterations.getGtdSnapshot(1); i++) {
            try {
                let encounter = combatGen.generate();
                if (encounter.components.length > 0) {
                    let loot = lootGen.generate(encounter, this.lootConfig);
                    this.results.push({encounter: encounter, loot: loot});
                }
                else {
                    console.warn('Generator produced 0-combatant result. Rerolling.')
                }
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    rerollCombat(res: Result): void {
        let combatGen = new CombatGenerator(this.combatConfig);
        let maxTries = 100;
        for (let i = 0; i < maxTries; i++) {
            try {
                res.encounter = combatGen.generate();
                break;
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    rerollLoot(res: Result): void {
        let lootGen = new LootGenerator('');
        let maxTries = 100;
        for (let i = 0; i < maxTries; i++) {
            try {
                res.loot = lootGen.generate(res.encounter, this.lootConfig);
                break;
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    rerollEncounter(slotProps: {index: number, data: Result}) {
        let combatGen = new CombatGenerator(this.combatConfig);
        let lootGen = new LootGenerator('');

        // Generate new results
        let maxTries = 100;
        for (let i = 0; i < maxTries; i++) {
            try {
                let encounter = combatGen.generate();
                let result = {
                    encounter: encounter,
                    loot: lootGen.generate(encounter, this.lootConfig)
                }
                this.results.splice(slotProps.index, 1, result);
                break;
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    valueString(arg: number) {
        return Format.currency(arg).trim();
    }

    showFilterModal() {
        this.displayModal = true;
        this.toaster.info('This feature is Coming Soon!');
    }
}

function updateFilterMap<T>(filterSpec: FilterSpec<T>, filterMap: {[key: string]: Filter<T>}) {
    if (filterSpec == null || filterMap == null) return;

    for (let [key, rootConfig] of Object.entries(filterSpec.configs)) {
        if (key in filterMap) Vue.set(filterMap[key], 'config', rootConfig);
        else Vue.set(filterMap, key, new Filter(rootConfig));
    }
}
