import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/ui/_pages/Home.vue';
import Contact from '@/ui/_pages/Contact.vue';
import Roadmap from '@/ui/_pages/Roadmap.vue';
import AboutUs from '@/ui/_pages/AboutUs.vue';
import Support from '@/ui/_pages/Support.vue';
import Upcoming from '@/ui/_pages/Upcoming.vue';
import Changelog from '@/ui/_pages/Changelog.vue';
import Disclaimers from '@/ui/_pages/Disclaimers.vue';

import DungeonGeneratorMockup from '@/ui/_mockups/DungeonGeneratorMockup.vue';

import GamebookUI from '@/ui/Gamebooks/GamebookUI.vue';
import EncounterGenerator from '@/ui/Generators/EncounterGenerator.vue';
import GeneratorConfig from '@/ui/Generators/GeneratorConfig.vue';
import GeneratorConfig2 from '@/ui/Generators/admin/GeneratorConfig2.vue';
import PartnerFAQ from '@/ui/Gamebooks/PartnerFAQ.vue';
import GamebooksFAQ from '@/ui/Gamebooks/GamebooksFAQ.vue';
import GamebooksAbout from '@/ui/Gamebooks/GamebooksAbout.vue';
import SimpleGenerators from '@/ui/Generators/SimpleGenerators.vue';
import ShopGeneratorPage from '@/ui/Generators/ShopGeneratorPage.vue';
import PointBuyCalculator from '@/ui/PointBuyCalculator/PointBuyCalculator.vue';
import RoadmapCMS from '@/ui/AdminPages/RoadmapCMS.vue';
import PolymorphTool from '@/ui/AdvancedSearch/PolymorphTool.vue';
import AdvancedItemSearch from '@/ui/AdvancedSearch/AdvancedItemSearch.vue';
import AdvancedSpellSearch from '@/ui/AdvancedSearch/AdvancedSpellSearch.vue';
import AdvancedMonsterSearch from '@/ui/AdvancedSearch/AdvancedMonsterSearch.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Home | Artemis'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: AboutUs,
        meta: {
            title: 'About | Artemis'
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: 'Contact Artemis'
        }
    },
    /*
    {
        path: '/pointbuy',
        name: 'PointBuyCalculator',
        component: PointBuyCalculator,
        meta: {
            title: 'Point Buy Calculator - Artemis'
        }
    },
    */
    {
        path: '/changelog',
        name: 'Changelog',
        component: Changelog,
        meta: {
            title: 'Changelog | Artemis'
        }
    },
    {
        path: '/roadmap',
        name: 'Roadmap',
        component: Roadmap,
        meta: {
            title: 'Roadmap | Artemis'
        }
    },
    {
        path: '/disclaimers',
        name: 'Disclaimers',
        component: Disclaimers,
        meta: {
            title: 'Disclaimers | Artemis'
        }
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
        meta: {
            title: 'Support Artemis!'
        }
    },
    {
        path: '/gamebooks',
        name: 'Gamebook Directory',
        component: GamebooksAbout,
        meta: {
            title: 'Solo RPG Gamebooks | Artemis'
        }
    },
    {
        path: '/gamebooks/faq',
        name: 'Gamebook FAQ',
        component: GamebooksFAQ,
        meta: {
            title: 'Solo RPG Gamebooks | Artemis'
        }
    },
    {
        path: '/partners/about',
        name: 'Partners',
        component: PartnerFAQ,
        meta: {
            title: 'Gamebook Partners | Artemis'
        }
    },
    {
        path: '/gamebooks/:gamebookId',
        name: 'Solo RPG Gamebooks',
        component: GamebookUI,
        meta: {
            title: 'Solo RPG Gamebooks | Artemis'
        }
    },
    {
        path: '/generators/config',
        name: 'GeneratorConfig2',
        component: GeneratorConfig2,
        meta: {
            title: 'Generator Config | Artemis'
        }
    },
    {
        path: '/generators/:category',
        name: 'EncounterGenerator',
        component: EncounterGenerator,
        meta: {
            title: 'Encounter Generator | Artemis'
        }
    },
    {
        path: '/names/generators',
        name: 'GeneratorConfig',
        component: GeneratorConfig,
        meta: {
            title: 'Generator Config | Artemis'
        }
    },
    {
        path: '/generate/shops',
        name: 'ShopGeneratorPage',
        component: ShopGeneratorPage,
        meta: {
            title: 'Shop Generator | Artemis'
        }
    },
    {
        path: '/simple/generators',
        name: 'SimpleGenerators',
        component: SimpleGenerators,
        meta: {
            title: 'Generators | Artemis'
        }
    },
    {
        path: '/spells/advanced_search',
        name: 'AdvancedSpellSearch',
        component: AdvancedSpellSearch,
        meta: {
            title: 'Advanced Spell Search | Artemis'
        }
    },
    {
        path: '/items/advanced_search',
        name: 'AdvancedItemSearch',
        component: AdvancedItemSearch,
        meta: {
            title: 'Advanced Item Search | Artemis'
        }
    },
    {
        path: '/bestiary/advanced_search',
        name: 'AdvancedMonsterSearch',
        component: AdvancedMonsterSearch,
        meta: {
            title: 'Advanced Monster Search | Artemis'
        }
    },
    {
        path: '/tools/polymorph',
        name: 'PolymorphTool',
        component: PolymorphTool,
        meta: {
            title: 'Polymorph Tool | Artemis'
        }
    },
    {
        path: '/preview/dgm51830',
        name: 'DungeonGeneratorMockup',
        component: DungeonGeneratorMockup,
        meta: {
            title: 'Dungeon Generator | Artemis'
        }
    },
    {
        path: '/admin/roadmap',
        name: 'RoadmapCMS',
        component: RoadmapCMS,
        meta: {
            title: 'Roadmap Editor | Artemis'
        }
    },
    {
        path: '*',
        name: 'Upcoming',
        component: Upcoming,
        meta: {
            title: 'Artemis'
        }
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

export default router;
