import {Grant} from '@/ts/api/grants/_index';

type Dynamic = {[key: string]: any};

export class ContentGrant extends Grant {
    targetId: string;

    constructor(json: Dynamic) {
        super(json);
        this.targetId = json.targetId ?? "";
    }
}