




















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ImageDetails, {CropData} from '@/ts/api/images/ImageDetails';
import Utils from '@/ts/util/Utils';

@Component
export default class ImageView extends Vue {
    @Prop({required: true})
    image!: ImageDetails;
    
    @Prop({required: true})
    height!: number;

    @Prop({required: true})
    width!: number;

    editMode = false;
    ox = 0;
    oy = 0;

    cx = 0;
    cy = 0;

    get viewport(): {width: number|boolean, height: number|boolean, left: number, top: number} {
        let crop = this.image.getClosestCrop(this.width, this.height);
        if (crop == null) {
            return {
                width: false,
                height: false,
                left: 0,
                top: 0
            };
        }
        else {
            let imgSize = crop.dimentions;
            let viewport = crop.viewport;
            let scale = this.width / viewport.width;
            let final = {
                width: imgSize.width * scale,
                height: imgSize.height * scale,
                left: viewport.x * scale,
                top: viewport.y * scale
            };

            // TODO: Force width/height
            let bottom = final.top + final.height;
            let right = final.left + final.width;
            if (bottom < this.height) {
                final.top = this.height - final.height;
            }
            if (right < this.width) {
                final.left = this.width - final.width;
            }

            return final;
        }
    }

    onDragged(arg: {el: HTMLElement, first: boolean, last: boolean, deltaX: number, deltaY: number, offsetX: number, offsetY: number, clientX: number, clientY: number}) {
        if (arg.first || arg.last) return;
        this.ox += arg.deltaX;
        this.oy += arg.deltaY;
    }

    acceptCrop() {
        // TODO: Actually commit changes
        this.cx = this.ox;
        this.cy = this.oy;
        this.editMode = false;
    }

    rejectCrop() {
        // TODO: Discard changes
        this.ox = this.cx;
        this.oy = this.cy;
        this.editMode = false;
    }
}
