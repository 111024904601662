


























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import { DiceRoll, SceneLog, SceneLogEntry } from '@/ts/gamebooks/SceneLog';
var images = require.context('@/assets/icons/dice/silos', false, /\.svg$/);

@Component({
    components: {
    }
})
export default class SceneLogUI extends Vue {
    @Prop({required: true})
    sceneLog!: SceneLog;

    imgUrl(fileName: string) {
        const dice = ['d4.svg', 'd6.svg', 'd8.svg', 'd10.svg', 'd12.svg', 'd20.svg'];
        if (!dice.includes(fileName)) fileName = 'd4.svg';
        return images(`./${fileName}`);
    }

    isObj(obj: any) {
        return typeof obj == 'object';
    }

    getRolls(entry: SceneLogEntry) {
        let rollEntries = entry.inlineSegments.filter(x => this.isObj(x), this) as DiceRoll[];
        return rollEntries.flatMap(x => x.diceResults);
    }

    getSvgClasses(roll: [number, number, boolean]) {
        let classes = '';
        if (roll[0] == roll[1]) classes = 'svg-shadow crit-success';
        else if (roll[1] == 1) classes = 'svg-shadow crit-fail';

        if (roll[2]) {
            classes += ' discarded';
        }
        return classes.trim();
    }

    diceTooltip(roll: DiceRoll) {
        return roll.tooltip;
    }
}
