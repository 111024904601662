export default class MDBuilder {
    md = "";

    field(name: string, value?: string, altValue?: string) {
        let line = name ? `**${name}:** ` : '';
        if (value) {
            this.md += '\n\n' + line + value;
        }
        else if (altValue) {
            this.md += '\n\n' + line + altValue;
        }
    }

    li(value?: string) {
        if (value) {
            let lines = this.md.split('\n');
            let last = lines[lines.length - 1];
            if (last.trim().length != 0) {
                if (last.trim().startsWith('*')) this.md += '\n';
                else this.md += '\n\n';
            }

            let indent = '';
            this.md += `${indent}* ${value}`;
        }
    }

    _header(toLine: (x: string) => string, value?: string, altValue?: string) {
        if (value) this.md += `\n\n${toLine(value)}`;
        else if (altValue) this.md += `\n\n${toLine(altValue)}`;
    }

    h2(value?: string, altValue?: string) {
        // A divider directly above an H2 indicates an empty section - remove the divider
        if (this.md.endsWith('\n\n---')) this.md = this.md.slice(0, this.md.length - '\n\n---'.length);
        this._header(x => `## ${x}`, value, altValue);
    }

    h3(value?: string, altValue?: string) {
        this._header(x => `### ${x}`, value, altValue);
    }

    h4(value?: string, altValue?: string) {
        this._header(x => `#### _${x}_`, value, altValue);
    }

    div() {
        this.md += '\n\n---';
    }

    build(preserveSuperscripts=false) {
        let md = this.md;

        if (!preserveSuperscripts) return md.replace(/<sup>[^M>]*M[^M>]*<\/sup>/g, '^M^').replace(/<sup>[^>]+<\/sup>/g, '');
        else return md;
    }
}