









































import Utils from '@/ts/util/Utils';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
}
