import {Bonus, Stat} from '@/ts/api/characters/_index';

type Dynamic = {[key: string]: any};
type StatMap = {[key: string]: Stat};
type BonusMap = {[key: string]: Bonus};

export class Value {
    refName: string;

    equation: string; // This is the current equation string. The origional equation is retreivable from the bonus grant template
    valueType: ValueType;
    inputMode: ValueMode;

    bonuses: BonusMap; // refName to Bonus
    components: StatMap; // refName to Stat
    derivations: StatMap; // refName to Stat

    _template = false; // PLACEHOLDER. DO NOT USE. Will remove once 'expectedFields' is supported for SanityCheck.objFieldCompare()

    constructor(json: Dynamic) {
        if (json == null) json = {};

        this.refName = json.refName ?? "";

        this.equation = json.equation ?? "";
        this.valueType = json.valueType ?? ValueType.NUMBER;
        this.inputMode = json.inputMode ?? ValueMode.DEFAULT;

        this.bonuses = Bonus.instantiate(json.bonuses);
        this.components = Stat.instantiate(json.components);
        this.derivations = Stat.instantiate(json.derivations);
    }
    
    get isStat(): boolean { console.warn('Value.isStat called directly'); return (this instanceof Stat); }
    get isBonus(): boolean { console.warn('Value.isBonus called directly'); return (this instanceof Bonus); }
}

export enum ValueType {
    STRING = "string",
    NUMBER = "number",
    INT = "integer",
    ENUM = "enum"
}

export enum ValueMode {
    DEFAULT = 0, // The equation string and bonuses will be evaluated normally
    DIRECT = 1, // The equation string will be interpreted literally; bonuses will be ignored
    OVERRIDE = 2 // The equation string will be evaluated, but bonuses will be ignored
}