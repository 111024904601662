
import { RoadmapEntry } from '@/ts/api/roadmap/RoadmapEntry';
import Utils from '@/ts/util/Utils';
import axios from 'axios';

export class RoadmapDAO {
    static async getCurrent(): Promise<RoadmapEntry[]> {
        // TODO: Check local storage first, then commence update
        let res: any = await axios.get('/api/roadmap/current');

        let get = (raw: any) => {
            let entry = new RoadmapEntry(raw.name, raw.type);
            Utils.overwrite(entry, raw);

            entry.children = entry.children.map(x => get(x));
            return entry;
        }

        let array = (res.data.phases ?? []).map((x: any) => get(x));
        return array;
    }
    
    static async getNext(): Promise<RoadmapEntry[]> {
        // TODO: Check local storage first, then commence update
        let res: any = await axios.get('/api/roadmap/next');

        let get = (raw: any) => {
            let entry = new RoadmapEntry(raw.name, raw.type);
            Utils.overwrite(entry, raw);

            entry.children = entry.children.map(x => get(x));
            return entry;
        }

        let array = (res.data.phases ?? []).map((x: any) => get(x));
        return array;
    }

    static async patch(phases: RoadmapEntry[], authInput: string) {
        let body = {
            phases: Utils.deepCopy(phases),
            auth: authInput
        };
        let response = await axios.post('/api/roadmap/next', body);
        return response;
    }
}