









































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import TemplateDisplay from '@/ui/Generators/admin/TemplateDisplay.vue';
import GroupDisplay from '@/ui/Generators/admin/GroupDisplay.vue';
import Sidebar from '@/ui/_components/page_layout/Sidebar.vue';

import Toaster from '@/ts/ui_integrations/Toaster';
import { LoreGenDAO } from '@/ts/api/loregen/LoreGenDAO';
import { ElementTemplate, TemplateGroup, TemplateOrGroup, TemplateTypes } from '@/ts/util/loregen2/Templates';
import Utils from '@/ts/util/Utils';
import Generator from '@/ts/util/loregen2/Generator';
import Segment from '@/ts/util/loregen/Segment';

@Component({
    components: {
        TemplateDisplay,
        GroupDisplay,
        Sidebar,
    }
})
export default class GeneratorConfig2 extends Vue {
    current: TemplateOrGroup|null = null;
    result: Segment|null = null;

    genWarning: string = "";

    toaster = new Toaster(this.$toast.add);
    contextItems: any[] = [];
    contextIndex = 1;
    authInput = "";

    selectionKeys = {};
    expandedKeys = {};

    nodes: any[] = [];

    @Watch('templates', {deep: true})
    updateNodes() {
        Utils.sync(
            this.templates,
            this.nodes,
            (s: TemplateOrGroup, t: any) => s.id == t.key,
            (s: TemplateOrGroup) => TemplateGroup.getChildren(s),
            (t: any) => t.children ?? [],
            (s: TemplateOrGroup) => s.isTemplate ? node(s as ElementTemplate) : group(s as TemplateGroup),
            (s: TemplateOrGroup, t: any) => { t.label = s.key; });
    }

    mounted() {
        this.updateNodes();
    }

    toggleContextMenu(event: any, items: any[]) {
        let newIndex = this.contextIndex == 2 ? 1 : 2;
        let oldOverlay: any = this.$refs[`context_menu_${this.contextIndex}`];
        let newOverlay: any = this.$refs[`context_menu_${newIndex}`];

        if (Array.isArray(oldOverlay)) oldOverlay = oldOverlay[0];
        if (Array.isArray(newOverlay)) newOverlay = newOverlay[0];
        
        if (this.contextItems == items) {
            oldOverlay.show(event);
        }
        else {
            oldOverlay?.hide();
            newOverlay.show(event);

            this.contextIndex = newIndex;
            this.contextItems = items;
        }
    }

    openTreeContextMenu(event: any, context?: TemplateOrGroup) {
        let $this = this;
        let items = [
            {
                label: 'New Group (in root)',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                    $this.newGroup();
                }
            },
            {
                label: 'New Template (in root)',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                    $this.newTemplate();
                }
            },
        ];

        if (context != null && context.isGroup) {
            let group = context as TemplateGroup;
            items.unshift(
                {
                    label: `New Group (in ${context.key})`,
                    icon: 'pi pi-fw pi-plus',
                    command: () => {
                        let path = TemplateGroup.getPath(context);
                        group.childGroups.push(new TemplateGroup(path));
                    }
                },{
                    label: `New Template (in ${context.key})`,
                    icon: 'pi pi-fw pi-plus',
                    command: () => {
                        let path = TemplateGroup.getPath(context);
                        group.children.push(new ElementTemplate(TemplateTypes.Line, path));
                    }
                }
            );
        }

        this.toggleContextMenu(event, items);
    }

    nodeSelected(node: {data: TemplateOrGroup, key: string}) {
        this.current = node.data;
    }

    newTemplate() {
        this.templates.push(new ElementTemplate(TemplateTypes.Line, ''));
    }

    newGroup() {
        this.templates.push(new TemplateGroup(''));
    }

    deleteTemplate(slotProps: {index: number, node: {data: TemplateOrGroup}}) {
        let data: TemplateOrGroup = slotProps.node.data;
        let success = TemplateGroup.findAndRemove(data, this.templates);
        if (!success) {
            this.toaster.warn('Error Deleting Template/Group: Item Not Found');
        }
        else {
            let delKey = data.key;
            let $this = this;
            setTimeout(() => {
                if ($this.current?.key == delKey) $this.current = null;
            }, 1);
        }
    }

    generate() {
        this.genWarning = "";
        this.result = null;
        if (!this.current) {
            this.genWarning = "No generator is selected";
        }
        else {
            let generator = new Generator(this.toaster);
            generator.templates.push(...this.templates);

            let path = TemplateGroup.getPath(this.current);
            this.result = generator.generate(path);
        }
    }

    save() {
        if (this.authInput?.trim().length > 0) {
            console.log(this.authInput)
            LoreGenDAO.patch2(this.templates, this.authInput).then(x => {
                if (x.status == 204) {
                    this.toaster.success('Saved!');
                }
                else if (x.status == 200) {
                    this.toaster.success('Up to Date!');
                }
                else {
                    this.toaster.warn(`${x.status}`, x.statusText);
                }
            })
            .catch(x => {
                this.toaster.error('Save failed');
                console.error(x);
            });
        }
        else {
            this.toaster.warn('Enter Auth Code First')
        }
    }

    /* TODO
    get loreGen(): LoreGen {
        return (this.$root as any).loreGen;
    }
    */

    get templates(): TemplateOrGroup[] {
        return (this.$root as any).generatorTemplates;
    }

    get noTemplates() {
        return this.templates.length == 0;
    }

    get isLoading() {
        return false;
    }

    get resultMD() {
        return this.result == null ? null : this.convertMD(this.result);
    }

    get resultVars() {
        if (this.result?.variables == null) return [];
        else return Object.entries(this.result.variables);
    }

    convertMD(segment: Segment) {
        return segment.displayText.replace(/\$/g, '\\$').replace(/\_/g, '\\_');
    }
}

function node(data: ElementTemplate) {
    return {
        key: data.id,
        label: data.key,
        data: data
    };
}
function group(data: TemplateGroup) {
    let treenode = {
        key: data.id,
        label: data.key,
        data: data,
        children: [] as any[],
        leaf: false,
    };
    for (let childGroup of data.childGroups) {
        treenode.children.push(group(childGroup));
    }
    for (let child of data.children) {
        treenode.children.push(node(child));
    }

    return treenode;
}
