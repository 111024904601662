import Randomizer from "@/ts/util/Randomizer";
import ItemEntry, { FirearmEra, ItemCategory, ItemFields, SimpleCategories } from '../api/items/ItemEntry';
import { NumberSetting } from "../util/config/Setting";
import LoreGen from "../util/loregen/LoreGen";
import Segment from '../util/loregen/Segment';
import { PFSources } from "../util/pfmath/PFSources";
import Utils from "../util/Utils";
import { CategoryWeights } from "./CategoryWeights";
import { ItemWorkshop } from "./LootGenerator";

export interface Shop {
    lore: Segment;
    baseValue: number,
    purchaseLimit: number,
    spellcastingLimit: number,
    discountThreshold: number,
    inventory: ItemEntry[],
    basicInventory: ItemEntry[],
}

export enum SettlementSize {
    Thorpe = "Thorpe",
    Hamlet = "Hamlet",
    Village = "Village",
    SmallTown = "Small Town",
    LargeTown = "Large Town",
    SmallCity = "Small City",
    LargeCity = "Large City",
    Metropolis = "Metropolis",
}

export class Settlements {
    static _baseValues = {
        [SettlementSize.Thorpe]: 50,
        [SettlementSize.Hamlet]: 200,
        [SettlementSize.Village]: 500,
        [SettlementSize.SmallTown]: 1000,
        [SettlementSize.LargeTown]: 2000,
        [SettlementSize.SmallCity]: 4000,
        [SettlementSize.LargeCity]: 8000,
        [SettlementSize.Metropolis]: 16000,
    };
    
    static _purchaseLimits = {
        [SettlementSize.Thorpe]: 500,
        [SettlementSize.Hamlet]: 1000,
        [SettlementSize.Village]: 2500,
        [SettlementSize.SmallTown]: 5000,
        [SettlementSize.LargeTown]: 10000,
        [SettlementSize.SmallCity]: 25000,
        [SettlementSize.LargeCity]: 50000,
        [SettlementSize.Metropolis]: 100000,
    };
    
    static _availableMagicItems = {
        [SettlementSize.Thorpe]: ['d4', '-', '-'],
        [SettlementSize.Hamlet]: ['d6', '-', '-'],
        [SettlementSize.Village]: ['2d4', 'd4', '-'],
        [SettlementSize.SmallTown]: ['3d4', 'd6', '-'],
        [SettlementSize.LargeTown]: ['3d4', '2d4', 'd4'],
        [SettlementSize.SmallCity]: ['4d4', '3d4', 'd6'],
        [SettlementSize.LargeCity]: ['4d4', '3d4', '2d4'],
        [SettlementSize.Metropolis]: ['*', '4d4', '3d4'],
    };
    
    static _slLimits = {
        [SettlementSize.Thorpe]: 1,
        [SettlementSize.Hamlet]: 2,
        [SettlementSize.Village]: 3,
        [SettlementSize.SmallTown]: 4,
        [SettlementSize.LargeTown]: 5,
        [SettlementSize.SmallCity]: 6,
        [SettlementSize.LargeCity]: 7,
        [SettlementSize.Metropolis]: 8,
    };

    static getBaseValue(size: SettlementSize) {
        return Settlements._baseValues[size];
    }

    static getPurchaseLimit(size: SettlementSize) {
        return Settlements._purchaseLimits[size];
    }

    static getSpellcastingLimit(size: SettlementSize) {
        return Settlements._slLimits[size];
    }

    static getMinorCount(size: SettlementSize, rnd: Randomizer) {
        let str = Settlements._availableMagicItems[size][0];
        if (str == '*') return '*';
        if (str == '-') return 0;
        else return rnd.roll(str);
    }

    static getMediumCount(size: SettlementSize, rnd: Randomizer) {
        let str = Settlements._availableMagicItems[size][1];
        if (str == '*') return '*';
        if (str == '-') return 0;
        else return rnd.roll(str);
    }

    static getMajorCount(size: SettlementSize, rnd: Randomizer) {
        let str = Settlements._availableMagicItems[size][2];
        if (str == '*') return '*';
        if (str == '-') return 0;
        else return rnd.roll(str);
    }

    static getTotalItemCount(size: SettlementSize, rnd: Randomizer): number {
        let total = 0;
        for (let func of [Settlements.getMinorCount, Settlements.getMediumCount, Settlements.getMajorCount]) {
            let count = func(size, rnd);
            total += ((typeof count == 'string') ? rnd.roll('2d6') : count);
        }
        return total;
    }
}

export enum ShopType {
    Magic = "Magic",
    Alchemy = "Alchemy",
    General = "General",
    ArmsAndArmor = "Arms",
}

const ShopCategories = {
    [ShopType.Magic]: [
        ItemCategory.Rods,
        ItemCategory.Staves,
        ItemCategory.IounStones,
        ItemCategory.Potions_Oils,
        ItemCategory.WondrousItems,
        
        ItemCategory.ChannelFoci,
        ItemCategory.ShadowPiercing,
        
        ItemCategory.UniqueArmor,
        ItemCategory.UniqueWeapon,
        ItemCategory.UniqueShields,
        
        ItemCategory.ArmorQualities,
        ItemCategory.ShieldQualities,
        ItemCategory.MeleeWeaponQualities,
        ItemCategory.RangedWeaponQualities,

        ItemCategory.InteligentItem,
        
        '*Potion',
        '*Scroll',
        '*Wand',
    ],
    [ShopType.Alchemy]: [
        ItemCategory.Potions_Oils,
        ItemCategory.AlchemicalRemedies,
        ItemCategory.AlchemicalTools,
        ItemCategory.Concoctions,
        ItemCategory.Tinctures,
        ItemCategory.Herbs,
        
        ItemCategory.AlchemicalAmmunition,
        ItemCategory.AlchemicalWeapons,

        ItemCategory.Dragoncraft,
        
        '*Potion',
        '*Recipies',
    ],
    [ShopType.ArmsAndArmor]: [
        ItemCategory.UniqueArmor,
        ItemCategory.UniqueWeapon,
        ItemCategory.UniqueShields,

        ItemCategory.ShieldQualities,
        ItemCategory.ArmorQualities,
        ItemCategory.MeleeWeaponQualities,
        ItemCategory.RangedWeaponQualities,
        
        ItemCategory.ArmorMods,
        ItemCategory.WeaponMods,
        
        ItemCategory.AlchemicalRemedies,
        ItemCategory.AlchemicalTools,
        ItemCategory.Concoctions,
        ItemCategory.Tinctures,
        ItemCategory.Herbs,

        ItemCategory.FirearmsGear,
        
        ItemCategory.SiegeFirearms,
        ItemCategory.SiegeWeapons,
        ItemCategory.Explosives,
        ItemCategory.Firearms,
        ItemCategory.Weapons,
        ItemCategory.Shields,
        ItemCategory.Armor,

        ItemCategory.Ammunition,
        ItemCategory.SiegeAmmunition,
        ItemCategory.FirearmsAmmunition,
    ],
    [ShopType.General]: [
        ItemCategory.UniqueArmor,
        ItemCategory.UniqueWeapon,
        ItemCategory.UniqueShields,
        
        ItemCategory.Altars,
        ItemCategory.Favours,
        ItemCategory.WondrousItems,
        ItemCategory.InteligentItem,
        
        ItemCategory.Staves,
        ItemCategory.Rods,
        ItemCategory.IounStones,
        ItemCategory.MagicalTattoo,
        ItemCategory.ShadowPiercing,
        ItemCategory.Potions_Oils,

        ItemCategory.ShieldQualities,
        ItemCategory.ArmorQualities,
        ItemCategory.MeleeWeaponQualities,
        ItemCategory.RangedWeaponQualities,
        
        ItemCategory.ArmorMods,
        ItemCategory.WeaponMods,

        ItemCategory.AlchemicalRemedies,
        ItemCategory.AlchemicalTools,
        ItemCategory.Concoctions,
        ItemCategory.Tinctures,
        ItemCategory.Herbs,

        ItemCategory.Dragoncraft,
        ItemCategory.AnimalGear,
        ItemCategory.AdventuringGear,
        ItemCategory.BlackMarket,
        ItemCategory.ChannelFoci,
        ItemCategory.Clothing,
        ItemCategory.Entertainment,
        ItemCategory.Food_Drink,
        ItemCategory.Kits,
        ItemCategory.Tools,
        ItemCategory.TortureImplements,

        ItemCategory.FirearmsGear,
        ItemCategory.Vehicles,
        
        ItemCategory.AlchemicalAmmunition,
        ItemCategory.AlchemicalWeapons,
        ItemCategory.SiegeFirearms,
        ItemCategory.SiegeWeapons,
        ItemCategory.Explosives,
        ItemCategory.Firearms,
        ItemCategory.Weapons,
        ItemCategory.Shields,
        ItemCategory.Armor,

        ItemCategory.Ammunition,
        ItemCategory.SiegeAmmunition,
        ItemCategory.FirearmsAmmunition,
        
        '*Potion',
        '*Scroll',
        '*Wand',
    ]
}

export class ShopConfig {
    settlementSize = SettlementSize.LargeTown;
    shopType = ShopType.General;
    loreGen: LoreGen;

    earlyFirearms = true;
    advancedFirearms = false;
    modernFirearms = false;

    magicItems = true;
    mundaneItems = true;
    alchemicalItems = true;
    regionalItems = true;
    
    categoryWeights = new CategoryWeights();
    intelligentChance = new NumberSetting('Intelligent %', 'The chance (in %) that any given shop will include an intelligent item.', 0, 100).setInitial(1);

    constructor(loreGen: LoreGen) {
        this.loreGen = loreGen;
    }
}

export default class ShopGenerator {
    // ueGenerator: UELootGenerator;
    random: Randomizer;

    constructor(seed="") {
        this.random = new Randomizer(seed);
    }

    generate(config: ShopConfig, itemDirectory: ItemEntry[]): Shop {
        let rnd = this.random;

        let baseValue = Settlements.getBaseValue(config.settlementSize);
        let purchaseLimit = Settlements.getPurchaseLimit(config.settlementSize);
        let spellcastingLimit = Settlements.getSpellcastingLimit(config.settlementSize);
        let discountThreshold = rnd.int(0, 10) + (rnd.int(0, 10) == 0 ? rnd.int(0, 10) : 0);

        let upperLimit = purchaseLimit*2;
        
        let normalInventory = rollInventory(rnd, config, itemDirectory, baseValue, upperLimit);
        let genIntItem = rnd.chance(config.intelligentChance.value ?? 0);
        if (genIntItem && normalInventory.length > 0) {
            // TODO: Do not make consumables intelligent
            let index = rnd.int(0, normalInventory.length - 1);
            let item = ItemWorkshop.makeIntelligent(normalInventory[index], rnd);
            normalInventory.splice(index, 1);
            normalInventory.unshift(item);
        }

        return {
            lore: config.loreGen.generateOne(`Shops 2`, { type: config.shopType }),
            baseValue,
            purchaseLimit,
            spellcastingLimit,
            discountThreshold,
            inventory: normalInventory,
            basicInventory: rollInventory(rnd, config, itemDirectory, -1, baseValue, upperLimit),
        };
    }
}

function rollInventory(rnd: Randomizer, config: ShopConfig, itemDirectory: ItemEntry[], baseValue: number, upperLimit: number, maxUpper?: number) {
    let validCategories: string[] = ShopCategories[config.shopType];
    let absPriceFilter = itemDirectory.filter(item => {
        if (!config.earlyFirearms && item.era == FirearmEra.Early) return false;
        if (!config.modernFirearms && item.era == FirearmEra.Modern) return false;
        if (!config.advancedFirearms && item.era == FirearmEra.Advanced) return false;
        
        if (!config.magicItems && ItemFields.isMagic(item)) return false;
        if (!config.mundaneItems && ItemFields.isMundane(item)) return false;
        if (!config.alchemicalItems && ItemFields.isAlchemical(item)) return false;
        if (!config.regionalItems && item.sources?.every(x => PFSources.isRegional(x))) return false;

        let price = ItemFields.parsePrice(item);
        return validCategories.includes(item.itemCategory) && price <= (maxUpper ?? upperLimit);
    });

    let table: {weight: number, text: SimpleCategories}[] = config.categoryWeights.rollableTable;
    let itemCount = Settlements.getTotalItemCount(config.settlementSize, rnd);
    let inventory: ItemEntry[] = [];
    // TODO: Possible infinite loop if filters are overly restrictive
    for (let i = 0; i < itemCount; i++) {
        let category = rnd.randomize(table);
        let largeFilter = (category == undefined) ? absPriceFilter : absPriceFilter.filter(x => ItemFields.simpleCategories(x).includes(category as SimpleCategories));
        let smallFilter = largeFilter.filter(x => {
            let price = ItemFields.parsePrice(x);
            return price > baseValue;
        });

        let filter = (smallFilter.length < 8) ? largeFilter: smallFilter;
        let item = rnd.selection(filter);
        if (item != null) inventory.push(item);
    }

    return inventory.sort((a, b) => ItemFields.parsePrice(a) - ItemFields.parsePrice(b));
}