type Dynamic = {[key: string]: any};

/**
 * Base class for AppliedContent and ContentTemplate.
 * The fields in BaseContent are present in ContentTemplate, but are considered to be overwritable by AppliedContent.
 * (i.e. a user can update the name or description to their liking - shortening or adding notes where they please)
 */
export abstract class BaseContent {
    templateId: string;
    contentType: string;
    abilityType: string;
    
    name: string;
    description: string;
    shortDescription: string;
    
    categories: string[];
    tags: string[];

    constructor(json: Dynamic) {
        if (json == null) json = {};

        this.templateId = json.templateId ?? "";
        this.contentType = json.contentType ?? "";
        this.abilityType = json.abilityType ?? "";
        
        this.name = json.name ?? "";
        this.description = json.description ?? "";
        this.shortDescription = json.shortDescription ?? "";
        this.categories = json.categories ?? [];
        
        this.tags = json.tags ?? [];
    }
}