import Lexer, { LexToken } from "./lexer";
type Token = (LexToken|ParseToken);
type CombineFunction = (a: Token[]) => [ParseToken[], string];
type EvalFunction = (a: ParseToken, context: any) => any;
type TokenMatchFunction = (token: ParseToken) => boolean;

export class ParseToken {
    token_type: string;
    text: string;
    children: ParseToken[];
    eval_function: EvalFunction|null;

    constructor(token_type: string, text: string) {
        this.token_type = token_type;
        this.text = text;
        this.children = [] as ParseToken[];
        this.eval_function = null; // Accepts 2 arguments: this:ParseToken, context:object
    }

    evaluate(context: any) {
        if (this.eval_function == null) {
            return null;
        }
        else {
            let value = this.eval_function(this, context);
            if (value === NaN) {
                console.warn('ParseNode evaluated to NaN', this, context);
                // TODO: return undefined?
            }
            return value;
        }
    }

    find_all(match_function: TokenMatchFunction): ParseToken[] {
        let results = [] as ParseToken[];
        if (match_function(this)) {
            results.push(this);
        }

        for (let child of this.children) {
            results = results.concat(child.find_all(match_function));
        }

        return results;
    }
}

export class ParsePattern {
    token_type: string;
    regex: RegExp;
    combine: CombineFunction;
    evaluate_function: EvalFunction;
    priority: number;

    constructor(token_type: string, regex: RegExp, combine: CombineFunction, evaluate_function: EvalFunction, priority=0) {
        this.token_type = token_type;
        this.regex = regex;
        this.combine = combine;
        this.evaluate_function = evaluate_function;
        this.priority = priority;
    }

    build(lexTokens: Token[]) {
        let token = new ParseToken(this.token_type, '');
        let results = this.combine(lexTokens);
        token.children = results[0];
        token.text = results[1];
        token.eval_function = this.evaluate_function;

        return token;
    }
}

export default class Parser {
    lexer: Lexer;
    patterns: ParsePattern[];

    constructor(lexer: Lexer) {
        this.lexer = lexer;
        this.patterns = [] as ParsePattern[];
    }

    define(token_type: string, regex: RegExp, combine: CombineFunction, evaluate: EvalFunction, priority: number) {
        this.patterns.push(new ParsePattern(token_type, regex, combine, evaluate, priority));
    }

    parse(string: string) {
        let lex_tokens = this.lexer.lex(string);
        return this.parse_list(lex_tokens);
    }

    parse_list(lex_tokens: LexToken[]) {
        let tokens = this.process_groups(lex_tokens);
        while (true) {
            let lex_string = tokens.map(x => x.token_type).join(' ');
            let result = this.search(lex_string);
            if (result == null) {
                break;
            }
            else {
                let pattern = result[0];
                let args = tokens.slice(result[1], result[2])
                let node = pattern.build(args);

                tokens.splice(result[1], result[2] - result[1], node);
            }
        }

        // TODO: At the end, all tokens should be reduced to ParseTokens
        return tokens;
    }

    process_groups(lex_tokens: LexToken[]): Token[] {
        let tokens = [] as Token[];
        let top = lex_tokens.shift();
        while (top != null) {
            if (top.token_type == 'group_open') {
                let end_index = this.find_group_close(top, lex_tokens);
                let children = this.parse_list(lex_tokens.splice(0, end_index));
                lex_tokens.shift(); // Remove group close token

                let parse_token = new ParseToken("GROUP" + top.text, this.get_group_pair(top.text)) // text = '()' / '[]' / etc.
                parse_token.eval_function = (x, c) => x.children[0].evaluate(c); // TODO: Better group evaluation

                if (children.length > 1) {
                    throw "Invalid parse expression";
                }
                else if (children.length == 1) {
                    console.assert(children[0] instanceof ParseToken);
                    parse_token.children = children as ParseToken[];
                }
                tokens.push(parse_token);
            }
            else {
                tokens.push(top);
            }
            
            top = lex_tokens.shift();
        }

        return tokens;
    }

    search(string: string) {
        let hits = [] as [ParsePattern, number, number][];
        let earliest_start = string.length;
        let highest_priority = -999;
        for (let pattern of this.patterns) {
            let matches = string.match(pattern.regex);
            if (matches != null && matches.length > 0) {
                let match = matches[0];
                let start = string.indexOf(match);
                let end = start + match.length;

                if (start > 0 && string.charAt(start-1) != ' ') {
                    // TODO: maybe get rid of this and just skip the match?
                    throw "Parse mismatch: " + match;
                }

                let hit: [ParsePattern, number, number] = [pattern, start, end];
                hits.push(hit);

                if (start < earliest_start) {
                    earliest_start = start;
                }
                if (pattern.priority > highest_priority) {
                    highest_priority = pattern.priority;
                }
            }
        }

        if (hits.length == 0) {
            return null;
        }

        let competitors = hits.filter(x => x[0].priority == highest_priority);
        let hit = competitors[0];
        for (let competitor of competitors) {
            if (competitor[1] < hit[1]) {
                hit = competitor;
            }
        }

        // Convert string indexes to array indexes matching the corresponding token array
        let substring = string.substring(hit[1], hit[2]);
        let previous = string.substring(0, hit[1]);
        let start_index = this.num_words(previous);
        let end_index = start_index + this.num_words(substring);

        return [hit[0], start_index, end_index] as [ParsePattern, number, number];
    }

    find_group_close(open_token: LexToken, lex_tokens: LexToken[]): number {
        let count = 0;
        let open_text = open_token.text;
        let close_text = this.get_group_pair(open_token.text).charAt(1);

        for (let index = 0; index < lex_tokens.length; index++) {
            let item = lex_tokens[index];
            if (item.text == close_text) {
                if (count == 0) {
                    return index;
                }
                else {
                    count -= 1;
                }
            }
            else if (item.text == open_text) {
                count += 1;
            }
        }

        throw "No group close found. Open: " + count + "; Text: " + open_token.text
    }

    num_words(string: string): number {
        let trimmed = string.trim();
        if (trimmed.length == 0) return 0;
        else return trimmed.split(" ").length;
    }

    get_group_pair(member: string): string {
        if (member == '(' || member == ')') return '()';
        else if (member == '[' || member == ']') return '[]';
        else if (member == '{' || member == '}') return '{}';
        else {
            console.warn('Parser found no group for member: ' + member);
            return '';
        }
    }
}
