import Utils from "@/ts/util/Utils";

export class RoadmapEntry {
    type: RoadmapEntryTypes;
    name: string;
    id: string;

    icon: string = 'None';
    subtitle: string = '';
    complete: boolean = false;
    description: string = '';
    children: RoadmapEntry[] = [];

    constructor(name: string, type: RoadmapEntryTypes) {
        this.id = Utils.guid();
        this.name = name;
        this.type = type;
    }
}

export enum RoadmapEntryTypes {
    Phase,
    Release,
    Feature
}