

















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class Contact extends Vue {
}
