import {ContentDAO, ContentTemplate} from '@/ts/api/content/_index';
import {Grant} from '@/ts/api/grants/_index';

import Toaster from '@/ts/ui_integrations/Toaster';
import Utils from '@/ts/util/Utils';
import Vue from 'vue';

type ContentGroupMap = {[key:string]: ContentTemplate[]};
type ContentMap = {[key:string]: ContentTemplate};
type StringMap = {[key: string]: string};

// TODO: Support ContentPacks
export class ContentManager {
    contentByType: ContentGroupMap = {}; // [contentType : ContentTemplate[]]; only includes top-level content types
    contentById: ContentMap = {}; // [templateId : ContentTemplate]; only includes top-level content
    intake: StringMap = {}; // [templateId : serializedJSON]; only includes top-level content

    isLoaded = false;

    saving = false;
    overlap = false;
    editMarks: string[] = [];

    toaster: Toaster;

    constructor(toaster: Toaster) {
        this.toaster = toaster;
    }

    /**
     * Searches through all content (both roots and decendants) to find a ContentTemplate with the given templateId
     * @param templateId The templateId of the ContentTemplate to search for
     */
    get(templateId: string): ContentTemplate|null {
        let queue = Object.values(this.contentById);
        let template = queue.shift();
        while (template != null) {
            if (template.templateId == templateId) {
                return template;
            }
            else {
                queue = queue.concat(Object.values(template.children))
            }

            template = queue.shift();
        }

        return null;
    }

    /**
     * 
     * @param templateId Template ID of a ContentTemplate or Grant
     */
    getPath(templateId: string): (ContentTemplate|Grant)[]|null {
        for (let content of Object.values(this.contentById)) {
            let path = pathSearch(content, templateId);
            if (path != null) return path;
        }
    
        return null;
    }

    async init() {
        let allContent = await ContentDAO.Templates.getAll();
        allContent.sort((a: ContentTemplate, b: ContentTemplate) => Utils.strComp(a.name, b.name));

        // Set known group-types in advance, so they appear in the proper order // TODO: Ordering content types should be handled by the Vue instance - not the data layer.
        Vue.set(this.contentByType, 'race', []);
        Vue.set(this.contentByType, 'class', []);
        Vue.set(this.contentByType, 'feat', []);
        
        for (let content of allContent) {
            let type = content.contentType;
            if (!(type in this.contentByType)) {
                Vue.set(this.contentByType, type, []);
            }
    
            let group: ContentTemplate[] = this.contentByType[type];
            let id = content.templateId;
    
            Vue.set(this.intake, id, JSON.stringify(ContentDAO.Templates.createSerializable(content)));
            Vue.set(this.contentById, id, content);
            group.push(content);
        }

        this.isLoaded = true;
    }

    getRootAncestor(content: ContentTemplate): ContentTemplate {
        let id = content.templateId;
        if (id in this.contentById) {
            return this.contentById[id];
        }
        else {
            let filter = Object.values(this.contentById as ContentMap).filter(x => id in x.children);
            console.assert(filter.length == 1, 'Duplicate ids found', id, filter);
            return filter[0];
        }
    }
    
    markEdit(content: ContentTemplate) {
        if (content!= null && !this.editMarks.includes(content.templateId)) {
            let rootAncestor = this.getRootAncestor(content);
            let intake = this.intake[rootAncestor.templateId];
            let outgoing = JSON.stringify(ContentDAO.Templates.createSerializable(rootAncestor));
            if (intake != outgoing) {
                this.editMarks.push(content.templateId);
            }
        }
    }

    hasEditMark(content: ContentTemplate) {
        if (this.editMarks.includes(content.templateId)) {
            return true;
        }
        else {
            for (let child of Object.values(content.children)) {
                if (this.hasEditMark(child)) return true;
            }
            return false;
        }
    }
    
    async save(autosave: boolean = false) {
        // TODO: Remove this
        return;
        // TODO: Only save on the root manager
        autosave = (autosave === true);
        if (this.editMarks.length == 0) {
            console.log(`No changes (${autosave ? 'autosave' : 'save'})`);
            if (!autosave) {
                this.toaster.success('Up to date!', null, 1000);
            }
            return;
        }

        console.log(autosave ? 'autosave' : 'save');
        if (this.saving) {
            this.overlap = true;
            console.log('-Overlap; exiting save');
            return;
        }
        
        if (autosave) this.toaster.info('Autosaving...');

        this.saving = true;
        let contentById: ContentMap = this.contentById;
        let table: any = {};
        let successCount = 0;
        for (let [key, content] of Object.entries(contentById)) {
            if (this.hasEditMark(content)) {
                let status: string|number = 'failed';
                try {
                    let res = await ContentDAO.Templates.patch(content);
                    status = res.status;
                    if (status >= 300) {
                        this.toaster.error(`Error saving ${content.name}`, `${content.templateId}`);
                    }
                    else {
                        Vue.set(this.intake, content.templateId, JSON.stringify(ContentDAO.Templates.createSerializable(content)));
                        successCount += 1;
                    }
                }
                catch (err) {
                    console.error(err);
                    this.toaster.error(`Error saving ${content.name}`, `${content.templateId}`);
                }
                table[key] = {
                    'name': content.name,
                    'type': content.contentType,
                    'status': status
                };
            }
        }
        console.table(table);
        console.log('complete');
        if (successCount > 0) this.toaster.success('Saved!', `${successCount} items saves`);
        
        let edits = this.editMarks;
        this.editMarks = [];
        for (let id of edits) {
            let content = this.contentById[id];
            this.markEdit(content);
        }

        this.saving = false;
        if (this.overlap) {
            this.overlap = false;
            this.save();
        }
    }
}
    
function pathSearch(parent: ContentTemplate|Grant, searchId: string): (ContentTemplate|Grant)[]|null {
    if (parent.templateId == searchId) {
        return [parent];
    }

    let dyn = parent as any;
    if ('grants' in dyn) {
        let grants = Object.values(dyn.grants) as Grant[];
        for (let x of grants) {
            let subpath = pathSearch(x, searchId);
            if (subpath != null) {
                let path = [parent];
                return path.concat(subpath);
            }
        }
    }
    if (parent instanceof ContentTemplate) {
        let children = Object.values(parent.children);
        for (let x of children) {
            let subpath = pathSearch(x, searchId);
            if (subpath != null) {
                let path = [parent] as (ContentTemplate|Grant)[];
                return path.concat(subpath);
            }
        }
    }

    return null;
}