



















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import AdvancedSearch from '@/ui/AdvancedSearch/AdvancedSearch.vue';
import TableResults from '@/ui/AdvancedSearch/TableResults.vue';
import CardResults from '@/ui/AdvancedSearch/CardResults.vue';

import { CardResultsConfig } from '@/ui/AdvancedSearch/CardResultsConfig';
import BestiaryEntry, { BestiaryDisplays } from '@/ts/api/bestiary/BestiaryEntry';
import { SmallMode, TableConfig } from './TableConfig';

@Component({
    components: {
        AdvancedSearch,
        CardResults,
        TableResults,
    }
})
export default class AdvancedMonsterSearch extends Vue {
    cardConfig(big: boolean): CardResultsConfig<BestiaryEntry> {
        return {
            big,
            sortAlgorithms: BestiaryDisplays.sortAlgorithms,
            getConfig: BestiaryDisplays.getCardConfig,
        };
    }

    tableConfig(): TableConfig<BestiaryEntry> {
        return {
            getSearchTerm: BestiaryDisplays.searchTerm,
            getConfig: BestiaryDisplays.getCardConfig,
            getLinks: item => item.links,
            columns: [
                {
                    name: "Name",
                    field: "name",
                    smMode: SmallMode.Compress,
                    display: entry => entry.name,
                    isSortable: true,
                },
                {
                    name: "CR",
                    field: "_cr",
                    smMode: SmallMode.Hide,
                    display: entry => entry.cr,
                    isSortable: true,
                },
                {
                    name: "HP",
                    field: "stats.hp",
                    smMode: SmallMode.Hide,
                    display: entry => entry.stats?.hp?.toString(),
                    isSortable: true,
                },
                {
                    name: "Type",
                    field: "type",
                    smMode: SmallMode.Hide,
                    display: entry => entry.type,
                    isSortable: true,
                },
                {
                    name: "Size",
                    field: "size",
                    smMode: SmallMode.Hide,
                    display: entry => entry.size,
                    isSortable: true,
                },
                {
                    name: "Sources",
                    field: "",
                    smMode: SmallMode.Hide,
                    display(entry: BestiaryEntry) {
                        return formatSourceList(entry.sources);
                    },
                    isMD: true,
                },
            ],
        };
    }
}

function formatSourceList(list: string[]) {
    return list?.map(x => x.split('pg.')[0].trim()).join('; \n\n') ?? '';
}
