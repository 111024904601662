import {Value} from '@/ts/api/characters/_index';
import DataUtils from '@/ts/util/DataUtils';

type StatMap = {[key: string]: Stat};
type Dynamic = {[key: string]: any};

export class Stat extends Value {
    constructor(json: Dynamic) {
        if (json == null) json = {};
        super(json);
    }

    get isStat() { return true; }
    get isBonus() { return false; }

    static instantiate(obj: any): StatMap {
        return DataUtils.initMap(obj, 'refName', x => new Stat(x), 'Stat');
    }
}