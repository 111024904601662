import SanityCheck from '@/ts/util/SanityCheck';
import Utils from '@/ts/util/Utils';
type Dynamic = {[key: string]: any};

export default class DataUtils {
    static initMap<T>(obj: any, idField: string, create: (json: Dynamic) => T, objTypeName: string, sanityCheck=true): {[key:string]: T} {
        let map: {[key:string]: T} = {};
        if (obj == null) {
            // console.warn('Attempted to instantiate map from null', {obj, idField, objTypeName});
        }
        else if (Array.isArray(obj)) {
            for (let value of obj) {
                let key = value[idField];
                console.assert(!Utils.isEmpty(key), 'Attempted to instantiate object without id', {value, idField, objTypeName});
                map[key] = create(value);
                if (sanityCheck) SanityCheck.objFieldCompare(map[key], value, objTypeName);
            }

            return map;
        }
        else if (typeof obj === 'object') {
            for (let key of Object.keys(obj)) {
                let value: any = obj[key];
                if (Utils.isEmpty(value[idField])) {
                    value[idField] = key;
                }
                console.assert(key == value[idField], "Expected the mapKey to match the object's key.", {key, valueId: value[idField], value, idField, objTypeName});
                map[key] = create(value);
                if (sanityCheck) SanityCheck.objFieldCompare(map[key], value, objTypeName);
            }

            return map;
        }
        else {
            console.warn(`Attempted to instantiate map from unexpected type: ${typeof obj}`, {obj, objTypeName});
        }
        
        return map;
    }
}