import {ContentTemplate} from '@/ts/api/content/_index';

type Dynamic = {[key: string]: any};

export class ClassFeatureTemplate extends ContentTemplate {
    levelStart: number;

    constructor(json: Dynamic) {
        super(json);
        this.levelStart = json.levelStart ?? 0;
    }
}