






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Utils from '@/ts/util/Utils';

@Component({
    components: {
    }
})
export default class NumInput extends Vue {
    @Prop({required: true})
    value!: number;

    stringValue = `${this.value}`;
    previousValue = this.value;

    @Watch('stringValue')
    onStringValueChange() {
        // TODO
    }

    @Watch('value')
    onValueChange() {
        this.stringValue = `${this.value}`;
        this.previousValue = this.value;
    }

    set(num: number, add: boolean) {
        let newValue = add ? (this.value + num) : num;
        this.stringValue = `${newValue}`;

        if (newValue != this.value) {
            this.$emit('input', newValue);
        }
    }

    syncValue() {
        let str = this.stringValue?.trim() ?? '';
        if (str == '') {
            this.set(this.previousValue, false);
            return;
        }

        let add = str.startsWith('+') || str.startsWith('-');
        if (str.startsWith('+')) {
            str = Utils.trim(this.stringValue, '+');
        }
        if (str.startsWith('--') || str.startsWith('\'-')) {
            str = str.slice(1);
            add = false;
        }

        // TODO: Support decimals?
        let num = parseInt(str);
        if (isNaN(num)) {
            // TODO: Toast warning
            console.warn('User input was NaN', {str});
            num = this.previousValue;
        }

        this.set(num, add);
    }

    onKeypress(event: any) {
        if (event.keyCode == 13) {
            // Enter Pressed
            this.syncValue();
            this.$nextTick(() => event.target.blur());
        }
    }

    onBlur(event: any) {
        this.syncValue();
    }

    onFocus(event: any) {
        this.previousValue = this.value;
        this.stringValue = '';
    }
}
