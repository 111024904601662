









































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import { GamebookSession } from '@/ts/gamebooks/GamebookSession';
import { Gamebook } from '@/ts/gamebooks/parsing/GamebookParser';
import { CombatScene } from '@/ts/gamebooks/parsing/Scene';
import { CombatSession, EnemyInstance } from '@/ts/gamebooks/CombatSession';
import Toaster from '@/ts/ui_integrations/Toaster';
import Utils from '@/ts/util/Utils';
import { DiceRoll, SceneLogEntry } from '@/ts/gamebooks/SceneLog';

@Component({
    components: {
    }
})
export default class GamebookCombatManager extends Vue {
    @Prop({required: true})
    scene!: CombatScene;

    @Prop({required: true})
    session!: GamebookSession;
    
    @Prop({required: true})
    combatSession!: CombatSession;

    activeTab = 'init';
    toaster = new Toaster(this.$toast.add);

    get gamebook(): Gamebook {
        return this.session.gamebook;
    }

    get nameList() {
        let names = this.combatSession.enemies.map(x => x.name);
        names.splice(this.combatSession.playerTurn, 0, 'Player');
        return names;
    }

    get hasDefeatedEnemies() {
        return this.combatSession.enemies.some(x => x.isDefeated);
    }

    viewEnemy(enemy: EnemyInstance) {
        // TODO
        this.toaster.info('Enemy view is coming soon!');
    }

    isDefeated(index: number) {
        let pIndex = this.combatSession.playerTurn;
        if (index == pIndex) return false;
        else {
            index = (index < pIndex) ? index : (index - 1);
            return this.combatSession.enemies[index].isDefeated;
        }
    }

    rollInit() {
        let log = this.session.sceneLog;
        let rollInit = (name: string, dex: number) => {
            dex = dex ?? 0;
            if (dex == undefined || isNaN(dex)) {
                console.warn('Unexpected dex for ' + name, {dex});
            }

            let init = Math.floor((dex - 10) / 2);
            let macroText = `d20+${init}`;
            
            log.push(`${Utils.possessiveForm(name)} initiative:`);
            let entry = log.push(`${macroText} 🠖 [[${macroText}]]`);
            let result = (entry.inlineSegments.filter(x => typeof x != 'string').pop() as DiceRoll)?.result;
            if (result == undefined) {
                this.toaster.warn(`Unable to roll init for ${name}`);
                console.warn(`Unable to roll init for ${name}`, {entry});
            }

            return result ?? 0;
        };

        this.combatSession.playerInitiative = rollInit('Player', ((this.session.player.init ?? 0)*2) + 10);
        for (let enemy of this.combatSession.enemies) {
            enemy.initiative = rollInit(enemy.name, enemy.enemy.dex);
        }
        
        this.combatSession.sortInitiatives();
    }

    restoreAll() {
        for (let enemy of this.combatSession.enemies) {
            this.restore(enemy);
        }
    }

    restore(enemy: EnemyInstance) {
            enemy.isDefeated = false;
    }

    sort() {
        if (this.combatSession.isUnsortable) {
            this.toaster.warn('Unset initiatives cannot be sorted!', 'It looks like all initiative values are still set to 0. Set the values manually, or click Roll Initiative', 5000);
        }
        else this.combatSession.sortInitiatives();
    }

    reset() {
        // TODO
        this.combatSession.resetTurnCounter();
        this.toaster.info('Turn/Round counter was reset!', 'Healthbars, conditions, and defeated enemies must be reset manually. Full reset funtionality is coming soon.', 5000);
    }

    nextTurn() {
        this.combatSession?.nextTurn();
    }

    openDefeatedContextMenu(event: any, enemy: EnemyInstance) {
        let items = [
            {
                label: 'Restore Enemy',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    enemy.isDefeated = false;
                }
            },
        ];
        (this as any).$toggleContextMenu(event, items);
    }
}
